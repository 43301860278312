import axios from 'axios';
import { axiosOptionsProps, returnUrlConfigProps } from './types';

export const getvaluationReport: (
  options: axiosOptionsProps,
  _callback: (arg: string) => void,
  resConfig: {
    api_request_url: string;
    queryApproaches: string;
  },
) => Promise<
  | {
      valuation_report_id: string;
      download_token: string;
    }
  | undefined
  | void
> = (options, _callback, resConfig) => {
  return axios(options)
    .then((res) => {
      if (res.status === 200) {
        _callback(
          `${resConfig.api_request_url}/valuations/reports_download/${res?.data.valuation_report_id}?download_token=${res?.data.download_token}&write_approachs=${resConfig.queryApproaches}`,
        );
      } else {
        console.log(res);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postCopyCalculation: (
  options: axiosOptionsProps,
  path: returnUrlConfigProps,
) => void = (options, path) => {
  return axios(options)
    .then((res) => {
      if (res.data.status === 'success') {
        location.href = `/reports/${path.report_id}/formula/${path.calculation_result_type}/${res.data.calculation_id}?ocr=${res.data.ocr}`;
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};
