import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

type OcrResultRowProp = {
  inheritanceValuationId: string;
  pageId: string;
};

export const OcrResultRow: React.FC<OcrResultRowProp> = React.memo(
  (props: OcrResultRowProp) => {
    const { inheritanceValuationId, pageId } = props;

    const [image, setImage] = useState('');

    // @ts-ignore
    const [timerId] = useState<NodeJS.Timeout | number>(0);
    const refTimerId = useRef(timerId);

    useEffect(() => {
      refTimerId.current = timerId;
    }, [timerId]);

    // TODO: ソースコードが重複しているのでまとめてください
    const setPdf = useCallback(() => {
      axios
        .get(
          `/inheritances/${inheritanceValuationId}/ocr/analysis_fs/${pageId}/page_pdf`,
        )
        .then((res) => {
          setImage(`data:application/pdf;base64,${res.data}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [pageId, inheritanceValuationId]);

    useEffect(() => {
      // @ts-ignore
      setPdf();
    }, [setPdf]);

    return (
      <div className="page_result h-5/6 flex flex-wrap mb-10 pt-10">
        <div className="w-full h-full">
          <embed
            className="pdf"
            type="application/pdf"
            src={image}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    );
  },
);
