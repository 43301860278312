import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { OcrResultRowProp } from './types';

export const OcrResultRow: React.FC<OcrResultRowProp> = (props) => {
  const { reportId, pageId } = props;
  const [image, setImage] = useState<string>('');
  // @ts-ignore
  const [timerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);

  const setPdf = useCallback(() => {
    axios
      .get(`/company_medical_reports/${reportId}/ocr/file_pdfs/${pageId}`)
      .then((res) => {
        setImage(`data:application/pdf;base64,${res.data}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageId, reportId]);

  useEffect(() => {
    // @ts-ignore
    setPdf();
  }, [setPdf]);

  return (
    <div className="page_result flex flex-wrap mb-10 pt-10">
      <div className="w-full">
        <embed
          type="application/pdf"
          src={`${image}#view=FitV&pagemode=none&toolbar=0&navpanes=0&scrollbar=0`}
          width="100%"
          height="720"
        />
      </div>
    </div>
  );
};
