import React, { useCallback, useState } from 'react';

export const PdfViewer: React.FC<{ thumbnail: string; isSelected: boolean }> = (
  props,
) => {
  const { thumbnail, isSelected } = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <>
      <div className="relative">
        <embed
          className="relative shadow-lg bg-white w-full"
          type="application/pdf"
          src={`data:application/pdf;base64,${thumbnail}#view=FitV&pagemode=none&toolbar=0&navpanes=0&scrollbar=0`}
          height="398"
        />
        {isSelected && (
          <span className="absolute right-2 top-2 inline-flex items-center justify-center h-6 w-6 rounded-full bg-green-500">
            <svg
              className="h-4 w-4 text-white font-bold"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </span>
        )}
        <svg
          onClick={handleModal}
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="expand-arrows-alt"
          className="absolute right-2 bottom-2 w-1/5 opacity-50 hover:opacity-100 cursor-pointer svg-inline--fa fa-expand-arrows-alt fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M448 344v112a23.94 23.94 0 0 1-24 24H312c-21.39 0-32.09-25.9-17-41l36.2-36.2L224 295.6 116.77 402.9 153 439c15.09 15.1 4.39 41-17 41H24a23.94 23.94 0 0 1-24-24V344c0-21.4 25.89-32.1 41-17l36.19 36.2L184.46 256 77.18 148.7 41 185c-15.1 15.1-41 4.4-41-17V56a23.94 23.94 0 0 1 24-24h112c21.39 0 32.09 25.9 17 41l-36.2 36.2L224 216.4l107.23-107.3L295 73c-15.09-15.1-4.39-41 17-41h112a23.94 23.94 0 0 1 24 24v112c0 21.4-25.89 32.1-41 17l-36.19-36.2L263.54 256l107.28 107.3L407 327.1c15.1-15.2 41-4.5 41 16.9z"
          ></path>
        </svg>
      </div>
      {isOpen && (
        <div
          onClick={handleModal}
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
            <embed
              className="relative shadow-lg bg-white"
              type="application/pdf"
              src={`data:application/pdf;base64,${thumbnail}#view=FitV&pagemode=none&toolbar=0&navpanes=0&scrollbar=0`}
              width="400"
              height="558"
            />
          </div>
        </div>
      )}
    </>
  );
};
