import React, { useEffect } from 'react';
import axios from 'axios';

type SimilaritiesFormProp = {
  inheritanceValuationId: number;
};

export const SimimilaritiesForm: React.FC<SimilaritiesFormProp> = (
  props: SimilaritiesFormProp,
) => {
  const { inheritanceValuationId } = props;
  const form = document.getElementById('similarities_industry_form');

  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitMaForm') {
          axios
            .post(
              `/inheritances/${inheritanceValuationId}/similarities`,
              event.data,
              {
                headers: {
                  'X-CSRF-Token':
                    // @ts-ignore
                    form.parentElement.getElementsByTagName('input')[0].value,
                },
              },
            )
            .then((res) => {
              if (res.status === 200) {
                location.href = `/inheritances/${inheritanceValuationId}`;
              } else {
                console.log(res);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          event.data &&
          event.data.name === 'cancelMaForm' &&
          event.data.value === 'canceled'
        ) {
          location.href = '/homes/functions';
        }
      },
      false,
    );
  }, [
    // @ts-ignore
    form.parentElement,
    inheritanceValuationId,
  ]);

  return <></>;
};
