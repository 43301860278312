import { useCallback, useState } from 'react';
import { getvaluationReport, postCopyCalculation } from './api';
import {
  UsedSimilaritiesResultsProps,
  ValuationReportCalculationProps,
  axiosOptionsProps,
  returnUrlConfigProps,
  useReportCalculationsStepProps,
  useReportDownloadButtonProps,
  valuationReportProps,
} from './types';

export const useReports = () => {
  const [deleteReportId, setDeleteReportId] = useState<number>(0);
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const showDeleteModal = useCallback(
    (report_id: number): void => {
      setDeleteReportId(report_id);
      setModalCheck(!modalCheck);
    },
    [modalCheck, setDeleteReportId],
  );
  const showUpdatedTime = useCallback((update_time: string): string => {
    const reportUpdatedDate: Date = new Date(update_time);
    const reportUpdatedYear: number = reportUpdatedDate.getFullYear();
    const reportUpdatedMonth: number = reportUpdatedDate.getMonth() + 1;
    const reportUpdatedDay: number = reportUpdatedDate.getDate();
    return `${reportUpdatedYear}年${reportUpdatedMonth}月${reportUpdatedDay}日`;
  }, []);
  const showFinancialDate = useCallback((update_time: string): string => {
    const reportUpdatedDate: Date = new Date(update_time);
    const reportUpdatedYear: number = reportUpdatedDate.getFullYear();
    const reportUpdatedMonth: number = reportUpdatedDate.getMonth() + 1;
    return `${reportUpdatedYear}年${reportUpdatedMonth}月期`;
  }, []);
  return {
    deleteReportId,
    setDeleteReportId,
    modalCheck,
    setModalCheck,
    showDeleteModal,
    showUpdatedTime,
    showFinancialDate,
  };
};

export const useReportDetails = (
  usedSimilaritiesResults: UsedSimilaritiesResultsProps[],
) => {
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const checkUsedSimilaritiesResults = useCallback(() => {
    switch (usedSimilaritiesResults.length) {
      case 3:
        setModalCheck(true);
        setModalMessage(
          'STEP3の株式価値算定に利用されている類似企業が全て異なります',
        );
        break;
      case 2:
        setModalCheck(true);
        setModalMessage(`STEP3の株式価値算定に利用されている類似企業が${usedSimilaritiesResults[0]['valuation']}と、
          ${usedSimilaritiesResults[1]['valuation']}で異なります。`);
        break;
      case 1:
        setModalCheck(true);
        setModalMessage(
          `STEP3の${usedSimilaritiesResults[0]['valuation']}で利用されている類似企業が異なります。`,
        );
        break;
      default:
        break;
    }
  }, [usedSimilaritiesResults]);
  return {
    modalMessage,
    modalCheck,
    setModalMessage,
    setModalCheck,
    checkUsedSimilaritiesResults,
  };
};

export const useReportSubjectsStep = (report: valuationReportProps) => {
  const parseResult = useCallback((pages) => {
    return pages ? JSON.parse(pages) : '';
  }, []);
  const checkSubjectsInputFormType = useCallback(
    (is_ocr: number, file_id: number, phase: number) => {
      return is_ocr === 1
        ? `/reports/${report.id}/ocr/analysis_fs/${file_id}/check?phase=${phase}`
        : `/reports/${report.id}/subjects/edit?phase=${phase}`;
    },
    [report.id],
  );
  const getSubjectsInputForm = useCallback((subject_input_url) => {
    location.href = subject_input_url;
  }, []);
  return {
    parseResult,
    checkSubjectsInputFormType,
    getSubjectsInputForm,
  };
};

export const useReportSimilaritiesStep = (report: valuationReportProps) => {
  const [reportSimilaritiesStepStyle, setReportSimilaritiesStepStyle] =
    useState({
      flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200',
      checkedButton:
        'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center',
      actionButton:
        'inline-flex items-center px-4 py-2 text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 cursor-pointer',
    });
  const getSimilaritiesForm = useCallback(() => {
    location.href = `/reports/${report.id}/similarities/new`;
  }, [report.id]);
  return {
    reportSimilaritiesStepStyle,
    setReportSimilaritiesStepStyle,
    getSimilaritiesForm,
  };
};

export const useReportCalculation = (
  isCalculated: boolean,
  calculation: ValuationReportCalculationProps,
) => {
  const [valuation, setValuation] = useState<number>(0);
  const [nonLiquidityDiscount, setNonLiquidityDiscount] = useState<number>(0);
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const [deleteValuationCalculation, setDeleteValuationCalculation] =
    useState<ValuationReportCalculationProps>(calculation);
  const nameStyle = isCalculated
    ? 'text-gray-600 text-lg font-semibold truncate'
    : 'text-gray-400 text-lg font-semibold truncate';
  const openDeleteModal = useCallback(
    (delete_calculation: ValuationReportCalculationProps): void => {
      setDeleteValuationCalculation(delete_calculation);
      setModalCheck(!modalCheck);
    },
    [modalCheck],
  );
  return {
    valuation,
    modalCheck,
    deleteValuationCalculation,
    nonLiquidityDiscount,
    nameStyle,
    openDeleteModal,
    setValuation,
    setNonLiquidityDiscount,
    setDeleteValuationCalculation,
    setModalCheck,
  };
};

export const useReportCalculationsStep = (
  props: useReportCalculationsStepProps,
) => {
  const { ocrFileIds, report, token } = props;
  const [isOpen, setPopover] = useState<boolean>(false);
  const [canUseOcrResult, setCanUseOcrResult] = useState<boolean>(false);
  const [canUseManualSubjectInputResults, setUseManualSubjectInputResults] =
    useState<boolean>(false);
  const [reportGenPptxStepStyle, setReportGenPptxStep] = useState({
    checkedButton:
      'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white',
    flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200',
  });
  const [hasMultipleCalculations, setHasMultipleCalculations] = useState<{
    [K in 'dcf' | 'ebitda' | 'per']: boolean;
  }>({
    dcf: false,
    ebitda: false,
    per: false,
  });

  const isMultipleCalculated = useCallback(
    (calculations: ValuationReportCalculationProps[]): boolean => {
      return (
        1 <
        calculations.filter((calculation) => calculation.result != null).length
      );
    },
    [],
  );

  const checkCalculatedCount = useCallback(
    (calculations: {
      [K in 'dcf' | 'ebitda' | 'per']: ValuationReportCalculationProps[];
    }): void => {
      setHasMultipleCalculations({
        dcf: isMultipleCalculated(calculations.dcf),
        ebitda: isMultipleCalculated(calculations.ebitda),
        per: isMultipleCalculated(calculations.per),
      });
    },
    [isMultipleCalculated],
  );

  const checkValuationResults = useCallback(
    (
      _valuation_result,
      _set_valuation: React.SetStateAction<number>,
      _set_non_liquidity_discount: React.SetStateAction<number>,
    ): void => {
      if (
        _valuation_result &&
        _valuation_result.result &&
        _valuation_result.result.valuation !== null
      ) {
        _set_valuation;
        _set_non_liquidity_discount;
      }
    },
    [],
  );

  const checkUseOcrResults = useCallback((): void => {
    if (0 < ocrFileIds.length) {
      setCanUseOcrResult(true);
    }
  }, [ocrFileIds.length]);

  const checkManualSubjectInputResults = useCallback((): void => {
    if (
      (report.first_phase_file_id ||
        report.second_phase_file_id ||
        report.third_phase_file_id) !== null
    ) {
      setUseManualSubjectInputResults(true);
    }
  }, [
    report.first_phase_file_id,
    report.second_phase_file_id,
    report.third_phase_file_id,
  ]);

  const getValuationForm = useCallback((url: string): void => {
    location.href = url;
  }, []);

  const showSelectPopover = useCallback((): void => {
    setPopover(!isOpen);
  }, [isOpen]);

  const createCalculation = useCallback(
    (calculation): void => {
      const requestData = { valuation_report_calculation_id: calculation.id };
      const requestConfig: axiosOptionsProps = {
        method: 'post',
        url: `/reports/${report.id}/formula/${calculation.result_type}/${calculation.id}/copy_calculation`,
        data: requestData,
        headers: {
          'X-CSRF-Token': token,
        },
      };
      const returnUrlConfig: returnUrlConfigProps = {
        report_id: report.id,
        calculation_result_type: calculation.result_type,
      };
      postCopyCalculation(requestConfig, returnUrlConfig);
    },
    // @ts-ignore
    [report.id, token],
  );

  return {
    hasMultipleCalculations,
    isOpen,
    reportGenPptxStepStyle,
    canUseManualSubjectInputResults,
    canUseOcrResult,
    createCalculation,
    showSelectPopover,
    getValuationForm,
    setPopover,
    setCanUseOcrResult,
    setUseManualSubjectInputResults,
    setReportGenPptxStep,
    checkManualSubjectInputResults,
    checkUseOcrResults,
    checkValuationResults,
    checkCalculatedCount,
  };
};

export const useReportDownloadButton = (
  props: useReportDownloadButtonProps,
) => {
  const { approaches, valuation_report_id, calculationIds, api_request_url } =
    props;
  const [getReportApi, setGetReportApi] = useState<string>('');
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [pptxMessage, setPptxMessage] = useState<string>('ダウンロード中');
  const queryApproaches = approaches.join(',');
  const showProgressBar = useCallback((): void => {
    let progress = 0;
    const invervalSpeed = 10;
    const incrementSpeed = 1;
    const bar = document.getElementById('bar');
    const progressInterval = setInterval((): void => {
      progress += incrementSpeed;
      // @ts-ignore
      bar.style.width = progress + '%';
      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, invervalSpeed);
  }, []);
  const getCalculationIdsQuery = useCallback(
    (calculation_ids: Array<{ [k: string]: number }>) => {
      const requestQueries = calculation_ids.map((calculation) => {
        const query = '';
        if ('dcf_calculation_id' in calculation) {
          return query + `dcf=${calculation['dcf_calculation_id']}`;
        }
        if ('ebitda_calculation_id' in calculation) {
          return query + `ebitda=${calculation['ebitda_calculation_id']}`;
        }
        if ('per_calculation_id' in calculation) {
          return query + `per=${calculation['per_calculation_id']}`;
        }
      });
      return requestQueries.join('&');
    },
    [],
  );
  const donwloadValuationReport = async () => {
    const requestConfig: axiosOptionsProps = {
      method: 'get',
      url: `/reports/${valuation_report_id}/download.json?${getCalculationIdsQuery(
        calculationIds,
      )}`,
    };
    await getvaluationReport(requestConfig, setGetReportApi, {
      api_request_url: api_request_url,
      queryApproaches: queryApproaches,
    });
  };
  const addReportDownloadListener = () => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data.name === 'progress') {
          setIsOpen(true);
          showProgressBar();
        } else if (event.data.name === 'done') {
          setIsOpen(false);
        } else if (event.data.name === 'failed') {
          setIsOpen(true);
          setPptxMessage('株価算定報告書の作成中にエラーが発生しました。');
        }
      },
      false,
    );
  };
  return {
    getReportApi,
    modalIsOpen,
    pptxMessage,
    setIsOpen,
    setPptxMessage,
    setGetReportApi,
    showProgressBar,
    getCalculationIdsQuery,
    donwloadValuationReport,
    addReportDownloadListener,
  };
};

export const useReportGenPptxStep = (report: valuationReportProps) => {
  const [reportGenPptxStepStyle, setReportGenPptxStep] = useState({
    checkButton:
      'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white',
    step: 'text-lg text-gray-400 font-semibold',
    name: 'pt-1.5 text-xl text-gray-400 font-bold',
    reportButton:
      'pointer-events-none inline-flex items-center px-4 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  });
  const getReportPptxForm = useCallback(() => {
    location.href = `/reports/${report.id}/profile`;
  }, [report.id]);
  return {
    reportGenPptxStepStyle,
    setReportGenPptxStep,
    getReportPptxForm,
  };
};
