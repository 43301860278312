import useSWR from 'swr';
import axios from 'axios';

const fetcher = (url) => axios.get(url).then((res) => res.data);

function getIndustries(list_id, resource) {
  // TODO: Hooksの使い方を間違えています
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, error } = useSWR(
    `/crawl/${resource}/${list_id}/industries`,
    fetcher,
  );
  const loading = !data && !error;
  return {
    loading,
    error,
    data,
  };
}

function getAreas(list_id, resource) {
  // TODO: Hooksの使い方を間違えています
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, error } = useSWR(
    `/crawl/${resource}/${list_id}/areas`,
    fetcher,
  );
  const loading = !data && !error;
  return {
    loading,
    error,
    data,
  };
}

export { getIndustries, getAreas };
