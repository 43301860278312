export const MoneyFormatter = (strMoney: number) => {
  const money = Math.floor(strMoney);
  const MONEY_PRESET = ['', '万', '億', '兆', '京', '垓'];
  const MONEY_LIMIT = 68;
  const nums = String(money)
    .replace(/(\d)(?=(\d\d\d\d)+$)/g, '$1,')
    .split(',')
    .reverse();

  if (String(strMoney).length < MONEY_LIMIT) {
    let converted_money = '';
    nums.forEach((num, i) => {
      if (!num.match(/^[0]+$/)) {
        converted_money =
          num.replace(/^[0]+/g, '') + MONEY_PRESET[i] + converted_money;
      } else if (num === '0') {
        converted_money = '0';
      }
    });
    const splited_money: string[] = converted_money.split('万');
    return 1 < splited_money.length
      ? `${splited_money[0]}万円`
      : `${splited_money}円`;
  } else {
    return 0;
  }
};

export const transferLocation = (url: string): void => {
  location.href = url;
};

export const transformDate = (date: string | null) => {
  if (!date) return null;
  const originalDateString = date;
  const originalDate = new Date(originalDateString);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });
