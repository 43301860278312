import { useCallback } from 'react';
import { SimilaritiesFormProps, postSimilaritiesOptions } from './types';

export const useSimilarApiReqeust = (props: SimilaritiesFormProps) => {
  const { postSimilarCompanies, path, token } = props;
  const addSimilarRequestListener = useCallback(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitMaForm') {
          const requestData = event.data;
          const requestConfig: postSimilaritiesOptions = {
            method: 'post',
            url: `${path}/similarities`,
            data: requestData,
            headers: {
              'X-CSRF-Token': token,
            },
          };
          postSimilarCompanies(requestConfig, path);
        } else if (
          event.data &&
          event.data.name === 'cancelMaForm' &&
          event.data.value === 'canceled'
        ) {
          location.href = '/homes/functions';
        }
      },
      false,
    );
  }, [path, token, postSimilarCompanies]);
  return { addSimilarRequestListener };
};
