import React from 'react';

export const NetAssets: React.FC = React.memo(() => {
  return (
    <>
      <div className="col-span-12 py-1.5 bg-gray-400">
        <p className="text-center text-base font-semibold text-white">
          純資産の部
        </p>
      </div>
    </>
  );
});
