import React, { useCallback } from 'react';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import { InheritanceValuationProps } from '../types/props';

export const InheritanceValuationOutput: React.FC<
  InheritanceValuationProps & { hasCalculatedValuation: boolean }
> = (
  props: InheritanceValuationProps & { hasCalculatedValuation: boolean },
) => {
  const { inheritanceValuation, hasCalculatedValuation } = props;

  const checkedButton: string = hasCalculatedValuation
    ? 'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white'
    : 'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white';

  const stepStyle: string = hasCalculatedValuation
    ? 'text-lg text-green-400 font-semibold'
    : 'text-lg text-gray-400 font-semibold';

  const genPdfNameStyle = hasCalculatedValuation
    ? 'pt-1.5 text-xl text-gray-700 font-bold'
    : 'pt-1.5 text-xl text-gray-400 font-bold';

  const getResultsUrl: () => void = useCallback(() => {
    location.href = `/inheritances/${inheritanceValuation.id}/result`;
  }, [inheritanceValuation.id]);

  return (
    <li>
      <div className="relative pb-8">
        <div className="relative flex space-x-3">
          <div>
            <span className={checkedButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
            <div>
              <div className="flex">
                <p className={stepStyle}>STEP4</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">3</span>分
                </p>
              </div>
              <p className={genPdfNameStyle}>評価明細書の作成</p>
              <p className="pt-1.5 text-sm text-gray-500">
                Step1からStep3の情報を元に取引相場のない株式（出資）の評価明細書を作成
              </p>
              <div className="pt-10">
                {hasCalculatedValuation ? (
                  <div
                    onClick={() => getResultsUrl()}
                    className="cursor-pointer inline-flex items-center px-4 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    明細書を書き出す
                  </div>
                ) : (
                  <div
                    onClick={() => getResultsUrl()}
                    className="pointer-events-none inline-flex items-center px-4 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    明細書を書き出す
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
