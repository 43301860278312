import React from 'react';
import { SubjectRow } from '../../../../../../components/SubjectRow/SubjectRow';

// @ts-ignore
export const CurrentAssets: React.FC<SubjectProps> = React.memo(
  // @ts-ignore
  (props: SubjectProps) => {
    const { getValues, setValue, control } = props;

    return (
      <>
        <div className="grid grid-cols-6 divide-y">
          <div className="flex col-span-12 grid grid-cols-12 bg-gray-200 py-2.5">
            <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
              科目
            </div>
            <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
              金額
            </div>
          </div>
          <div className="flex col-span-12 grid grid-cols-12 py-2">
            <div className="col-span-6 text-left pl-2 font-semibold">
              固定資産
            </div>
          </div>
          <div className="flex col-span-12 grid grid-cols-12 py-1">
            <div className="col-span-6 text-left pl-6 pt-1 font-semibold">
              有形固定資産
            </div>
          </div>
          <SubjectRow
            label="土地"
            value={'subject.land'}
            subject={'subject.land'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            indent
          />
          <div className="flex col-span-12 grid grid-cols-12 py-1">
            <div className="col-span-6 text-left pl-6 pt-1 font-semibold">
              投資その他の資産
            </div>
          </div>
          <SubjectRow
            label="上場有価証券"
            value={'subject.listed_investment_securities'}
            subject={'subject.listed_investment_securities'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            indent
          />
          <SubjectRow
            label="投資有価証券"
            value={'subject.investment_securities'}
            subject={'subject.investment_securities'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            indent
          />
          <SubjectRow
            label="投資有価証券"
            value={'subject.investment_securities'}
            subject={'subject.investment_securities'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            indent
          />
          <div className="flex col-span-12 grid grid-cols-12 py-1">
            <div className="col-span-6 text-left pl-2 pt-1 font-semibold">
              資産の部合計
            </div>
            <SubjectRow
              label="総資産"
              value={'subject.assets'}
              subject={'subject.assets'}
              control={control}
              getValues={getValues}
              setValue={setValue}
            />
          </div>
        </div>
      </>
    );
  },
);
