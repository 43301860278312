import React from 'react';

type ValuationOptionProps = {
  companyName: string;
  titleColor: string;
  backgroundColor: string;
};

export const TopPagePreview: React.FC<ValuationOptionProps> = React.memo(
  (props) => {
    const { companyName, titleColor, backgroundColor } = props;

    return (
      <>
        <div className="shadow-md mt-10 w-12/12 h-96 border border-gray-200">
          <div className="grid grid-cols-12">
            <div className="col-span-3">
              <p
                style={{ backgroundColor: backgroundColor }}
                className="h-96"
              ></p>
            </div>
            <div className="col-span-1 ml-1">
              <p
                style={{ backgroundColor: backgroundColor }}
                className="h-96 w-2"
              ></p>
            </div>
            <div className="col-span-7">
              <div className="grid grid-cols-12">
                <div className="col-span-10"></div>
                <div className="col-span-2 pt-3">
                  <p className="text-xs border border-red-200 w-20 text-red-500 mincho">
                    confidential
                  </p>
                </div>
              </div>
              <p className="pt-16 text-xs mincho">サンプル株式会社御中</p>
              <p
                style={{ color: titleColor }}
                className="pt-10 text-base mincho"
              >
                株式価値算定報告書
              </p>
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-2">
                  <div
                    style={{ borderColor: titleColor }}
                    className="border"
                  ></div>
                </div>
              </div>
              <p className="pt-32 text-xs mincho">{companyName}</p>
              <p className="pt-38 text-xs mincho">
                {new Date().getFullYear()}年{new Date().getMonth() + 1}月
                {new Date().getDate()}日
              </p>
            </div>
          </div>
        </div>
      </>
    );
  },
);
