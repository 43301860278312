import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyMedicalReportProfilesProps, SectionsProps } from './types';

const AI_COMMENT_ROLES: { [k: string]: { [k: string]: string } } = {
  certified_public_accountant: {
    name: '公認会計士',
  },
  tax_accountant: {
    name: '税理士',
  },
  lawer: {
    name: '弁護士',
  },
  financial_consultant: {
    name: '財務コンサルタント',
  },
  management_consultant: {
    name: '経営コンサルタント',
  },
  merger_and_acqusition_advisor: {
    name: 'M&Aアドバイザー',
  },
  financial_advisor: {
    name: 'フィナンシャルアドバイザー',
  },
  banker: {
    name: '銀行員',
  },
};

export const CompanyMedicalReportComments: React.FC<
  CompanyMedicalReportProfilesProps
> = (props) => {
  const { sections, setSections, company_medical_report_id, report_comments } =
    props;
  const [selectedRole, setSelectedRole] = useState<string>(
    Object.keys(AI_COMMENT_ROLES)[0],
  );

  const inputSection = useMemo(
    () => [
      {
        label: 'セクション名',
        inputName: 'section_name',
      },
      {
        label: '依頼文',
        inputName: 'message',
      },
      {
        label: 'フォーマット',
        inputName: 'format',
      },
    ],
    [],
  );

  const handleStateChange = useCallback(
    (section_index: number, sectionData: SectionsProps) => {
      setSections((prevSections) => {
        const sectionIndex = prevSections.findIndex(
          (section) => section.section_index === section_index,
        );
        if (sectionIndex === -1) {
          return prevSections;
        }
        const updatedSections = [...prevSections];
        updatedSections[sectionIndex] = sectionData;
        return updatedSections;
      });
    },
    [setSections],
  );

  const getFinancialComment = useCallback(
    async (section_index: number) => {
      handleStateChange(section_index, {
        ...sections[section_index],
        loading: true,
      });
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            company_medical_report_id: company_medical_report_id,
            role: selectedRole,
            section: sections[section_index],
          }),
        };
        const response = await fetch('/api/open_ai', requestOptions);
        const data = await response.json();
        handleStateChange(section_index, {
          ...sections[section_index],
          chat_history: data.response.chat_history,
          content: data.response.content,
          trials: sections[section_index].trials + 1,
          loading: false,
        });
      } catch (err) {
        console.debug(err);
        handleStateChange(section_index, {
          ...sections[section_index],
          loading: false,
        });
      }
    },
    [selectedRole, sections, handleStateChange, company_medical_report_id],
  );

  useEffect(() => {
    if (report_comments) {
      const updatedSections = [...sections];
      report_comments.forEach((report_comment, index) => {
        updatedSections[index].content = report_comment.content;
        updatedSections[index].section_name = report_comment.section_name;
        updatedSections[index].category = report_comment.category;
      });
      setSections(updatedSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_comments]);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-6">
        <div className="bg-gray-50 border border-gray-300 rounded mt-12 w-FULL">
          <div>
            <legend className="text-xl font-bold text-black pl-3 py-1.5">
              6.AIアドバイスの設定
            </legend>
          </div>
          <div className="pb-3 grid grid-cols-12">
            <div className="col-span-2 mt-1 block text-sm font-medium sm:pl-3.5 text-gray-700 sm:pt-1.5">
              ロール設定
            </div>
            <div className="col-span-10 mt-1 pr-4">
              <select
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs border-gray-300 rounded-sm"
                name={selectedRole}
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
              >
                {Object.keys(AI_COMMENT_ROLES).map((key) => (
                  <option key={key} value={key}>
                    {AI_COMMENT_ROLES[key].name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {sections.map((section) => (
          <React.Fragment key={section.section_index}>
            <div className="bg-gray-50 border border-gray-300 rounded mt-3 w-full">
              <div>
                <legend className="text-lg font-bold text-black pl-3 py-1">
                  ポイント{section.section_index + 1}の設定
                </legend>
              </div>
              {inputSection.map(({ label, inputName }, index) => (
                <div key={index} className="sm:pt-2 grid grid-cols-12 pr-3">
                  <div className="col-span-2 block text-sm font-medium sm:pl-3.5 mt-2.5 text-gray-700">
                    {label}
                  </div>
                  <div className="col-span-10 mt-1">
                    {inputName !== 'format' ? (
                      <input
                        type="text"
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs border-gray-300 rounded-sm"
                        placeholder={String(section[inputName])}
                        name={`[company_medical_report_profile_form][section_index_${section.section_index}]${inputName}`}
                        // @ts-ignore
                        value={section[inputName]}
                        onChange={(e) =>
                          handleStateChange(section.section_index, {
                            ...section,
                            [inputName]: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <select
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs border-gray-300 rounded-sm"
                        name={`[company_medical_report_profile_form][section_index_${section.section_index}]${inputName}`}
                        value={section[inputName]}
                        onChange={(e) =>
                          handleStateChange(section.section_index, {
                            ...section,
                            [inputName]: e.target.value,
                          })
                        }
                      >
                        <option value="bullet">バレット形式</option>
                        <option value="text">文章形式</option>
                      </select>
                    )}
                  </div>
                </div>
              ))}
              <div className="sm:pt-3 pb-3 flex justify-end sm:gap-36 w-full">
                {section.loading ? (
                  <div className="justify-center align-center rounded px-8 py-1 bg-gray-300 font-medium text-white text-xs">
                    読み込み中
                  </div>
                ) : (
                  <div
                    onClick={() => getFinancialComment(section.section_index)}
                    className="cursor-pointer justify-center align-center rounded px-8 py-1 bg-blue-500 font-medium text-white hover:bg-blue-600 text-xs"
                  >
                    設定する
                  </div>
                )}
                <p className="font-bold text-right text-green-500 text-xs mt-1 px-3">
                  リクエスト回数: {section.trials}回
                </p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="col-span-6 pl-12 pt-12">
        <div className="w-full bg-white border border-gray-300 h-full">
          <hr className="bg-blue-300 h-1 mt-6 mx-8" />
          <h1 className="text-lg pl-8 font-bold">
            あなたの会社へのAIアドバイス
          </h1>
          <hr className="bg-blue-400 h-1 mt-1 mx-8" />
          {sections.map((section) => (
            <React.Fragment key={section.section_index}>
              <div className="pt-12">
                <h1 className="text-lg pl-8 font-bold">
                  <span className="bg-yellow-400 text-xs rounded px-2 py-0.5 text-white font-bold ml-3 mr-2">
                    {`ポイント${section.section_index + 1}`}
                  </span>
                  {section.section_name}
                </h1>
                <div className="pl-5 pt-1">
                  {section.loading ? (
                    <div className="flex justify-center h-40 pt-16">
                      <div className="animate-ping h-2 w-2 bg-blue-600 rounded-full pt-8"></div>
                      <div className="animate-ping h-2 w-2 bg-blue-600 rounded-full pt-8 mx-5"></div>
                      <div className="animate-ping h-2 w-2 bg-blue-600 rounded-full pt-8"></div>
                    </div>
                  ) : (
                    <textarea
                      className="border border-none w-11/12 h-40 text-xs"
                      name={`[company_medical_report_profile_form][section_index_${section.section_index}]content`}
                      onChange={(e) =>
                        handleStateChange(section.section_index, {
                          ...section,
                          content: e.target.value,
                        })
                      }
                      value={section.content}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
