import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';
import { useFormulaForm } from './hooks';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { FormulaWaccSelector } from './FormulaWaccSelector';
import { CompanyProps, FormulaFormProps, IndustryProps } from './types';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    width: '90%',
    transform: 'translate(-50%, -50%)',
  },
};

export const FormulaForm: React.FC<FormulaFormProps> = (props) => {
  const {
    userRole,
    resourceName,
    reportId,
    calculationId,
    industries,
    similaritiesCompanies,
  } = props;

  const {
    companyValue,
    valueText,
    requestSimilaritiesCompanies,
    unlevererdBeta,
    responseValidatesMessages,
    showModal,
    closeMessage,
    modalCheck,
    setModal,
    showValuation,
    showAverage,
    showMedian,
    showDeRatioAverage,
    hasValuationData,
    isChecked,
    checkCompanies,
    updateRequestSimilaritiesCompanies,
    changeRequestIndustry,
    convertYen,
    operateValuationResource,
    validateTargetValue,
    checkSimilaritiesCompaniesCount,
    postValuation,
  } = useFormulaForm(industries, similaritiesCompanies);
  const token = document
    ?.getElementById('report_valuation_form')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  useEffect(() => {
    Modal.setAppElement('#formula_form');
    checkSimilaritiesCompaniesCount();
    operateValuationResource(resourceName);
    window.addEventListener(
      'message',
      (event) =>
        // @ts-ignore
        postValuation(
          event,
          resourceName,
          `/reports/${reportId}/formula/${resourceName}/${calculationId}`,
          `/reports/${reportId}/formula/${resourceName}/${calculationId}/check`,
          token,
        ),
      false,
    );
  }, [
    similaritiesCompanies,
    postValuation,
    resourceName,
    requestSimilaritiesCompanies,
    checkSimilaritiesCompaniesCount,
    operateValuationResource,
    reportId,
    calculationId,
    token,
  ]);

  return (
    <ModalProvider>
      {!similaritiesCompanies && 0 < industries?.length ? (
        <div className="text-left my-4 pb-6">
          <h3 className="text-left text-lg font-semibold text-gray-700 mb-1">
            類似業種リスト
          </h3>
          <select
            onChange={(e) => changeRequestIndustry(e)}
            name="industry"
            id="industry_id"
            className="rounded-sm mt-1"
          >
            {industries?.map((industry: IndustryProps) => (
              <option
                key={industry['industry_id']}
                value={industry['industry_id']}
              >
                {industry['name']}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <></>
      )}
      {requestSimilaritiesCompanies ? (
        <div className="border mt-3 mr-6">
          <div className="bg-gray-100 border border-gray-100 flex justify-between">
            <p className="mx-1 py-1 font-semibold">類似上場企業の選択</p>
            <div
              onClick={() => setModal(true)}
              className="bg-transparent py-2 text-xs hover:bg-green-500 text-green-700 font-semibold hover:text-white px-2 bg-white border border-green-500 hover:border-transparent rounded"
            >
              編集
            </div>
          </div>
          {requestSimilaritiesCompanies?.map((company: CompanyProps) => (
            <div key={company.company_name} className="bg-white">
              <div className="grid grid-cols-12">
                <div className="col-span-8 text-left text-sm py-1 px-1">
                  {company.company_name}
                </div>
                {resourceName === 'dcf' ? (
                  <div className="col-span-2 text-center border-l">
                    <p className="py-1">{showValuation(company, 'de_ratio')}</p>
                  </div>
                ) : (
                  <div className="col-span-2"></div>
                )}
                <div className="col-span-2 border-l text-center">
                  <p className="py-1">{showValuation(company, companyValue)}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="grid grid-cols-12 bg-red-50">
            <div className="col-span-8 text-left py-1 px-1 text-sm font-semibold">
              {valueText} 平均値
            </div>
            {resourceName === 'dcf' ? (
              <div className="col-span-2 border-l"></div>
            ) : (
              <div className="col-span-2"></div>
            )}
            <div className="col-span-2 text-center border-l py-1 font-semibold">
              {showAverage(requestSimilaritiesCompanies, companyValue).toFixed(
                2,
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 bg-red-50">
            <div className="col-span-8 text-left py-1 px-1 text-sm">
              {valueText} 中央値
            </div>
            {resourceName === 'dcf' ? (
              <div className="col-span-2 border-l"></div>
            ) : (
              <div className="col-span-2"></div>
            )}
            <div className="col-span-2 text-center border-l py-1">
              {showMedian(requestSimilaritiesCompanies, companyValue)}
            </div>
          </div>
          {resourceName === 'dcf' ? (
            <div className="grid grid-cols-12 bg-red-50">
              <div className="col-span-8 text-left py-1 px-1 text-sm">
                D/Eレシオ 平均値
              </div>
              <div className="col-span-2 border-l">
                <p className="py-1 text-center">
                  {showDeRatioAverage(requestSimilaritiesCompanies).toFixed(2)}
                </p>
              </div>
              <div className="col-span-2 border-l text-left py-1"></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {resourceName === 'dcf' ? (
        <FormulaWaccSelector
          averageUnleveredBeta={unlevererdBeta}
          userRole={userRole}
        />
      ) : (
        <></>
      )}

      {showModal ? (
        <Modal isOpen style={modalStyle}>
          <div className="overflow-hidden ring-1 ring-gray-200 md:rounded-xs">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-blue-500">
                <tr>
                  <th
                    scope="col"
                    className="pl-3 py-1.5 text-left text-xs font-semibold text-white"
                  ></th>
                  <th
                    scope="col"
                    className="pl-3 py-1.5 text-left text-xs font-bold text-white"
                  >
                    コード
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    会社名
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    時価総額
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    業種
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    PER
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    EV/EBITDA
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    Unlevered_beta
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    leverered_beta
                  </th>
                  <th
                    scope="col"
                    className="py-1.5 text-left text-xs font-bold text-white"
                  >
                    D/Eレシオ
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {similaritiesCompanies?.map((company: CompanyProps) => (
                  <tr key={company.company_name}>
                    <td className="whitespace-nowrap text-left py-4 pl-3 text-sm text-gray-800">
                      {hasValuationData(company, resourceName) ? (
                        <input
                          id={company.company_name}
                          type="checkbox"
                          value={JSON.stringify(company)}
                          onChange={(e) => checkCompanies(e)}
                          checked={isChecked(JSON.stringify(company))}
                          autoComplete="off"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 pl-3 text-sm text-gray-800">
                      {company.ticker}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {company.company_name}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {company.capital !== null
                        ? `${convertYen(company.capital)}円`
                        : null}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {company.industry}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {validateTargetValue(company, 'per')}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {validateTargetValue(company, 'ev_per_ebitda')}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {validateTargetValue(company, 'unlevered_beta')}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {validateTargetValue(company, 'levered_beta')}
                    </td>
                    <td className="whitespace-nowrap text-left py-4 text-sm text-gray-800">
                      {validateTargetValue(company, 'de_ratio')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {closeMessage && (
            <p className="text-red-500">
              一つ以上の類似企業を選択してください。
            </p>
          )}
          <div className="flex justify-center mt-5">
            <u
              className="text-gray-600 hover:text-gray-900 px-16 mt-2 cursor-pointer content-center"
              onClick={() => setModal(false)}
            >
              閉じる
            </u>
            <div
              className="cursor-pointer content-center inline-flex justify-center py-2 px-6 text-sm font-medium rounded-sm text-white bg-green-500 hover:bg-green-600"
              onClick={updateRequestSimilaritiesCompanies}
            >
              反映する
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <MessageModal
        setModal={modalCheck}
        modalType="message_list"
        modalDom="#formula_form"
        errorMessages={responseValidatesMessages}
      />
    </ModalProvider>
  );
};
