import React from 'react';
import { CorporateTaxReturn } from './CorporateTaxReturn';

export const CtContainer: React.FC = React.memo((props) => {
  // @ts-ignore
  const { register, getValues, setValue, phase, Controller, control } = props;
  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-9 pl-5">
        <div className="inline-block min-w-full py-1 align-middle md:px-8 lg:px-10">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-10 md:rounded-xs">
            <div className="grid grid-cols-12 divide-x">
              <div className="col-span-12 text-center bg-gray-400 text-lg font-semibold text-white">
                法人税申告書（別表）
              </div>
              <div className="col-span-3 divide-x py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  別表番号
                </p>
              </div>
              <div className="col-span-5 py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  科目
                </p>
              </div>
              <div className="col-span-4 py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  金額
                </p>
              </div>
              <div className="col-span-12">
                <CorporateTaxReturn
                  // @ts-ignore
                  Controller={Controller}
                  control={control}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  phase={phase}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
