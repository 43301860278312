import React from 'react';
import { AssetsContainer } from './assets/AssetsContainer';
import { LiabilitiesContainer } from './liabilities/LiabilitiesContainer';
// import { SubjectProps, Subjects } from '../../../../../report/types';

// interface BsContainerProps extends SubjectProps {
//   subject: Subjects;
//   phase: string;
// }

/**
 * 貸借対照表
 * 資産の部（左）AssetsContainer
 * 負債の部（右上）LiabilitiesContainer
 * 純資産の部（右下) LiabilitiesContainer -> NetAssetsContainer
 */
// @ts-ignore
export const BsContainer: React.FC<BsContainerProps> = React.memo(
  // @ts-ignore
  (props: BsContainerProps) => {
    const { subject, Controller, register, getValues, setValue, control } =
      props;
    return (
      <>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-3 lg:-mx-4 pb-12">
          <div className="inline-block min-w-full py-1 align-middle md:px-8 lg:px-10">
            <div className="overflow-hidden ring-1 ring-black ring-opacity-10 md:rounded-xs">
              <div className="grid grid-cols-12 divide-x">
                <div className="col-span-12 text-center bg-gray-400 text-lg font-semibold text-white">
                  貸借対照表
                </div>
                <div className="col-span-6 divide-x">
                  <p className="text-center bg-gray-400 py-0.5 text-base font-semibold text-white">
                    資産の部
                  </p>
                </div>
                <div className="col-span-6">
                  <p className="text-center bg-gray-400 py-0.5 text-base font-semibold text-white">
                    負債の部
                  </p>
                </div>
                <div className="col-span-6">
                  <AssetsContainer
                    Controller={Controller}
                    control={control}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </div>
                <div className="col-span-6">
                  <LiabilitiesContainer
                    // @ts-ignore
                    Controller={Controller}
                    control={control}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    subject={subject}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
