import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import CrawlingResult from './CrawlingResult';

interface CrawlProp {
  listId: string;
  resourceName: string;
}

const Result: React.FC<CrawlProp> = (props: CrawlProp) => {
  const [progressMessage, setProgressMessage] =
    useState('ただいま取得中・・・');
  const [crawlingStatus, setCrawlingStatus] = useState(true);
  const [crawlingCount, setCrawlingCount] = useState(0);
  const countUp = useRef(crawlingCount);

  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);

  useEffect(() => {
    countUp.current = crawlingCount;
  }, [crawlingCount]);

  const checkStatus = useCallback(() => {
    axios
      .get(`/crawl/${props.resourceName}/${props.listId}/status`)
      .then((res) => {
        if (res.data.status === 'success') {
          switch (res.data.body.status) {
            case 'active':
              setProgressMessage(res.data.body.message);
              setCrawlingCount(countUp.current + 1);
              setCrawlingStatus(true);
              clearInterval(refTimerId.current as number);
              break;
            case 'success':
            case 'failed':
              setProgressMessage(res.data.body.message);
              setCrawlingStatus(false);
              clearInterval(refTimerId.current as number);
              break;
          }
        } else if (res.data.status === 'failed') {
          setInterval(
            (location.href = `/crawl/${props.resourceName}/new`),
            2000,
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setProgressMessage('解析でエラーが発生しました。');
        clearInterval(refTimerId.current as number);
        setInterval((location.href = `/crawl/${props.resourceName}/new`), 2000);
      });
  }, [props.listId, props.resourceName]);

  useEffect(() => {
    if (crawlingStatus) {
      setTimerId(setInterval(checkStatus, 3000));
    }
  }, [checkStatus, crawlingCount, crawlingStatus]);

  return (
    <div className="flex flex-wrap lg:mx-20 md:mx-20 sm:mx-2 mb-10">
      {crawlingStatus === false ? (
        <CrawlingResult
          listId={props.listId}
          resourceName={props.resourceName}
        />
      ) : (
        <>
          <div className="min-w-full flex justify-center">
            {/* @ts-ignore */}
            <Loader type="Oval" color="#00BFFF" height={130} width={130} />
          </div>
          <p className="lg:text-2xl md:tex-2xl min-w-full flex justify-center py-5 text-lg font-semibold text-blue-600">
            {progressMessage}
          </p>
        </>
      )}
    </div>
  );
};

export default Result;
