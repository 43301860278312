import React, { useCallback, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { updateUsersProfile } from './api';
import { UsersFunctions } from './UsersFunctions';
import { InputField } from '../../components/InputField/InputField';
import { SelectField } from '../../components/SelectField/SelectField';
import { RadioGroup } from '../../components/RadioGroup/RadioGroup';
import { UserProps, UsersAccountOptionProps } from './types';

export const UsersAccountOption: React.FC<UsersAccountOptionProps> = (
  props,
) => {
  const { user, postKeys, employeesKeys, roleKeys, allFunctions, functionIds } =
    props;
  const token = document
    ?.getElementById('send_users_account_edit_form')
    ?.parentElement?.getElementsByTagName('input')[0].value;
  const twoFactorOptions = [
    { value: true, label: 'はい' },
    { value: false, label: 'いいえ' },
  ];
  const [formState, setFormState] = useState<UserProps>({
    id: user.id,
    last_name: user.last_name,
    first_name: user.first_name,
    company_name: user.company_name,
    department: user.department,
    email: user.email,
    phone: user.phone,
    post: user.post,
    employees: user.employees,
    role: user.role,
    two_factor_auth_enabled: user.two_factor_auth_enabled,
    company_logo: '',
    function_ids: functionIds,
  });
  const handleInputChange = useCallback((e) => {
    const { name, type, value } = e.target;

    if (type === 'file') {
      const file = e.target.files[0];
      setFormState((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  return (
    <>
      <Toaster />
      <div className="grid grid-cols-5 gap-x-2 gap-y-8 pt-12">
        <div className="grid grid-cols-1" />
        <div className="bg-white shadow-sm ring-1 ring-gray-300 sm:rounded-xl md:col-span-3">
          <div className="px-2 sm:px-0 px-12 text-center pt-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-900">
              プロフィール設定
            </h2>
            <p className="mt-1 text-md leading-6 text-gray-600">
              アカウント情報を入力してください。
            </p>
          </div>
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-full grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <InputField
                  name="last_name"
                  value={formState.last_name}
                  onChange={handleInputChange}
                  label="姓"
                  required
                />
              </div>
              <div className="sm:col-span-3">
                <InputField
                  name="first_name"
                  value={formState.first_name}
                  onChange={handleInputChange}
                  label="名"
                  required
                />
              </div>
              <div className="sm:col-span-3">
                <InputField
                  name="company_name"
                  value={formState.company_name}
                  onChange={handleInputChange}
                  label="会社名"
                  required
                />
              </div>
              <div className="sm:col-span-3">
                <InputField
                  name="department"
                  value={formState.department}
                  onChange={handleInputChange}
                  label="部署"
                />
              </div>
              <div className="sm:col-span-3">
                <InputField
                  name="email"
                  value={formState.email}
                  onChange={handleInputChange}
                  label="メールアドレス"
                  required
                />
              </div>
              <div className="sm:col-span-3">
                <InputField
                  name="phone"
                  value={formState.phone}
                  onChange={handleInputChange}
                  label="電話番号"
                />
              </div>
              <div className="sm:col-span-full">
                <SelectField
                  name="post"
                  value={formState.post}
                  onChange={handleInputChange}
                  options={postKeys}
                  label="役職"
                />
              </div>
              <div className="sm:col-span-full">
                <SelectField
                  name="employees"
                  value={formState.employees}
                  onChange={handleInputChange}
                  options={employeesKeys}
                  label="社員数"
                />
              </div>
              <div className="sm:col-span-full">
                <SelectField
                  name="role"
                  value={formState.role}
                  onChange={handleInputChange}
                  options={roleKeys}
                  label="権限"
                />
              </div>
              <UsersFunctions
                role={formState.role}
                formState={formState}
                setFormState={setFormState}
                allFunctions={allFunctions}
              />
              <div className="sm:col-span-full">
                <RadioGroup
                  name="two_factor_auth_enabled"
                  value={JSON.parse(formState.two_factor_auth_enabled)}
                  onChange={handleInputChange}
                  options={twoFactorOptions}
                  label="2段階認証を有効にする"
                />
              </div>

              <div className="col-span-full">
                <p className="block text-md font-medium leading-6 text-gray-900">
                  コーポレートロゴ
                </p>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <div className="mt-4 flex text-md leading-6 text-gray-600">
                      <label className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                        <input
                          id="file-upload"
                          name="company_logo"
                          type="file"
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-8 py-4 sm:px-8">
            <div
              className="cursor-pointer rounded-md bg-indigo-600 px-8 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              // @ts-ignore
              onClick={(e) => updateUsersProfile(e, token, formState)}
            >
              変更を保存
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
