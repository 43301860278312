import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';

interface ValuationFormProp {
  industries: Array<object>;
  form: HTMLFormElement;
  resourceName: string;
}

const ValuationForm: React.FC<ValuationFormProp> = (
  props: ValuationFormProp,
) => {
  const [jsonData, setJsonData] = useState('');

  useEffect(() => {
    // メッセージ受信イベント
    // microxでは、ma_maker_apiからiframeを取得する。
    // iframe内での処理はma_maker_apiで行われ、最終的にpostする部分で、こちらで受信する。
    // 具体的には、ma_maker_apiのiframeでreduxアクションが走る。（submitTempForm）
    // window.parent.postMessage({ name: "submitMaForm", value: value }, "*");
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitMaForm') {
          setJsonData(JSON.stringify(event.data));
          console.log(props.form);
          // # form.submitはrailsのヘルパー
          // props.form はシンプルなhtml formタグ
          // microdxのエンドポイントにPOST
          props.form.submit();
        } else if (
          event.data &&
          event.data.name === 'cancelMaForm' &&
          event.data.value === 'canceled'
        ) {
          location.href = '/homes/functions';
        }
      },
      false,
    );
  }, [props.form]);

  return (
    <div>
      {props.industries.length > 0 ? (
        <div
          className={
            props.resourceName === 'asset' ? 'hidden' : 'text-left my-4'
          }
        >
          <label htmlFor="industry_id">業種：</label>
          <select name="industry" id="industry_id">
            {props.industries.map((industry) => (
              <option
                // @ts-ignore
                key={industry['industry_id']}
                // @ts-ignore
                value={industry['industry_id']}
              >
                {/* @ts-ignore */}
                {industry['name']}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <textarea
        id="json_data"
        name="json_data"
        value={jsonData}
        readOnly={true}
        className="hidden"
      ></textarea>
    </div>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  // @ts-ignore
  const props = window.jsProps || { industries: [] };
  const form = document.getElementById('valuation_form');
  if (form !== null) {
    const resourceName = form.getAttribute('resource');
    ReactDOM.render(
      <ValuationForm
        // @ts-ignore
        resourceName={resourceName}
        industries={props.industries}
        form={form.parentElement as HTMLFormElement}
      />,
      form,
    );
  }
});
