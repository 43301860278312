import React from 'react';
import { InterestBearingDebt } from './InterestBearingDebt';

// @ts-ignore
export const LiabilitiesContainer: React.FC<LiabilitiesContainerProps> =
  // @ts-ignore
  React.memo((props: LiabilitiesContainerProps) => {
    const { subject, register, getValues, setValue, Controller, control } =
      props;
    return (
      <>
        <div className="grid grid-cols-12 divide-y">
          <div className="flex col-span-12 grid grid-cols-12 bg-gray-200 py-2.5">
            <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
              科目
            </div>
            <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
              金額
            </div>
          </div>
          <InterestBearingDebt
            Controller={Controller}
            control={control}
            register={register}
            getValues={getValues}
            setValue={setValue}
            subject={subject}
          />
        </div>
      </>
    );
  });
