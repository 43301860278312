import React from 'react';
import { Valuation } from '../../../components/Valuation/Valuation';
import { ValuationResult } from './types';

export const CompanyMedicalReportValuationResult = ({
  valuationResult,
  valuationName,
}: {
  valuationResult: ValuationResult;
  valuationName: string;
}) => {
  if (!valuationResult || !valuationResult.result)
    return (
      <p className="pl-6 pr-6 pb-3 pt-3 text-gray-300 text-2xl text-center">
        未評価
      </p>
    );
  const valuation = parseFloat(String(valuationResult.result.valuation));
  if (valuation === 0)
    return (
      <div className="pl-6 pr-6 pb-3 pt-3 text-blue-500 text-2xl text-center font-bold">
        算定不可
      </div>
    );
  return (
    <Valuation
      valuation={valuation}
      valuationName={valuationName}
      valuationResult={valuationResult}
    />
  );
};
