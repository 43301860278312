import React, { useMemo } from 'react';

type ValuationOptionProps = {
  companyName: string;
  titleColor: string;
  tableColor: string;
  tableTextColor: string;
};

export const ApproachPagePreview: React.FC<ValuationOptionProps> = React.memo(
  (props) => {
    const { companyName, titleColor, tableColor, tableTextColor } = props;

    const topTexts = useMemo(() => {
      return [
        '・​本算定における事業計画は、過去実績に基づく成り行き計画を引いている。',
        '・売上高、売上原価及び販管費の計価値は以下の通りである。',
      ];
    }, []);

    const columnHeaderValues = useMemo(() => {
      const thisYear = new Date().getFullYear();
      const thisMonth = new Date().getMonth();
      return [
        '',
        `直前期 ${thisYear}年${thisMonth + 1}月期`,
        `計画1期 ${thisYear + 1}年${thisMonth + 1}月期`,
        `計画2期 ${thisYear + 2}年${thisMonth + 1}月期`,
        `計画3期 ${thisYear + 3}年${thisMonth + 1}月期`,
        `計画4期 ${thisYear + 4}年${thisMonth + 1}月期`,
        `計画5期 ${thisYear + 5}年${thisMonth + 1}月期`,
      ];
    }, []);

    const columnValues = useMemo(() => {
      return [
        ['売上高', 1000, 2000, 3000, 4000, 5000, 6000],
        ['売上原価', 1000, 2000, 3000, 4000, 5000, 6000],
        ['売上総利益', 1000, 2000, 3000, 4000, 5000, 6000],
        ['販売費及び一般管理費', 1000, 2000, 3000, 4000, 5000, 6000],
        ['営業利益', 1000, 2000, 3000, 4000, 5000, 6000],
        ['法人税等', 1000, 2000, 3000, 4000, 5000, 6000],
        ['NOPAT', 1000, 2000, 3000, 4000, 5000, 6000],
        ['正味運転資本の増減', 1000, 2000, 3000, 4000, 5000, 6000],
        ['減価償却費', 1000, 2000, 3000, 4000, 5000, 6000],
        ['CAPEX', 1000, 2000, 3000, 4000, 5000, 6000],
        ['FCF', 1000, 2000, 3000, 4000, 5000, 6000],
      ];
    }, []);

    const bottomTexts = useMemo(() => {
      return [
        '※売上高：前年度比平均から年平均成長率を算出し、成り行き予想をしている。',
        '※過去実績から売上年平均成長率が2.00%と算出されたことから、計画期は2.00％成長するものと仮定している。',
        '※売上原価、販管費は対売上比率平均を算出し、成り行き予想をしている。',
        '※過去実績から売上原価率（売上原価÷売上高）が100.00%、販管費比率（販管費÷売上高）が100.00%と算定されたことから、計画値も同様の水準で推移するものと仮定している。',
      ];
    }, []);

    return (
      <>
        <div className="shadow-md mt-10 w-12/12 h-96 border border-gray-200">
          <div className="grid grid-cols-12">
            {/* ページタイトル */}
            <div className="col-span-12 pl-5">
              <div className="grid grid-cols-12">
                <div className="col-span-10">
                  <p
                    style={{ color: titleColor, fontSize: '10px' }}
                    className="pt-3 mincho"
                  >
                    3.前提条件及び評価明細
                  </p>
                </div>
                <div className="col-span-2 pt-3">
                  <p className="text-xs border border-red-200 w-20 text-red-500 mincho">
                    confidential
                  </p>
                </div>
              </div>
              <p style={{ color: titleColor }} className="text-sm mincho">
                DCF法における前提条件[事業計画]
              </p>
            </div>

            {/* 冒頭説明文 */}
            <div className="col-span-12 pt-3 pl-5">
              {topTexts.map((val) => (
                <p key={val} style={{ fontSize: '6px' }} className="mincho">
                  {val}
                </p>
              ))}
            </div>

            {/* アプローチテーブル */}
            <div className="col-span-12 pt-1 pl-5">
              <table className="border-collapse border border-slate-500">
                <thead>
                  <tr>
                    {columnHeaderValues.map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="w-24 lg:w-20 border border-slate-600"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="w-16 text-center border border-slate-600"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {columnValues[0].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[1].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[2].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[3].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[4].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[5].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[6].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[7].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[8].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[9].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[10].map((val, idx) =>
                      idx === 0 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-left"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                </tbody>
              </table>
            </div>

            {/* 末尾説明文 */}
            <div className="col-span-12 pt-20 pl-5">
              {bottomTexts.map((val) => (
                <p key={val} style={{ fontSize: '6px' }} className="mincho">
                  {val}
                </p>
              ))}
            </div>

            {/* コピーライト */}
            <div className="col-span-12 px-5">
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-2">
                  <div className="border border-gray-200"></div>
                  <p
                    className="text-center text-gray-400"
                    style={{ fontSize: '6px' }}
                  >
                    本報告書は、当社と貴社との契約に基づき実施した調査結果を貴社内部の意思決定の参考とするために作成されており、第三者への開示は当社の事前許可なくできません。
                  </p>
                  <p
                    className="text-center text-gray-400"
                    style={{ fontSize: '6px' }}
                  >
                    ©︎ {new Date().getFullYear()} {companyName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
