import React from 'react';
import { SubjectRow } from '../../../SubjectRow/SubjectRow';
import { SubjectProps } from '../../types';

export const NetAssets: React.FC<SubjectProps> = (props) => {
  const { getValues, setValue, control } = props;
  return (
    <>
      <div className="col-span-12 py-2.5 bg-gray-400">
        <p className="text-center text-base font-semibold text-white">
          純資産の部
        </p>
      </div>
      <div className="flex col-span-12 grid grid-cols-12 py-2.5 bg-gray-200">
        <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
          科目
        </div>
        <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
          金額
        </div>
      </div>
      <SubjectRow
        label="株主資本"
        value={'subject.shareholders_equity'}
        subject={'subject.shareholders_equity'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '貸借対照表内の「株主資本」を入力する',
          placement: 'bottom-6 -left-36',
        }}
      />
      <SubjectRow
        label="資本金"
        rules={{
          required: '必須項目です。',
          min: { value: 1, message: '1以上の数値を入力してください。' },
        }}
        value={'subject.share_capital'}
        subject={'subject.share_capital'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '貸借対照表内の「資本金」を入力する',
          placement: 'bottom-6 -left-36',
        }}
      />
      <SubjectRow
        label="資本準備金"
        value={'subject.legal_capital_surplus'}
        subject={'subject.legal_capital_surplus'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '貸借対照表内の「資本準備金」を入力する',
          placement: 'bottom-6 -left-36',
        }}
      />
      <SubjectRow
        label="純資産"
        value={'subject.net_assets'}
        subject={'subject.net_assets'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '貸借対照表内の「純資産」を入力する',
          placement: 'bottom-6 -left-36',
        }}
      />
    </>
  );
};
