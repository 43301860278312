import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ResultRow from './result_row';
import './result_row.scss';

interface AnalyzeResultProp {
  fileId: string;
  pageIds: Array<string>;
  docTypes: Array<string>;
}

const AnalyzeResult: React.FC<AnalyzeResultProp> = (
  props: AnalyzeResultProp,
) => {
  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);
  const [visibleFlg, setVisibleFlg] = useState(false);

  // 解析が終わったらダウンロードボタンを表示
  const visibleDownloadButton = useCallback(() => {
    const prog = document.getElementsByClassName('analyze_progress');
    const vFlg = !Array.from(prog).some(
      (pro) => pro.textContent === 'ただいま解析中...',
    );

    if (vFlg) {
      setVisibleFlg(vFlg);
      clearInterval(refTimerId.current as number);
    }
  }, []);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);
  useEffect(() => {
    setTimerId(setInterval(visibleDownloadButton, 3000));
  }, [visibleDownloadButton]);

  return (
    <div>
      <div>
        {props.pageIds.map((value) => (
          <ResultRow key={value} pageId={value} />
        ))}
      </div>
      <div className="my-10">
        {visibleFlg ? (
          <>
            <a
              href={`/ocr/analysis_fs/download.xlsx?page_ids=${encodeURIComponent(
                props.pageIds.join(','),
              )}&doc_types=${encodeURIComponent(props.docTypes.join(','))}`}
              className="inline-block m-2 px-6 py-3 text-white bg-blue-800 rounded cursor-pointer"
            >
              ダウンロードする（.xlsx）
            </a>
          </>
        ) : null}
      </div>
    </div>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  // 表示
  const result = document.getElementById('result');
  if (result !== null) {
    // @ts-ignore
    const ids = JSON.parse(result.getAttribute('data-ids'));
    // @ts-ignore
    const doc_types = JSON.parse(result.getAttribute('data-doc_types'));
    const fileid = result.getAttribute('data-fileid');
    ReactDOM.render(
      // @ts-ignore
      <AnalyzeResult fileId={fileid} pageIds={ids} docTypes={doc_types} />,
      result,
    );
  }
});
