import React, { useCallback, useState } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import {
  CompanyMedicalReportOptionProps,
  UserProps,
  ValuationReportOptionProps,
} from './types';

export const notify = (status: string, message: string) => {
  if (status === 'success') {
    toast(message, {
      style: {
        backgroundColor: '#ffffff',
        padding: '12px',
        borderRadius: '5px',
      },
      icon: '✅',
    });
  } else {
    toast(message, {
      style: {
        backgroundColor: '#ffffff',
        padding: '12px',
        borderRadius: '10px',
      },
      icon: '❌',
    });
  }
};

export const updateUsersProfile = async (
  e: React.FormEvent<HTMLFormElement>,
  token: string,
  formState: UserProps,
) => {
  e.preventDefault();
  const formParams = new FormData();
  formParams.append('user[last_name]', formState.last_name);
  formParams.append('user[first_name]', formState.first_name);
  formParams.append('user[company_name]', formState.company_name);
  formParams.append('user[department]', formState.department);
  formParams.append('user[email]', formState.email);
  formParams.append('user[phone]', formState.phone);
  formParams.append('user[post]', formState.post);
  formParams.append('user[employees]', formState.employees);
  formParams.append('user[role]', formState.role);
  if (Array.isArray(formState.function_ids)) {
    formState.function_ids.forEach((functionId) => {
      // @ts-ignore
      formParams.append('user[function_ids][]', functionId);
    });
  }
  formParams.append(
    'user[two_factor_auth_enabled]',
    formState.two_factor_auth_enabled,
  );
  if (formState.company_logo) {
    formParams.append('user[company_logo]', formState.company_logo);
  }
  try {
    const response = await axios.patch(`/users/${formState.id}`, formParams, {
      headers: {
        'X-CSRF-Token': token,
      },
    });
    console.log(response.data);
    if (response.data.status === 'success') {
      notify('success', 'ユーザー情報を更新しました。');
    } else {
      notify('failed', 'ユーザー情報の更新失敗しました。');
    }
  } catch (error) {
    console.error(error);
    notify('failed', 'ユーザー情報の更新失敗しました。');
  }
};

export const updateCompanyMedicalReportOption = async (
  e: React.FormEvent<HTMLFormElement>,
  token: string,
  formState: CompanyMedicalReportOptionProps,
) => {
  e.preventDefault();
  const formParams = new FormData();
  formParams.append(
    'company_medical_report_option[top_image_type]',
    formState.topImageType,
  );
  formParams.append(
    'company_medical_report_option[background_character]',
    formState.backgroundCharacter,
  );
  formParams.append(
    'company_medical_report_option[table_cell_color]',
    formState.tableCellColor,
  );
  formParams.append(
    'company_medical_report_option[table_header_color]',
    formState.tableHeaderColor,
  );
  formParams.append(
    'company_medical_report_option[table_cell_text_color]',
    formState.tableCellTextColor,
  );
  formParams.append(
    'company_medical_report_option[table_header_text_color]',
    formState.tableHeaderTextColor,
  );

  try {
    const response = await axios.patch(
      `/users/${formState.user_id}/company_medical_report_options`,
      formParams,
      {
        headers: {
          'X-CSRF-Token': token,
        },
      },
    );
    console.log(response.data);
    if (response.data.status === 'success') {
      notify('success', '決算書チェッカー情報を更新しました。');
    } else {
      notify('failed', '決算書チェッカー情報の更新失敗しました。');
    }
  } catch (error) {
    console.error(error);
    notify('failed', '決算書チェッカー情報の更新失敗しました。');
  }
};

export const updateValuationReportOption = async (
  e: React.FormEvent<HTMLFormElement>,
  token: string,
  formState: ValuationReportOptionProps,
) => {
  e.preventDefault();
  const formParams = new FormData();
  formParams.append('valuation_report_option[dcf]', String(formState.dcf));
  formParams.append(
    'valuation_report_option[ebitda]',
    String(formState.ebitda),
  );
  formParams.append('valuation_report_option[per]', String(formState.per));
  formParams.append(
    'valuation_report_option[inheritance]',
    String(formState.inheritance),
  );
  formParams.append(
    'valuation_report_option[background_color]',
    formState.background_color,
  );
  formParams.append(
    'valuation_report_option[title_color]',
    formState.title_color,
  );
  formParams.append(
    'valuation_report_option[table_color]',
    formState.table_color,
  );
  formParams.append(
    'valuation_report_option[table_text_color]',
    formState.table_text_color,
  );
  formParams.append('valuation_report_option[font]', formState.font);

  try {
    const response = await axios.patch(
      `/users/${formState.user_id}/valuation_report_options`,
      formParams,
      {
        headers: {
          'X-CSRF-Token': token,
        },
      },
    );
    console.log(response.data);
    if (response.data.status === 'success') {
      notify('success', response.data.notice);
    } else {
      notify('failed', '株価算定報告書情報の更新に失敗しました。');
    }
  } catch (error) {
    console.error(error);
    notify('failed', '株価算定報告書情報の更新に失敗しました。');
  }
};

export const useSlideImage = (user_id: string, token: string | undefined) => {
  const [imagesSrc, setImagesSrc] = useState({
    top_image: '',
    family_succession_image: '',
    employee_succession_image: '',
    stock_listing_image: '',
    ma_image: '',
    cessation_image: '',
  });

  const fetchSlideImage = useCallback(
    async (slide_name) => {
      try {
        const response = await axios.get(
          `/users/${user_id}/company_medical_report_options/slide_image?slide_name=${slide_name}`,
          {
            responseType: 'arraybuffer',
            headers: {
              'X-CSRF-Token': token,
            },
          },
        );

        if (response.status === 204) {
          return;
        }

        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );

        setImagesSrc((prevImagesSrc) => ({
          ...prevImagesSrc,
          [`${slide_name}`]: `data:image/png;base64,${base64}`,
        }));
      } catch (error) {
        console.error('画像の取得に失敗しました', error);
      }
    },
    [user_id, token],
  );

  const updateSlideImage = useCallback(
    async (slide_name, slide_src) => {
      try {
        const response = await axios.post(
          `/users/${user_id}/company_medical_report_options/update_slide_image?slide_name=${slide_name}`,
          slide_src,
          {
            headers: {
              'X-CSRF-Token': token,
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (response.status === 204) {
          console.log('スライド画像がありません。');
          return;
        }
        notify('success', 'スライド画像を更新しました。');
        fetchSlideImage(slide_name);
      } catch (error) {
        notify('failed', 'スライド画像の更新に失敗しました。');
        console.error('画像の更新に失敗しました', error);
      }
    },
    [user_id, token, fetchSlideImage],
  );

  const deleteSlideImage = useCallback(
    async (slide_name) => {
      try {
        const response = await axios.delete(
          `/users/${user_id}/company_medical_report_options/delete_slide_image?slide_name=${slide_name}`,
          {
            headers: {
              'X-CSRF-Token': token,
            },
          },
        );

        if (response.data.status === 'success') {
          notify('success', 'スライド画像を削除しました。');
          setImagesSrc((prevImagesSrc) => ({
            ...prevImagesSrc,
            [`${slide_name}`]: '',
          }));
        } else {
          notify('failed', 'スライド画像の削除に失敗しました。');
          console.error('画像の削除に失敗しました');
        }
      } catch (error) {
        notify('failed', 'スライド画像の削除に失敗しました。');
        console.error('画像の削除に失敗しました', error);
      }
    },
    [user_id, token],
  );

  return { imagesSrc, fetchSlideImage, updateSlideImage, deleteSlideImage };
};
