import React, { useCallback, useEffect, useState } from 'react';

import axios from 'axios';
import { ModalProvider } from 'react-modal-hook';

import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { ValuationFormProps } from '../types/props';

export const ValuationForm: React.FC<ValuationFormProps> = (
  props: ValuationFormProps,
) => {
  const {
    resourceName,
    inheritacenValuationId,
    calculationId,
    selectedIndustries,
  } = props;

  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const [responseValidatesMessages, setResponseValidatesMessages] = useState<
    string[]
  >([]);
  const form = document.getElementById('inheritance_valuation_form');

  const postValuation = useCallback(
    (e) => {
      if (e.data && e.data.name === 'submitMaForm') {
        const postData = {
          results: e.data,
          calculation_id: calculationId,
        };
        axios
          .post(
            `/inheritances/${inheritacenValuationId}/formula/${resourceName}`,
            postData,
            {
              headers: {
                'X-CSRF-Token':
                  // @ts-ignore
                  form.parentElement.getElementsByTagName('input')[0].value,
              },
            },
          )
          .then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
              location.href = `/inheritances/${inheritacenValuationId}/formula/${resourceName}/check?calculation_id=${calculationId}`;
            } else {
              const validateItems = JSON.parse(res.data.results).validates;
              // @ts-ignore
              const validateMessages = validateItems.map((_) =>
                _.message.replace(_.key, _.name).replace('null', '未入力'),
              );
              setModalCheck(!modalCheck);
              setResponseValidatesMessages(validateMessages);
            }
          })
          .catch((err) => {
            console.log(err);
            location.href = `/inheritances/${inheritacenValuationId}`;
          });
      } else if (
        e.data &&
        e.data.name === 'cancelMaForm' &&
        e.data.value === 'canceled'
      ) {
        location.href = `/inheritances/${inheritacenValuationId}`;
      }
    },
    // @ts-ignore
    [
      calculationId,
      inheritacenValuationId,
      resourceName,
      // @ts-ignore
      form.parentElement,
      modalCheck,
    ],
  );

  useEffect((): void => {
    window.addEventListener('message', (event) => postValuation(event), false);
  }, [postValuation]);

  return (
    <ModalProvider>
      {/* 類似業種 */}
      {selectedIndustries ? (
        <div className="border mt-3 mr-6">
          <div className="bg-gray-100 border border-gray-100 flex justify-between">
            <p className="mx-1 py-1 font-semibold">STEP2で選択した類似業種</p>
            {/* <div
                className="bg-transparent py-2 text-xs hover:bg-green-500 text-green-700 font-semibold hover:text-white px-2 bg-white border border-green-500 hover:border-transparent rounded"
              >
                編集
              </div> */}
          </div>
          {/* @ts-ignore */}
          {selectedIndustries?.map((selected_industry: unknown) => (
            <div key={Math.random()} className="bg-white flex justify-between">
              {/* @ts-ignore */}
              {selected_industry?.industry.name &&
                /* @ts-ignore */
                selected_industry?.industry.id && (
                  <p className="mx-1 py-1">
                    {/* @ts-ignore */}
                    {selected_industry?.industry?.name}（No.
                    {/* @ts-ignore */}
                    {selected_industry?.industry?.id}）
                  </p>
                )}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      <div id="valuation_message_modal">
        <MessageModal
          setModal={modalCheck}
          modalType="message_list"
          modalDom="#valuation_message_modal"
          errorMessages={responseValidatesMessages}
        />
      </div>
    </ModalProvider>
  );
};
