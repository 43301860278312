// TODO：Reactに以下の処理を移す
// リロードによるスキャンチケットの二重カウント対策
// /ocr/analysis/id/result URLの書き換え
const loc = location.toString();
if (loc.includes('analysis') && loc.includes('result')) {
  if (window.performance) {
    if (performance.navigation.type === 0) {
      const url = new URL(location);
      url.toString();
      url.searchParams.set('success', 'true');
      history.pushState(null, null, url.href);
    }
  }
}
