// @ts-ignore
window.SelectAll = () => {
  // eslint-disable-next-line prefer-const
  let wordsForm = document.getElementById('words_form'),
    // @ts-ignore
    // eslint-disable-next-line prefer-const
    elements = wordsForm.elements['delete_form[word_ids][]'],
    // eslint-disable-next-line prefer-const
    elementLength = elements.length,
    // @ts-ignore
    count,
    // @ts-ignore
    // eslint-disable-next-line prefer-const
    element = wordsForm.elements['check_all'];
  for (count = 0; count < elementLength; count++) {
    if (element.checked == true) {
      elements[count].checked = true;
    } else {
      elements[count].checked = false;
    }
  }
};
