// TODO：Reactに以下の処理を移す
// リロードによるスキャンチケットの二重カウント対策
// /ocr/hif/id/convert URLの書き換え
const loc = location.toString();
if (loc.includes('hif') && loc.includes('convert')) {
  if (window.performance) {
    if (performance.navigation.type === 0) {
      const url = new URL(location);
      url.toString();
      url.searchParams.set('success', 'true');
      history.pushState(null, null, url.href);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // 商業登記簿謄本
  const uploadArea = document.getElementById('uploadArea');
  if (uploadArea !== null) {
    uploadArea.addEventListener('dragover', (event) => {
      event.preventDefault();
      event.target.classList.add('drag');
    });

    uploadArea.addEventListener('dragleave', (event) => {
      event.target.classList.remove('drag');
    });

    // 追加のdropイベント
    uploadArea.addEventListener('drop', (event) => {
      event.preventDefault();
      var input = document.querySelectorAll('input[name="files[]"]');
      input[0].files = makeFileList(input, event);
      var reset_ul = document.querySelector('#file_list');
      reset_ul.innerHTML = '';
      makeTitle(reset_ul);

      var num = 0;
      Array.from(input[0].files).forEach(function (item) {
        var ul = document.querySelector('#file_list');
        var li = createLi();
        var text = document.createTextNode(item.name);
        var space = document.createTextNode('　');
        const deleteButton = createButton(num);
        li.appendChild(text);
        li.appendChild(space);
        li.appendChild(deleteButton);
        ul.appendChild(li);
        num = num + 1;
      });
    });

    // ファイルが選択されたら実行
    document
      .getElementById('upload_file')
      .addEventListener('change', function () {
        var reset_ul = document.querySelector('#file_list');
        reset_ul.innerHTML = '';
        makeTitle(reset_ul);

        var input = document.querySelectorAll('input[name="files[]"]');

        var num = 0;
        Array.from(input[0].files).forEach(function (item) {
          var ul = document.querySelector('#file_list');
          var li = createLi();
          var text = document.createTextNode(item.name);
          var space = document.createTextNode('　');
          const deleteButton = createButton(num);
          li.appendChild(text);
          li.appendChild(space);
          li.appendChild(deleteButton);
          ul.appendChild(li);
          num = num + 1;
        });
      });

    // ファイル削除
    document
      .getElementById('file_list')
      .addEventListener('click', function (e) {
        // id取得
        var file_id = e.path[0].id;
        var input = document.querySelectorAll('input[name="files[]"]');

        // 削除後の配列作成
        var new_files = createNewFiles(file_id, input);

        // FileList再作成
        input[0].files = createNewFileList(new_files);

        // リストの表示
        var reset_ul = document.querySelector('#file_list');
        reset_ul.innerHTML = '';
        makeTitle(reset_ul);
        var num = 0;
        Array.from(input[0].files).forEach(function (item) {
          var ul = document.querySelector('#file_list');
          var li = createLi();
          var text = document.createTextNode(item.name);
          var space = document.createTextNode('　');
          const deleteButton = createButton(num);
          li.appendChild(text);
          li.appendChild(space);
          li.appendChild(deleteButton);
          ul.appendChild(li);
          num = num + 1;
        });
      });
    uploadAnimation();
  }

  // 債権譲渡謄本
  const creditUploadArea = document.getElementById('credit_upload_area');
  if (creditUploadArea !== null) {
    creditUploadArea.addEventListener('dragover', (event) => {
      event.preventDefault();
      event.target.classList.add('drag');
    });

    creditUploadArea.addEventListener('dragleave', (event) => {
      event.target.classList.remove('drag');
    });

    // 追加のdropイベント
    creditUploadArea.addEventListener('drop', (event) => {
      event.preventDefault();
      var input = document.querySelectorAll('input[name="credit_files[]"]');
      input[0].files = makeFileList(input, event);
      var reset_ul = document.querySelector('#credit_file_list');
      reset_ul.innerHTML = '';
      makeTitle(reset_ul);
      var num = 0;

      Array.from(input[0].files).forEach(function (item) {
        var ul = document.querySelector('#credit_file_list');
        var li = createLi();
        var text = document.createTextNode(item.name);
        var space = document.createTextNode('　');
        const deleteButton = createButton(num);
        li.appendChild(text);
        li.appendChild(space);
        li.appendChild(deleteButton);
        ul.appendChild(li);
        num = num + 1;
      });
    });

    // ファイルが選択されたら実行
    document
      .getElementById('upload_credit_file')
      .addEventListener('change', function () {
        var reset_ul = document.querySelector('#credit_file_list');
        reset_ul.innerHTML = '';
        makeTitle(reset_ul);

        var input = document.querySelectorAll('input[name="credit_files[]"]');

        var num = 0;
        Array.from(input[0].files).forEach(function (item) {
          var ul = document.querySelector('#credit_file_list');
          var li = createLi();
          var text = document.createTextNode(item.name);
          var space = document.createTextNode('　');
          const deleteButton = createButton(num);
          li.appendChild(text);
          li.appendChild(space);
          li.appendChild(deleteButton);
          ul.appendChild(li);
          num = num + 1;
        });
      });

    // ファイル削除
    document
      .getElementById('credit_file_list')
      .addEventListener('click', function (e) {
        // id取得
        var file_id = e.path[0].id;
        var input = document.querySelectorAll('input[name="credit_files[]"]');

        // 削除後の配列作成
        var new_files = createNewFiles(file_id, input);

        // FileList再作成
        input[0].files = createNewFileList(new_files);

        // リストの表示
        var reset_ul = document.querySelector('#credit_file_list');
        reset_ul.innerHTML = '';
        makeTitle(reset_ul);
        var num = 0;
        Array.from(input[0].files).forEach(function (item) {
          var ul = document.querySelector('#credit_file_list');
          var li = createLi();
          var text = document.createTextNode(item.name);
          var space = document.createTextNode('　');
          const deleteButton = createButton(num);
          li.appendChild(text);
          li.appendChild(space);
          li.appendChild(deleteButton);
          ul.appendChild(li);
          num = num + 1;
        });
      });
    uploadAnimation();
  }
});

// アップロードアニメーション
const uploadAnimation = () => {
  document.getElementById('up-btn').addEventListener('click', function (error) {
    console.error('error =', error);
    var loadRap = document.getElementById('load-rap');
    loadRap.classList.add('hidden');
    var loadingdIcon = document.getElementById('loading-icon');
    loadingdIcon.classList.remove('hidden');
  });
};

function makeTitle(reset_ul) {
  var title = document.createTextNode('アップロードファイル：');
  var p = document.createElement('p');
  p.appendChild(title);
  reset_ul.appendChild(p);
}

function createLi() {
  var li = document.createElement('li');
  li.classList.add('list-disc', 'pt-1');
  return li;
}

function makeFileList(input, event) {
  // 選択済みファイル
  var file_length = input[0].files.length;
  var selected_files = Array.from(input[0].files).slice(0, file_length);
  // ファイル追加
  var add_file_length = event.dataTransfer.files.length;
  var add_files = Array.from(event.dataTransfer.files).slice(
    0,
    add_file_length,
  );
  var new_files = selected_files.concat(add_files);

  // FileList再作成
  let list = new DataTransfer();
  new_files.forEach(function (item) {
    list.items.add(item);
  });
  return list.files;
}

function createNewFiles(file_id, input) {
  // 削除後の配列作成
  var file_length = input[0].files.length;
  var front_files = Array.from(input[0].files).slice(0, file_id);
  var start_point = Number(file_id) + 1;
  var back_files = Array.from(input[0].files).slice(start_point, file_length);
  var new_files = front_files.concat(back_files);
  return new_files;
}

function createNewFileList(new_files) {
  // FileList再作成
  let list = new DataTransfer();
  new_files.forEach(function (item) {
    list.items.add(item);
  });
  return list.files;
}

function createButton(num) {
  const deleteButton = document.createElement('input');
  deleteButton.type = 'button';
  deleteButton.value = '削除';
  deleteButton.classList.add(
    'text-xs',
    'rounded-md',
    'px-2',
    'py-1',
    'hover:text-white',
    'bg-gray-100',
    'hover:bg-black',
  );
  deleteButton.id = num;
  return deleteButton;
}
