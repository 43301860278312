import React, { useCallback } from 'react';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import { InheritanceValuationProps } from '../types/props';

export const InheritanceValuationSubjects: React.FC<
  InheritanceValuationProps
> = (props: InheritanceValuationProps) => {
  const { inheritanceValuation } = props;

  const firstFsUploadPagesResult =
    inheritanceValuation.first_financial_statement_upload_pages
      ? JSON.parse(inheritanceValuation.first_financial_statement_upload_pages)
      : '';

  const firstCtUploadPagesResult =
    inheritanceValuation.first_corporate_tax_return_upload_pages
      ? JSON.parse(inheritanceValuation.first_corporate_tax_return_upload_pages)
      : '';
  const secondCtUploadPagesResult =
    inheritanceValuation.second_corporate_tax_return_upload_pages
      ? JSON.parse(
          inheritanceValuation.second_corporate_tax_return_upload_pages,
        )
      : '';
  const thirdCtUploadPagesResult =
    inheritanceValuation.third_corporate_tax_return_upload_pages
      ? JSON.parse(inheritanceValuation.third_corporate_tax_return_upload_pages)
      : '';

  const checkSubjectsInputFormType = useCallback(
    (
      is_ocr: number,
      file_id: number,
      phase: number,
      analysis_type: string,
      subject_type: string,
    ) => {
      if (is_ocr === 1) {
        const url = `/inheritances/${inheritanceValuation.id}/ocr/${analysis_type}/${file_id}/check?type=${subject_type}&phase=${phase}`;
        return url;
      } else {
        const url = `/inheritances/${inheritanceValuation.id}/subjects/edit?type=${subject_type}&phase=${phase}`;
        return url;
      }
    },
    [inheritanceValuation.id],
  );

  const getSubjectsInputForm = useCallback((subject_input_url) => {
    location.href = subject_input_url;
  }, []);

  return (
    <>
      <div className="relative pb-8">
        <span
          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300"
          aria-hidden="true"
        ></span>

        <div className="relative flex space-x-3">
          <div>
            <span className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white">
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule={'evenodd'}
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule={'evenodd'}
                />
              </svg>
            </span>
          </div>

          <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
            <div>
              <div className="flex">
                <p className="text-lg text-green-400 font-semibold">STEP1</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">3</span>分
                </p>
              </div>
              <p className="pt-1.5 text-xl text-gray-700 font-bold">
                財務税務情報の入力
              </p>
              <p className="pt-1.5 text-sm text-gray-500">
                過去3期分の法人税申告書及び直前期の決算書を入力する
              </p>

              <div className="grid grid-cols-12 pt-10">
                <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                  直前期
                </div>

                <div className="text-right col-span-1 text-xs text-green-500 pt-2">
                  推奨
                </div>

                {inheritanceValuation.first_corporate_tax_return_upload_status ||
                inheritanceValuation.first_phase_corporate_tax_return_file_id ? (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          checkSubjectsInputFormType(
                            inheritanceValuation.first_corporate_tax_return_upload_status,
                            firstCtUploadPagesResult['file_id'],
                            1,
                            'analysis_ct',
                            'tax',
                          ),
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      法人税申告書情報を編集する
                    </div>
                  </div>
                ) : (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          `/inheritances/${inheritanceValuation.id}/subjects/new?phase=1&type=tax`,
                        )
                      }
                      className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      法人税申告書情報を入力する
                    </div>
                  </div>
                )}

                <div className="text-right col-span-1 text-xs text-green-500 pt-2">
                  推奨
                </div>

                {inheritanceValuation.first_financial_statement_upload_status ||
                inheritanceValuation.first_phase_financial_statement_file_id ? (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          checkSubjectsInputFormType(
                            inheritanceValuation.first_financial_statement_upload_status,
                            firstFsUploadPagesResult['file_id'],
                            1,
                            'analysis_fs',
                            'financial',
                          ),
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      決算書情報を編集する
                    </div>
                  </div>
                ) : (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          `/inheritances/${inheritanceValuation.id}/subjects/new?phase=1&type=financial`,
                        )
                      }
                      className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      決算書情報を入力する
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-12 pt-5">
                <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                  直前々期
                </div>
                <div className="text-right col-span-1 text-xs text-gray-500 pt-2">
                  任意
                </div>
                {inheritanceValuation.second_corporate_tax_return_upload_status ||
                inheritanceValuation.second_phase_corporate_tax_return_file_id ? (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          checkSubjectsInputFormType(
                            inheritanceValuation.second_corporate_tax_return_upload_status,
                            secondCtUploadPagesResult['file_id'],
                            2,
                            'analysis_ct',
                            'tax',
                          ),
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      法人税申告書情報を編集する
                    </div>
                  </div>
                ) : (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          `/inheritances/${inheritanceValuation.id}/subjects/new?phase=2&type=tax`,
                        )
                      }
                      className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      法人税申告書情報を入力する
                    </div>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-12 pt-5">
                <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                  直前々期の前期
                </div>
                <div className="text-right col-span-1 text-xs text-gray-500 pt-2">
                  任意
                </div>
                {inheritanceValuation.third_corporate_tax_return_upload_status ||
                inheritanceValuation.third_phase_corporate_tax_return_file_id ? (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          checkSubjectsInputFormType(
                            inheritanceValuation.third_corporate_tax_return_upload_status,
                            thirdCtUploadPagesResult['file_id'],
                            3,
                            'analysis_ct',
                            'tax',
                          ),
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      法人税申告書情報を編集する
                    </div>
                  </div>
                ) : (
                  <div className="col-span-4 ml-4">
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          `/inheritances/${inheritanceValuation.id}/subjects/new?phase=3&type=tax`,
                        )
                      }
                      className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      法人税申告書情報を入力する
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
