import React, { Dispatch, SetStateAction } from 'react';
import { CompanyMedicalReportPreview } from './previews/CompanyMedicalReportPreview';
import { CompanyMedicalReportPreviewProps } from './types';
import { CompanyMedicalReportOptionProps } from '../users/types';
import { ColorPicker } from '../../components/ColorPicker/ColorPicker';

export const CompanyMedicalReportOptions: React.FC<{
  company_medical_report_option: CompanyMedicalReportPreviewProps;
  chart_sample_img: string;
  rotation_sample_img: string;
  formState: CompanyMedicalReportOptionProps;
  setFormState: Dispatch<SetStateAction<CompanyMedicalReportOptionProps>>;
}> = (props) => {
  const { chart_sample_img, rotation_sample_img, formState, setFormState } =
    props;

  return (
    <>
      <p className="mb-5 block text-md font-medium leading-6 text-gray-900">
        レポートカラー設定
      </p>
      <div className="grid grid-cols-4">
        <ColorPicker
          color={formState.tableHeaderColor}
          label="テーブルヘッダー"
          name="tableHeaderColor"
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, tableHeaderColor: color }))
          }
        />
        <ColorPicker
          label="テーブルヘッダーテキスト"
          color={formState.tableHeaderTextColor}
          name="tableHeaderTextColor"
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, tableHeaderTextColor: color }))
          }
        />
        <ColorPicker
          label="テーブルセル"
          color={formState.tableCellColor}
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, tableCellColor: color }))
          }
          name="tableCellColor"
        />
        <ColorPicker
          color={formState.tableCellTextColor}
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, tableCellTextColor: color }))
          }
          label="テーブルセルテキスト"
          name="tableCellTextColor"
        />
      </div>
      <CompanyMedicalReportPreview
        tableHeaderColor={formState.tableHeaderColor}
        tableHeaderTextColor={formState.tableHeaderTextColor}
        tableCellColor={formState.tableCellColor}
        tableCellTextColor={formState.tableCellTextColor}
        chartSampleImg={chart_sample_img}
        rotationSampleImg={rotation_sample_img}
      />
    </>
  );
};
