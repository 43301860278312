// @ts-ignore
window.toggleModal = function toggleModal(
  // @ts-ignore
  modal_id,
  // @ts-ignore
  dictionary_id,
  // @ts-ignore
  dictionary_name,
) {
  // 削除ボタンの生成
  const open_delete_modal_btn = document.getElementById(
    'open_delete_modal_btn',
  );
  if (open_delete_modal_btn) {
    open_delete_modal_btn.remove();
  }
  const delete_button =
    '<a href="/ocr/dictionaries/' +
    dictionary_id +
    '" data-method="delete" id="open_delete_modal_btn" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">削除</a>';
  const button_div = document.getElementById('button_div');
  // @ts-ignore
  button_div.insertAdjacentHTML('afterbegin', delete_button);

  // 未使用
  // const delete_target_dictionary_name = document.getElementById(
  //   'delete_target_dictionary_name',
  // );

  // 辞書名の挿入
  // @ts-ignore
  document.getElementById('delete_target_dictionary_name').innerHTML =
    dictionary_name;

  // modalの表示
  // @ts-ignore
  document.getElementById(modal_id).classList.toggle('hidden');
};
