import React from 'react';
import { SubjectRow } from '../../SubjectRow/SubjectRow';
import { SubjectProps } from '../types';
interface ProfitAndLossStatementProps extends SubjectProps {
  phase: string;
}

export const ProfitAndLossStatement: React.FC<ProfitAndLossStatementProps> = (
  props: ProfitAndLossStatementProps,
) => {
  const { getValues, setValue, phase, control } = props;
  return (
    <div className="grid grid-cols-12 divide-y">
      <SubjectRow
        label="売上高"
        value={'subject.net_sales'}
        subject={'subject.net_sales'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '損益計算書内の「売上高合計」を入力する',
          placement: 'bottom-6 -left-12',
        }}
      />
      <SubjectRow
        label="売上原価"
        value={'subject.cost_of_sales'}
        subject={'subject.cost_of_sales'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '損益計算書内の「売上原価合計」を入力する',
          placement: 'bottom-6 -left-12',
        }}
      />
      <SubjectRow
        label="販売費及び一般管理費"
        value={'subject.sga'}
        subject={'subject.sga'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '損益計算書内の「販売費及び一般管理費」を入力する',
          placement: 'bottom-6 -left-12',
        }}
      />
      <SubjectRow
        label="営業利益"
        value={'subject.operating_profit'}
        subject={'subject.operating_profit'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '損益計算書内の「営業利益」を入力する',
          placement: 'bottom-6 -left-12',
        }}
      />
      {phase == '1' ? (
        <>
          <div className="flex col-span-12 grid grid-cols-12 py-1">
            <div className="col-span-6 text-left pl-2 pt-1">営業外費用</div>
            <div className="col-span-6 pr-3"></div>
          </div>
          <SubjectRow
            label="支払利息"
            value={'subject.interest_expenses'}
            subject={'subject.interest_expenses'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            toolTipProps={{
              text: '損益計算書内の「支払利息」、「社債利息」等、営業外費用内の利息と名の付く科目を集計して入力する',
              placement: 'bottom-6 -left-12',
            }}
            indent
          />
          <SubjectRow
            label="当期純利益"
            value={'subject.profit'}
            subject={'subject.profit'}
            control={control}
            getValues={getValues}
            setValue={setValue}
            toolTipProps={{
              text: '損益計算書内の「当期純利益」を入力する',
              placement: 'bottom-6 -left-12',
            }}
            indent
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
