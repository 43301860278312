import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import domtoimage from 'dom-to-image';

interface ResultRowProp {
  pageId: string;
}

const ResultRow: React.FC<ResultRowProp> = (props: ResultRowProp) => {
  const [image, setImage] = useState('');

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [result, setResult] = useState([]);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [progress, setProgress] = useState('ただいま解析中...');

  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);

  // TODO: ソースコードが重複しているのでまとめてください
  const tableToImage = useCallback(() => {
    const tableWrapper = document.getElementById(
      `result_table_${props.pageId}`,
    );
    // @ts-ignore
    const table = tableWrapper.getElementsByTagName('table')[0];
    if (table !== undefined) {
      domtoimage
        .toPng(table)
        .then(function (dataUrl) {
          // dataUrlが返ってくるのでImageを生成し生成した画像を読み込む
          const img = new Image();
          img.src = dataUrl;
          img.className = 'w-full';

          // 読み込みが終わったらimgを描画する
          img.onload = () => {
            // @ts-ignore
            tableWrapper.appendChild(img);
            table.remove();
          };
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.pageId]);

  // 解析データ表示
  // TODO: ソースコードが重複しているのでまとめてください
  const setResultTable = useCallback(() => {
    axios
      .get(`/ocr/analysis/${props.pageId}/page_table_result`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          clearInterval(refTimerId.current as number);

          setResult(res.data.body);

          // 解析データ無し
          if (res.data.body.length === 0) {
            setProgress('OCR変換が出来ませんでした。');
          }

          tableToImage();
        } else if (res.data.status === 'failed') {
          setProgress('OCR変換が出来ませんでした。');
          clearInterval(refTimerId.current as number);
        } else if (res.data.status === 'error') {
          setProgress('OCR変換でエラーが発生しました。');
          clearInterval(refTimerId.current as number);
        }
      })
      .catch((err) => {
        console.log(err);
        setProgress('OCR変換でエラーが発生しました。');
        clearInterval(refTimerId.current as number);
      });
  }, [props.pageId, tableToImage]);

  // PDF表示（初期表示のみ）
  // TODO: ソースコードが重複しているのでまとめてください
  const setPdf = useCallback(() => {
    axios
      .get(`/ocr/analysis/${props.pageId}/page_pdf`)
      .then((res) => {
        setImage(`data:application/pdf;base64,${res.data}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.pageId]);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);
  useEffect(() => {
    setPdf();
    setTimerId(setInterval(setResultTable, 3000));
    setResultTable();
  }, [setPdf, setResultTable]);

  return (
    <div className="page_result flex">
      <div className="w-full">
        <embed
          className="pdf"
          type="application/pdf"
          src={image}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default ResultRow;
