import React from 'react';
import { useReportSubjectsStep } from './hooks';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { ReportSubjectsStepProps } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import NaviIcon from '../../../../../assets/images/icons/icon_navi.png';

export const ReportSubjectsStep: React.FC<ReportSubjectsStepProps> = (
  props,
) => {
  const { report } = props;
  const { parseResult, checkSubjectsInputFormType, getSubjectsInputForm } =
    useReportSubjectsStep(report);

  return (
    <>
      <div className="relative pb-8">
        <span
          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300"
          aria-hidden="true"
        ></span>
        <div className="relative flex space-x-3">
          <div>
            <span className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white">
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule={'evenodd'}
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule={'evenodd'}
                />
              </svg>
            </span>
          </div>
          <div className="w-full">
            <div className="flex">
              <p className="text-lg text-green-400 font-semibold">STEP1</p>
              <span className="ml-3 mr-2 mt-2">
                <img className="h-3 w-3" src={TimeIcon} />
              </span>
              <p className="text-xs text-gray-400 mt-1.5">
                所要時間 約<span className="font-bold">3</span>分
              </p>
            </div>
            <div className="grid grid-cols-12 w-6/12">
              <div className="col-span-11">
                <p className="text-xl text-gray-700 font-bold pt-3">
                  財務情報の入力
                </p>
              </div>
              <div className="col-span-1 mb-3">
                <Tooltip
                  baseStyles="rounded-full bg-white"
                  tipPlacement="bottom-1 left-3"
                  tipText="過去3期分の決算書（貸借対照表、損益計算書）をご用意して頂きご入力をお願いします。計算書のPDFデータをアップロードすると決算数値を取り込む補助機能を搭載しております。"
                >
                  <span className="h-10 w-10 flex items-end justify-end bg-white">
                    <img className="h-10 w-10" src={NaviIcon} />
                  </span>
                </Tooltip>
              </div>
            </div>
            <hr className="w-6/12"></hr>
            <p className="pt-1.5 text-sm text-gray-500">
              貸借対照表、損益計算書、販売費及び一般管理費内訳及び製造原価報告書の財務数値を入力をします。
            </p>
            <p className="text-sm text-gray-500">
              ※OCR解析で入力サポートすることもできます。
            </p>

            <div className="grid grid-cols-10 pt-4">
              <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                直前期
              </div>
              <div className="text-right col-span-1 text-xs text-green-500 pt-2">
                推奨
              </div>
              <div className="col-span-6 ml-4">
                {report.first_phase_upload_status ||
                report.first_phase_file_id ? (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        checkSubjectsInputFormType(
                          report.first_phase_upload_status,
                          parseResult(report.first_upload_pages)['file_id'],
                          1,
                        ),
                      )
                    }
                    className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 hover:text-white"
                  >
                    決算書情報を編集する
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        `/reports/${report.id}/subjects/new?phase=1`,
                      )
                    }
                    className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    決算書情報を入力する
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-10 pt-5">
              <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                直前々期
              </div>
              <div className="text-right col-span-1 text-xs text-gray-500 pt-2">
                任意
              </div>
              <div className="col-span-6 ml-4">
                {report.second_phase_upload_status ||
                report.second_phase_file_id ? (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        checkSubjectsInputFormType(
                          report.second_phase_upload_status,
                          parseResult(report.second_upload_pages)['file_id'],
                          2,
                        ),
                      )
                    }
                    className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 hover:text-white"
                  >
                    決算書情報を編集する
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        `/reports/${report.id}/subjects/new?phase=2`,
                      )
                    }
                    className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    決算書情報を入力する
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-10 pt-5">
              <div className="col-span-2 text-sm font-semibold text-gray-500 pt-1.5">
                直前々期の前期
              </div>
              <div className="text-right col-span-1 text-xs text-gray-500 pt-2">
                任意
              </div>
              <div className="col-span-6 ml-4">
                {report.third_phase_upload_status ||
                report.third_phase_file_id ? (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        checkSubjectsInputFormType(
                          report.third_phase_upload_status,
                          parseResult(report.third_upload_pages)['file_id'],
                          3,
                        ),
                      )
                    }
                    className="cursor-pointer outline-2 inline-flex items-center px-8 py-2 border text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 hover:text-white"
                  >
                    決算書情報を編集する
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        `/reports/${report.id}/subjects/new?phase=3`,
                      )
                    }
                    className="cursor-pointer inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    決算書情報を入力する
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
