// @ts-ignore
window.toggleModal = function toggleModal(
  // @ts-ignore
  modalID,
  // @ts-ignore
  dictionary_id,
  // @ts-ignore
  word_id,
  // @ts-ignore
  word_name,
) {
  // 削除処理
  // 削除ボタンの生成
  const open_delete_modal_btn = document.getElementById(
    'open_delete_modal_btn',
  );
  if (open_delete_modal_btn) {
    open_delete_modal_btn.remove();
  }
  const delete_button =
    '<a href="/ocr/dictionaries/' +
    dictionary_id +
    '/words/' +
    word_id +
    '" data-method="delete" id="open_delete_modal_btn" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">削除</a>';
  const delete_button_div = document.getElementById('delete_button_div');
  // @ts-ignore
  delete_button_div.insertAdjacentHTML('afterbegin', delete_button);

  // 単語名の挿入
  const delete_target_word_name = document.getElementById(
    'delete_target_word_name',
  );
  // @ts-ignore
  delete_target_word_name.innerHTML = word_name;

  // @ts-ignore
  // modalの表示
  document.getElementById(modalID).classList.toggle('hidden');
};

// @ts-ignore
window.submit_fnc = () => {
  // @ts-ignore
  document.getElementById('delete_submit').click();
};

window.addEventListener('load', () => {
  console.log('hoge');
});
