import React from 'react';
import { TopPagePreview } from './TopPagePreview';
import { HeaderPagePreview } from './HeaderPagePreview';
import { SummaryPagePreview } from './SummaryPagePreview';
import { ApproachPagePreview } from './ApproachPagePreview';

type ValuationReportPreviewProps = {
  companyName: string;
  titleColor: string;
  backgroundColor: string;
  tableColor: string;
  tableTextColor: string;
};

export const ValuationReportPreview: React.FC<ValuationReportPreviewProps> =
  React.memo((props) => {
    const {
      companyName,
      titleColor,
      backgroundColor,
      tableColor,
      tableTextColor,
    } = props;

    return (
      <>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-6">
            <TopPagePreview
              companyName={companyName}
              titleColor={titleColor}
              backgroundColor={backgroundColor}
            />
          </div>
          <div className="col-span-6">
            <HeaderPagePreview
              companyName={companyName}
              titleColor={titleColor}
            />
          </div>
          <div className="col-span-6">
            <SummaryPagePreview
              companyName={companyName}
              titleColor={titleColor}
              backgroundColor={backgroundColor}
              tableColor={tableColor}
              tableTextColor={tableTextColor}
            />
          </div>
          <div className="col-span-6">
            <ApproachPagePreview
              companyName={companyName}
              titleColor={titleColor}
              tableColor={tableColor}
              tableTextColor={tableTextColor}
            />
          </div>
        </div>
      </>
    );
  });
