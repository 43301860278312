import React, { ChangeEventHandler } from 'react';
type CheckboxFieldProps = {
  name: string;
  label: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  checked,
  onChange,
}) => (
  <div className="relative flex items-start">
    <div className="flex h-6 items-center">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </div>
    <div className="ml-3 text-md leading-6">
      <label className="font-medium text-gray-900">{label}</label>
    </div>
  </div>
);
