import React from 'react';
import { InheritanceValuations } from './InheritanceValuations';
import { InheritanceValuationsProps } from '../types/props';

export const InheritanceValuationIndex: React.FC<InheritanceValuationsProps> = (
  props: InheritanceValuationsProps,
) => {
  const { inheritanceValuations } = props;

  return (
    <>
      {0 < inheritanceValuations?.length ? (
        <div className="flex flex-col pl-36 pr-36 pt-7">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border border-gray-200 sm:rounded-sm">
                <table className="table-auto min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col-2"
                        className="w-6/12 pl-3 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                      >
                        対象会社名
                      </th>
                      <th
                        scope="col"
                        className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                      >
                        課税時期
                      </th>
                      <th
                        scope="col"
                        className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                      >
                        最終更新日
                      </th>
                      <th
                        scope="col"
                        className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                      <th
                        scope="col"
                        className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <InheritanceValuations
                      inheritanceValuations={inheritanceValuations}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col pl-36 pr-36 pt-20 text-center">
          検索結果が見つかりませんでした。
        </div>
      )}
    </>
  );
};
