import React, { useEffect } from 'react';
import { useReportGenPptxStep } from './hooks';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { reportGenPptxStepProps } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import NaviIcon from '../../../../../assets/images/icons/icon_navi.png';

export const ReportGenPptxStep: React.FC<reportGenPptxStepProps> = (props) => {
  const {
    report,
    isDcfEnabled,
    isEbitdaEnabled,
    isPerEnabled,
    isDcfCalculated,
    isEbitdaCalculated,
    isPerCalculated,
  } = props;
  const { reportGenPptxStepStyle, setReportGenPptxStep, getReportPptxForm } =
    useReportGenPptxStep(report);

  useEffect(() => {
    if (
      (isDcfCalculated && isDcfEnabled) ||
      (isEbitdaCalculated && isEbitdaEnabled) ||
      (isPerCalculated && isPerEnabled)
    ) {
      setReportGenPptxStep({
        checkButton:
          'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white',
        step: 'text-lg text-green-400 font-semibold',
        name: 'pt-1.5 text-xl text-gray-700 font-bold',
        reportButton:
          'cursor-pointer inline-flex items-center px-4 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <div className="relative pb-8">
        <div className="relative flex space-x-3">
          <div>
            <span className={reportGenPptxStepStyle.checkButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="w-full">
            <div>
              <div className="flex">
                <p className="text-lg text-green-400 font-semibold">STEP4</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">1</span>分
                </p>
              </div>
              <div className="grid grid-cols-12 w-6/12">
                <div className="col-span-11">
                  <p className="text-xl text-gray-700 font-bold pt-3">
                    株価算定報告書の作成
                  </p>
                </div>
                <div className="col-span-1 mb-3">
                  <Tooltip
                    baseStyles="rounded-full bg-white"
                    tipPlacement="bottom-1 left-3"
                    tipText="Step3で評価した株式価値を株価算定報告書に出力することが出来ます。評価に使用した資料、評価対象会社の情報の入力をお願いします。"
                  >
                    <span className="h-10 w-10 flex items-end justify-end bg-white">
                      <img className="h-10 w-10" src={NaviIcon} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <hr className="w-6/12"></hr>
              <p className="pt-1.5 text-sm text-gray-500">
                算定結果をもとに株価算定報告書をパワーポイント形式で書き出します
              </p>
              <div className="pt-4">
                <div
                  onClick={() => getReportPptxForm()}
                  className={reportGenPptxStepStyle.reportButton}
                >
                  レポートを書き出す
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
