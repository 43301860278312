import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';
import { useFormulaForm } from '../../reports/formula/hooks';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { FormulaWaccSelector } from '../../reports/formula/FormulaWaccSelector';
import { FormulaFormProps } from '../../reports/formula/types';

export const ValuationForm: React.FC<FormulaFormProps> = (props) => {
  const {
    userRole,
    resourceName,
    reportId,
    calculationId,
    industries,
    similarIndustry,
    similaritiesCompanies,
  } = props;

  const {
    responseValidatesMessages,
    modalCheck,
    postValuation,
    switchResourceValue,
  } = useFormulaForm(industries, similaritiesCompanies, similarIndustry);
  const token = document
    ?.getElementById('report_valuation_form')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  useEffect(() => {
    Modal.setAppElement('#formula_form');
    window.addEventListener(
      'message',
      (event) =>
        postValuation(
          event,
          resourceName,
          `/company_medical_reports/${reportId}/valuations?id=${calculationId}&method=${resourceName}`,
          `/company_medical_reports/${reportId}/valuation_results/${calculationId}`,
          token,
        ),
      false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalProvider>
      {similarIndustry ? (
        <div className="border mt-3 mr-6 mb-8">
          <div className="flex bg-gray-100 border border-gray-100 flex justify-between">
            <div className="relative flex items-start">
              <div className="flex items-center mt-1"></div>
              <div className="ml-2 mb-1">
                <p className="text-gray-800 font-semibold">類似業種</p>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="grid grid-cols-12">
              <div className="col-span-8 text-left text-sm py-1 px-1">
                {similarIndustry?.name}
              </div>
            </div>
            <div className="grid grid-cols-12 bg-red-50">
              <div className="col-span-10 text-left py-1 px-1 text-sm">
                {switchResourceValue(resourceName)?.name}
              </div>
              <div className="col-span-2 text-center text-sm py-1">
                {switchResourceValue(resourceName)?.value}
              </div>
            </div>
            {resourceName === 'dcf' ? (
              <div className="grid grid-cols-12 bg-red-50">
                <div className="col-span-10 text-left py-1 px-1 text-sm">
                  Unleverdβ 平均値
                </div>
                <div className="col-span-2 text-center text-sm py-1">
                  {similarIndustry?.unlevered_beta}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {resourceName === 'dcf' ? (
        <FormulaWaccSelector
          // @ts-ignore
          averageUnleveredBeta={similarIndustry?.unlevered_beta}
          userRole={userRole}
        />
      ) : (
        <></>
      )}
      <MessageModal
        setModal={modalCheck}
        modalType="message_list"
        modalDom="#formula_form"
        errorMessages={responseValidatesMessages}
      />
    </ModalProvider>
  );
};
