import React, { useMemo } from 'react';
import { MoneyFormatter } from '../../utils/common';
import { VALUTION_MAX_RATE, VALUTION_MIN_RATE } from '../../utils/constant';
import { ValuationResult } from '../../pages/company_medical_reports/company_medical_reports/types';

export const Valuation = ({
  valuation,
  valuationName,
  valuationResult,
}: {
  valuation: number;
  valuationName: string;
  valuationResult: ValuationResult;
}) => {
  const nonLiquidDiscount = useMemo((): number => {
    if (!valuationResult) return 0;
    if (valuationName === '相続税評価') return 0;
    if (!('breakdown' in valuationResult)) return 0;
    if (!valuationResult.breakdown[0].non_liquidity_discount) return 0;
    return Number(valuationResult.breakdown[0].non_liquidity_discount);
  }, [valuationName, valuationResult]);

  const formattedValuation = useMemo(() => {
    if (valuationName === '相続税評価') {
      return MoneyFormatter(Math.round(valuation));
    }
    const minValuation = MoneyFormatter(
      Math.round(valuation * VALUTION_MIN_RATE * (1 - nonLiquidDiscount)),
    );
    const maxValuation = MoneyFormatter(
      Math.round(valuation * VALUTION_MAX_RATE * (1 - nonLiquidDiscount)),
    );
    return `${minValuation} 〜 ${maxValuation}`;
  }, [valuation, valuationName, nonLiquidDiscount]);

  return (
    <div className="pb-1 pt-3 text-blue-500 text-lg text-center font-semibold">
      {formattedValuation}
    </div>
  );
};
