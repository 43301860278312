import React from 'react';
import { Sga } from './Sga';
import { SubjectProps } from '../types';

export const SgaContainer: React.FC<SubjectProps> = (props: SubjectProps) => {
  const { getValues, setValue, control, reportType = 'reports' } = props;
  return (
    <Sga
      getValues={getValues}
      setValue={setValue}
      control={control}
      reportType={reportType}
    />
  );
};
