import React, { useEffect } from 'react';
import { useCompanyMedicalReportPptxStep } from './hooks';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import { CompanyMedicalReportProps } from './types';

type CompanyMedicalReportPptxStepProps = {
  companyMedicalReport: CompanyMedicalReportProps;
  allValuationsCalculated: boolean;
};

export const CompanyMedicalReportPptxStep: React.FC<
  CompanyMedicalReportPptxStepProps
> = (props) => {
  const { companyMedicalReport, allValuationsCalculated } = props;
  const { reportGenPptxStepStyle, setReportGenPptxStep, getReportPptxForm } =
    useCompanyMedicalReportPptxStep(companyMedicalReport);

  useEffect(() => {
    if (!allValuationsCalculated) return;
    setReportGenPptxStep({
      checkButton:
        'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white',
      step: 'text-lg text-green-400 font-semibold',
      name: 'pt-1.5 text-xl text-gray-700 font-bold',
      reportButton:
        'cursor-pointer inline-flex items-center px-14 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <div className="relative pb-8">
        <div className="relative flex space-x-3">
          <div>
            <span className={reportGenPptxStepStyle.checkButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
            <div>
              <div className="flex">
                <p className={reportGenPptxStepStyle.step}>STEP4</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">1</span>分
                </p>
              </div>
              <p className={reportGenPptxStepStyle.name}>
                決算書チェッカーの作成
              </p>
              <p className="pt-1.5 text-sm text-gray-500">
                算定結果をもとに決算書チェッカーをパワーポイント形式で書き出します
              </p>
              <div className="pt-4">
                <div
                  onClick={() => getReportPptxForm()}
                  className={reportGenPptxStepStyle.reportButton}
                >
                  レポートを書き出す
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
