import React from 'react';
import { CurrentAssets } from './CurrentAssets';
import { SubjectProps } from '../../types';

interface AssetsContainerProps extends SubjectProps {
  phase: string;
}

export const AssetsContainer: React.FC<AssetsContainerProps> = (
  props: AssetsContainerProps,
) => {
  const { getValues, setValue, phase, control } = props;
  return (
    <>
      {phase == '1' ? (
        <CurrentAssets
          control={control}
          getValues={getValues}
          setValue={setValue}
        />
      ) : (
        <></>
      )}
    </>
  );
};
