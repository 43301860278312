import React, { useCallback, useEffect, useState } from 'react';
import { UsersSlideImageUploader } from './UsersSlideImageUploader';
import { CompanyMedicalReportOptions } from '../company_medical_report_options/CompanyMedicalReportOptions';
import { RadioGroup } from '../../components/RadioGroup/RadioGroup';
import { updateCompanyMedicalReportOption, useSlideImage } from './api';
import {
  CompanyMedicalReportOptionProps,
  UsersCompanyMedicalReportOptionProps,
} from './types';

export const UsersCompanyMedicalReportOption: React.FC<
  UsersCompanyMedicalReportOptionProps
> = (props) => {
  const {
    company_medical_report_option,
    user_id,
    chart_sample_img,
    rotation_sample_img,
  } = props;
  const token = document
    ?.getElementById('company_medical_report_option_edit')
    ?.parentElement?.getElementsByTagName('input')[0].value;
  const { imagesSrc, fetchSlideImage, updateSlideImage, deleteSlideImage } =
    useSlideImage(user_id, token);
  const [formState, setFormState] = useState<CompanyMedicalReportOptionProps>({
    user_id: user_id,
    dcf: company_medical_report_option.dcf,
    ebitda: company_medical_report_option.ebitda,
    per: company_medical_report_option.per,
    tableHeaderColor:
      company_medical_report_option.tableHeaderColor ?? '#000000',
    tableCellColor: company_medical_report_option.tableCellColor ?? '#000000',
    tableCellTextColor:
      company_medical_report_option.tableCellTextColor ?? '#000000',
    tableHeaderTextColor:
      company_medical_report_option.tableHeaderTextColor ?? '#000000',
    backgroundCharacter: company_medical_report_option.backgroundCharacter,
    topImageType: company_medical_report_option.topImageType,
  });

  const characterBackgrounOptions = [
    { value: true, label: '設定する' },
    { value: false, label: '設定しない' },
  ];

  const characterCoverOptions = [
    { value: 'character', label: '設定する' },
    { value: 'default', label: '設定しない' },
  ];

  const handleInputChange = useCallback((e) => {
    const { name, type, value } = e.target;
    if (e.target.name.startsWith('company_medical_report_option')) {
      setFormState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    if (type === 'file') {
      const file = e.target.files[0];
      setFormState((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  useEffect(() => {
    const slideNames = [
      'top_image',
      'family_succession_image',
      'employee_succession_image',
      'stock_listing_image',
      'ma_image',
      'cessation_image',
    ];
    slideNames.forEach((slideName) => {
      fetchSlideImage(slideName);
    });
  }, [fetchSlideImage]);

  return (
    <div className="grid grid-cols-5 gap-x-2 gap-y-8 pt-12">
      <div className="grid grid-cols-1" />
      <div className="bg-white shadow-sm ring-1 ring-gray-300 sm:rounded-xl md:col-span-3">
        <div className="px-2 sm:px-0 px-12 text-center pt-6">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">
            決算書チェッカー設定
          </h2>
          <p className="mt-1 text-md leading-6 text-gray-600">
            決算書チェッカーの設定を入力してください。
          </p>
        </div>
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-full grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-full pt-6">
              <RadioGroup
                name="topImageType"
                value={formState.topImageType}
                onChange={handleInputChange}
                options={characterCoverOptions}
                label="レポート表紙にキャラクターを設定する"
              />
            </div>
            <div className="sm:col-span-full pt-6">
              <RadioGroup
                name="backgroundCharacter"
                value={JSON.parse(formState.backgroundCharacter)}
                onChange={handleInputChange}
                options={characterBackgrounOptions}
                label="レポート背景にキャラクターを設定する"
              />
            </div>
            <UsersSlideImageUploader
              slideName={'top_image'}
              slideLabel={'レポート表紙画像'}
              slideSrc={imagesSrc.top_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <UsersSlideImageUploader
              slideName={'family_succession_image'}
              slideLabel={'「親族内承継」スライド画像'}
              slideSrc={imagesSrc.family_succession_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <UsersSlideImageUploader
              slideName={'employee_succession_image'}
              slideLabel={'「従業員承継」スライド画像'}
              slideSrc={imagesSrc.employee_succession_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <UsersSlideImageUploader
              slideName={'stock_listing_image'}
              slideLabel={'「株式上場」スライド画像'}
              slideSrc={imagesSrc.stock_listing_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <UsersSlideImageUploader
              slideName={'ma_image'}
              slideLabel={'「第三者承継（M&A）」スライド画像'}
              slideSrc={imagesSrc.ma_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <UsersSlideImageUploader
              slideName={'cessation_image'}
              slideLabel={'「廃業」スライド画像'}
              slideSrc={imagesSrc.cessation_image}
              onUpdateSlideImage={updateSlideImage}
              onDeleteSlideImage={deleteSlideImage}
            />
            <div className="sm:col-span-full pt-6">
              <CompanyMedicalReportOptions
                company_medical_report_option={company_medical_report_option}
                chart_sample_img={chart_sample_img}
                rotation_sample_img={rotation_sample_img}
                formState={formState}
                setFormState={setFormState}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-8 py-4 sm:px-8">
          <div
            className="cursor-pointer rounded-md bg-indigo-600 px-8 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            // @ts-ignore
            onClick={(e) =>
              // @ts-ignore
              updateCompanyMedicalReportOption(e, token, formState)
            }
          >
            変更を保存
          </div>
        </div>
      </div>
    </div>
  );
};
