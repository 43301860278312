import React, { useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import { AsyncPaginate } from 'react-select-async-paginate';
import { CompanyMedicalReportComments } from './CompanyMedicalReportComments';
import { CompanyMedicalReportDownloadButton } from './CompanyMedicalReportDownloadButton';
import { CompanyMedicalReportProfileHooks } from './hooks';
import { loadOptions } from './api';
import { MoneyFormatter, transformDate } from '../../../utils/common';
import {
  CompanyMedicalReportProfileProps,
  CompanyMedicalReportSelectOptionValue,
} from './types';
import {
  DcfSchema,
  EbitdaSchema,
  InheritanceSchema,
  PerSchema,
} from '../../../utils/schema';

const VALUATIONS_SCHEMA = {
  dcf: DcfSchema,
  ebitda: EbitdaSchema,
  per: PerSchema,
  inheritance: InheritanceSchema,
};

export const CompanyMedicalReportProfile = (
  props: CompanyMedicalReportProfileProps,
) => {
  const { company_medical_report_profile_form, valuations } = props;
  const token = document
    ?.getElementById('company_medical_report_proile')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  const {
    formState,
    sections,
    selectedValues,
    handleSelectValuesChange,
    setSections,
    updateFormState,
    updateProfile,
    handleAddNewSelector,
  } = CompanyMedicalReportProfileHooks(
    company_medical_report_profile_form,
    // @ts-ignore
    token,
  );

  const FormatOptionLabel = React.memo(
    ({ option }: { option: CompanyMedicalReportSelectOptionValue }) => (
      <div className="grid grid-cols-11 flex gap-4">
        <div className="col-span-2 pt-1 text-sm font-bold">
          {option?.evaluation_company_name}
        </div>
        <div className="col-span-1 pt-1 text-sm font-bold">
          {option?.report_year}
        </div>
        <div className="col-span-2 bg-blue-100 rounded-sm text-sm p-1 flex">
          <p className="font-bold">DCF</p>
          <p className="pl-4">{MoneyFormatter(option?.dcf)}</p>
        </div>
        <div className="col-span-2 bg-blue-100 rounded-sm text-sm p-1 flex">
          <p className="font-semibold">EBITDA</p>
          <p className="pl-4">{MoneyFormatter(option?.ebitda)}</p>
        </div>
        <div className="col-span-2 bg-blue-100 rounded-sm text-sm p-1 flex">
          <p className="font-semibold">PER</p>
          <p className="pl-4">{MoneyFormatter(option?.per)}</p>
        </div>
        <div className="col-span-2 bg-blue-100 rounded-sm text-sm p-1 flex">
          <p className="font-semibold">相続税評価</p>
          <p className="pl-4">{MoneyFormatter(option?.inheritance)}</p>
        </div>
      </div>
    ),
  );

  const showValuations = useCallback(
    (method_type: 'dcf' | 'ebitda' | 'per' | 'inheritance') => {
      if (method_type === 'inheritance') {
        try {
          const valuation_result = valuations[method_type].result;
          if (typeof valuation_result !== 'string') return null;
          const valuation = VALUATIONS_SCHEMA[method_type].parse(
            JSON.parse(valuation_result),
          );

          const result = Number(valuation.result.valuation);
          const inheritance_tax = valuation.result.inheritance_tax;
          if (
            !result ||
            typeof result !== 'number' ||
            !inheritance_tax ||
            typeof inheritance_tax !== 'number'
          )
            return null;
          return (
            <>
              <div className="grid grid-cols-12 pl-8 flex place-items-center">
                <div className="col-span-5">
                  <p className="text-blue-600 text-sm text-start py-1">
                    相続税法上の評価
                  </p>
                </div>
                <div className="col-span-6">
                  <p className="text-blue-600 text-sm text-start py-1">
                    {MoneyFormatter(result)}
                  </p>
                </div>
              </div>
            </>
          );
        } catch (error) {
          console.error(error);
          return <></>;
        }
      } else {
        try {
          const valuation_result = valuations[method_type].result;
          if (typeof valuation_result !== 'string') return null;
          const valuation = VALUATIONS_SCHEMA[method_type].parse(
            JSON.parse(valuation_result),
          );
          const result = Number(valuation.breakdown['0'].valuation);
          const non_liquidity_discount =
            valuation.breakdown['0'].non_liquidity_discount;
          if (
            !result ||
            typeof result !== 'number' ||
            !non_liquidity_discount ||
            typeof non_liquidity_discount !== 'number'
          )
            return null;
          const lowerValuation = Number(
            (result * 0.9 * (1.0 - non_liquidity_discount)).toFixed(0),
          );
          const upperValuation = Number(
            (result * 1.1 * (1.0 - non_liquidity_discount)).toFixed(0),
          );
          return (
            <>
              <p className="text-blue-600 text-sm text-center text-base">
                {MoneyFormatter(lowerValuation)} 〜
                {MoneyFormatter(upperValuation)}
              </p>
              <p className="mt-4 font-normal text-xs">
                ※非流動性ディスカウント({non_liquidity_discount * 100}{' '}
                %)を適用しています。
              </p>
            </>
          );
        } catch (error) {
          console.error(error);
          return <></>;
        }
      }
    },
    [valuations],
  );

  return (
    <>
      <Toaster />
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-6 md:gap-6">
            <div className="md:mt-0 md:col-span-6">
              <div className="pt-5">
                <div className="bg-gray-50 border border-gray-300 rounded px-6">
                  <div>
                    <legend className="text-xl font-bold text-black pl-3 py-3">
                      1.評価対象会社情報
                    </legend>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-6">
                      <div className="sm:pt-2">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          会社名{' '}
                          <span className="bg-red-600 text-xs rounded px-2 py-0.5 text-white font-bold ml-3">
                            必須
                          </span>
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              required
                              type="text"
                              placeholder={
                                formState.evaluationCompanyName || ''
                              }
                              value={formState.evaluationCompanyName || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'evaluationCompanyName',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                      <div className="sm:pt-5">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          所在地
                          <input
                            className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                            type="text"
                            placeholder={
                              formState.evaluationCompanyLocation || ''
                            }
                            value={formState.evaluationCompanyLocation || ''}
                            onChange={(e) =>
                              updateFormState(
                                'evaluationCompanyLocation',
                                e.target.value,
                              )
                            }
                          />
                        </label>
                      </div>
                      <div className="sm:pt-5">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          従業員数{' '}
                          <span className="bg-red-600 text-xs rounded px-2 py-0.5 text-white font-bold ml-3">
                            必須
                          </span>
                          <input
                            className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                            required
                            type="number"
                            placeholder={
                              formState.evaluationCompanyEmployees || ''
                            }
                            value={formState.evaluationCompanyEmployees || ''}
                            onChange={(e) =>
                              updateFormState(
                                'evaluationCompanyEmployees',
                                e.target.value,
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="sm:pt-2">
                        <label className="block font-medium sm:pl-7 text-gray-700  sm:pt-1.5">
                          {' '}
                          設立日
                          <input
                            className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                            type="date"
                            placeholder={String(
                              transformDate(
                                formState.evaluationCompanyCreatedDate,
                              ),
                            )}
                            value={String(
                              transformDate(
                                formState.evaluationCompanyCreatedDate,
                              ),
                            )}
                            onChange={(e) =>
                              updateFormState(
                                'evaluationCompanyCreatedDate',
                                e.target.value,
                              )
                            }
                          />
                        </label>
                      </div>
                      <div className="sm:pt-5 pl-4">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          代表取締役
                          <input
                            className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                            type="text"
                            placeholder={
                              formState.evaluationCompanyPresident || ''
                            }
                            value={formState.evaluationCompanyPresident || ''}
                            onChange={(e) =>
                              updateFormState(
                                'evaluationCompanyPresident',
                                e.target.value,
                              )
                            }
                          />
                        </label>
                      </div>
                      <div className="sm:pt-5 pl-4">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          発行済株式総数
                          <input
                            className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                            type="number"
                            placeholder={String(
                              formState.evaluationCompanySharesCount,
                            )}
                            value={Number(
                              formState.evaluationCompanySharesCount,
                            )}
                            onChange={(e) =>
                              updateFormState(
                                'evaluationCompanySharesCount',
                                Number(e.target.value),
                              )
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:pt-5 pb-8 pl-3.5">
                    <label className="block font-medium text-gray-700 sm:pt-1.5">
                      {' '}
                      事業内容
                    </label>
                    <input
                      className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                      type="text"
                      placeholder={formState.evaluationCompanyBusiness || ''}
                      value={formState.evaluationCompanyBusiness || ''}
                      onChange={(e) =>
                        updateFormState(
                          'evaluationCompanyBusiness',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                </div>
                <div className="bg-gray-50 border border-gray-300 rounded mt-12 px-6">
                  <div>
                    <legend className="text-xl font-bold text-black pl-3 py-3">
                      2.レポート作成会社情報
                    </legend>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-6">
                      <div className="sm:pt-2">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          会社名{' '}
                          <span className="bg-red-600 text-xs rounded px-2 py-0.5 text-white font-bold ml-3">
                            必須
                          </span>
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              required
                              type="text"
                              placeholder={formState.makerCompanyName || ''}
                              value={formState.makerCompanyName || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'makerCompanyName',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                      <div className="sm:pt-5">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          代表取締役{' '}
                          <span className="bg-red-600 text-xs rounded px-2 py-0.5 text-white font-bold ml-3">
                            必須
                          </span>
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              required
                              type="text"
                              placeholder={formState.makerCompanyAuthor || ''}
                              value={formState.makerCompanyAuthor || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'makerCompanyAuthor',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                      <div className="sm:pt-5 pb-8">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          メールアドレス
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              required
                              type="text"
                              placeholder={formState.makerCompanyEmail || ''}
                              value={formState.makerCompanyEmail || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'makerCompanyEmail',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="sm:pt-2 pl-4">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          所在地
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              type="text"
                              placeholder={formState.makerCompanyLocation || ''}
                              value={formState.makerCompanyLocation || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'makerCompanyLocation',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                      <div className="sm:pt-5 pl-4">
                        <label className="block text-sm font-medium sm:pl-3.5 text-gray-700  sm:pt-1.5">
                          電話番号
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              type="text"
                              placeholder={
                                formState.makerCompanyPhoneNumber || ''
                              }
                              value={formState.makerCompanyPhoneNumber || ''}
                              onChange={(e) =>
                                updateFormState(
                                  'makerCompanyPhoneNumber',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 border border-gray-300 rounded mt-12 px-6">
                  <div>
                    <legend className="text-xl font-bold text-black pl-3 py-3">
                      3.レポート情報
                    </legend>
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-6 pb-8">
                      <div className="sm:pt-2">
                        <label className="block font-medium sm:pl-7 text-gray-700  sm:pt-1.5">
                          {' '}
                          作成日
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              required
                              type="date"
                              placeholder={String(
                                transformDate(formState.reportCreationDate),
                              )}
                              value={String(
                                transformDate(formState.reportCreationDate),
                              )}
                              onChange={(e) =>
                                updateFormState(
                                  'reportCreationDate',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="sm:pt-2">
                        <label className="block font-medium sm:pl-7 text-gray-700  sm:pt-1.5">
                          {' '}
                          提出日
                          <div className="mt-1">
                            <input
                              className="w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-sm"
                              type="date"
                              placeholder={String(
                                transformDate(formState.reportSubmissionDate),
                              )}
                              value={String(
                                transformDate(formState.reportSubmissionDate),
                              )}
                              onChange={(e) =>
                                updateFormState(
                                  'reportSubmissionDate',
                                  e.target.value,
                                )
                              }
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 border border-gray-300 rounded mt-12 px-6">
                  <div>
                    <legend className="text-xl font-bold text-black pl-3 py-3">
                      4.算定結果
                    </legend>
                  </div>
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-3 pb-8 px-2">
                      <div className="sm:pt-2">
                        <div className="w-full h-40 border border-gray-200 bg-white">
                          <p className="p-2 text-white font-bold bg-blue-600 text-sm text-center">
                            DCF法の算定結果
                          </p>
                          <div className="p-3 pt-6 font-bold text-sm">
                            {showValuations('dcf')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 pb-8 px-2">
                      <div className="sm:pt-2">
                        <div className="w-full h-40 border border-gray-200 bg-white">
                          <p className="p-2 text-white font-bold bg-blue-600 text-sm text-center">
                            EV/EBITDA法の算定結果
                          </p>
                          <div className="p-3 pt-6 font-bold text-sm">
                            {showValuations('ebitda')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 pb-8 px-2">
                      <div className="sm:pt-2">
                        <div className="w-full h-40 border border-gray-200 bg-white">
                          <p className="p-2 text-white font-bold bg-blue-600 text-sm text-center">
                            PER倍率法の算定結果
                          </p>
                          <div className="p-3 pt-6 font-bold text-sm">
                            {showValuations('per')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 pb-8 px-2">
                      <div className="sm:pt-2">
                        <div className="w-full h-40 border border-gray-200 bg-white">
                          <p className="p-2 text-white font-bold bg-blue-600 text-sm text-center">
                            相続税法上の評価
                          </p>
                          <div className="p-3 py-8 font-bold text-sm">
                            {showValuations('inheritance')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 border border-gray-300 rounded mt-12 px-6 pb-6">
                  <div>
                    <legend className="text-xl font-bold text-black pl-3 py-3">
                      5.別決算期のレポート選択
                    </legend>
                  </div>
                  <div className="grid grid-cols-12">
                    {selectedValues.map((selectValue, index) => (
                      <div className="col-span-12 pb-2" key={index}>
                        <label className="block font-medium sm:pl-7 text-gray-700 sm:pt-1.5">
                          {`${index + 1}.レポート選択`}
                          <AsyncPaginate
                            // @ts-ignore
                            value={selectValue}
                            loadOptions={loadOptions}
                            closeMenuOnSelect={false}
                            formatOptionLabel={(option) => (
                              // @ts-ignore
                              <FormatOptionLabel option={option} />
                            )}
                            onChange={(value) =>
                              // @ts-ignore
                              handleSelectValuesChange(index, value)
                            }
                            isSearchable={false}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center mt-2">
                    <div
                      onClick={handleAddNewSelector}
                      className="mt-4 px-4 w-2/12 text-center py-2 bg-blue-500 text-white rounded-md cursor-pointer"
                    >
                      追加
                    </div>
                  </div>
                </div>
                <CompanyMedicalReportComments
                  sections={sections}
                  setSections={setSections}
                  company_medical_report_id={
                    company_medical_report_profile_form.company_medical_report
                      .id
                  }
                  report_comments={
                    company_medical_report_profile_form.report_comments
                  }
                />
                <div className="pl-20 flex justify-center gap-4 pt-12">
                  <div
                    onClick={updateProfile}
                    className="cursor-pointer inline-flex justify-center rounded px-20 py-3 bg-green-500 text-base font-medium text-white hover:bg-green-600"
                  >
                    保存する
                  </div>
                  <CompanyMedicalReportDownloadButton
                    reportId={
                      company_medical_report_profile_form.company_medical_report
                        .id
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
