import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { TooltipProps } from './types';

/**
 * ツールチップ表示用コンポーネント
 * @param {TooltipProps} props
 * @returns Tooltip
 */
export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = (props) => {
  const {
    baseStyles,
    buttonColor,
    tipText,
    tipTextAlign,
    tipTextColor,
    tipColor,
    tipWidth,
    tipPlacement,
    children,
  } = props;

  const [toolTip, setTooltip] = useState<{ visibility: 'visible' | 'hidden' }>({
    visibility: 'hidden',
  });
  const buttonStyles = useMemo(
    () => (baseStyles ? baseStyles : 'rounded-full w-6 h-6'),
    [baseStyles],
  );
  const buttonColorClasses = useMemo(
    () => (buttonColor ? buttonColor : 'bg-green-500 hover:bg-green-700'),
    [buttonColor],
  );
  const tipPlacementClasses = useMemo(
    () => (tipPlacement ? tipPlacement : 'bottom-6 left-0'),
    [tipPlacement],
  );
  const tipTextAlignClasses = useMemo(
    () => (tipTextAlign ? tipTextAlign : 'text-left'),
    [tipTextAlign],
  );
  const tipWidthClasses = useMemo(
    () => (tipWidth ? tipWidth : 'w-80'),
    [tipWidth],
  );
  const tipColorClass: string = useMemo(
    () => (tipColor ? tipColor : 'bg-green-500'),
    [tipColor],
  );
  const tipTextColorClass: string = useMemo(
    () => (tipTextColor ? tipTextColor : 'text-white'),
    [tipTextColor],
  );
  const tipContents: string = useMemo(
    () => (tipText ? tipText : ''),
    [tipText],
  );

  const onMouseOverHandler: () => void = useCallback(() => {
    setTooltip({ visibility: 'visible' });
  }, []);
  const onMouseLeaveHandler: () => void = useCallback(() => {
    setTooltip({ visibility: 'hidden' });
  }, []);

  return (
    <>
      <button
        onMouseOver={onMouseOverHandler}
        onMouseLeave={onMouseLeaveHandler}
        className={`cursor-pointer relative ${buttonStyles} ${buttonColorClasses}`}
        disabled
      >
        <div
          className={`
          flex inline-block
          absolute z-10
          text-[11px] p-2.5
          rounded-md
          ${tipPlacementClasses} ${toolTip.visibility}
          ${tipTextAlignClasses} ${tipWidthClasses}
          ${tipColorClass} ${tipTextColorClass}`}
        >
          {tipContents}
        </div>
        {children}
      </button>
    </>
  );
};
