import React from 'react';
import { ResultPagePreview } from './ResultPagePreview';
import { SubjectPagePreview } from './SubjectPagePreview';
import { CompanyMedicalReportPreviewProps } from '../types';

export const CompanyMedicalReportPreview: React.FC<
  CompanyMedicalReportPreviewProps
> = (props) => {
  const {
    tableHeaderColor,
    tableHeaderTextColor,
    tableCellColor,
    tableCellTextColor,
    chartSampleImg,
    rotationSampleImg,
  } = props;

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-6">
          <ResultPagePreview
            tableHeaderColor={tableHeaderColor}
            tableHeaderTextColor={tableHeaderTextColor}
            tableCellColor={tableCellColor}
            tableCellTextColor={tableCellTextColor}
            chartSampleImg={chartSampleImg}
          />
        </div>
        <div className="col-span-6">
          <SubjectPagePreview
            tableHeaderColor={tableHeaderColor}
            tableHeaderTextColor={tableHeaderTextColor}
            tableCellColor={tableCellColor}
            tableCellTextColor={tableCellTextColor}
            rotationSampleImg={rotationSampleImg}
          />
        </div>
      </div>
    </>
  );
};
