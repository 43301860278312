import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { PdfViewer } from '../PdfViewer/PdfViewer';
import { PageSelectorProps, pageProps } from './types';
const PER_PAGE_COUNT = 20;
const commonClassName =
  'cursor-pointer hover:bg-blue-500 hover:border-blue-500 hover:text-white rounded-md relative z-10 border border-solid border-gray-400 bg-white text-gray-600 inline-flex items-center rounded-sm py-2 px-4 text-sm transition duration-200 ease-in-out';

export const PageSelector: React.FC<PageSelectorProps> = (props) => {
  const { pages, phase } = props;
  const [currentOcrPages, setCurrentOcrPages] = useState<pageProps[]>([]);
  const [selectedPages, setSelectedPages] = useState<pageProps[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);

  const isSelected = useCallback(
    (pageId: string) => {
      return selectedPages.some((page) => page.page_id === pageId);
    },
    [selectedPages],
  );

  const handlePageClick = useCallback(
    (event) => {
      const newOffset = (event.selected * PER_PAGE_COUNT) % pages.length;
      const endOffset =
        ((event.selected * PER_PAGE_COUNT) % pages.length) + PER_PAGE_COUNT;
      setItemOffset(newOffset);
      // @ts-ignore
      setCurrentOcrPages(pages.slice(newOffset, endOffset));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pages],
  );

  const handleSelectChange = useCallback(
    (page: pageProps) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOptionValue = event.target.value;
      const [documentType] = selectedOptionValue.split(',');
      const updatedSelectedPages = [...selectedPages].filter(
        (selectedPage) => selectedPage.page_id !== page.page_id,
      );
      if (documentType !== 'not_selected') {
        const selectedPage = { ...page, document_type: documentType };
        updatedSelectedPages.push(selectedPage);
      }
      setSelectedPages(updatedSelectedPages);
    },
    [selectedPages],
  );

  useEffect(() => {
    const endOffset = itemOffset + PER_PAGE_COUNT;
    // @ts-ignore
    setCurrentOcrPages(pages.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(pages.length / PER_PAGE_COUNT));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPageData = useCallback(() => {
    const pageData = selectedPages.map((_, index) => {
      return `"${selectedPages[index].document_type},${selectedPages[index].page_id}"`;
    });
    return `[${pageData}]`;
  }, [selectedPages]);

  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-8 mt-6">
        {currentOcrPages.map((page: pageProps) => (
          <React.Fragment key={page.page_id}>
            <div className="h-full pb-8">
              <PdfViewer
                thumbnail={page.thumbnail}
                isSelected={isSelected(page.page_id)}
              />
              <select
                id={`page_data_${page.page_id}`}
                className={`w-full mt-2 rounded-sm border border-solid ${
                  isSelected(page.page_id)
                    ? 'border-green-600 text-green-600 bg-green-50 font-semibold'
                    : 'border-gray-600 text-gray-600'
                } shadow-sm bg-gray-50`}
                onChange={handleSelectChange(page)}
              >
                <option value="not_selected">
                  書類の種類を選択してください
                </option>
                <option value={`bs,${page.page_id}`}>貸借対照表</option>
                <option value={`pl,${page.page_id}`}>損益計算書</option>
                <option value={`sga,${page.page_id}`}>
                  販売費及び一般管理費
                </option>
                <option value={`cr,${page.page_id}`}>製造原価報告書</option>
              </select>
              <input type="hidden" name="phase" value={phase} />
              <input
                type="hidden"
                name="selected_page_data"
                value={setPageData()}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      {pages.length > 20 && (
        <div className="w-full">
          <ReactPaginate
            // @ts-ignore
            className="bg-white flex justify-center pt-4 pb-12 py-2 inline-flex gap-1"
            pageClassName={commonClassName}
            previousClassName={commonClassName}
            nextClassName={commonClassName}
            activeClassName={`${commonClassName} border-blue-500 bg-blue-50 text-blue-600`}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            breakLabel="..."
            nextLabel="Next >"
            previousLabel="< Prev"
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
          />
        </div>
      )}
    </>
  );
};
