import React, { useEffect } from 'react';
import { useReportSimilaritiesStep } from './hooks';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { reportSimilaritiesStep } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import NaviIcon from '../../../../../assets/images/icons/icon_navi.png';

export const ReportSimilaritiesStep: React.FC<reportSimilaritiesStep> = (
  props,
) => {
  const { report } = props;
  const {
    reportSimilaritiesStepStyle,
    setReportSimilaritiesStepStyle,
    getSimilaritiesForm,
  } = useReportSimilaritiesStep(report);
  useEffect(() => {
    if (report.similarities_status) {
      setReportSimilaritiesStepStyle({
        flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300',
        checkedButton:
          'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center',
        actionButton:
          'inline-flex items-center px-4 py-2 text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 cursor-pointer',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <div className="relative pb-8">
        <span
          className={reportSimilaritiesStepStyle.flowLineStyle}
          aria-hidden="true"
        ></span>
        <div className="relative flex space-x-3">
          <div>
            <span className={reportSimilaritiesStepStyle.checkedButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="w-full">
            <div>
              <div className="flex">
                <p className="text-lg text-green-400 font-semibold">STEP2</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">1</span>分
                </p>
              </div>
              <div className="grid grid-cols-12 w-6/12">
                <div className="col-span-11">
                  <p className="text-xl text-gray-700 font-bold pt-3">
                    類似上場企業の選定
                  </p>
                </div>
                <div className="col-span-1 mb-3">
                  <Tooltip
                    baseStyles="rounded-full bg-white"
                    tipPlacement="bottom-1 left-3"
                    tipText="評価したい会社と事業内容が似ている上場企業を3社から10社選定をお願いします。臨時的な出来事があった上場企業を選択されると適正な評価額が算出されないことがございます。"
                  >
                    <span className="h-10 w-10 flex items-end justify-end bg-white">
                      <img className="h-10 w-10" src={NaviIcon} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <hr className="w-6/12"></hr>
              <p className="pt-1.5 text-sm text-gray-500">
                類似する上場企業の市場株価を算定結果に反映
              </p>
              <div className="pt-4">
                <div
                  onClick={() => getSimilaritiesForm()}
                  className={reportSimilaritiesStepStyle.actionButton}
                >
                  類似上場企業選定を行う
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
