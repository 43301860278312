import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { OcrPageProps, getPdfProps, ocrRequestProps } from './types';
import { Subjects } from '../../../components/Subject/types';

export const getPdf = (props: getPdfProps) => {
  const { reportId, pageId, setImage } = props;
  axios
    .get(`/company_medical_reports/${reportId}/ocr/file_pdfs/${pageId}`)
    .then((res) => {
      setImage(`data:application/pdf;base64,${res.data}`);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postFileSummary: (
  options: ocrRequestProps,
  path: string,
) => Promise<Subjects | void | undefined> = (options, path) => {
  return axios(options)
    .then((res) => {
      if (res.data.status === 201) {
        location.href = path;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFileSummary: (
  options: ocrRequestProps,
  _setValue: (arg: string, arg_second: Subjects) => void,
  _setSubject: (arg: Subjects[]) => void,
  _visibleDownloadButton: () => void,
) => Promise<Subjects | void | undefined> = (
  options,
  _setValue,
  _setSubject,
  _visibleDownloadButton,
) => {
    return axios(options)
      .then((res) => {
        if (res.status === 200) {
          _setValue('subject', res.data.subjects);
          _setSubject([res.data.subjects]);
          _visibleDownloadButton();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const getFileStatus: (
  options: ocrRequestProps,
  path: string,
  _callbackProps: {
    _pageIds: string[];
    _refTimerId: MutableRefObject<number>;
    _getSummary: () => void;
    _setloadingMessage: Dispatch<SetStateAction<string>>;
    startTime: number;
  },
) => Promise<AxiosResponse | void | undefined> = (
  options,
  path,
  _callbackProps,
) => {
    const { _pageIds, _refTimerId, _getSummary, _setloadingMessage, startTime } =
      _callbackProps;
    return axios(options)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          if ((Date.now() - startTime) / 1000 > 120) {
            clearInterval(_refTimerId.current as number);
            _setloadingMessage('画像解析中にエラーが発生しました。');
            location.href = path;
          }
          const analyzedFile: OcrPageProps[] = res?.data.pages.filter(
            (page: OcrPageProps) =>
              page.status === 'success' || page.status === 'failed',
          );
          if (analyzedFile.length === _pageIds.length) {
            _getSummary();
            clearInterval(_refTimerId.current as number);
          }
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };
