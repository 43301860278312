import React, { useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { MessageModalProps } from './types';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const MessageModal: React.FC<MessageModalProps> = (props) => {
  const {
    setModal,
    modalType,
    modalDom,
    modalMessage,
    errorMessages,
    setModalCheck,
    url,
    reqAction,
  } = props;

  const MessagesList = useCallback(() => {
    return (
      <ul>
        {errorMessages?.map((message: string, idx: number) => (
          <li
            className="font-medium text-gray-800"
            key={message + idx.toString()}
          >
            {message}
          </li>
        ))}
      </ul>
    );
  }, [errorMessages]);

  const Message = useCallback(() => {
    return <div className="text-base pb-3 text-red-600">{modalMessage}</div>;
  }, [modalMessage]);

  const Confirm = useCallback(() => {
    return (
      <>
        <h2 className="pb-1 font-semibold text-gray-700">
          一度削除すると、今後もとに戻すことはできません。それでもよろしいですか？
        </h2>
        <button
          type="button"
          className="mt-8 w-25 inline-flex justify-center rounded border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
        >
          <a href={url} data-method={reqAction}>
            削除する
          </a>
        </button>
      </>
    );
  }, [reqAction, url]);

  const closeModal = useCallback(
    (_callback: () => void) => {
      if (typeof setModalCheck !== 'undefined') {
        setModalCheck(false);
      }
      return _callback();
    },
    [setModalCheck],
  );

  const [showModal, hideModal] = useModal(
    () => (
      <Modal isOpen style={modalStyle}>
        {(() => {
          switch (modalType) {
            case 'message_list':
              return <MessagesList />;
            case 'message':
              return <Message />;
            case 'confirm':
              return <Confirm />;
            default:
              break;
          }
        })()}
        <div className="grid place-items-end">
          <button
            className="mt-4 px-2 py-2 border border-transparent text-sm text-white text-bold leading-4 shadow-md font-medium rounded bg-indigo-600"
            onClick={() => closeModal(hideModal)}
          >
            閉じる
          </button>
        </div>
      </Modal>
    ),
    [modalMessage, errorMessages],
  );

  useEffect((): void => {
    if (setModal) {
      Modal.setAppElement(modalDom);
      showModal();
    }
  }, [modalDom, modalMessage, showModal, setModal]);

  return <></>;
};
