export const RISK_FREE_RATE = 0.0025;
export const EQUITY_RISK_PREMIUM = 0.06;
export const SIZE_RISK_PREMIUM = 0.0;
export const MAX_TAX_RATE = 33.58;
export const MIN_TAX_RATE = 29.74;
export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    width: '45%',
    transform: 'translate(-50%, -50%)',
  },
};
export const VALUTION_MIN_RATE = 0.9;
export const VALUTION_MAX_RATE = 1.1;
