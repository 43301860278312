import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import domtoimage from 'dom-to-image';

interface ResultRowProp {
  pageId: string;
}

const ResultRow: React.FC<ResultRowProp> = (props: ResultRowProp) => {
  const [image, setImage] = useState('');
  const [result, setResult] = useState([]);
  const [progress, setProgress] = useState('ただいま解析中...');

  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);

  // PDF表示（初期表示のみ）
  // TODO: ソースコードが重複しているのでまとめてください
  const setPdf = useCallback(() => {
    axios
      .get(`/ocr/analysis/${props.pageId}/page_pdf`)
      .then((res) => {
        setImage(`data:application/pdf;base64,${res.data}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.pageId]);

  // TODO: ソースコードが重複しているのでまとめてください
  const tableToImage = useCallback(() => {
    const tableWrapper = document.getElementById(
      `result_table_${props.pageId}`,
    );
    // @ts-ignore
    const table = tableWrapper.getElementsByTagName('table')[0];
    if (table !== undefined) {
      domtoimage
        .toPng(table)
        // @ts-ignore
        .then(function (dataUrl) {
          // dataUrlが返ってくるのでImageを生成し生成した画像を読み込む
          const img = new Image();
          img.src = dataUrl;
          img.className = 'w-full';

          // 読み込みが終わったらimgを描画する
          img.onload = () => {
            // @ts-ignore
            tableWrapper.appendChild(img);
            table.remove();
          };
        })
        // @ts-ignore
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.pageId]);

  // 解析データ表示
  // TODO: ソースコードが重複しているのでまとめてください
  const setResultTable = useCallback(() => {
    axios
      .get(`/ocr/analysis/${props.pageId}/page_table_result`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          clearInterval(refTimerId.current as number);

          setResult(res.data.body);

          // 解析データ無し
          if (res.data.body.length === 0) {
            setProgress('OCR解析が出来ませんでした。');
          }

          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          tableToImage();
        } else if (res.data.status === 'failed') {
          setProgress('OCR解析が出来ませんでした。');
          clearInterval(refTimerId.current as number);
        } else if (res.data.status === 'error') {
          setProgress('OCR解析でエラーが発生しました。');
          clearInterval(refTimerId.current as number);
        }
      })
      .catch((err) => {
        console.log(err);
        setProgress('OCR解析でエラーが発生しました。');
        clearInterval(refTimerId.current as number);
      });
  }, [props.pageId, tableToImage]);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setPdf();
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setTimerId(setInterval(setResultTable, 3000));
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setResultTable();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [setPdf, setResultTable]);

  return (
    <div className="page_result flex flex-wrap lg:mx-36 md:mx-8 sm:mx-2 mb-10">
      <div className="md:w-2/4 w-full">
        <embed
          className="pdf"
          type="application/pdf"
          src={image}
          width="100%"
          height="100%"
        />
      </div>
      <div id={`result_table_${props.pageId}`} className="md:w-2/4 w-full pr-4">
        <h4 className="bg-blue-400 w-full p-2 text-white text-left">
          OCR解析結果
        </h4>
        <table className="table-auto w-full border">
          <tbody>
            {result && result.length > 0 ? (
              result.map((row, index) => (
                <tr key={`page_${props.pageId}_${index}`}>
                  {/* @ts-ignore */}
                  {row.map((value, idx) => (
                    <td
                      className="border text-left p-1 text-sm"
                      key={`page_${props.pageId}_${index}_${idx}`}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  {progress === 'ただいま解析中...' ? (
                    <div className="text-center my-6">
                      <p className="mx-auto analyzing_image w-16 h-16"></p>
                    </div>
                  ) : null}
                  <div className="text-center my-4 analyze_progress">
                    {progress}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultRow;
