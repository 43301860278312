import React, { ChangeEventHandler } from 'react';

type InputFieldProps = {
  name: string;
  label?: string;
  value: string;
  checked?: boolean;
  type?: 'text';
  onChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
  name,
  value,
  onChange,
  label,
  type = 'text',
  required = false,
}) => (
  <>
    <label className="block text-md font-medium leading-6 text-gray-900">
      {label}
      {required && (
        <span className="bg-red-500 text-xs rounded px-2 py-0.5 text-white font-bold ml-3">
          必須
        </span>
      )}
    </label>
    <div className="mt-2">
      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-md sm:leading-6"
        />
      </div>
    </div>
  </>
);
