import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const postSimilarCompanies: (
  options: AxiosRequestConfig,
  path: string,
) => void = (options, path) => {
  axios(options)
    .then((res: AxiosResponse) => {
      if (res.status === 200) {
        location.href = path;
      } else {
        console.log(res);
      }
    })
    .catch((err: AxiosError) => {
      console.log(err);
    });
};
