import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import { Popover } from 'react-tiny-popover';

import { InheritanceValuationCalculation } from './InheritanceValuationCalculation';
import {
  InheritanceValuationProps,
  ValuationCalculation,
} from '../types/props';

export const InheritanceValuationCalculations: React.FC<
  InheritanceValuationProps & {
    valuationCalculations: ValuationCalculation[];
    calculatedValuation: ValuationCalculation[];
    hasCalculatedValuation: boolean;
    corporateTaxFileIds: number[];
  }
> = (
  props: InheritanceValuationProps & {
    valuationCalculations: ValuationCalculation[];
    calculatedValuation: ValuationCalculation[];
    hasCalculatedValuation: boolean;
    corporateTaxFileIds: number[];
  },
) => {
  const {
    inheritanceValuation,
    valuationCalculations,
    calculatedValuation,
    hasCalculatedValuation,
    corporateTaxFileIds,
  } = props;

  const [isOpen, setPopover] = useState<boolean>(false);
  const [canUseOcrResult, setCanUseOcrResult] = useState<boolean>(false);
  const form = document.getElementById('inheritance_valuation_form');

  const checkedButton = hasCalculatedValuation
    ? 'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white'
    : 'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white';

  const stepStyle = hasCalculatedValuation
    ? 'text-lg text-green-400 font-semibold'
    : 'text-lg text-gray-400 font-semibold';

  const valuaitonNameStyle = hasCalculatedValuation
    ? 'pt-1.5 text-xl text-gray-700 font-bold'
    : 'pt-1.5 text-xl text-gray-400 font-bold';

  const flowLineStyle = hasCalculatedValuation
    ? 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300'
    : 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200';

  const setSelectPopover = useCallback((): void => {
    setPopover(!isOpen);
  }, [isOpen]);

  const createCalculation = useCallback(
    (source_id: number, source: string): void => {
      axios
        .post(
          `/inheritances/${inheritanceValuation.id}/formula/inheritance/copy_calculation`,
          { source_id: source_id, source: source },
          {
            headers: {
              'X-CSRF-Token':
                // @ts-ignore
                form.parentElement.getElementsByTagName('input')[0].value,
            },
          },
        )
        .then((res) => {
          if (res.data.status === 'success') {
            location.href = `/inheritances/${inheritanceValuation.id}/formula/inheritance/calculation?ocr=${res.data.ocr}&calculation_id=${res.data.calculation_id}`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // @ts-ignore
    [form.parentElement, inheritanceValuation.id],
  );

  const selectContent = useCallback(() => {
    return (
      <>
        <div className="px-3 py-3 border border-gray-300 w-full bg-white rounded-sm">
          {canUseOcrResult ? (
            <div
              onClick={() => {
                createCalculation(
                  inheritanceValuation.id,
                  'inheritance_valuation',
                );
              }}
              className="cursor-pointer text-gray-500 hover:text-blue-600 ml-2 pt-1"
            >
              STEP1の値を利用して算定を追加する
            </div>
          ) : (
            <div
              onClick={() => {
                location.href = `/inheritances/${inheritanceValuation.id}/formula/inheritance/calculation?ocr=false`;
              }}
              className="cursor-pointer text-gray-500 hover:text-blue-600 ml-2 pt-1"
            >
              算定を追加する
            </div>
          )}
          {calculatedValuation?.map(
            (calculation: ValuationCalculation, idx) => (
              <div
                key={calculation.id}
                onClick={() => {
                  createCalculation(calculation.id, 'calculation');
                }}
                className="cursor-pointer text-gray-500 hover:text-blue-600 ml-2 pt-1"
              >
                <span className="text-xs mr-2 bg-white text-gray-500 border border-gray-500 text-xs px-1.5 rounded-full inline-flex items-center">
                  {idx + 1}
                </span>
                の算定内容を複製する
              </div>
            ),
            [],
          )}
        </div>
      </>
    );
  }, [
    calculatedValuation,
    canUseOcrResult,
    createCalculation,
    inheritanceValuation.id,
  ]);

  useEffect(() => {
    if (0 < corporateTaxFileIds.length) {
      setCanUseOcrResult(true);
    }
  }, [corporateTaxFileIds, corporateTaxFileIds.length]);

  return (
    <>
      <li>
        <div className="relative pb-8">
          <span className={flowLineStyle} aria-hidden="true"></span>
          <div className="relative flex space-x-3">
            <div>
              <span className={checkedButton}>
                <svg
                  className="h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>

            <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
              <div>
                <div className="flex">
                  <p className={stepStyle}>STEP3</p>
                  <span className="ml-3 mr-2 mt-2">
                    <img className="h-3 w-3" src={TimeIcon} />
                  </span>
                  <p className="text-xs text-gray-400 mt-1.5">
                    所要時間 約<span className="font-bold">3</span>分
                  </p>
                </div>
                <p className={valuaitonNameStyle}>相続税法上の算定</p>
                <p className="pt-1.5 text-sm text-gray-500">
                  相続税法上の株価を簡易算定
                </p>
                <p className="text-xs text-gray-500">
                  *算定は、5個まで可能です。
                </p>
                <div className="grid grid-cols-12 gap-6">
                  {valuationCalculations?.map((calculation, idx) => (
                    <div key={calculation.id} className="col-span-4">
                      <InheritanceValuationCalculation
                        calculationIndex={idx}
                        calculation={calculation}
                        corporateTaxFileIds={corporateTaxFileIds}
                      />
                    </div>
                  ))}

                  {hasCalculatedValuation &&
                  valuationCalculations.length < 5 ? (
                    <div className="col-span-4 mt-5 text-center py-28 bg-white rounded-lg border border-gray-300 border-dashed">
                      <Popover
                        isOpen={isOpen ? true : false}
                        onClickOutside={() => setSelectPopover()}
                        positions={['bottom']}
                        padding={10}
                        content={selectContent()}
                      >
                        <div
                          onClick={() => setSelectPopover()}
                          className="cursor-pointer bg-gray-200 hover:bg-green-600 hover:text-white text-gray-400 font-bold py-2 px-2 rounded inline-flex items-center"
                        >
                          <span className="px-6">＋ 算定を追加</span>
                        </div>
                      </Popover>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};
