import { useCallback, useState } from 'react';
import { sendSubject } from './api';
import { AxiosRequestConfig } from 'axios';
import { useSubjectsInputFormProps } from './types';

export const useSubjectsInputForm = (props: useSubjectsInputFormProps) => {
  const { method, phase, reportId, resource, token, getValues, subjectValue } =
    props;
  const [subject, setSubject] = useState<{ [k: string]: number }[]>([
    subjectValue,
  ]);

  const sendSubjectRequest = useCallback(() => {
    const requestData = {
      subject: getValues('subject'),
      datatype: 'merged',
      phase: phase,
    };
    const requestConfig: AxiosRequestConfig = {
      method: method,
      url: `/${resource}/${reportId}/subjects.json`,
      data: requestData,
      headers: { 'X-CSRF-Token': token },
    };
    sendSubject(requestConfig, `/${resource}/${reportId}`);
  }, [getValues, phase, method, resource, reportId, token]);

  const onSubmit = useCallback(() => {
    sendSubjectRequest();
  }, [sendSubjectRequest]);

  return {
    subject,
    sendSubjectRequest,
    onSubmit,
    setSubject,
  };
};
