import React from 'react';
import { useCallback, useState } from 'react';
import { MoneyFormatter } from '../../utils/common';
import { ValuationReportCalculationProps } from '../../pages/reports/report/types';
import { ValuationCheckboxProps } from './types';
export const ValuationCheckBox: React.FC<ValuationCheckboxProps> = (props) => {
  const { valuations, inputName } = props;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleChange = useCallback(
    (event) => {
      event.target.value === selectedOption
        ? setSelectedOption(null)
        : setSelectedOption(event.target.value);
    },
    [selectedOption],
  );

  const valuationResult = useCallback((valuation) => {
    return JSON.parse(valuation.result);
  }, []);

  return (
    <>
      {valuations.map(
        (valuation: ValuationReportCalculationProps, index: number) => (
          <React.Fragment key={index}>
            <div className="flex items-start ml-2">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  value={valuation.id}
                  name={inputName}
                  checked={selectedOption === String(valuation.id)}
                  onChange={handleChange}
                />
              </div>
              <div className="ml-5 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  {valuationResult(valuation)['breakdown']['0']['valuation'] !==
                  null ? (
                    <>
                      <span className="text-sm px-2">
                        {MoneyFormatter(
                          Math.round(
                            parseFloat(
                              valuationResult(valuation).result.valuation,
                            ) *
                              0.9 *
                              (1 -
                                valuationResult(valuation).breakdown[0]
                                  .non_liquidity_discount),
                          ),
                        )}
                      </span>
                      〜
                      <span className="text-sm px-2">
                        {MoneyFormatter(
                          Math.round(
                            parseFloat(
                              valuationResult(valuation).result.valuation,
                            ) *
                              1.1 *
                              (1 -
                                valuationResult(valuation).breakdown[0]
                                  .non_liquidity_discount),
                          ),
                        )}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </label>
              </div>
            </div>
          </React.Fragment>
        ),
      )}
    </>
  );
};
