import React, { useCallback, useState } from 'react';

// TODO: react-dropdownに変更
import { Popover } from 'react-tiny-popover';
import { ModalProvider } from 'react-modal-hook';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { InheritanceValuationsProps } from '../types/props';

export const InheritanceValuations: React.FC<InheritanceValuationsProps> = (
  props: InheritanceValuationsProps,
) => {
  const { inheritanceValuations } = props;

  const [isOpen, setPopover] = useState<boolean>(false);
  const [deleteInheritanceValuationId, setDeleteInheritanceValuationId] =
    useState<number>(0);
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const [inheritanceValuationId, setInheritanceValuationId] =
    useState<number>(0);

  const setEditPopover = useCallback(
    (inheritance_valuation_id: number): void => {
      setInheritanceValuationId(inheritance_valuation_id);
      setPopover(!isOpen);
    },
    [isOpen],
  );

  const setDeleteModal = useCallback(
    (inheritance_valuation_id: number): void => {
      setDeleteInheritanceValuationId(inheritance_valuation_id);
      setModalCheck(!modalCheck);
    },
    [modalCheck, setDeleteInheritanceValuationId],
  );

  const setUpdatedTime = useCallback((update_time: string): string => {
    const inheritanceValuationsUpdatedDate: Date = new Date(update_time);
    const inheritanceValuationsUpdatedYear: number =
      inheritanceValuationsUpdatedDate.getFullYear();
    const inheritanceValuationsUpdatedMonth: number =
      inheritanceValuationsUpdatedDate.getMonth() + 1;
    const inheritanceValuationsUpdatedDay: number =
      inheritanceValuationsUpdatedDate.getDate();
    return `${inheritanceValuationsUpdatedYear}年${inheritanceValuationsUpdatedMonth}月${inheritanceValuationsUpdatedDay}日`;
  }, []);

  const setTaxDate = useCallback((tax_period: string): string => {
    const inheritanceValuationsTaxDate: Date = new Date(tax_period);
    const inheritanceValuationsTaxYear: number =
      inheritanceValuationsTaxDate.getFullYear();
    const inheritanceValuationsTaxMonth: number =
      inheritanceValuationsTaxDate.getMonth() + 1;
    return `${inheritanceValuationsTaxYear}年${inheritanceValuationsTaxMonth}月`;
  }, []);

  return (
    <ModalProvider>
      {inheritanceValuations?.map((inheritance_valuation) => (
        <tr key={inheritance_valuation.id}>
          <td className="pl-3 py-2 whitespace-nowrap text-sm font-medium text-black hover:text-blue-700">
            <a href={`/inheritances/${inheritance_valuation.id}`}>
              {inheritance_valuation.evaluation_company_name}
            </a>
          </td>
          <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500">
            {inheritance_valuation.evaluation_company_tax_period !== null
              ? setTaxDate(inheritance_valuation.evaluation_company_tax_period)
              : null}
          </td>
          <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500">
            {inheritance_valuation.updated_at !== null
              ? setUpdatedTime(inheritance_valuation.updated_at)
              : null}
          </td>
          <td className="cursor-pointer hover:text-blue-600 py-2 whitespace-nowrap text-left text-sm font-medium text-blue-500">
            <a href={`/inheritances/${inheritance_valuation.id}`}>
              株価診断をする
            </a>
          </td>
          <td className="cursor-pointer hover:text-red-700 py-2 whitespace-nowrap text-left text-sm font-medium text-red-500">
            <Popover
              isOpen={
                inheritance_valuation.id === inheritanceValuationId && isOpen
                  ? true
                  : false
              }
              onClickOutside={() => setEditPopover(inheritance_valuation.id)}
              positions={['right']}
              padding={10}
              content={
                <div className="border border-gray-300 w-32 h-14 bg-white rounded-sm">
                  <a
                    href={`/inheritances/${inheritance_valuation.id}/edit`}
                    className="cursor-pointer text-green-500 hover:text-green-600 ml-2 pt-2"
                  >
                    編集する
                  </a>
                  <div
                    onClick={() => setDeleteModal(inheritance_valuation.id)}
                    className="cursor-pointer text-red-500 hover:text-red-600 ml-2 pt-1"
                  >
                    削除する
                  </div>
                </div>
              }
            >
              <button
                onClick={() => setEditPopover(inheritance_valuation.id)}
                className="btn btn-circle btn-outline bg-gray-200 hover:bg-gray-100 rounded-full"
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22px"
                  height="22px"
                  viewBox="0 0 992 992"
                >
                  <g>
                    <circle cx="174.3" cy="496" r="74.3" />
                    <circle cx="496" cy="496" r="74.3" />
                    <circle cx="797.7" cy="496" r="74.3" />
                  </g>
                </svg>
              </button>
            </Popover>
          </td>
        </tr>
      ))}
      {deleteInheritanceValuationId !== 0 ? (
        <MessageModal
          setModal={modalCheck}
          modalType="confirm"
          modalDom="#inheritance_index_page"
          url={`/inheritances/${deleteInheritanceValuationId}`}
          reqAction={'delete'}
          setModalCheck={setModalCheck}
        />
      ) : (
        <></>
      )}
    </ModalProvider>
  );
};
