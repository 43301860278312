import React, { useEffect } from 'react';
import { useOcrResultRows } from './hooks';
import { OcrResultRowProp } from './types';

export const OcrResultRow: React.FC<OcrResultRowProp> = (props) => {
  const { reportId, pageId } = props;
  const { image, getPdfRequest } = useOcrResultRows({ reportId, pageId });

  useEffect(() => {
    getPdfRequest();
  }, [getPdfRequest, pageId, reportId]);

  return (
    <div className="h-3/6 flex flex-wrap mb-10 pt-10">
      <div className="w-full">
        <embed
          className="pdf"
          type="application/pdf"
          src={image}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};
