import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { ErrorMessage } from '@hookform/error-message';
import { inputProps } from './types';

export const SubjectInput: React.FC<inputProps> = (props) => {
  const { subject, setValue, defaultValue, _callback, errors } = props;
  const [inputValue, setInputValue] = useState<number>(defaultValue);

  const setSubjectValue = useCallback(
    (e) => {
      if (e.target.value === '') {
        // @ts-ignore
        setValue(subject, null);
        setInputValue(e.target.value);
        return;
      }
      // @ts-ignore
      setValue(subject, Number(e.target.value.replace(/,/g, '')));
      _callback
        ? _callback(subject, Number(e.target.value.replace(/,/g, '')))
        : '';
      setInputValue(e.target.value);
    },
    [_callback, setValue, subject],
  );

  useEffect(() => {
    // @ts-ignore
    setValue(subject, defaultValue);
  }, [defaultValue, setValue, subject]);

  return (
    <>
      <NumberFormat
        // @ts-ignore
        onChange={(e) => setSubjectValue(e)}
        className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
        thousandsGroupStyle="thousand"
        decimalSeparator="."
        displayType="input"
        type="text"
        value={inputValue}
        thousandSeparator={true}
        allowNegative={true}
        decimalScale={2}
      />
      {errors && (
        <ErrorMessage
          // @ts-ignore
          errors={errors}
          // @ts-ignore
          name={subject}
          render={({ message }) => (
            <p className="text-left font-semibold text-red-500">{message}</p>
          )}
        />
      )}
    </>
  );
};
