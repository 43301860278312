import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useCompanyMedicalReportDownloadButton } from './hooks';
import { CompanyMedicalReportDownloadButtonProps } from './types';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    position: 'absolute',
    top: '30%',
    left: '30%',
    right: '30%',
    bottom: 'auto',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
};

export const CompanyMedicalReportDownloadButton: React.FC<
  CompanyMedicalReportDownloadButtonProps
> = (props) => {
  const { reportId } = props;
  // @ts-ignore
  const token: string = document
    .getElementById('company_medical_report_proile')
    .parentElement.getElementsByTagName('input')[0].value;
  const {
    downloadPptxApi,
    modalIsOpen,
    pptxMessage,
    donwloadReport,
    addReportDownloadListener,
  } = useCompanyMedicalReportDownloadButton();
  useEffect(() => {
    Modal.setAppElement('#profile_page');
    addReportDownloadListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={() =>
          reportId && token ? donwloadReport(reportId, token) : null
        }
        className="inline-flex justify-center pt-3 px-6 text-sm font-medium rounded text-white bg-blue-500 hover:bg-blue-600"
      >
        <span className="mr-2">
          <svg
            width="24px"
            height="22px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0856 12.5315C14.8269 12.2081 14.3549 12.1557 14.0315 12.4144L12.75 13.4396V10.0001C12.75 9.58585 12.4142 9.25006 12 9.25006C11.5858 9.25006 11.25 9.58585 11.25 10.0001V13.4396L9.96849 12.4144C9.64505 12.1557 9.17308 12.2081 8.91432 12.5315C8.65556 12.855 8.708 13.327 9.03145 13.5857L11.5287 15.5835C11.6575 15.6877 11.8215 15.7501 12 15.7501C12.1801 15.7501 12.3453 15.6866 12.4746 15.5809L14.9685 13.5857C15.2919 13.327 15.3444 12.855 15.0856 12.5315Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.46038 7.28393C9.40301 5.8274 11.0427 4.86182 12.9091 4.86182C15.7228 4.86182 18.024 7.05632 18.1944 9.82714C18.2506 9.825 18.307 9.82392 18.3636 9.82392C20.7862 9.82392 22.75 11.7878 22.75 14.2103C22.75 16.6328 20.7862 18.5966 18.3636 18.5966L7 18.5966C3.82436 18.5966 1.25 16.0223 1.25 12.8466C1.25 9.67101 3.82436 7.09665 7 7.09665C7.50391 7.09665 7.99348 7.16164 8.46038 7.28393ZM12.9091 6.36182C11.404 6.36182 10.1021 7.23779 9.48806 8.51108C9.31801 8.86369 8.90536 9.0262 8.54054 8.88424C8.0639 8.69877 7.54477 8.59665 7 8.59665C4.65279 8.59665 2.75 10.4994 2.75 12.8466C2.75 15.1939 4.65279 17.0966 7 17.0966L18.3627 17.0966C19.9568 17.0966 21.25 15.8044 21.25 14.2103C21.25 12.6162 19.9577 11.3239 18.3636 11.3239C18.1042 11.3239 17.8539 11.358 17.6164 11.4214C17.3762 11.4855 17.1198 11.4265 16.9319 11.2637C16.7439 11.1009 16.6489 10.8556 16.6781 10.6087C16.6955 10.461 16.7045 10.3103 16.7045 10.1573C16.7045 8.0611 15.0053 6.36182 12.9091 6.36182Z"
              fill="white"
            />
          </svg>
        </span>
        ダウンロードする(.pptx)
      </button>
      <iframe height="0px" width="0px" src={downloadPptxApi}></iframe>
      <Modal
        isOpen={modalIsOpen}
        // @ts-ignore
        style={customStyles}
      >
        <h2 className="pb-1 font-semibold text-gray-700">{pptxMessage}</h2>
        <div className="h-6 relative max-w-xl rounded-full overflow-hidden">
          <div className="w-full h-full bg-gray-200 absolute"></div>
          <div id="bar" className="h-full bg-blue-500 py-4 relative w-0"></div>
        </div>
      </Modal>
    </>
  );
};
