import React from 'react';
import { SubjectRow } from '../../SubjectRow/SubjectRow';
import { SubjectProps } from '../types';

export const Sga: React.FC<SubjectProps> = (props: SubjectProps) => {
  const { getValues, setValue, control, reportType } = props;
  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-9 pl-5 text-sm">
        <div className="inline-block min-w-full py-1 align-middle md:px-8 lg:px-10">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-10 md:rounded-xs">
            <div className="grid grid-cols-12 divide-x">
              <div className="col-span-12 text-center bg-gray-400 text-lg font-semibold text-white">
                販売費及び一般管理費内訳書
              </div>
              <div className="col-span-6 divide-x py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  科目
                </p>
              </div>
              <div className="col-span-6 py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  金額
                </p>
              </div>
              <SubjectRow
                label="減価償却費"
                value={'subject.depreciation'}
                subject={'subject.depreciation'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                toolTipProps={{
                  text: '販管費内の「減価償却費」、「長期前払費用償却」、「のれん償却費」等を集計・入力する',
                  placement: 'bottom-6 -left-12',
                }}
              />
              {reportType === 'company_medical_reports' ? (
                <>
                  <SubjectRow
                    label="人件費"
                    value={'subject.personnel_expense'}
                    subject={'subject.personnel_expense'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「人件費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="外注費"
                    value={'subject.subcontracting_cost'}
                    subject={'subject.subcontracting_cost'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「外注費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="広告宣伝費"
                    value={'subject.advertising_expense'}
                    subject={'subject.advertising_expense'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「広告宣伝費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="交際費"
                    value={'subject.entertainment_expense'}
                    subject={'subject.entertainment_expense'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「交際費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="リース料"
                    value={'subject.lease'}
                    subject={'subject.lease'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「リース」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="水道光熱費"
                    value={'subject.utilities_expense'}
                    subject={'subject.utilities_expense'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「水道光熱費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="地代家賃"
                    value={'subject.land_rent'}
                    subject={'subject.land_rent'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「地代家賃」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                  <SubjectRow
                    label="通信費"
                    value={'subject.communication_expense'}
                    subject={'subject.communication_expense'}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    toolTipProps={{
                      text: '販管費内の「通信費」を入力する',
                      placement: 'bottom-6 -left-12',
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-9 pl-5 pt-8 text-sm">
        <div className="inline-block min-w-full py-1 align-middle md:px-8 lg:px-10">
          <div className="overflow-hidden ring-1 ring-black ring-opacity-10 md:rounded-xs">
            <div className="grid grid-cols-12 divide-x">
              <div className="col-span-12 text-center bg-gray-400 text-lg font-semibold text-white">
                製造原価報告書
              </div>
              <div className="col-span-6 divide-x py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  科目
                </p>
              </div>
              <div className="col-span-6 py-2.5 bg-gray-200">
                <p className="text-center text-base font-semibold text-gray-600">
                  金額
                </p>
              </div>
              <SubjectRow
                label="減価償却費"
                value={'subject.manufacturing_depreciation'}
                subject={'subject.manufacturing_depreciation'}
                control={control}
                getValues={getValues}
                setValue={setValue}
                toolTipProps={{
                  text: '製造業等で製造原価報告書を作成している場合は、製造原価報告書内の「減価償却費」を入力する',
                  placement: 'bottom-6 -left-12',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
