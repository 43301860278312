import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getAreas, getIndustries } from './data/use-filter';
import './pagination.scss';

type CrawlingResultProp = {
  listId: string;
  resourceName: string;
};

type selectData = {
  area_id: string;
  industry_id: string;
};

const CrawlingResult: React.FC<CrawlingResultProp> = (
  props: CrawlingResultProp,
) => {
  const [progress, setProgress] = useState('ただいまリスト準備中...');
  const [companies, setCompanies] = useState([]);
  const [currentCompanies, setCurrentCompanies] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const { register, handleSubmit } = useForm<selectData>();
  const industryResponse = getIndustries(props.listId, props.resourceName);
  const areaResponse = getAreas(props.listId, props.resourceName);
  const perPage = 25;

  // ページネーション作成
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setPagination = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (companies) => {
      const endOffset = itemOffset + perPage;
      setCurrentCompanies(companies.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(companies.length / perPage));
    },
    [itemOffset],
  );

  // 解析結果取得
  const getCompanies = useCallback(
    (params) => {
      axios
        .get(`/crawl/${props.resourceName}/${props.listId}/result`, {
          params: params,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            setProgress('リストが完成しました。');
            setCompanies(res.data.body);
            setPagination(res.data.body);
          } else if (res.data.status == 'failed') {
            setProgress('条件に一致する企業は見つかりませんでした。');
          }
        })
        .catch((err) => {
          setProgress('条件に一致する企業は見つかりませんでした。');
          console.log(err);
        });
    },
    [props.listId, props.resourceName, setPagination],
  );

  useEffect(() => {
    getCompanies({ area_id: '', industry_id: '' });
  }, [getCompanies]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    // @ts-ignore
    setCurrentCompanies(companies.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(companies.length / perPage));
  }, [itemOffset, perPage, companies]);

  /**
   * ページ遷移
   */
  const handlePageClick = useCallback(
    (event) => {
      const newOffset = (event.selected * perPage) % companies.length;
      setItemOffset(newOffset);
    },
    [companies.length],
  );

  /**
   * 円形式に変換
   */
  const setJpCurrency = useCallback((sales: number) => {
    const jpSales = String(sales);
    const jpSalesLength = jpSales.length;
    const digits = ['', '万', '億', '兆'];
    let result = '';
    const results = [];
    for (let i = 0; i < Math.ceil(jpSalesLength / 4); i++) {
      results[i] = Number(
        jpSales.substring(jpSalesLength - i * 4, jpSalesLength - (i + 1) * 4),
      );
      if (results[i] != 0)
        result =
          String(results[i]).replace(/(\d)(?=(\d\d\d)+$)/g, '$1,') +
          digits[i] +
          result;
    }
    return result + '円';
  }, []);

  return (
    <div className="crawling-result w-full">
      <div className="filter-description min-w-full text-left">
        <p className="text-gray-400 text-xl font-semibold pt-1">絞り込み検索</p>
      </div>
      <div className="company-filter my-7">
        <form
          className="grid grid-cols-8 gap-2"
          onSubmit={handleSubmit(getCompanies)}
        >
          <select
            // @ts-ignore
            name="area cursor-pointer"
            {...register('area_id')}
            className="col-span-2
                         text-gray-600
                         block appearance-none
                         bg-white border
                         border-gray-400
                         hover:border-gray-500
                         py-2
                         rounded
                         shadow
                         leading-tight
                         focus:outline-none
                         focus:shadow-outline"
          >
            <option key="" value="">
              全ての地域から選択する
            </option>
            {/* @ts-ignore */}
            {areaResponse.data?.body.area.map((value) => (
              <option key={value.area_id} value={value.area_id}>
                {value.name}
              </option>
            ))}
          </select>
          <select
            // @ts-ignore
            name="industry cursor-pointer"
            {...register('industry_id')}
            className="col-span-2
                         text-gray-600
                         block
                         appearance-none
                         bg-white border
                         border-gray-400
                         hover:border-gray-500
                         py-2
                         rounded
                         shadow
                         leading-tight
                         focus:outline-none
                         focus:shadow-outline"
          >
            <option key="" value="">
              全ての業種から選択する
            </option>
            {/* @ts-ignore */}
            {industryResponse.data?.body.industries.map((value) => (
              <option key={value.industry_id} value={value.industry_id}>
                {value.name}
              </option>
            ))}
          </select>
          <input
            value="検索"
            className="cursor-pointer
                              bg-blue-600
                              text-white
                              hover:bg-blue-700
                              hover:text-white
                              rounded-sm
                              font-medium"
            type="submit"
          />
        </form>
      </div>
      <div className="w-full">
        {/* @ts-ignore */}
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          // @ts-ignore
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageClassName="page-item rounded-md"
          pageLinkClassName="page-link rounded-md cursor-pointer"
          activeClassName="active-page-item rounded-md"
          activeLinkClassName="active rounded-md"
          previousLinkClassName="page-item rounded-md"
          nextLinkClassName="page-item rounded-md"
          disabledClassName="disabled-button"
        />
      </div>
      <div className="bg-gray-200 shadow p-6">
        {/* @ts-ignore */}
        {currentCompanies && currentCompanies.length > 0 ? (
          // @ts-ignore
          currentCompanies.map((company, _index) => (
            <div
              key={_index}
              className="max-w-full place-items-start py-10 px-4 bg-white rounded-sm my-5 mx-1"
            >
              <div className="company-info">
                <div className="flex justify-start space-x-4">
                  {company.prefecture ? (
                    <button
                      className="
                                             cursor-auto
                                             text-left
                                             bg-green-600
                                             text-white
                                             font-semibold
                                             text-sm
                                             py-0.5
                                             px-2.5
                                             rounded-full"
                    >
                      {company.prefecture}
                    </button>
                  ) : (
                    ''
                  )}
                  {company.ticker ? (
                    <button className="cursor-auto text-left bg-blue-600 text-white font-semibold text-sm py-0.5 px-2.5 rounded-full">
                      上場企業
                    </button>
                  ) : (
                    ''
                  )}
                  {company.url ? (
                    <div className="company-url flex justify-end">
                      <a
                        href={company.url}
                        target="_blank"
                        className="
                   text-left
                   py-0.5
                   px-1
                   border
                   hover:bg-gray-600
                   hover:text-white
                   border-gray-500
                   text-gray-500
                   text-sm
                   inline-flex
                   items-center"
                        rel="noreferrer"
                      >
                        <span className="pr-2">
                          {/* @ts-ignore */}
                          <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </span>
                        会社サイト
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <h2 className="text-left text-gray-800 text-2xl font-semibold py-2">
                  {company.name}
                </h2>
                <div className="text-left grid grid-cols-12">
                  {company.net_sales ? (
                    <p className="col-span-3 text-gray-400 text-sm">
                      売上高: {setJpCurrency(company.net_sales)}
                    </p>
                  ) : (
                    <p className="text-gray-400 text-sm">売上高: -</p>
                  )}
                  {company.net_assets ? (
                    <p className="col-span-3 text-gray-400 text-sm">
                      資本金: {setJpCurrency(company.net_assets)}
                    </p>
                  ) : (
                    <p className="text-gray-400 text-sm">資本金: -</p>
                  )}
                </div>
                <div className="words-badge flex flex-wrap justify-start pt-4">
                  {/* @ts-ignore eslint-disable-next-line @typescript-eslint/no-shadow */}
                  {company.words.slice(0, 10).map((word, __index) => (
                    <button
                      key={__index}
                      className="cursor-auto text-left bg-gray-100 p-1 m-1 border border-gray-400 text-blue-900 text-sm inline-flex items-center"
                    >
                      {word}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>{progress}</div>
        )}
      </div>
    </div>
  );
};

export default CrawlingResult;
