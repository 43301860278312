import React from 'react';
import { NetAssets } from './NetAssets';

export const NetAssetsContainer: React.FC = React.memo(() => {
  return (
    <>
      <NetAssets />
    </>
  );
});
