import axios from 'axios';
import type { GroupBase, OptionsOrGroups } from 'react-select';
import { sleep } from '../../../utils/common';
import {
  CompanyMedicalReportSelectOptions,
  OptionType,
  ProfileFormStateProps,
  SectionsProps,
} from './types';

export const postBatchValuationRequest = (token: string, reportId: string) => {
  axios
    .post(
      `/company_medical_reports/${reportId}/batch_valuations`,
      {},
      {
        headers: {
          'X-CSRF-Token': token,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) {
        location.href = `/company_medical_reports/${reportId}`;
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const putProfileRequest = async (
  token: string,
  companyMedicalReportId: string,
  formData: ProfileFormStateProps,
  sections: SectionsProps[],
  selectReports: string[],
) => {
  try {
    const response = await axios({
      method: 'put',
      url: `/company_medical_reports/${companyMedicalReportId}/profile`,
      data: {
        company_medical_report_profile_form: {
          evaluation_company_name: formData.evaluationCompanyName,
          evaluation_company_location: formData.evaluationCompanyLocation,
          evaluation_company_created_date:
            formData.evaluationCompanyCreatedDate,
          evaluation_company_president: formData.evaluationCompanyPresident,
          evaluation_company_shares_count:
            formData.evaluationCompanySharesCount,
          evaluation_company_employees: formData.evaluationCompanyEmployees,
          evaluation_company_business: formData.evaluationCompanyBusiness,
          maker_company_name: formData.makerCompanyName,
          maker_company_author: formData.makerCompanyAuthor,
          maker_company_email: formData.makerCompanyEmail,
          maker_company_location: formData.makerCompanyLocation,
          maker_company_phone_number: formData.makerCompanyPhoneNumber,
          report_creation_date: formData.reportCreationDate,
          report_submission_date: formData.reportSubmissionDate,
          section_index_0: sections[0],
          section_index_1: sections[1],
          section_index_2: sections[2],
          selected_reports: selectReports,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
    });

    if (response.data.status === 200) {
      return 'success';
    } else {
      return 'failed';
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const loadOptions = async (
  search: string,
  prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
) => {
  const currentPage = Math.floor(prevOptions.length / 10) + 1;
  await sleep(1000);
  try {
    const response = await axios.get('/company_medical_reports/index_json', {
      params: {
        page: currentPage,
        q: { evaluation_company_name_cont: search },
      },
    });
    const { data } = response;
    const reportOptions: OptionType[] = data.company_medical_reports.map(
      (item: CompanyMedicalReportSelectOptions) => ({
        value: item.id,
        evaluation_company_name: `${item.evaluation_company_name}`,
        report_year: item.report_year,
        dcf: item.dcf?.result?.valuation,
        per: item.per?.result?.valuation,
        ebitda: item.ebitda?.result?.valuation,
        inheritance: item.inheritance?.result?.valuation,
      }),
    );
    const hasMore = data.company_medical_reports.length === 10;
    return {
      options: reportOptions,
      hasMore,
    };
  } catch (error) {
    console.error('API request error:', error);
    return {
      options: [],
      hasMore: false,
    };
  }
};
