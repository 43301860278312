import React from 'react';
import { CompanyMedicalReportPreviewProps } from '../types';

export const SubjectPagePreview: React.FC<CompanyMedicalReportPreviewProps> = (
  props,
) => {
  const {
    tableHeaderColor,
    tableHeaderTextColor,
    tableCellColor,
    tableCellTextColor,
    rotationSampleImg,
  } = props;

  const subjectHeaders = ['', '直前期'];
  const subjectRows = [
    ['売上債権', '-'],
    ['売掛金', '-'],
    ['受取手形', '-'],
    ['棚卸資産', '-'],
    ['商品', '-'],
    ['製品', '-'],
    ['仕掛品', '-'],
    ['仕入債務', '-'],
    ['買掛金', '-'],
    ['支払手形', '-'],
    ['有利子負債', '-'],
    ['短期借入金', '-'],
    ['長期借入金', '-'],
    ['社債', '-'],
    ['リース債務', '-'],
    ['非事業用資産', '-'],
    ['上場有価証券', '-'],
    ['保険積立金', '-'],
  ];

  const rotationHeaders = ['', '直前期'];
  const rotationRows = [
    ['ROA\n当期純利益 ÷ 総資産', '0.0%'],
    ['ROI\n当期純利益 ÷ (有利子負債 + 純資産)', '0.0%'],
    ['D/Eレシオ\n有利子負債 ÷ 純資産', '0.0%'],
  ];

  const sgaHeaders = [
    '販売費及び一般管理費',
    '今回の決算書',
    '対販管費率',
    '業界平均',
    '業界平均との差',
  ];
  const sgaRows = [
    ['販売費及び一般管理費', '-', '-', '-', '-'],
    ['人件費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['広告宣伝費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['交際費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['地代家賃', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['減価償却費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['リース', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['水道光熱費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['通信費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['外注費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
  ];

  const managementHeaders = ['', '御社', '業界平均', '上場平均'];
  const managementRows = [
    ['ROA\n当期純利益 ÷ 総資産', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    [
      'ROI\n当期純利益 ÷ (有利子負債 + 純資産)',
      '0.0%',
      '49.3%~56.9%',
      '72.3%~76.9%',
    ],
    ['D/Eレシオ\n有利子負債 ÷ 純資産', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
  ];

  return (
    <>
      <div
        style={{ fontSize: '6px' }}
        className="shadow-md mt-4 w-full h-auto border border-gray-200 p-4"
      >
        <div className="grid grid-cols-12 pt-2">
          <div className="col-span-12">
            <h1 className="font-semibold ">販売費及び一般管理費</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {sgaHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sgaRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            rowIndex % 2 === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`${
                          cellIndex === 0 ? 'text-left' : 'text-right'
                        } border border-gray-300 px-4 py-0.5`}
                      >
                        {cellContent}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid grid-cols-12 pt-9 gap-6">
          <div className="col-span-6">
            <h1 className="font-semibold ">科目内訳</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {subjectHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {subjectRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            rowIndex % 2 === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`${
                          cellIndex === 0 ? 'text-left' : 'text-right'
                        } border border-gray-300 px-4 py-0.5`}
                      >
                        {cellContent}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-span-6">
            <h1 className="font-semibold ">回転期間</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {rotationHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rotationRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            cellIndex === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`border border-gray-300 px-4 py-0.5 ${
                          cellIndex === 0 ? 'text-left' : 'text-center'
                        }`}
                      >
                        {cellContent.split('\n').map((line, lineIndex) => (
                          <span key={lineIndex}>
                            {line}
                            {lineIndex < cellContent.split('\n').length - 1 && (
                              <br />
                            )}
                          </span>
                        ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <img className="h-6/12 w-full" src={rotationSampleImg} />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-8">
          <div className="col-span-12">
            <h1 className="font-semibold ">経営指標</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {managementHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {managementRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            cellIndex === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`${
                          cellIndex === 0 ? 'text-left' : 'text-right'
                        } border border-gray-300 px-4 py-0.5`}
                      >
                        {cellContent.split('\n').map((line, lineIndex) => (
                          <span key={lineIndex}>
                            {line}
                            {lineIndex < cellContent.split('\n').length - 1 && (
                              <br />
                            )}
                          </span>
                        ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <p className="pt-3">
          ※業界平均は e-Stat、上場平均は EDINET の情報を加工・集計しております
        </p>
      </div>
    </>
  );
};
