import React, { useEffect } from 'react';
import { useSimilarApiReqeust } from './hooks';
import { postSimilarCompanies } from './api';
import { SimilarCompaniesProps } from './types';

export const SimilarCompanies: React.FC<SimilarCompaniesProps> = (props) => {
  const { path } = props;
  const token = document
    ?.getElementById('similarities_form')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  const { ...SimilarCompaniesHooks } = useSimilarApiReqeust({
    postSimilarCompanies,
    path,
    // @ts-ignore
    token,
  });

  useEffect(() => {
    SimilarCompaniesHooks.addSimilarRequestListener();
  }, [SimilarCompaniesHooks]);

  return <></>;
};
