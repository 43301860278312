import { z } from 'zod';

const unionElement = z.union([z.string(), z.number(), z.boolean(), z.null()]);

export const PerSchema = z.object({
  result: z.object({
    valuation: unionElement,
    enterprise_value: unionElement,
    business_value: unionElement,
    goodwill: unionElement,
  }),
  breakdown: z.object({
    0: z.object({
      non_liquidity_discount: unionElement,
      profit: unionElement,
      industry: unionElement,
      per: unionElement,
      valuation: unionElement,
    }),
  }),
  process: z.array(z.array(unionElement)),
  validates: z.array(z.unknown()),
  message: unionElement,
});

export const EbitdaSchema = z.object({
  result: z.object({
    valuation: unionElement,
    enterprise_value: unionElement,
    business_value: unionElement,
    goodwill: unionElement,
  }),
  breakdown: z.object({
    0: z.object({
      non_liquidity_discount: unionElement,
      average_ebitda: unionElement,
      operating_profit: unionElement,
      depreciation: unionElement,
      ebitda: unionElement,
      industry: unionElement,
      ebitda_ratio: unionElement,
      enterprise_value: unionElement,
      cash_and_deposits: unionElement,
      insurance_funds: unionElement,
      listed_investment_securities: unionElement,
      other_non_business_assets_exept: unionElement,
      other_non_business_assets: unionElement,
      debt_like_item: unionElement,
      interest_bearing_debt: unionElement,
      account_receivable: unionElement,
      notes_receivable: unionElement,
      other_receivable: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable_trade: unionElement,
      notes_payable: unionElement,
      other_payable: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      surplus_cash: unionElement,
      asset_for_non_business: unionElement,
      net_debt: unionElement,
      valuation: unionElement,
    }),
  }),
  process: z.array(z.array(unionElement)),
  validates: z.array(unionElement),
  message: unionElement,
});

export const DcfSchema = z.object({
  result: z.object({
    valuation: unionElement,
    enterprise_value: unionElement,
    business_value: unionElement,
    goodwill: unionElement,
  }),
  breakdown: z.object({
    0: z.object({
      industry: unionElement,
      unlevered_beta: unionElement,
      average_de_ratio: unionElement,
      share_capital: unionElement,
      effective_tax_rate: unionElement,
      growth_rate: unionElement,
      sixth_term_growth_rate: unionElement,
      interest_expenses: unionElement,
      interest_bearing_debt: unionElement,
      shareholders_equity: unionElement,
      de_ratio: unionElement,
      levered_beta: unionElement,
      risk_free_rate: unionElement,
      risk_free_rate_org: unionElement,
      risk_free_rate_date: unionElement,
      equity_risk_premium: unionElement,
      equity_cost: unionElement,
      average_interest_beading_debt: unionElement,
      debt_cost: unionElement,
      debt_rate: unionElement,
      wacc: unionElement,
      manual_net_sales_rate: unionElement,
      manual_cost_of_sales_rate: unionElement,
      manual_sga_rate: unionElement,
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      account_receivable: unionElement,
      notes_receivable: unionElement,
      other_receivable: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable_trade: unionElement,
      notes_payable: unionElement,
      other_payable: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      trade_receivable_rotate: unionElement,
      inventories_rotate: unionElement,
      accounts_payable_ratate: unionElement,
      cash_and_deposits: unionElement,
      insurance_funds: unionElement,
      listed_investment_securities: unionElement,
      other_non_business_assets_exept: unionElement,
      other_non_business_assets: unionElement,
      debt_like_item: unionElement,
      surplus_cash: unionElement,
      asset_for_non_business: unionElement,
      net_debt: unionElement,
      non_liquidity_discount: unionElement,
      dcpc: unionElement,
      fcf_pv_sum: unionElement,
      terminal_value: unionElement,
      terminal_value_pv: unionElement,
      business_value: unionElement,
      enterprise_value: unionElement,
      valuation: unionElement,
      size_risk_premium: unionElement,
    }),
    1: z.object({
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      operation_capital: unionElement,
      fcf: unionElement,
      dcpc: unionElement,
      fcf_pv: unionElement,
    }),
    2: z.object({
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      operation_capital: unionElement,
      fcf: unionElement,
      dcpc: unionElement,
      fcf_pv: unionElement,
    }),
    3: z.object({
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      operation_capital: unionElement,
      fcf: unionElement,
      dcpc: unionElement,
      fcf_pv: unionElement,
    }),
    4: z.object({
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      operation_capital: unionElement,
      fcf: unionElement,
      dcpc: unionElement,
      fcf_pv: unionElement,
    }),
    5: z.object({
      net_sales: unionElement,
      cost_of_sales: unionElement,
      sga: unionElement,
      depreciation: unionElement,
      capex: unionElement,
      gross: unionElement,
      operating_income: unionElement,
      income_taxes: unionElement,
      nopat: unionElement,
      trade_receivable: unionElement,
      inventories: unionElement,
      accounts_payable: unionElement,
      capital_balance: unionElement,
      operation_capital: unionElement,
      fcf: unionElement,
      dcpc: unionElement,
      fcf_pv: unionElement,
    }),
    '-1': z.object({ interest_bearing_debt: unionElement }),
  }),
  process: z.array(z.union([z.array(unionElement), z.array(unionElement)])),
  validates: z.array(z.unknown()),
  message: unionElement,
});

export const InheritanceSchema = z.object({
  result: z.object({
    result_selected: unionElement,
    company_size: unionElement,
    stock_price: unionElement,
    valuation: unionElement,
    inheritance_tax: unionElement,
  }),
  document: z.object({
    page_1: z.object({
      treasury_voting: unionElement,
      largest_shareholder_group_resolution_rights_rate: unionElement,
      group_voting: unionElement,
      taxpayer_relevant_parties_group_resolution_rights_rate: unionElement,
      number_of_shares_issued: unionElement,
      calc_method: unionElement,
      is_officer: unionElement,
      is_main_shareholders: unionElement,
      is_other_shareholders: unionElement,
      calc_method_after: unionElement,
    }),
    page_2: z.object({
      assets: unionElement,
      net_sales: unionElement,
      employees: unionElement,
      company_size_asset: z.object({
        industry: unionElement,
        size: unionElement,
      }),
      company_size_employee: unionElement,
      company_size_sales: z.object({
        industry: unionElement,
        size: unionElement,
      }),
      company_size: unionElement,
    }),
    page_3: z.object({
      ratio_semi_elements_dividend_1: unionElement,
      ratio_semi_elements_income_1: unionElement,
      ratio_semi_elements_net_asset_1: unionElement,
      ratio_semi_elements_dividend_2: unionElement,
      ratio_semi_elements_income_2: unionElement,
      ratio_semi_elements_net_asset_2: unionElement,
      assets: unionElement,
      stock_and_investment_sum_mpe: unionElement,
      stock_owned_rate: unionElement,
      land_sum_mpe: unionElement,
      land_owned_rate: unionElement,
      company_size: unionElement,
      ratio_semi_elements_0: z.boolean(),
      ratio_semi_elements: z.boolean(),
      stock_owned: z.boolean(),
      company_size_owned: z.boolean(),
      year_progress: z.boolean(),
      before_opening: z.boolean(),
      closed: z.boolean(),
      under_liquidation: z.boolean(),
    }),
    page_4: z.object({
      modification_ratio_semi_market_value_2: unionElement,
      net_asset_per_1_stock_1: unionElement,
      net_asset_per_1_stock_2: unionElement,
      company_type: unionElement,
      stock_price: unionElement,
      similar_rate: unionElement,
      stock_price_1: unionElement,
      share_capital: unionElement,
      number_of_shares_issued: unionElement,
      treasury_shares: unionElement,
      number_of_issued_share_50_yen: unionElement,
      capital_amount_per_1_stock: unionElement,
      annual_dividend_amount_before_1_term: unionElement,
      non_recurring_dividend_amount_before_1_term: unionElement,
      ordinary_annual_dividend_amount_before_1_term: unionElement,
      annual_dividend_amount_before_2_term: unionElement,
      non_recurring_dividend_amount_before_2_term: unionElement,
      ordinary_annual_dividend_amount_before_2_term: unionElement,
      annual_average_dividend_amount: unionElement,
      annual_dividend_on_shares: unionElement,
      annual_dividend_on_shares_result: unionElement,
      dividend_pay_back_market_value: unionElement,
      valuation: unionElement,
    }),
    page_5: z.object({
      share_capital: unionElement,
      number_of_shares_issued: unionElement,
      treasury_shares: unionElement,
      capital_amount_per_1_stock: unionElement,
      number_of_issued_share_50_yen: unionElement,
      annual_dividend_amount_before_1_term: unionElement,
      non_recurring_dividend_amount_before_1_term: unionElement,
      ordinary_annual_dividend_amount_before_1_term: unionElement,
      annual_dividend_amount_before_2_term: unionElement,
      non_recurring_dividend_amount_before_2_term: unionElement,
      ordinary_annual_dividend_amount_before_2_term: unionElement,
      annual_dividend_amount_before_3_term: unionElement,
      non_recurring_dividend_amount_before_3_term: unionElement,
      ordinary_annual_dividend_amount_before_3_term: unionElement,
      annual_average_dividend_amount_1: unionElement,
      annual_average_dividend_amount_2: unionElement,
      ratio_semi_elements_dividend_1: unionElement,
      ratio_semi_elements_dividend_2: unionElement,
      annual_dividend_amount_50_yen: unionElement,
      taxable_income_before_1_term: unionElement,
      taxable_income_before_2_term: unionElement,
      taxable_income_before_3_term: unionElement,
      profit_amount_before_1_term: unionElement,
      profit_amount_before_2_term: unionElement,
      profit_amount_before_3_term: unionElement,
      income_tax_not_included_before_1_term: unionElement,
      income_tax_not_included_before_2_term: unionElement,
      income_tax_not_included_before_3_term: unionElement,
      income_tax_before_1_term: unionElement,
      income_tax_before_2_term: unionElement,
      income_tax_before_3_term: unionElement,
      deduction_of_loss_carryforwards_before_1_term: unionElement,
      deduction_of_loss_carryforwards_before_2_term: unionElement,
      deduction_of_loss_carryforwards_before_3_term: unionElement,
      ordinary_annual_income_amount_before_1_term: unionElement,
      ordinary_annual_income_amount_before_2_term: unionElement,
      ordinary_annual_income_amount_before_3_term: unionElement,
      ratio_semi_elements_income_1: unionElement,
      ratio_semi_elements_income_2: unionElement,
      annual_income_amount_average_50_yen: unionElement,
      share_capital_before_1_term: unionElement,
      share_capital_before_2_term: unionElement,
      profit_reserve_amount_before_1_term: unionElement,
      profit_reserve_amount_before_2_term: unionElement,
      net_asset_market_value_before_1_term: unionElement,
      net_asset_market_value_before_2_term: unionElement,
      ratio_semi_elements_net_asset_1: unionElement,
      ratio_semi_elements_net_asset_2: unionElement,
      net_asset_market_value_50_yen: unionElement,
      industry_1: unionElement,
      industry_1_number: unionElement,
      industry_1_month_1: unionElement,
      industry_1_month_2: unionElement,
      industry_1_month_3: unionElement,
      industry_1_price_1: unionElement,
      industry_1_price_2: unionElement,
      industry_1_price_3: unionElement,
      industry_1_price_4: unionElement,
      industry_1_price_5: unionElement,
      industry_1_price_min: unionElement,
      industry_2: unionElement,
      industry_2_number: unionElement,
      industry_2_month_1: unionElement,
      industry_2_month_2: unionElement,
      industry_2_month_3: unionElement,
      industry_2_price_1: unionElement,
      industry_2_price_2: unionElement,
      industry_2_price_3: unionElement,
      industry_2_price_4: unionElement,
      industry_2_price_5: unionElement,
      industry_2_price_min: unionElement,
      industry_1_dividend: unionElement,
      industry_1_profit: unionElement,
      industry_1_net_asset: unionElement,
      ratio_semi_rate_1_dividend: unionElement,
      ratio_semi_rate_1_profit: unionElement,
      ratio_semi_rate_1_net_asset: unionElement,
      ratio_semi_rate_1: unionElement,
      ratio_semi_value_1: unionElement,
      industry_2_dividend: unionElement,
      industry_2_profit: unionElement,
      industry_2_net_asset: unionElement,
      ratio_semi_rate_2_dividend: unionElement,
      ratio_semi_rate_2_profit: unionElement,
      ratio_semi_rate_2_net_asset: unionElement,
      ratio_semi_rate_2: unionElement,
      ratio_semi_value_2: unionElement,
      ratio_semi_value: unionElement,
      ratio_semi_value_per_1_stock: unionElement,
      dividend_amount: unionElement,
      modification_ratio_semi_market_value_1: unionElement,
      payment_amount: unionElement,
      allocation_number: unionElement,
      number_of_allotments_or_grants: unionElement,
      modification_ratio_semi_market_value_2: unionElement,
    }),
    page_6: z.object({
      assets: z.array(
        z.object({
          name: unionElement,
          book_value: unionElement,
          market_price: unionElement,
        }),
      ),
      assets_book_value: unionElement,
      assets_market_price: unionElement,
      liabilities: z.array(z.unknown()),
      liabilities_book_value: unionElement,
      liabilities_market_price: unionElement,
      stock_and_investment_sum: unionElement,
      stock_and_investment_sum_mpe: unionElement,
      land_sum_mpe: unionElement,
      net_asset_by_inheritance: unionElement,
      net_asset_by_account_book: unionElement,
      evaluation_difference: unionElement,
      corporate_tax_amount_equivalent: unionElement,
      taxation_time_net_asset: unionElement,
      number_of_shares_issued: unionElement,
      net_asset_per_1_stock_1: unionElement,
      net_asset_per_1_stock_2: unionElement,
    }),
    page_7: z.object({
      modification_ratio_semi_market_value_2: unionElement,
      net_asset_per_1_stock_1: unionElement,
      net_asset_per_1_stock_2: unionElement,
      stock_price_1: unionElement,
      stock_price_one: unionElement,
      stock_price_owned: unionElement,
      stock_price_land: unionElement,
      stock_price_year_progress: z.boolean(),
      stock_price_before_opening_or_closed: z.boolean(),
      share_capital: unionElement,
      number_of_shares_issued: unionElement,
      treasury_shares: unionElement,
      number_of_issued_share_50_yen: unionElement,
      capital_amount_per_1_stock: unionElement,
      annual_dividend_amount_before_1_term: unionElement,
      non_recurring_dividend_amount_before_1_term: unionElement,
      ordinary_annual_dividend_amount_before_1_term: unionElement,
      annual_dividend_amount_before_2_term: unionElement,
      non_recurring_dividend_amount_before_2_term: unionElement,
      ordinary_annual_dividend_amount_before_2_term: unionElement,
      annual_average_dividend_amount: unionElement,
      annual_dividend_on_shares: unionElement,
      annual_dividend_on_shares_result: unionElement,
      dividend_pay_back_market_value: unionElement,
      stock_price_pay_back: unionElement,
    }),
  }),
  document_description: z.object({
    page_1: z.array(
      z.union([
        z.object({ key: unionElement, name: unionElement, type: unionElement }),
        z.object({
          key: unionElement,
          name: unionElement,
          type: unionElement,
          enum: z.array(unionElement),
        }),
      ]),
    ),
    page_2: z.array(
      z.object({ key: unionElement, name: unionElement, type: unionElement }),
    ),
    page_3: z.array(
      z.union([
        z.object({ key: unionElement, name: unionElement, type: unionElement }),
        z.object({
          key: unionElement,
          name: unionElement,
          type: unionElement,
          enum: z.array(unionElement),
        }),
      ]),
    ),
    page_4: z.array(
      z.union([
        z.object({ key: unionElement, name: unionElement, type: unionElement }),
        z.object({
          key: unionElement,
          name: unionElement,
          type: unionElement,
          enum: z.array(unionElement),
        }),
        z.object({ key: unionElement, name: unionElement }),
      ]),
    ),
    page_5: z.array(
      z.union([
        z.object({ key: unionElement, name: unionElement, type: unionElement }),
        z.object({ key: unionElement, name: unionElement }),
      ]),
    ),
    page_6: z.array(
      z.object({ key: unionElement, name: unionElement, type: unionElement }),
    ),
    page_7: z.array(
      z.object({ key: unionElement, name: unionElement, type: unionElement }),
    ),
  }),
  process: z.array(z.array(unionElement)),
  validates: z.array(z.unknown()),
  message: unionElement,
});
