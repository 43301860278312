import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useCompanyMedicalReportSubjectsStep } from './hooks';
import { CompanyMedicalReportSubjectsStepProps } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';

export const CompanyMedicalReportSubjectsStep: React.FC<
  CompanyMedicalReportSubjectsStepProps
> = (props) => {
  const {
    companyMedicalReport,
    isOcrUploaded,
    isSubjectUploaded,
    hasBusinessPlan,
  } = props;
  const [hasUploadedPlan, setHasUploadedPlan] = useState(false);
  const [hasUploadedSubjects, setHasUploadedSubjects] = useState(false);

  const {
    downloadFile,
    uploadBusinessPlan,
    uploadSubjects,
    parseResult,
    checkSubjectsInputFormType,
    getSubjectsInputForm,
  } = useCompanyMedicalReportSubjectsStep(companyMedicalReport);

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    file_name: 'valuation_plan' | 'subjects' = 'valuation_plan',
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file_name === 'valuation_plan') {
        uploadBusinessPlan(file, companyMedicalReport.id);
        setHasUploadedPlan(true);
      } else {
        uploadSubjects(file, companyMedicalReport.id);
        setHasUploadedSubjects(true);
      }
      event.target.value = '';
    }
  };

  useEffect(() => {
    hasBusinessPlan ? setHasUploadedPlan(true) : setHasUploadedPlan(false);
    isSubjectUploaded
      ? setHasUploadedSubjects(true)
      : setHasUploadedSubjects(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toaster />
      <div className="relative pb-8">
        <span
          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300"
          aria-hidden="true"
        ></span>
        <div className="relative flex space-x-3">
          <div>
            <span className="h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white">
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule={'evenodd'}
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule={'evenodd'}
                />
              </svg>
            </span>
          </div>
          <div>
            <div className="flex">
              <p className="text-lg text-green-400 font-semibold">STEP1</p>
              <span className="ml-3 mr-2 mt-2">
                <img className="h-3 w-3" src={TimeIcon} />
              </span>
              <p className="text-xs text-gray-400 mt-1.5">
                所要時間 約<span className="font-bold">3</span>分
              </p>
            </div>
            <p className="text-xl text-gray-700 font-bold">財務情報の入力</p>
            <p className="pt-1.5 text-sm text-gray-500">
              貸借対照表、損益計算書、販売費及び一般管理費内訳及び製造原価報告書の財務数値を入力をします。
            </p>
            <p className="text-sm text-gray-500">
              ※OCR解析で入力サポートすることもできます。
            </p>

            <div className="grid grid-cols-10 pt-2">
              <div className="col-span-3 ml-3 flex gap-8">
                <div className="text-green-600 pt-7">推奨</div>
                {isSubjectUploaded || isOcrUploaded ? (
                  <div
                    onClick={() =>
                      getSubjectsInputForm(
                        checkSubjectsInputFormType(
                          isOcrUploaded,
                          // @ts-ignore
                          parseResult(companyMedicalReport.ocr_upload_pages)[
                            'file_id'
                          ],
                          1,
                        ),
                      )
                    }
                    className="mt-6 cursor-pointer outline-2 inline-flex items-center px-12 py-2.5 border text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 hover:text-white"
                  >
                    決算書情報を編集する
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        getSubjectsInputForm(
                          `/company_medical_reports/${companyMedicalReport.id}/subjects/new?phase=1`,
                        )
                      }
                      className="mt-6 cursor-pointer inline-flex items-center px-12 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      決算書情報を入力する
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <div className="flex gap-4 pt-6 ml-3 flex">
              <div
                className={`${
                  hasUploadedPlan ? 'text-blue-500' : 'text-gray-500'
                } pt-1`}
              >
                任意
              </div>
              <label
                className={`${
                  hasUploadedSubjects
                    ? 'text-white bg-blue-500'
                    : 'text-white bg-gray-400'
                } ml-4 cursor-pointer inline-flex items-center px-9 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => handleFileUpload(e, 'subjects')}
                  style={{ display: 'none' }}
                />
                決算書をアップロードする
                <div className="flex items-center justify-center gap-4 cursor-pointer"></div>
              </label>
              <div
                onClick={() => downloadFile('subjects')}
                className="cursor-pointer text-blue-500 pt-1"
              >
                決算書テンプレートはこちら
              </div>
            </div> */}

            <div className="flex gap-4 pt-6 ml-3 flex">
              <div
                className={`${
                  hasUploadedPlan ? 'text-blue-500' : 'text-gray-500'
                } pt-1`}
              >
                任意
              </div>
              <label
                className={`${
                  hasUploadedPlan
                    ? 'text-white bg-blue-500'
                    : 'text-white bg-gray-400'
                } ml-4 cursor-pointer inline-flex items-center px-7 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => handleFileUpload(e, 'valuation_plan')}
                  style={{ display: 'none' }}
                />
                事業計画をアップロードする
                <div className="flex items-center justify-center gap-4 cursor-pointer"></div>
              </label>
              <div
                onClick={() => downloadFile('valuation_plan')}
                className="cursor-pointer text-blue-500 pt-1"
              >
                事業計画テンプレートはこちら
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
