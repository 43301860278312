import React, { useCallback, useEffect, useState } from 'react';
import { ConditionalInputProps } from './types';
const DEFAULT_MAX_LENGTH = 30;

export const ConditionalInput: React.FC<ConditionalInputProps> = (props) => {
  const { defaultOptions, placeholder, modelAttribute } = props;
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [textInput, setTextInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleOptionsChange = useCallback((event) => {
    setSelectedOption(event.target.value);
  }, []);
  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);
  const showSelectOptions = useCallback(() => {
    setTextInput(!selectedOption);
    setSelectedOption(defaultOptions[0]);
  }, [defaultOptions, selectedOption]);

  useEffect(() => {
    setTextInput(selectedOption === '表紙名を入力する');
  }, [selectedOption]);

  const renderInput = () => (
    <div className="flex">
      <input
        className="max-w-sm block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-sm"
        name={modelAttribute}
        placeholder={inputValue ? inputValue : placeholder}
        value={inputValue}
        onChange={handleInputChange}
        type="text"
        required
        maxLength={DEFAULT_MAX_LENGTH}
        size={DEFAULT_MAX_LENGTH}
      />
      <p
        className="text-sm pt-2 text-blue-500 px-4 cursor-pointer hover:text-blue-600"
        onClick={showSelectOptions}
      >
        ※オプションから選択する
      </p>
    </div>
  );

  const renderSelect = () => (
    <select
      className="text-base sm:text-sm rounded-sm border-gray-300 h-3/12 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      name={modelAttribute}
      onChange={handleOptionsChange}
      value={selectedOption}
    >
      {defaultOptions.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );

  return textInput ? renderInput() : renderSelect();
};
