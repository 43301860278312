import React, { useState } from 'react';
import { SubjectRow } from '../../../SubjectRow/SubjectRow';
import { SubjectProps } from '../../types';

export const CurrentAssets: React.FC<SubjectProps> = (props) => {
  const { getValues, setValue, control } = props;
  const [debtLikeItems, setDebtLikeItems] = useState<boolean>(false);
  return (
    <>
      <div className="grid grid-cols-12 divide-y text-sm">
        <div className="flex col-span-12 grid grid-cols-12 bg-gray-200 py-2.5">
          <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
            科目
          </div>
          <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
            金額
          </div>
        </div>
        <SubjectRow
          label="現金及び預金"
          value={'subject.cash_and_deposits'}
          subject={'subject.cash_and_deposits'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「現金及び預金」、「現金」、「預金」、「普通預金」、「定期預金」等、現金又は預金と名の付く全ての科目を集計して入力する',
            placement: 'bottom-6',
          }}
        />
        <div className="flex col-span-12 grid grid-cols-12 py-2.5">
          <div className="col-span-6 text-left pl-2">売上債権</div>
          <div className="col-span-6"></div>
        </div>
        <SubjectRow
          label="売掛金"
          value={'subject.account_receivable'}
          subject={'subject.account_receivable'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「売掛金」、「関係会社売掛金」等、売掛金と名の付く全ての科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="受取手形"
          value={'subject.notes_receivable'}
          subject={'subject.notes_receivable'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「受取手形」と名の付く科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="その他の売上債権"
          value={'subject.other_receivable'}
          subject={'subject.other_receivable'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「電子記録債権」、「完成工事未収入金」等、売上債権の性質を有する科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <div className="flex col-span-12 grid grid-cols-12 py-2.5">
          <div className="col-span-6 text-left pl-2 pt-1">棚卸資産</div>
          <div className="col-span-6 pr-3"></div>
        </div>
        <SubjectRow
          label="商品"
          value={'subject.merchandise'}
          subject={'subject.merchandise'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「商品」と名の付く科目を集計して入力する。※「商品・製品」の場合は、こちらに入力し、製品は未入力とする。',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="製品"
          value={'subject.finished_goods'}
          subject={'subject.finished_goods'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「製品」と名の付く科目を集計して入力する。',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="仕掛品"
          value={'subject.work_in_process'}
          subject={'subject.work_in_process'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「仕掛品」、「半製品」等、仕掛品の性質を有する科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="その他の棚卸資産"
          value={'subject.other_inventory'}
          subject={'subject.other_inventory'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「原材料」、「貯蔵品」、「未成工事支出金」等、棚卸資産の性質を有する科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <div className="flex col-span-12 grid grid-cols-12 py-2">
          <div className="col-span-6 text-left pl-2 pt-1">固定資産</div>
          <div className="col-span-6 pr-3"></div>
        </div>
        <SubjectRow
          label="土地"
          value={'subject.land'}
          subject={'subject.land'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「土地」科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <div className="flex col-span-12 grid grid-cols-12 py-2.5">
          <div className="col-span-6 text-left pl-2 pt-1">非事業用資産</div>
          <div className="col-span-6 pr-3"></div>
        </div>
        <SubjectRow
          label="上場有価証券"
          value={'subject.listed_investment_securities'}
          subject={'subject.listed_investment_securities'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「有価証券」、「投資有価証券」等、有価証券と名の付く全ての科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="投資有価証券"
          value={'subject.investment_securities'}
          subject={'subject.investment_securities'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「有価証券」、「投資有価証券」等、有価証券と名の付く全ての科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="保険積立金"
          value={'subject.insurance_funds'}
          subject={'subject.insurance_funds'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「保険積立金」と名の付く科目を集計して入力する。',
            placement: 'bottom-6',
          }}
          indent
        />
        <SubjectRow
          label="その他の非事業用資産"
          value={'subject.other_non_business_assets_exept'}
          subject={'subject.other_non_business_assets_exept'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「貸付金」、「出資金」等、換金性が高く現金同等物の性質を有する科目を集計して入力する',
            placement: 'bottom-6',
          }}
          indent
        />
        <div className="flex col-span-12 grid grid-cols-12 py-2.5">
          <div
            onClick={() => setDebtLikeItems(!debtLikeItems)}
            className="cursor-pointer col-span-7 text-left pl-2 pt-1 text-blue-600"
          >
            デットライクアイテム▼
          </div>
          <div className="col-span-5 pr-3"></div>
        </div>
        {debtLikeItems && (
          <>
            <SubjectRow
              label="未払配当金"
              value={'subject.dividends_payable'}
              subject={'subject.dividends_payable'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              toolTipProps={{
                text: '「未払配当金」科目を集計して入力する',
                placement: 'bottom-6',
              }}
              indent
            />
            <SubjectRow
              label="役員退職慰労引当金"
              value={'subject.provision_for_directors_retirement_benefits'}
              subject={'subject.provision_for_directors_retirement_benefits'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              toolTipProps={{
                text: '「役員退職慰労引当金」科目を集計して入力する',
                placement: 'bottom-6',
              }}
              indent
            />
            <SubjectRow
              label="退職給付引当金"
              value={'subject.employees_pension_and_retirement_benefits'}
              subject={'subject.employees_pension_and_retirement_benefits'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              toolTipProps={{
                text: '「退職給付引当金」科目を集計して入力する',
                placement: 'bottom-6',
              }}
              indent
            />
            <SubjectRow
              label="資産除去債務"
              value={'subject.asset_retirement_obligations'}
              subject={'subject.asset_retirement_obligations'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              toolTipProps={{
                text: '「資産除去債務」科目を集計して入力する',
                placement: 'bottom-6',
              }}
              indent
            />
            <SubjectRow
              label="その他のデッドライクアイテム"
              value={'subject.debt_like_exept'}
              subject={'subject.debt_like_exept'}
              control={control}
              getValues={getValues}
              setValue={setValue}
              toolTipProps={{
                text: '「その他のデッドライクアイテム」科目を集計して入力する',
                placement: 'bottom-6',
              }}
              indent
            />
          </>
        )}
        <SubjectRow
          label="総資産"
          value={'subject.assets'}
          subject={'subject.assets'}
          control={control}
          getValues={getValues}
          setValue={setValue}
          toolTipProps={{
            text: '「総資産」科目を集計して入力する',
            placement: 'bottom-6',
          }}
        />
      </div>
    </>
  );
};
