import { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { postBatchValuationRequest, putProfileRequest } from './api';
import {
  CompanyMedicalReportProfileFormProps,
  CompanyMedicalReportProps,
  CompanyMedicalReportSelectOptions,
  CompanyMedicalReportvaluationProps,
  ProfileFormStateProps,
  SectionsProps,
} from './types';

export const notify = (status: string, message: string) => {
  if (status === 'success') {
    toast(message, {
      style: {
        backgroundColor: '#ffffff',
        padding: '12px',
        borderRadius: '5px',
      },
      icon: '✅',
    });
  } else {
    toast(message, {
      style: {
        backgroundColor: '#ffffff',
        padding: '12px',
        borderRadius: '10px',
      },
      icon: '❌',
    });
  }
};

export const useCompanyMedicalReports = () => {
  const [deleteReportId, setDeleteReportId] = useState<number>(0);
  const [modalCheck, setModalCheck] = useState<boolean>(false);

  /**
   * 削除確認モーダルを設定
   * @params report_id {number}
   * @return void
   **/
  const showDeleteModal = useCallback(
    (report_id: number): void => {
      setDeleteReportId(report_id);
      setModalCheck(!modalCheck);
    },
    [modalCheck, setDeleteReportId],
  );

  /**
   * レポート更新日を設定
   * @params update_time {string}
   * @return レポート更新日 {string}
   **/
  const showUpdatedTime = useCallback((update_time: string): string => {
    const reportUpdatedDate: Date = new Date(update_time);
    const reportUpdatedYear: number = reportUpdatedDate.getFullYear();
    const reportUpdatedMonth: number = reportUpdatedDate.getMonth() + 1;
    const reportUpdatedDay: number = reportUpdatedDate.getDate();
    return `${reportUpdatedYear}年${reportUpdatedMonth}月${reportUpdatedDay}日`;
  }, []);

  /**
   * レポート作成日を設定
   * @params update_time {string}
   * @return レポート作成日 {string}
   **/
  const showFinancialDate = useCallback((update_time: string): string => {
    const reportUpdatedDate: Date = new Date(update_time);
    const reportUpdatedYear: number = reportUpdatedDate.getFullYear();
    const reportUpdatedMonth: number = reportUpdatedDate.getMonth() + 1;
    return `${reportUpdatedYear}年${reportUpdatedMonth}月期`;
  }, []);

  /**
   * resultがnullでない数をカウント
   * @params company_medical_report_valuations {CompanyMedicalReportvaluationProps[]}
   * @return resultがnullでない数 {number}
   **/
  const countValidResults = useCallback(
    (
      company_medical_report_valuations: CompanyMedicalReportvaluationProps[],
    ): number => {
      return company_medical_report_valuations.reduce((count, valuation) => {
        if (valuation.result !== null) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    [],
  );

  return {
    deleteReportId,
    modalCheck,
    setDeleteReportId,
    setModalCheck,
    showDeleteModal,
    showUpdatedTime,
    showFinancialDate,
    countValidResults,
  };
};

export const useCompanyMedicalReportDetails = () => {
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  return {
    modalMessage,
    modalCheck,
    setModalMessage,
    setModalCheck,
  };
};

export const useCompanyMedicalReportSubjectsStep = (
  // @ts-ignore
  report: valuationReportProps,
) => {
  const parseResult = useCallback((pages) => {
    return pages ? JSON.parse(pages) : '';
  }, []);
  const checkSubjectsInputFormType = useCallback(
    (is_ocr: boolean, file_id: number, phase: number) => {
      return is_ocr
        ? `/company_medical_reports/${report.id}/ocr/file_results/${file_id}/edit?phase=${phase}`
        : `/company_medical_reports/${report.id}/subjects/edit?phase=${phase}`;
    },
    [report.id],
  );
  const getSubjectsInputForm = useCallback((subject_input_url) => {
    location.href = subject_input_url;
  }, []);
  const downloadFile = useCallback(
    async (file_name: 'valuation_plan' | 'subjects') => {
      const response = await axios.get(
        `/files/download?filename=${file_name}.xlsx&filetype=application/vnd.ms-excel`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${file_name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
    [],
  );
  const uploadBusinessPlan = useCallback(
    async (file, company_medical_report_id) => {
      const csrfToken = document?.querySelector?.(
        'meta[name="csrf-token"]',
        // @ts-ignore
      )?.content;
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post(
          `/business_plans?company_medical_report_id=${company_medical_report_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-CSRF-Token': csrfToken,
            },
          },
        )
        .then((response) => {
          console.debug(response);
          notify(response.data.status, response.data.message);
        })
        .catch((error) => {
          console.error(error);
          notify(error.data.status, error.data.message);
        });
    },
    [],
  );
  const uploadSubjects = useCallback(
    async (file, company_medical_report_id) => {
      const csrfToken = document?.querySelector?.(
        'meta[name="csrf-token"]',
        // @ts-ignore
      )?.content;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_type', 'template');
      axios
        .post(
          `/company_medical_reports/${company_medical_report_id}/subjects/upload_subjects`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-CSRF-Token': csrfToken,
            },
          },
        )
        .then((response) => {
          console.debug(response);
          notify(response.data.status, response.data.message);
        })
        .catch((error) => {
          console.error(error);
          notify(error.data.status, error.data.message);
        });
    },
    [],
  );
  return {
    parseResult,
    checkSubjectsInputFormType,
    getSubjectsInputForm,
    downloadFile,
    uploadBusinessPlan,
    uploadSubjects,
  };
};

export const useCompanyMedicalReportIndustriesStep = (
  // @ts-ignore
  report: valuationReportProps,
) => {
  const [reportSimilaritiesStepStyle, setReportSimilaritiesStepStyle] =
    useState({
      flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200',
      checkedButton:
        'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center',
      actionButton:
        'inline-flex items-center px-14 py-2 text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 cursor-pointer',
    });
  const getSimilaritiesForm = useCallback(() => {
    location.href = `/company_medical_reports/${report.id}/industries`;
  }, [report.id]);
  return {
    reportSimilaritiesStepStyle,
    setReportSimilaritiesStepStyle,
    getSimilaritiesForm,
  };
};

export const useCompanyMedicalReportValuationStep = () => {
  const [reportGenPptxStepStyle, setReportGenPptxStep] = useState({
    checkedButton:
      'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white',
    flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200',
  });
  const postBatchValuation = useCallback((token, reportId) => {
    postBatchValuationRequest(token, reportId);
  }, []);
  return {
    reportGenPptxStepStyle,
    setReportGenPptxStep,
    postBatchValuation,
  };
};

export const useCompanyMedicalReportPptxStep = (
  report: CompanyMedicalReportProps,
) => {
  const [reportGenPptxStepStyle, setReportGenPptxStep] = useState({
    checkButton:
      'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white',
    step: 'text-lg text-gray-400 font-semibold',
    name: 'pt-1.5 text-xl text-gray-400 font-bold',
    reportButton:
      'pointer-events-none inline-flex items-center px-14 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  });
  const getReportPptxForm = useCallback(() => {
    location.href = `/company_medical_reports/${report.id}/profile/edit`;
  }, [report.id]);
  return {
    reportGenPptxStepStyle,
    setReportGenPptxStep,
    getReportPptxForm,
  };
};

export const useCompanyMedicalReportDownloadButton = () => {
  const [downloadPptxApi, setDownloadPptxApi] = useState<string>('');
  const [pptxResults, setPptxResults] = useState<string>('');
  const [getReportApi, setGetReportApi] = useState<string>('');
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [pptxMessage, setPptxMessage] =
    useState<string>('決算書チェッカーのダウンロード開始');
  const postResultRef = useRef('');
  postResultRef.current = pptxResults;

  const showProgressBar = useCallback((): void => {
    let progress = 0;
    const invervalSpeed = 10;
    const incrementSpeed = 0.3;
    const bar = document.getElementById('bar');
    const progressInterval = setInterval((): void => {
      progress += incrementSpeed;
      // @ts-ignore
      bar.style.width = progress + '%';
      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, invervalSpeed);
  }, []);

  const donwloadReport = useCallback((reportId: number, token: string) => {
    setIsOpen(true);
    axios
      .post(`/company_medical_reports/${reportId}/pptx`, '', {
        headers: {
          'X-CSRF-Token': token,
        },
      })
      .then((res) => {
        if (res.data.status === 'success') {
          showProgressBar();
          setPptxMessage('決算書チェッカーダウンロード中');
          setPptxResults(res.data);
          setDownloadPptxApi(res.data.download_path);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      }),
      [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addReportDownloadListener = () => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data.name === 'submitSource') {
          // @ts-ignore
          event.source.postMessage(
            { name: 'download_pptx', value: postResultRef.current },
            // @ts-ignore
            '*',
          );
        } else if (event.data.name === 'done') {
          setIsOpen(false);
        } else if (event.data.name === 'failed') {
          setIsOpen(true);
          setPptxMessage(
            '決算書チェッカーのダウンロード中にエラーが発生しました。',
          );
        }
      },
      false,
    );
  };

  return {
    downloadPptxApi,
    setDownloadPptxApi,
    getReportApi,
    modalIsOpen,
    pptxMessage,
    setIsOpen,
    setPptxMessage,
    setGetReportApi,
    showProgressBar,
    donwloadReport,
    addReportDownloadListener,
    pptxResults,
    setPptxResults,
  };
};

export const CompanyMedicalReportProfileHooks = (
  company_medical_report_profile_form: CompanyMedicalReportProfileFormProps,
  token: string,
) => {
  const [formState, setFormState] = useState<ProfileFormStateProps>({
    evaluationCompanyName:
      company_medical_report_profile_form.evaluation_company_name,
    evaluationCompanyLocation:
      company_medical_report_profile_form.evaluation_company_location,
    evaluationCompanyEmployees:
      company_medical_report_profile_form.evaluation_company_employees,
    evaluationCompanyCreatedDate:
      company_medical_report_profile_form.evaluation_company_created_date,
    evaluationCompanyPresident:
      company_medical_report_profile_form.evaluation_company_president,
    evaluationCompanySharesCount:
      company_medical_report_profile_form.evaluation_company_shares_count,
    evaluationCompanyBusiness:
      company_medical_report_profile_form.evaluation_company_business,
    makerCompanyName: company_medical_report_profile_form.maker_company_name,
    makerCompanyAuthor:
      company_medical_report_profile_form.maker_company_author,
    makerCompanyEmail: company_medical_report_profile_form.maker_company_email,
    makerCompanyLocation:
      company_medical_report_profile_form.maker_company_location,
    makerCompanyPhoneNumber:
      company_medical_report_profile_form.maker_company_phone_number,
    reportCreationDate:
      company_medical_report_profile_form.report_creation_date,
    reportSubmissionDate:
      company_medical_report_profile_form.report_submission_date,
  });

  const [selectedValues, setSelectedValues] = useState<
    CompanyMedicalReportSelectOptions[]
  >(company_medical_report_profile_form.selected_reports);

  const [sections, setSections] = useState<SectionsProps[]>([
    {
      category: '',
      section_name: '',
      message: '',
      content: '',
      chat_history: [],
      section_index: 0,
      trials: 0,
      loading: false,
      format: 'bullet',
    },
    {
      category: '',
      section_name: '',
      message: '',
      content: '',
      chat_history: [],
      section_index: 1,
      trials: 0,
      loading: false,
      format: 'bullet',
    },
    {
      category: '',
      section_name: '',
      message: '',
      content: '',
      chat_history: [],
      section_index: 2,
      trials: 0,
      loading: false,
      format: 'bullet',
    },
  ]);

  const handleSelectValuesChange = useCallback(
    (index: number, value: CompanyMedicalReportSelectOptions[]) => {
      const newValues = [...selectedValues];
      // @ts-ignore
      newValues[index] = value;
      setSelectedValues(newValues);
    },
    [selectedValues],
  );

  const handleAddNewSelector = useCallback(() => {
    // @ts-ignore
    setSelectedValues((prevValues) => [...prevValues, []]);
  }, []);

  const updateFormState = useCallback((key: string, value: string | number) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const updateProfile = useCallback(async () => {
    try {
      const response = await putProfileRequest(
        token,
        // @ts-ignore
        company_medical_report_profile_form.company_medical_report.id,
        formState,
        sections,
        selectedValues,
      );

      if (response === 'success') {
        notify('success', '保存に成功しました。');
      } else {
        notify('failed', '保存に失敗しました。');
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    company_medical_report_profile_form.company_medical_report.id,
    formState,
    sections,
    selectedValues,
    token,
  ]);

  return {
    sections,
    formState,
    selectedValues,
    handleSelectValuesChange,
    setSelectedValues,
    handleAddNewSelector,
    updateFormState,
    updateProfile,
    setFormState,
    setSections,
  };
};
