import React from 'react';
import { CompanyMedicalReportTableRow } from './CompanyMedicalReportTableRow';
import { ModalProvider } from 'react-modal-hook';
import { CompanyMedicalReportProps } from './types';

export const CompanyMedicalReports: React.FC<{
  company_medical_reports: CompanyMedicalReportProps[];
}> = (props) => {
  const { company_medical_reports } = props;

  return (
    <ModalProvider>
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="w-5/12 pl-3 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              対象会社名
            </th>
            <th
              scope="col"
              className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              進捗ステータス
            </th>
            <th
              scope="col"
              className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            >
              最終更新日
            </th>
            <th
              scope="col"
              className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              scope="col"
              className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
            ></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {company_medical_reports?.map(
            (company_medical_report: CompanyMedicalReportProps) => (
              <CompanyMedicalReportTableRow
                key={company_medical_report.id}
                company_medical_report={company_medical_report}
              />
            ),
          )}
        </tbody>
      </table>
    </ModalProvider>
  );
};
