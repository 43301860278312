import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import { FileUploaderProps } from './types';
const CHUNK_SIZE = 1000000;
const MAX_FILE_SIZE = '60MB';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const { phase, ocr_files_path } = props;
  const [files, setFiles] = useState([]);
  const [uploadUuid, setUploadUuid] = useState<string>('');
  const [alert, setAlert] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const csrfToken = document?.querySelector?.(
    'meta[name="csrf-token"]',
    // @ts-ignore
  )?.content;
  const uploadOcrFile = useCallback(() => {
    setAlert(false);
    setLoading(true);
    axios
      .post(`${ocr_files_path}/upload/?upload_uuid=${uploadUuid}`, null, {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      })
      .then((response) => {
        setLoading(false);
        console.debug(response);
        const fileId = response.data.ocr_file_id;
        window.location.href = `${ocr_files_path}/${fileId}?phase=${phase}`;
      })
      .catch((error) => {
        setLoading(false);
        setAlert(true);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocr_files_path, phase, uploadUuid]);

  return (
    <>
      <div className="container">
        <div
          className="bg-gradient-to-r from-green-400 to-blue-500 absolute left-0 top-0 w-full h-1/3"
          style={{ zIndex: -1000 }}
        ></div>
        <div className="text-center">
          <h1 className="inline-block mb-4 text-white lg:text-2xl md:text-xl text-lg lg:p-6 md:p-2 p-0">
            決算書PDFをOCR解析
          </h1>
        </div>
        <div className="shadow p-6 bg-white text-center pt-8 pb-80">
          <h3 className="inline-block bg-gray-100 font-base rounded-md text-gray-700 p-6 mb-6">
            ※ 決算書のPDF、JPEGなどを下記にアップロードしてください
          </h3>
          <div className="flex justify-center pt-16 cursor-pointer">
            <input type="file" id={uploadUuid} style={{ display: 'none' }} />
            <FilePond
              className="w-96 cursor-pointer"
              name="files"
              labelIdle='<div class="flex items-center justify-center gap-4 cursor-pointer"><span>ファイルをドラッグ＆ドロップしてください</span><span class="filepond--label-action">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="17 8 12 3 7 8"></polyline>
                <line x1="12" y1="3" x2="12" y2="15"></line>
              </svg>
            </span></div>'
              files={files}
              chunkUploads
              chunkForce
              chunkSize={CHUNK_SIZE}
              maxFileSize={MAX_FILE_SIZE}
              acceptedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
              onupdatefiles={(fileItems) => {
                // @ts-ignore
                setFiles(fileItems);
              }}
              onprocessfiles={() => {
                console.debug('chunk upload finished');
                setUploaded(true);
              }}
              server={{
                process: {
                  url: ocr_files_path,
                  method: 'POST',
                  headers: {
                    'X-CSRF-Token': csrfToken,
                  },
                  onload: (response) => {
                    console.debug(response);
                    setUploadUuid(response.responseText);
                    return response.responseText;
                  },
                },
                patch: {
                  url: `${ocr_files_path}/`,
                  method: 'PATCH',
                  headers: {
                    'X-CSRF-Token': csrfToken,
                  },
                  ondata: (formData: string) => {
                    console.debug(formData);
                    return formData;
                  },
                },
                revert: {
                  url: `${ocr_files_path}/${uploadUuid}`,
                  method: 'DELETE',
                  headers: {
                    'X-CSRF-Token': csrfToken,
                  },
                  // @ts-ignore
                  onload: (response) => {
                    console.debug(response);
                    setUploaded(false);
                  },
                },
              }}
            />
          </div>
          {alert ? (
            <>
              <div className="flex justify-center">
                <div className="w-96 bg-red-100 rounded-md py-4">
                  <p className="text-red-500 text-sm">
                    アップロードに失敗しました。再度お試しください。
                  </p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="flex justify-center pt-16">
            {uploaded && 0 < files.length ? (
              <>
                <div
                  className="cursor-pointer inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={uploadOcrFile}
                >
                  アップロードする
                  {loading ? (
                    <>
                      <div
                        className="flex justify-center"
                        aria-label="読み込み中"
                      >
                        <div
                          className="animate-spin h-5 w-5 border-2 border-white rounded-full"
                          style={{ borderTopColor: 'transparent' }}
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <svg
                        className="-mr-0.5 h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
