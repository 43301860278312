import React, { ChangeEventHandler } from 'react';

type SelectFieldProps = {
  name: string;
  label?: string;
  value: string;
  checked?: boolean;
  type?: 'text' | 'date';
  onChange: ChangeEventHandler<HTMLSelectElement>;
  options?: string[];
  required?: boolean;
};

// 選択フィールド用のコンポーネント
export const SelectField: React.FC<SelectFieldProps> = (props) => {
  const { name, value, onChange, options, label } = props;

  return (
    <>
      <label className="block text-md font-medium leading-6 text-gray-900">
        {label}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6"
      >
        <option value="">{label}</option>
        {options?.map((option, _) => (
          <option key={_} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};
