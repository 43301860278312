import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { inputProps } from '../../types/props';

export const SubjectInput: React.FC<inputProps> = React.memo(
  (props: inputProps) => {
    const { subject, setValue, className, defaultValue, _callback } = props;

    const checkDefaultValue = useCallback((v) => {
      return v === 0 ? null : v;
    }, []);

    const setSubjectValue = useCallback(
      (e) => {
        // @ts-ignore
        setValue(subject, Number(e.target.value.replace(/,/g, '')));
        _callback
          ? _callback(subject, Number(e.target.value.replace(/,/g, '')))
          : '';
      },
      [_callback, setValue, subject],
    );

    return (
      // @ts-ignore
      <NumberFormat
        thousandsGroupStyle="thousand"
        decimalSeparator="."
        displayType="input"
        type="text"
        defaultValue={checkDefaultValue(defaultValue)}
        // @ts-ignore
        onChange={(e) => setSubjectValue(e)}
        thousandSeparator={true}
        allowNegative={true}
        decimalScale={2}
        className={className}
      />
    );
  },
);
