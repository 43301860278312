import React from 'react';
import { UsersSlideImageUploaderProps } from './types';

export const UsersSlideImageUploader: React.FC<UsersSlideImageUploaderProps> = (
  props,
) => {
  const {
    slideLabel,
    slideName,
    slideSrc,
    onUpdateSlideImage,
    onDeleteSlideImage,
  } = props;

  return (
    <div className="col-span-full pt-2">
      <p className="block text-md font-medium leading-6 text-gray-900">
        {slideLabel}
      </p>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          <div className="mt-4 flex text-md leading-6 text-gray-600">
            <label className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
              {slideSrc ? (
                <>
                  <img src={slideSrc} alt={slideLabel} />
                  <div onClick={() => onDeleteSlideImage(slideName)}>
                    画像を削除
                  </div>
                </>
              ) : (
                <input
                  id="file-upload"
                  type="file"
                  onChange={(e) => {
                    const file = e?.target?.files?.[0] ?? '';
                    const formData = new FormData();
                    formData.append('slide_src', file);
                    // @ts-ignore
                    onUpdateSlideImage(slideName, formData);
                  }}
                />
              )}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
