import React, { useCallback, useMemo } from 'react';
import { CompanyMedicalReportValuationResult } from './CompanyMedicalReportValuationResult';
import { CompanyMedicalReportValuationButton } from './CompanyMedicalReportValuationButton';
import DcfIcon from '../../../../../assets/images/icons/icon_dcf.png';
import EbitdaIcon from '../../../../../assets/images/icons/icon_ebitda.png';
import PerIcon from '../../../../../assets/images/icons/icon_per.png';
import InheritanceIcon from '../../../../../assets/images/icons/icon_souzoku.png';

import {
  CompanyMedicalReportValuationPartProps,
  CompanyMedicalReportvaluationProps,
  ValuationResult,
} from './types';
import {
  DcfSchema,
  EbitdaSchema,
  InheritanceSchema,
  PerSchema,
} from '../../../utils/schema';

const VALUATIONS = {
  dcf: {
    icon: DcfIcon,
    name: 'DCF法',
    schema: DcfSchema,
  },
  ebitda: {
    icon: EbitdaIcon,
    name: 'EV/EBITDA倍率法',
    schema: EbitdaSchema,
  },
  per: {
    icon: PerIcon,
    name: 'PER倍率法',
    schema: PerSchema,
  },
  inheritance: {
    icon: InheritanceIcon,
    name: '相続税法上の評価',
    schema: InheritanceSchema,
  },
};

export const CompanyMedicalReportValuation: React.FC<
  CompanyMedicalReportValuationPartProps
> = (props) => {
  const { valuation } = props;

  const parseValuationResult = useCallback(
    (valuationValue: CompanyMedicalReportvaluationProps): ValuationResult => {
      if (!valuationValue.result) return null;
      const valuationSchema = VALUATIONS[valuationValue.method_type]['schema'];
      if (!valuationSchema) return null;
      try {
        return valuationSchema.parse(JSON.parse(valuationValue.result));
      } catch (e) {
        console.debug('Schema Error', e);
        return null;
      }
    },
    [],
  );

  const validatedValuationResult = useMemo((): ValuationResult => {
    return parseValuationResult(valuation);
  }, [valuation, parseValuationResult]);

  const valuationConfig = useMemo(
    () => VALUATIONS[valuation.method_type],
    [valuation.method_type],
  );

  return (
    <div className="col-span-1 bg-white rounded border border-gray-300">
      <div className="flex items-center justify-between pl-6 pr-6 pt-2 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <img className="h-5 w-5" src={valuationConfig.icon} />
            <h3 className="text-gray-500 text-lg font-semibold truncate">
              {valuationConfig.name}
            </h3>
          </div>
        </div>
      </div>
      <CompanyMedicalReportValuationResult
        valuationResult={validatedValuationResult}
        valuationName={valuationConfig.name}
      />
      <CompanyMedicalReportValuationButton
        valuationResult={validatedValuationResult}
        {...props}
      />
    </div>
  );
};
