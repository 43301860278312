import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { BsContainer } from './bs/BsContainer';
import { SubjectsInputFormProps } from '../../types/props';

export const SubjectsInputForm: React.FC<SubjectsInputFormProps> = React.memo(
  (props: SubjectsInputFormProps) => {
    const { inheritanceValuationId, phase, subjectValue, method } = props;
    const [subject, setSubject] = useState<unknown[]>();
    const form = document.getElementById('subjects_form');

    const { register, handleSubmit, getValues, setValue, control } =
      // @ts-ignore
      useForm<unknown>({
        defaultValues: {
          subject: '',
        },
      });

    useEffect(() => {
      if (subjectValue) {
        // @ts-ignore
        setValue('subject', subjectValue);
        setSubject([subjectValue]);
      }
    }, [setValue, subjectValue]);

    const postSubjects = useCallback(() => {
      const requestData = {
        // @ts-ignore
        subject: getValues('subject'),
        datatype: 'merged',
        phase: phase,
        type: 'financial_statement',
      };
      const requestConfig = {
        method: method,
        url: `/inheritances/${inheritanceValuationId}/subjects.json`,
        data: requestData,
        headers: {
          // @ts-ignore
          'X-CSRF-Token': form.getElementsByTagName('input')[0].value,
        },
      };
      axios(requestConfig)
        .then((res) => {
          if (res.status === 200) {
            location.href = `/inheritances/${inheritanceValuationId}`;
          }
        })
        .catch((err) => {
          location.href = `/inheritances/${inheritanceValuationId}`;
          console.log(err);
        });
    }, [form, getValues, inheritanceValuationId, method, phase]);

    const onSubmit = () => {
      postSubjects();
    };

    return (
      <>
        <div className="grid grid-cols-12 pt-1">
          <div className="col-span-2"></div>
          <div className="col-span-8">
            {
              // @ts-ignore
              0 < subject?.length ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 text-center pt-2">
                      <button
                        type="submit"
                        className="inline-block m-4 px-12 py-2 text-white bg-green-500 hover:bg-green-600 rounded-sm cursor-pointer"
                      >
                        決算書情報を適用する
                      </button>
                    </div>
                    <div className="col-span-12 text-right pr-3">
                      <div
                        onClick={() => {
                          location.href = `/inheritances/${inheritanceValuationId}/ocr/analysis_fs/new?phase=${phase}`;
                        }}
                        className="inline-block m-4 px-4 py-1 text-white bg-blue-500 hover:bg-blue-600 rounded-sm cursor-pointer"
                      >
                        OCR取り込み（β版）
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <BsContainer
                        // @ts-ignore
                        Controller={Controller}
                        register={register}
                        control={control}
                        getValues={getValues}
                        // @ts-ignore
                        subject={subject}
                        setValue={setValue}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 text-center">
                    <button
                      type="submit"
                      className="inline-block m-4 px-12 py-2 text-white bg-green-500 hover:bg-green-600 rounded-sm cursor-pointer"
                    >
                      決算書情報を適用する
                    </button>
                  </div>
                </form>
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </>
    );
  },
);
