import React from 'react';
import { Controller } from 'react-hook-form';
import { SubjectInput } from '../SubjectInput/SubjectInput';
import { Tooltip } from '../Tooltip/Tooltip';
import { SubjectRowProps } from './types';

export const SubjectRow: React.FC<SubjectRowProps> = (props) => {
  const {
    label,
    value,
    subject,
    control,
    getValues,
    setValue,
    _callback = null,
    indent = false,
    rules = { required: false },
    toolTipProps = { text: '', placement: 'bottom-6 -left-36' },
  } = props;
  return (
    <div className="flex col-span-12 grid grid-cols-12 py-1">
      <div className={`col-span-7 text-left pl-2 pt-1 ${indent ? 'pl-8' : ''}`}>
        {label}
        <span className="mx-2 z-10">
          <Tooltip
            tipPlacement={`${toolTipProps.placement}`}
            baseStyles="rounded-full w-4 h-4"
            tipText={`${toolTipProps.text}`}
          >
            <p className="text-white text-xs">?</p>
          </Tooltip>
        </span>
      </div>
      <div className="col-span-5 pr-3">
        {value && (
          <Controller
            // @ts-ignore
            name={subject}
            control={control}
            rules={rules}
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-shadow
            render={({ value, formState }) => (
              <SubjectInput
                value={value}
                // @ts-ignore
                defaultValue={
                  // @ts-ignore
                  getValues(subject) === 0 ? null : getValues(subject)
                }
                setValue={setValue}
                // @ts-ignore
                subject={subject}
                errors={formState.errors}
                // @ts-ignore
                _callback={_callback ? _callback : null}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
