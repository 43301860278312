import React from 'react';
import { useForm } from 'react-hook-form';
import { useSubjectsInputForm } from './hooks';
import { BsContainer } from '../../components/Subject/bs/BsContainer';
import { PlContainer } from '../../components/Subject/pl/PlContainer';
import { SgaContainer } from '../../components/Subject/sga/SgaContainer';
import { SubjectsInputFormProps } from './types';

export const SubjectsInputForm: React.FC<SubjectsInputFormProps> = (props) => {
  const {
    reportId,
    phase,
    subjectValue,
    method,
    resource = 'reports',
    ocrPath = `/reports/${reportId}/ocr/analysis_fs/new?phase=${phase}`,
  } = props;

  const token = document
    ?.getElementById('subjects_form')
    ?.getElementsByTagName('input')[0].value;

  const { handleSubmit, getValues, setValue, control } = useForm({
    defaultValues: {
      subject: subjectValue,
    },
    criteriaMode: 'all',
    shouldFocusError: true,
  });
  const { subject, onSubmit } = useSubjectsInputForm({
    method,
    phase,
    reportId,
    resource,
    // @ts-ignore
    token,
    getValues,
    subjectValue,
  });

  return (
    <>
      <div className="grid grid-cols-12 pt-1">
        <div className="col-span-2" />
        <div className="col-span-8">
          {0 < subject?.length ? (
            // @ts-ignore
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-12 pb-6">
                <div className="col-span-12 text-center pt-2 pb-2">
                  <button
                    type="submit"
                    className="cursor-pointer rounded bg-green-500 px-12 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    決算書情報を適用する
                  </button>
                </div>
              </div>
              <div className="cursor-pointer col-span-12 py-3 pr-6 align justify-end flex gap-3">
                <div
                  onClick={() => {
                    location.href = ocrPath;
                  }}
                  className="rounded bg-indigo-600 px-12 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  OCR取り込み(β版)
                </div>
                {resource === 'company_medical_reports' ? (
                  <div
                    onClick={() =>
                      (location.href = `/company_medical_reports/${reportId}/subjects/upload`)
                    }
                    className="flex cursor-pointer rounded bg-white px-2 py-2.5 text-sm font-bold text-blue-500 border border-blue-600 hover:bg-blue-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    会計ソフトから決算書ファイルをインポート
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {phase !== '3' ? (
                <div className="pb-10 pt-3">
                  <BsContainer
                    setValue={setValue}
                    getValues={getValues}
                    // @ts-ignore
                    control={control}
                    phase={phase}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="grid grid-cols-12">
                <div className="col-span-6">
                  <PlContainer
                    setValue={setValue}
                    getValues={getValues}
                    // @ts-ignore
                    control={control}
                    phase={phase}
                  />
                </div>
              </div>
              <div className="pt-10 grid grid-cols-12">
                <div className="col-span-6">
                  <SgaContainer
                    setValue={setValue}
                    getValues={getValues}
                    // @ts-ignore
                    control={control}
                    reportType={resource}
                  />
                </div>
              </div>
              <div className="col-span-12 text-center pt-12">
                <button
                  type="submit"
                  className="rounded bg-green-500 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  決算書情報を適用する
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
