import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

import {
  InheritanceValuationResultsProps,
  ValuationCalculation,
} from '../types/props';

export const InheritanceValuationResults: React.FC<
  InheritanceValuationResultsProps
> = (props: InheritanceValuationResultsProps) => {
  const { calculations, inheritanceValuationId } = props;

  const [downloadPdfApi, setDownloadPdfApi] = useState<string>('');
  const [downloadPptxApi, setDownloadPptxApi] = useState<string>('');
  const [pptxResults, setPptxResults] = useState<string>('');
  const postResultRef = useRef('');
  postResultRef.current = pptxResults;

  // @ts-ignore
  const X_CSRF_TOKEN: string = document
    .getElementById('download_calculation_statement')
    .parentElement.getElementsByTagName('input')[0].value;

  const downloadCalculationStatementPdf: (
    calculation: ValuationCalculation,
  ) => void = useCallback(
    (calculation: ValuationCalculation) => {
      // iframeReloadのため、query=${Math.random()適用
      setDownloadPdfApi(
        `/inheritances/${inheritanceValuationId}/download?calculation_id=${
          calculation.id
        }&random=${Math.random()}`,
      );
    },
    [inheritanceValuationId],
  );

  const downloadCalculationStatementPptx = useCallback(
    (calculation: ValuationCalculation) => {
      // @ts-ignore
      setPptxResults(calculation.results);

      axios
        .post(`/inheritances/${inheritanceValuationId}/download_iframe`, '', {
          headers: {
            'X-CSRF-Token': X_CSRF_TOKEN,
          },
        })
        .then((res) => {
          if (res.data.status === 'success') {
            setDownloadPptxApi(res.data.download_url);
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [X_CSRF_TOKEN, inheritanceValuationId],
  );

  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitSource') {
          // @ts-ignore
          event.source.postMessage(
            { name: 'download_pptx', value: postResultRef.current },
            // @ts-ignore
            '*',
          );
        }
      },
      true,
    );
  }, []);

  return (
    <>
      <div className="flex flex-col pl-36 pt-7">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block w-10/12 sm:px-6 lg:px-8">
            <div className="border border-gray-200 sm:rounded-sm">
              <table className="table-auto min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="pl-3 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      計算明細書
                    </th>
                    <th
                      scope="col"
                      className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col-2"
                      className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    >
                      コメント
                    </th>
                    <th
                      scope="col"
                      className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="relative py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {calculations?.map((calculation, index) => (
                    <tr key={calculation.id}>
                      <td className="pl-3 py-2 whitespace-nowrap text-sm font-medium text-black">
                        計算明細書 {`${index}`}
                      </td>
                      <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500"></td>
                      <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500"></td>
                      <td className="text-right cursor-pointer hover:text-blue-600 py-2 whitespace-nowrap text-left text-sm font-medium text-blue-500 flex flex-row-reverse gap-3">
                        <button
                          type="button"
                          onClick={() =>
                            downloadCalculationStatementPptx(calculation)
                          }
                          className="inline-flex justify-center py-2 px-6 text-sm font-medium rounded-sm text-white bg-green-500 hover:bg-green-600"
                        >
                          <span className="mr-2">
                            <svg
                              width="24px"
                              height="22px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.0856 12.5315C14.8269 12.2081 14.3549 12.1557 14.0315 12.4144L12.75 13.4396V10.0001C12.75 9.58585 12.4142 9.25006 12 9.25006C11.5858 9.25006 11.25 9.58585 11.25 10.0001V13.4396L9.96849 12.4144C9.64505 12.1557 9.17308 12.2081 8.91432 12.5315C8.65556 12.855 8.708 13.327 9.03145 13.5857L11.5287 15.5835C11.6575 15.6877 11.8215 15.7501 12 15.7501C12.1801 15.7501 12.3453 15.6866 12.4746 15.5809L14.9685 13.5857C15.2919 13.327 15.3444 12.855 15.0856 12.5315Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.46038 7.28393C9.40301 5.8274 11.0427 4.86182 12.9091 4.86182C15.7228 4.86182 18.024 7.05632 18.1944 9.82714C18.2506 9.825 18.307 9.82392 18.3636 9.82392C20.7862 9.82392 22.75 11.7878 22.75 14.2103C22.75 16.6328 20.7862 18.5966 18.3636 18.5966L7 18.5966C3.82436 18.5966 1.25 16.0223 1.25 12.8466C1.25 9.67101 3.82436 7.09665 7 7.09665C7.50391 7.09665 7.99348 7.16164 8.46038 7.28393ZM12.9091 6.36182C11.404 6.36182 10.1021 7.23779 9.48806 8.51108C9.31801 8.86369 8.90536 9.0262 8.54054 8.88424C8.0639 8.69877 7.54477 8.59665 7 8.59665C4.65279 8.59665 2.75 10.4994 2.75 12.8466C2.75 15.1939 4.65279 17.0966 7 17.0966L18.3627 17.0966C19.9568 17.0966 21.25 15.8044 21.25 14.2103C21.25 12.6162 19.9577 11.3239 18.3636 11.3239C18.1042 11.3239 17.8539 11.358 17.6164 11.4214C17.3762 11.4855 17.1198 11.4265 16.9319 11.2637C16.7439 11.1009 16.6489 10.8556 16.6781 10.6087C16.6955 10.461 16.7045 10.3103 16.7045 10.1573C16.7045 8.0611 15.0053 6.36182 12.9091 6.36182Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                          ダウンロードする(.pptx)
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            downloadCalculationStatementPdf(calculation)
                          }
                          className="inline-flex justify-center py-2 px-6 text-sm font-medium rounded-sm text-white bg-yellow-400 hover:bg-yellow-500"
                        >
                          <span className="mr-2">
                            <svg
                              width="24px"
                              height="22px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.0856 12.5315C14.8269 12.2081 14.3549 12.1557 14.0315 12.4144L12.75 13.4396V10.0001C12.75 9.58585 12.4142 9.25006 12 9.25006C11.5858 9.25006 11.25 9.58585 11.25 10.0001V13.4396L9.96849 12.4144C9.64505 12.1557 9.17308 12.2081 8.91432 12.5315C8.65556 12.855 8.708 13.327 9.03145 13.5857L11.5287 15.5835C11.6575 15.6877 11.8215 15.7501 12 15.7501C12.1801 15.7501 12.3453 15.6866 12.4746 15.5809L14.9685 13.5857C15.2919 13.327 15.3444 12.855 15.0856 12.5315Z"
                                fill="white"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.46038 7.28393C9.40301 5.8274 11.0427 4.86182 12.9091 4.86182C15.7228 4.86182 18.024 7.05632 18.1944 9.82714C18.2506 9.825 18.307 9.82392 18.3636 9.82392C20.7862 9.82392 22.75 11.7878 22.75 14.2103C22.75 16.6328 20.7862 18.5966 18.3636 18.5966L7 18.5966C3.82436 18.5966 1.25 16.0223 1.25 12.8466C1.25 9.67101 3.82436 7.09665 7 7.09665C7.50391 7.09665 7.99348 7.16164 8.46038 7.28393ZM12.9091 6.36182C11.404 6.36182 10.1021 7.23779 9.48806 8.51108C9.31801 8.86369 8.90536 9.0262 8.54054 8.88424C8.0639 8.69877 7.54477 8.59665 7 8.59665C4.65279 8.59665 2.75 10.4994 2.75 12.8466C2.75 15.1939 4.65279 17.0966 7 17.0966L18.3627 17.0966C19.9568 17.0966 21.25 15.8044 21.25 14.2103C21.25 12.6162 19.9577 11.3239 18.3636 11.3239C18.1042 11.3239 17.8539 11.358 17.6164 11.4214C17.3762 11.4855 17.1198 11.4265 16.9319 11.2637C16.7439 11.1009 16.6489 10.8556 16.6781 10.6087C16.6955 10.461 16.7045 10.3103 16.7045 10.1573C16.7045 8.0611 15.0053 6.36182 12.9091 6.36182Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                          ダウンロードする(.pdf)
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <iframe
        id="calculation_statement_pdf"
        height="0px"
        width="0px"
        src={downloadPdfApi}
      ></iframe>
      <iframe
        id="calculation_statement_pptx"
        height="0px"
        width="0px"
        src={downloadPptxApi}
      ></iframe>
    </>
  );
};
