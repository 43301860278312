import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';

interface ResultRowProp {
  pngSrcs: string;
}

const ResultRow: React.FC<ResultRowProp> = (props: ResultRowProp) => {
  const [pdfImage, setPdfImage] = useState('');
  const [pngImage, setPngImage] = useState('');
  const [progress, setProgress] = useState('ただいま補正中...');
  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);

  useEffect(() => {
    refTimerId.current = timerId;
  }, [timerId]);

  // 補正前のPDF表示
  // TODO: ソースコードが重複しているのでまとめてください
  const setPdf = useCallback(() => {
    axios
      // @ts-ignore
      .get(`/debug/color_tone/${props.pngSrcs['page_id']}/page_pdf`)
      .then((res) => {
        setPdfImage(`data:application/pdf;base64,${res.data}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.pngSrcs]);

  // 補正後のPNG表示
  const setPng = useCallback(() => {
    // @ts-ignore
    if (!props.pngSrcs['png_src']) {
      setProgress('補正が出来ませんでした。');
    }
    // @ts-ignore
    setPngImage(`data:application/png;base64,${props.pngSrcs['png_src']}`);
    setProgress('補正が完了しました。');
  }, [props.pngSrcs]);

  useEffect(() => {
    setPdf();
    setTimerId(setInterval(setPng, 3000));
    setPng();
  }, [setPdf, setPng]);

  return (
    <div className="page_result flex flex-wrap lg:mx-36 md:mx-8 sm:mx-2 mb-10">
      <div className="md:w-2/4 w-full pr-4">
        <h4 className="bg-blue-400 w-full p-2 text-white text-left">
          補正結果
        </h4>
        {progress === '補正が完了しました。' ? (
          <img src={pngImage} width="100%" height="100%" />
        ) : (
          <div className="text-center my-4 analyze_progress">{progress}</div>
        )}
      </div>

      <div className="md:w-2/4 w-full">
        <embed
          className="pdf"
          type="application/pdf"
          src={pdfImage}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default ResultRow;
