import React from 'react';
import { CompanyMedicalReportPreviewProps } from '../types';

export const ResultPagePreview: React.FC<CompanyMedicalReportPreviewProps> = (
  props,
) => {
  const {
    tableHeaderColor,
    tableHeaderTextColor,
    tableCellColor,
    tableCellTextColor,
    chartSampleImg,
  } = props;

  const valuationHeaders = ['アプローチ', '株式価値'];
  const valuationRows = [
    ['PER倍率法\n純利益に基づく評価', '100万〜200万'],
    ['EV/EBITDA倍率法\n営業利益に基づく評価', '100万〜200万'],
    ['DCF法\n従来の収益に基づく評価', '100万〜200万'],
    [
      '簿価純資産法\n純資産に基づく評価',
      '純資産 10万\n*EBITDA1年 10万\n*EBITDA2年 10万\n*EBITDA3年 10万',
    ],
  ];

  const plHeaders = [
    '損益計算書',
    '今回の決算書',
    '対売上率',
    '業界平均',
    '上場平均',
  ];
  const plRows = [
    ['売上高', '-', '-', '-', '-'],
    ['売上原価', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['売上総利益', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['販売費及び一般管理費', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['営業利益', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['支払利息', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['当期純利益', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['EBITDA(営業利益・減価償却費)', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
  ];

  const bsHeaders = [
    '貸借対照表',
    '今回の決算書',
    '対総資産率',
    '業界平均',
    '上場平均',
  ];
  const bsRows = [
    ['現金及び預金', '-', '-', '-', '-'],
    ['売上債権', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['棚卸資産', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['仕入債務', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['有利子負債', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['純資産', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
    ['純資産(負債及び純資産)', '-', '0.0%', '49.3%~56.9%', '72.3%~76.9%'],
  ];

  return (
    <>
      <div
        style={{ fontSize: '6px' }}
        className="shadow-md mt-4 w-full h-auto border border-gray-200 p-4 "
      >
        <div className="flex justify-center">
          <hr className="w-full bg-blue-500 h-0.5 mt-3"></hr>
        </div>
        <h1 className="flex justify-start pt-1 font-bold ">診断結果</h1>
        <div className="flex justify-center">
          <hr className="w-full bg-blue-500 h-0.5 mt-1"></hr>
        </div>

        <div className="border border-black bg-yellow-200 mt-2 h-24">
          <h1 className="flex justify-start text-black-200 font-semibold p-3">
            Summary
          </h1>
          <ul className="p-2 ">
            <li>
              ・株式価値評価結果は以下になります。EV/EBITDA倍率法、PER倍率法は、株式価値評価がマイナス評価になった為、採用せずとなっております。
            </li>
            <li>
              ・営業利益が＋0円となるとEV/EBITDA倍率法に則った評価がプラス、当期純利益が＋0円となるとPER倍率法に則った評価がプラスとなります。
            </li>
            <li>
              ・業界平均と比較して、営業利益が3.5%（約0円）下回っています。原価または販売費及び一般管理費の見直しをすることをお勧めします。
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-12 pt-3">
          <div className="col-span-6">
            <h1 className="font-semibold ">株式算定</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {valuationHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {valuationRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            cellIndex === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className="text-center border border-gray-300 px-4 py-0.5"
                      >
                        {cellContent.split('\n').map((line, lineIndex) => (
                          <span key={lineIndex}>
                            {line}
                            {lineIndex < cellContent.split('\n').length - 1 && (
                              <br />
                            )}
                          </span>
                        ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-span-6">
            <img className="h-full w-full" src={chartSampleImg} />
          </div>
        </div>
        <div className="grid grid-cols-12 pt-2">
          <div className="col-span-12">
            <h1 className="font-semibold ">損益計算書分析(PL)</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {plHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {plRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            rowIndex % 2 === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`${
                          cellIndex === 0 ? 'text-left' : 'text-right'
                        } border border-gray-300 px-4 py-0.5`}
                      >
                        {cellContent}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid grid-cols-12 pt-2">
          <div className="col-span-12">
            <h1 className="font-semibold ">貸借対照表分析(BS)</h1>
            <table className="border border-gray-300 w-full">
              <thead>
                <tr>
                  {bsHeaders.map((header) => (
                    <th
                      key={header}
                      style={{
                        color: tableHeaderTextColor,
                        backgroundColor: tableHeaderColor,
                      }}
                      className="border border-gray-300 px-4 py-0.5"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {bsRows.map((rowContent, rowIndex) => (
                  <tr key={rowIndex}>
                    {rowContent.map((cellContent, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          color: `${
                            cellIndex === 0 ? tableCellTextColor : '#000000'
                          }`,
                          backgroundColor: `${
                            rowIndex % 2 === 0 ? tableCellColor : ''
                          }`,
                        }}
                        className={`${
                          cellIndex === 0 ? 'text-left' : 'text-right'
                        } border border-gray-300 px-4 py-0.5`}
                      >
                        {cellContent}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <p className="pt-3">
          ※業界平均は e-Stat、上場平均は EDINET の情報を加工・集計しております
        </p>
      </div>
    </>
  );
};
