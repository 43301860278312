export const InheritanceSubject: { code: string; value: null | number }[] = [
  // 所得金額又は欠損金額
  { code: 'OT_CTTI', value: null },
  // 期末現在の発行済株式の総数又は出資の総額
  { code: 'OT_NOIS', value: null },
  // 当期利益又は当期欠損
  { code: 'OT_NOAIA', value: null },
  // 配当
  { code: 'OT_ADA', value: null },
  // 利益積立金額の計算に関する明細書 差引合計額
  { code: 'OT_IAA', value: null },
  // 資本金等の額の計算に関する明細書 差引合計額
  { code: 'NA_SE_CT', value: null },
  // 所得税額
  { code: 'OT_IT', value: null },
  // 当期控除額
  { code: 'OT_DTLCF', value: null },
  //  受取配当等の益金不算入額
  { code: 'OT_RDENI', value: null },
];
