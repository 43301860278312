import React, { useCallback, useEffect } from 'react';
import { Popover } from 'react-tiny-popover';
import { useReportCalculationsStep } from './hooks';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { MoneyFormatter } from '../../../utils/common';
import { ReportCalculation } from './ReportCalculation';
import { ReportCalculationsStepProps } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import DcfIcon from '../../../../../assets/images/icons/icon_dcf.png';
import EbitdaIcon from '../../../../../assets/images/icons/icon_ebitda.png';
import PerIcon from '../../../../../assets/images/icons/icon_per.png';
import NaviIcon from '../../../../../assets/images/icons/icon_navi.png';

export const ReportCalculationsStep: React.FC<ReportCalculationsStepProps> = (
  props,
) => {
  const {
    report,
    ocrFileIds,
    isDcfEnabled,
    isEbitdaEnabled,
    isPerEnabled,
    isDcfCalculated,
    isEbitdaCalculated,
    isPerCalculated,
    dcfCalculations,
    ebitdaCalculations,
    perCalculations,
  } = props;
  const token = document
    ?.getElementById('calculations')
    ?.parentElement?.getElementsByTagName('input')[0].value;
  const {
    hasMultipleCalculations,
    isOpen,
    canUseOcrResult,
    canUseManualSubjectInputResults,
    reportGenPptxStepStyle,
    createCalculation,
    showSelectPopover,
    getValuationForm,
    setReportGenPptxStep,
    checkManualSubjectInputResults,
    checkUseOcrResults,
    checkValuationResults,
    checkCalculatedCount,
  } = useReportCalculationsStep({
    ocrFileIds,
    report,
    // @ts-ignore
    token,
  });

  useEffect(() => {
    checkUseOcrResults();
    checkManualSubjectInputResults();
    if (isDcfCalculated || isEbitdaCalculated || isPerCalculated) {
      setReportGenPptxStep({
        checkedButton:
          'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white',
        flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300',
      });
    }
    checkCalculatedCount({
      dcf: dcfCalculations,
      ebitda: ebitdaCalculations,
      per: perCalculations,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValuation = useCallback(
    (valuation: number, nonLiquidityDiscount: number): JSX.Element => {
      switch (valuation) {
        case 0:
          return (
            <div className="pl-6 pr-6 pb-3 text-blue-600 text-xl font-bold">
              算定エラーが発生した為、再計算をお願いします。
            </div>
          );
        default:
          return (
            <div className="pb-1 pt-3 text-blue-500 text-lg text-center font-semibold">
              {MoneyFormatter(
                Math.round(valuation * 0.9 * (1 - nonLiquidityDiscount)),
              )}
              〜{' '}
              {MoneyFormatter(
                Math.round(valuation * 1.1 * (1 - nonLiquidityDiscount)),
              )}
            </div>
          );
      }
    },
    [],
  );

  const selectContent = useCallback(() => {
    return (
      <>
        <div className="px-3 py-3 border border-gray-400 w-full bg-white rounded">
          <div className="flex">
            <div className="text-center w-20 text-gray-600 text-sm">
              算定タイプ
            </div>
            <div className="text-sm w-40 mx-5 text-gray-600">
              バリュエーション
            </div>
          </div>
          <hr></hr>
          {[...dcfCalculations, ...ebitdaCalculations, ...perCalculations]?.map(
            (calculation) =>
              calculation?.result && (
                <React.Fragment key={calculation.id}>
                  <div className="cursor-pointer mt-2">
                    <div className="flex">
                      <div className="text-left px-2 w-20 text-gray-800 text-sm font-semibold">
                        {calculation.result_type?.toUpperCase()}
                      </div>
                      <div className="text-sm w-64 mx-5 text-gray-600 hover hover:text-blue-600">
                        {MoneyFormatter(
                          Math.round(
                            parseFloat(
                              JSON.parse(calculation?.result)?.result.valuation,
                            ) *
                              0.9 *
                              (1 -
                                JSON.parse(calculation?.result)?.breakdown[0]
                                  .non_liquidity_discount),
                          ),
                        )}
                        ~{' '}
                        {MoneyFormatter(
                          Math.round(
                            parseFloat(
                              JSON.parse(calculation?.result)?.result.valuation,
                            ) *
                              1.1 *
                              (1 -
                                JSON.parse(calculation?.result)?.breakdown[0]
                                  .non_liquidity_discount),
                          ),
                        )}
                      </div>
                      <div
                        onClick={() => {
                          createCalculation(calculation);
                        }}
                        className="text-right py-1 px-2 border border-radius bg-green-500 hover:bg-green-600 text-white rounded text-xs"
                      >
                        複製する
                      </div>
                    </div>
                  </div>
                  <hr className="mt-1"></hr>
                </React.Fragment>
              ),
            [],
          )}
        </div>
      </>
    );
  }, [createCalculation, dcfCalculations, ebitdaCalculations, perCalculations]);

  return (
    <div className="relative pb-8">
      <span
        className={reportGenPptxStepStyle.flowLineStyle}
        aria-hidden="true"
      ></span>
      <div className="relative flex space-x-3">
        <span className={reportGenPptxStepStyle.checkedButton}>
          <svg
            className="h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <div className="w-full">
          <div>
            <div className="flex">
              <p className="text-lg text-green-400 font-semibold">STEP3</p>
              <span className="ml-3 mr-2 mt-2">
                <img className="h-3 w-3" src={TimeIcon} />
              </span>
              <p className="text-xs text-gray-400 mt-1.5">
                所要時間 約<span className="font-bold">1</span>分
              </p>
            </div>
            <div className="grid grid-cols-12 w-6/12">
              <div className="col-span-11">
                <p className="text-xl text-gray-700 font-bold pt-3">
                  株式価値の算定
                </p>
              </div>
              <div className="col-span-1 mb-3">
                <Tooltip
                  baseStyles="rounded-full bg-white"
                  tipPlacement="bottom-1 left-3"
                  tipText="将来の収益獲得力に起点を置いたDCF法、営業利益を起点に置いたEV/EBITDA倍率法及び当期純利益を起点に置いたPER倍率法の3つのアプローチで株式価値の評価が出来ます。評価の目的に合わせてアプローチをご選択してください。"
                >
                  <span className="h-10 w-10 flex items-end justify-end bg-white">
                    <img className="h-10 w-10" src={NaviIcon} />
                  </span>
                </Tooltip>
              </div>
            </div>
            <hr className="w-6/12"></hr>
            <p className="pt-1.5 text-sm text-gray-500">
              会計・税務の視点からあなたの会社の価値を算定
            </p>
            <div className="pt-2">
              <div className="grid grid-cols-12 pt-3 gap-3">
                {isDcfEnabled ? (
                  dcfCalculations?.map((calculation) => (
                    <div key={calculation.id} className="col-span-4 min-w-full">
                      <ReportCalculation
                        report={report}
                        icon={DcfIcon}
                        calculation={calculation}
                        valuationName="DCF法"
                        valuationType="dcf"
                        hasMultipleCalculations={hasMultipleCalculations.dcf}
                        checkValuation={checkValuation}
                        getValuationForm={getValuationForm}
                        checkValuationResults={checkValuationResults}
                        isCalculated={isDcfCalculated}
                        canUseOcrResult={canUseOcrResult}
                        canUseManualSubjectInputResults={
                          canUseManualSubjectInputResults
                        }
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {isEbitdaEnabled ? (
                  ebitdaCalculations?.map((calculation) => (
                    <div key={calculation.id} className="col-span-4 min-w-full">
                      <ReportCalculation
                        report={report}
                        icon={EbitdaIcon}
                        calculation={calculation}
                        valuationName="EV/EBITDA倍率法"
                        valuationType="ebitda"
                        hasMultipleCalculations={hasMultipleCalculations.ebitda}
                        checkValuation={checkValuation}
                        getValuationForm={getValuationForm}
                        checkValuationResults={checkValuationResults}
                        isCalculated={isEbitdaCalculated}
                        canUseOcrResult={canUseOcrResult}
                        canUseManualSubjectInputResults={
                          canUseManualSubjectInputResults
                        }
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {isPerEnabled ? (
                  perCalculations?.map((calculation) => (
                    <div key={calculation.id} className="col-span-4 min-w-full">
                      <ReportCalculation
                        report={report}
                        icon={PerIcon}
                        calculation={calculation}
                        valuationName="PER倍率法"
                        valuationType="per"
                        hasMultipleCalculations={hasMultipleCalculations.per}
                        checkValuation={checkValuation}
                        getValuationForm={getValuationForm}
                        checkValuationResults={checkValuationResults}
                        isCalculated={isPerCalculated}
                        canUseOcrResult={canUseOcrResult}
                        canUseManualSubjectInputResults={
                          canUseManualSubjectInputResults
                        }
                      />
                    </div>
                  ))
                ) : (
                  <></>
                )}
                {isDcfCalculated || isEbitdaCalculated || isPerCalculated ? (
                  <div className="col-span-4 text-center py-12 bg-white rounded border border-gray-300 border-dashed">
                    <Popover
                      isOpen={isOpen ? true : false}
                      onClickOutside={() => showSelectPopover()}
                      positions={['bottom']}
                      content={selectContent()}
                    >
                      <div
                        onClick={() => showSelectPopover()}
                        className="mt-3 cursor-pointer bg-gray-200 hover:bg-green-500 hover:text-white text-gray-400 font-bold py-2 rounded inline-flex items-center"
                      >
                        <span className="px-6">＋ 算定を追加</span>
                      </div>
                    </Popover>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
