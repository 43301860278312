import React, { useCallback, useMemo } from 'react';
import { transferLocation } from '../../../utils/common';
import { CompanyMedicalReportCalculationButtonProps } from './types';

export const CompanyMedicalReportValuationButton: React.FC<
  CompanyMedicalReportCalculationButtonProps
> = (props) => {
  const {
    companyMedicalReport,
    valuation,
    valuationResult,
    isOcrUploaded,
    isSubjectUploaded,
  } = props;

  const handleClick = useCallback(
    (type: string) => {
      if (type !== 'calculate' && type !== 'confirm') return;
      const url =
        type === 'calculate'
          ? `/company_medical_reports/${companyMedicalReport.id}/valuations/${valuation.id}?method=${valuation.method_type}`
          : `/company_medical_reports/${companyMedicalReport.id}/valuation_results/${valuation.id}`;
      transferLocation(url);
    },
    [companyMedicalReport.id, valuation.id, valuation.method_type],
  );

  const zeroValuation = useMemo(() => {
    if (!valuationResult) return false;
    return (
      valuationResult &&
      parseFloat(String(valuationResult.result.valuation)) === 0
    );
  }, [valuationResult]);

  return (
    <>
      <div className="grid grid-cols-2 gap-1 pt-3">
        <div className="text-center col-span-2">
          <div
            onClick={() => handleClick('calculate')}
            className="cursor-pointer inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {zeroValuation
              ? '再計算する'
              : isOcrUploaded || isSubjectUploaded
              ? '決算書から再計算'
              : '計算する'}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1">
        <div className="col-span-1 text-center">
          {valuation.result ? (
            <div
              onClick={() => handleClick('confirm')}
              className="underline cursor-pointer inline-flex items-center px-12 py-2 text-sm leading-4 font-medium text-blue-600"
            >
              詳しく見る
            </div>
          ) : (
            <div className="underline inline-flex items-center px-12 py-1 text-sm leading-4 font-medium text-blue-600"></div>
          )}
        </div>
      </div>
    </>
  );
};
