import React from 'react';
import { CurrentAssets } from './CurrentAssets';
import { SubjectProps } from '../../../../types/props';

export const AssetsContainer: React.FC<SubjectProps> = React.memo(
  (props: SubjectProps) => {
    const { register, getValues, setValue, Controller, control } = props;
    return (
      <>
        <CurrentAssets
          Controller={Controller}
          control={control}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </>
    );
  },
);
