import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { notify } from '../users/api';
import { InputField } from '../../components/InputField/InputField';
import { RadioGroup } from '../../components/RadioGroup/RadioGroup';

type CompanyProps = {
  company_id: number;
  company_name: string;
  ip_addresses: { address: string; mode: string }[];
};

export const CompanyIpAddress: React.FC<CompanyProps> = (props) => {
  const { company_id, company_name, ip_addresses } = props;
  const token = useMemo(
    () =>
      document
        ?.getElementById('send_company_edit_form')
        ?.parentElement?.getElementsByTagName('input')[0].value,
    [],
  );
  const initializedIpAddresses = useMemo(
    () =>
      ip_addresses.map((ip) => ({
        ...ip,
        mode: ip.mode !== undefined ? ip.mode : 'allowed',
      })),
    [ip_addresses],
  );

  const [formState, setFormState] = useState<CompanyProps>({
    company_id,
    company_name,
    ip_addresses: initializedIpAddresses,
  });

  const twoFactorOptions = useMemo(
    () => [
      { value: 'allowed', label: 'はい' },
      { value: 'denied', label: 'いいえ' },
    ],
    [],
  );

  const updateFormState = useCallback((e) => {
    const { name, value } = e.target;
    if (name.startsWith('ip_address_')) {
      const index = parseInt(name.split('_')[2], 10);
      setFormState((prevState) => ({
        ...prevState,
        ip_addresses: prevState.ip_addresses.map((ip, i) =>
          i === index ? { ...ip, address: value } : ip,
        ),
      }));
    } else if (name.startsWith('mode_')) {
      const index = parseInt(name.split('_')[1], 10);
      setFormState((prevState) => ({
        ...prevState,
        ip_addresses: prevState.ip_addresses.map((ip, i) =>
          i === index ? { ...ip, mode: value } : ip,
        ),
      }));
    }
  }, []);

  const addNewIpAddress = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      ip_addresses: [
        ...prevState.ip_addresses,
        { address: '', mode: 'allowed' },
      ],
    }));
  }, []);

  const removeIpAddress = useCallback((indexToRemove) => {
    setFormState((prevState) => ({
      ...prevState,
      ip_addresses: prevState.ip_addresses.filter((_, index) => index !== indexToRemove),
    }));
  }, []);

  const submitCompanyInfo = useCallback(async () => {
    try {
      const submitFormState = {
        company_id: formState.company_id,
        company_name: formState.company_name,
        ip_addresses: formState.ip_addresses.filter(ip => ip.address !== ''),
      };
      const response = await axios.patch(
        `/companies/${submitFormState.company_id}`,
        submitFormState,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token || '',
          },
        },
      );

      if (response.status === 200) {
        notify('success', '企業情報が更新されました。');
      } else {
        notify('failed', '企業情報の更新に失敗しました。');
      }
    } catch (error) {
      console.error(error);
      notify('failed', '企業情報の更新に失敗しました。');
    }
  }, [formState, token]);

  return (
    <>
      <Toaster />
      <div className="grid grid-cols-5 gap-x-2 gap-y-8 pt-12">
        <div className="grid grid-cols-1" />
        <div className="bg-white shadow-sm ring-1 ring-gray-300 sm:rounded-xl md:col-span-3">
          <div className="px-2 sm:px-0 px-12 text-center pt-6">
            <h2 className="text-xl font-semibold leading-7 text-gray-900">
              企業情報設定
            </h2>
            <p className="mt-1 text-md leading-6 text-gray-600">
              企業情報を入力してください。
            </p>
          </div>
          <div className="px-4 py-6 sm:p-8">
            <InputField
              name="company_name"
              value={formState.company_name}
              onChange={updateFormState}
              label="企業名"
              required
            />
          </div>
          {formState?.ip_addresses?.map((ip_address, i) => (
            <div key={i} className="px-4 py-3 sm:p-8">
              <div className="flex grid sm:grid-cols-12 gap-x-4 max-w-full">
                <div className="sm:col-span-6">
                  <InputField
                    name={`ip_address_${i}`}
                    value={ip_address.address}
                    onChange={updateFormState}
                    label="IPアドレス"
                  />
                </div>
                <div className="sm:col-span-4">
                  <RadioGroup
                    name={`mode_${i}`}
                    value={ip_address.mode}
                    onChange={updateFormState}
                    options={twoFactorOptions}
                    label="通信を許可する"
                  />
                </div>
                <div className="sm:col-span-2 pt-10 cursor-pointer">
                  <div
                    className="text-red-700 underline"
                    onClick={() => removeIpAddress(i)}
                  >
                    削除
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="px-4 py-6 sm:p-8">
            <div className="flex justify-center">
              <div
                className="rounded-md text-blue-700 hover:text-blue-800 underline px-4 py-2 text-white hover:text-blue-700 cursor-pointer"
                onClick={addNewIpAddress}
              >
                IPアドレスを追加
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-8 py-4 sm:px-8">
            <div
              className="cursor-pointer rounded-md bg-indigo-600 px-8 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              // @ts-ignore
              onClick={submitCompanyInfo}
            >
              変更を保存
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
