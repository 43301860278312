import axios from 'axios';
import { postCalculationProps, validateItemProps } from './types';
export const postCalculation: (
  options: postCalculationProps,
  path: string,
  _callbacks: {
    _setModalCheck: (arg: boolean) => void;
    _setResponseValidatesMessages: (arg: string[]) => void;
  },
) => Promise<void | undefined | string[]> = (options, path, _callbacks) => {
  const { _setModalCheck, _setResponseValidatesMessages } = _callbacks;
  return axios(options)
    .then((res) => {
      if (res.status === 200 && res.data.status === 'success') {
        location.href = path;
      } else {
        const validateItems: validateItemProps[] = JSON.parse(
          res.data.valuation_results,
        ).validates;
        const validateMessages: string[] = validateItems.map((item) =>
          item.message.replace(item.key, item.name).replace('null', '未入力'),
        );
        _setModalCheck(true);
        _setResponseValidatesMessages(validateMessages);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
