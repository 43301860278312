import React, { useCallback, useState } from 'react';
import { ValuationReportOptions } from '../valuation_report_options/ValuationReportOptions';
import { CheckboxField } from '../../components/CheckboxField/CheckboxField';
import { SelectField } from '../../components/SelectField/SelectField';
import { updateValuationReportOption } from './api';
import {
  UsersValuationReportOptionProps,
  ValuationReportOptionProps,
} from './types';

export const UsersValuationReportOption: React.FC<
  UsersValuationReportOptionProps
> = (props) => {
  const {
    valuation_report_option,
    inheritance_valuation_option,
    user_id,
    company_name,
    company_color,
    fontKeys,
  } = props;
  const token = document
    ?.getElementById('valuation_report_option_edit')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  const [formState, setFormState] = useState<ValuationReportOptionProps>({
    user_id: user_id,
    dcf: valuation_report_option.dcf,
    ebitda: valuation_report_option.ebitda,
    per: valuation_report_option.per,
    inheritance: inheritance_valuation_option.inheritance,
    background_color: valuation_report_option.background_color ?? '#000000',
    title_color: valuation_report_option.title_color ?? '#000000',
    table_color: valuation_report_option.table_color ?? '#000000',
    table_text_color: valuation_report_option.table_text_color ?? '#000000',
    font: valuation_report_option.font,
  });

  const handleInputChange = useCallback((e) => {
    const { name, type, value, checked } = e.target;
    if (type === 'checkbox') {
      setFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  return (
    <div className="grid grid-cols-5 gap-x-2 gap-y-8 pt-12">
      <div className="grid grid-cols-1" />
      <div className="bg-white shadow-sm ring-1 ring-gray-300 sm:rounded-xl md:col-span-3">
        <div className="px-2 sm:px-0 px-12 text-center pt-6">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">
            株価算定報告書設定
          </h2>
          <p className="mt-1 text-md leading-6 text-gray-600">
            株価算定報告書の設定を入力してください。
          </p>
        </div>
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-full grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-full">
              <fieldset>
                <legend className="flex text-md font-semibold leading-6 text-gray-900">
                  税法評価
                </legend>
                <div className="space-y-5 pt-4">
                  <CheckboxField
                    name="inheritance"
                    // @ts-ignore
                    value={formState.inheritance}
                    onChange={handleInputChange}
                    label="相続税法上の評価"
                  />
                </div>
              </fieldset>
            </div>
            <div className="sm:col-span-full pt-6">
              <fieldset>
                <legend className="flex text-md font-semibold leading-6 text-gray-900">
                  会計評価
                </legend>
                <div className="space-y-5 pt-4">
                  <CheckboxField
                    name="dcf"
                    // @ts-ignore
                    value={formState.dcf}
                    onChange={handleInputChange}
                    label="DCF法"
                  />
                  <CheckboxField
                    name="ebitda"
                    // @ts-ignore
                    value={formState.ebitda}
                    onChange={handleInputChange}
                    label="EV/EBITDA法"
                  />
                  <CheckboxField
                    name="per"
                    // @ts-ignore
                    value={formState.per}
                    onChange={handleInputChange}
                    label="PER倍率法"
                  />
                </div>
              </fieldset>
            </div>
            <div className="sm:col-span-full pt-6">
              <SelectField
                name="font"
                value={formState.font}
                onChange={handleInputChange}
                options={fontKeys}
                label="レポートフォント設定"
              />
            </div>
            <div className="sm:col-span-full pt-6">
              <ValuationReportOptions
                company_name={company_name}
                company_color={company_color}
                formState={formState}
                setFormState={setFormState}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-8 py-4 sm:px-8">
          <div
            className="cursor-pointer rounded-md bg-indigo-600 px-8 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            // @ts-ignore
            onClick={(e) =>
              // @ts-ignore
              updateValuationReportOption(e, token, formState)
            }
          >
            変更を保存
          </div>
        </div>
      </div>
    </div>
  );
};
