import ReactDOM from 'react-dom';
import React from 'react';
import ResultRow from './convert_row';
import './result_row.scss';

interface AnalyzeResultProp {
  fileId: string;
  pageIds: Array<string>;
}

const AnalyzeResult: React.FC<AnalyzeResultProp> = (
  props: AnalyzeResultProp,
) => {
  return (
    <div>
      <div>
        {props.pageIds.map((value) => (
          <ResultRow key={value} pageId={value} />
        ))}
      </div>
    </div>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  // 表示
  const result = document.getElementById('result');
  if (result !== null) {
    // @ts-ignore
    const ids = JSON.parse(result.getAttribute('data-ids'));
    // @ts-ignore
    const fileid = result.getAttribute('data-fileid');
    // @ts-ignore
    ReactDOM.render(<AnalyzeResult fileId={fileid} pageIds={ids} />, result);
  }
});

export default ResultRow;
