import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  const targets: HTMLCollection =
    document.getElementsByClassName('thumbnail_pdf');
  if (targets.length > 0) {
    const pdf = document.getElementById('pdf');
    const container = document.getElementById('pdf_viewer');

    // クリックでPDF表示
    for (let i = 0; i < targets.length; i++) {
      // @ts-ignore
      const page_id: string = targets[i].getAttribute('data-page_id');
      targets[i]
        .getElementsByClassName('open_pdf_view')[0]
        .addEventListener('click', () => {
          const spinner = document.getElementById('load_spinner');
          // @ts-ignore
          spinner.style.display = 'flex';
          // const target = e.target;

          axios
            .get(`/ocr/analysis/${page_id}/page_pdf`)
            .then((res) => {
              // @ts-ignore
              pdf.setAttribute(
                'src',
                `data:application/pdf;base64,${res.data}`,
              );
              // @ts-ignore
              container.style.display = 'flex';
              // @ts-ignore
              spinner.style.display = 'none';
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }

    // モーダル閉じる
    // @ts-ignore
    container.addEventListener('click', (e) => {
      e.stopPropagation();

      if (e.target == container) {
        // @ts-ignore
        pdf.removeAttribute('src');
        // @ts-ignore
        container.style.display = 'none';
      }
    });
  }
});
