import { useCallback, useRef, useState } from 'react';
import { postCalculation } from './api';
import {
  CompanyProps,
  DcfProps,
  IndustryProps,
  postCalculationProps,
  requestDataProps,
} from './types';
import {
  EQUITY_RISK_PREMIUM,
  MAX_TAX_RATE,
  MIN_TAX_RATE,
  RISK_FREE_RATE,
  SIZE_RISK_PREMIUM,
} from '../../../utils/constant';

export const useFormulaForm = (
  industries: IndustryProps[],
  similaritiesCompanies: CompanyProps[],
  similarIndustry?: IndustryProps,
) => {
  const [showModal, setModal] = useState<boolean>(false);
  const [closeMessage, setCloseMessage] = useState<boolean>(false);
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const [companyValue, setCompanyValue] = useState<keyof CompanyProps>('per');
  const [responseValidatesMessages, setResponseValidatesMessages] = useState<
    string[]
  >([]);
  const [unlevererdBeta, setUnlevererdBeta] = useState<number>(0);
  const [valueText, setValueText] = useState<string>('per');
  const [requestIndustry, setRequestIndustry] = useState<number>(
    industries?.[0]?.['industry_id'],
  );
  const [checkedSimilaritiesCompanies, setCheckedSimilaritiesCompanies] =
    useState<CompanyProps[] | []>([]);
  const [requestSimilaritiesCompanies, setRequestSimilaritiesCompanies] =
    useState<CompanyProps[]>(similaritiesCompanies);
  const selectedCompaniesRef = useRef<CompanyProps[] | []>([]);
  selectedCompaniesRef.current = requestSimilaritiesCompanies;

  /**
   * 算定に利用する類似企業の任意の値を表示
   * @params {company}
   * @return {number | string}
   */
  const showValuation = useCallback(
    (
      company: CompanyProps,
      targetValue: keyof CompanyProps,
    ): number | string => {
      if (!company[targetValue]) return '-';
      return Number(company[targetValue]).toFixed(2);
    },
    [],
  );

  /**
   * 類似企業内から算定アプローチが入力済みの企業を取得
   * @param similarities_companies {similarities_companies}
   * @param targetValue {string}
   * @return {Array}
   */
  const filterCompanies = useCallback(
    (
      similarities_companies: CompanyProps[],
      targetValue: keyof CompanyProps,
    ): Array<number | string> => {
      return similarities_companies
        .filter((company: CompanyProps) => company[targetValue])
        .map((company: CompanyProps) => {
          return company[targetValue];
        });
    },
    [],
  );

  /**
   * 指定類似企業数値の値の合計値
   * @param companies {string}
   * @param targetValue {string}
   * @return sum {number}
   */
  const sumValuationApproach = useCallback(
    (companies: CompanyProps[], targetValue: keyof CompanyProps): number => {
      return companies?.reduce((sum: number, company: CompanyProps) => {
        return sum + Number(company[targetValue]);
      }, 0);
    },
    [],
  );

  /**
   * Valuation平均値
   * @param companies {string}
   * @param targetValue {string}
   * @return {number}
   */
  const showAverage = useCallback(
    (companies: CompanyProps[], targetValue: keyof CompanyProps): number => {
      const sumValuationResult: number = sumValuationApproach(
        companies,
        targetValue,
      );
      const validSimilarities = filterCompanies(companies, targetValue);
      return sumValuationResult / validSimilarities.length;
    },
    [filterCompanies, sumValuationApproach],
  );

  /**
   * Valuation中央値
   * @param companies {string}
   * @param targetValue {string}
   * @return {string}
   */
  const showMedian = useCallback(
    (companies: CompanyProps[], targetValue: keyof CompanyProps): string => {
      const filteredCompanies = filterCompanies(companies, targetValue);
      filteredCompanies.sort((a, b) => {
        return Number(a) - Number(b);
      });
      const half = Math.floor(filteredCompanies.length / 2);
      return filteredCompanies.length % 2
        ? Number(filteredCompanies[half]).toFixed(2)
        : (
            (Number(filteredCompanies[half - 1]) +
              Number(filteredCompanies[half])) /
            2
          ).toFixed(2);
    },
    [filterCompanies],
  );

  /**
   * D/Eレシオ平均値
   * @param companies {string}
   * @return {number}
   */
  const showDeRatioAverage = useCallback(
    (companies: CompanyProps[]): number => {
      const sumValuationResult: number = sumValuationApproach(
        companies,
        'de_ratio',
      );
      const validSimilarities = filterCompanies(companies, 'de_ratio');
      return sumValuationResult / validSimilarities.length;
    },
    [filterCompanies, sumValuationApproach],
  );

  /**
   * 類似企業の値を確認
   * @param companies {string}
   * @param resourceName {string}
   * @return {boolean}
   */
  const hasValuationData = useCallback(
    (
      company: CompanyProps,
      resourceName: 'dcf' | 'per' | 'ebitda',
    ): boolean => {
      switch (resourceName) {
        case 'dcf':
          return (
            company.unlevered_beta !== '' &&
            company.unlevered_beta !== null &&
            company.levered_beta !== '' &&
            company.levered_beta !== null &&
            company.de_ratio !== '' &&
            company.de_ratio !== null
          );
        case 'ebitda':
          return company.ev_per_ebitda !== '' && company.ev_per_ebitda !== null;
        case 'per':
          return company.per !== '' && company.per !== null;
        default:
          return true;
      }
    },
    [],
  );

  /**
   * 類似企業のチェックステータスを設定
   * @param company {string}
   * @return {boolean}
   */
  const isChecked = useCallback(
    (company: string): boolean => {
      const checkCompany = JSON.parse(company);
      const matchedCompany = checkedSimilaritiesCompanies?.filter(
        (selectedCompany: CompanyProps) =>
          selectedCompany.ticker === checkCompany.ticker,
      );
      return 0 < matchedCompany.length;
    },
    [checkedSimilaritiesCompanies],
  );

  /**
   * 選択済みの企業か確認
   * @params selectedCompanies {CompanyProps[]} 既に選択されている企業
   * @params changedCompany {CompanyProps} 選択した企業
   * @return {boolean}
   */
  const sortCompanies = useCallback(
    (
      selectedCompanies: CompanyProps[],
      changedCompany: CompanyProps,
    ): CompanyProps[] => {
      const sameCompany = selectedCompanies.filter(
        (prev_company: CompanyProps) =>
          prev_company.ticker === changedCompany.ticker,
      );

      if (0 < sameCompany.length) {
        const unChangedCompanies = selectedCompanies.filter(
          (prev_company: CompanyProps) =>
            prev_company.ticker !== changedCompany.ticker,
        );
        return [...unChangedCompanies];
      } else {
        return [...selectedCompanies, changedCompany];
      }
    },
    [],
  );

  /**
   * 類似企業のチェックステータスを設定
   * @params e
   * @return {boolean}
   */
  const checkCompanies = useCallback(
    (e): void => {
      const changedCompany: CompanyProps = JSON.parse(e.target.value);
      const sortedCompanies: CompanyProps[] = sortCompanies(
        checkedSimilaritiesCompanies,
        changedCompany,
      );
      setCheckedSimilaritiesCompanies(sortedCompanies);
    },
    [checkedSimilaritiesCompanies, sortCompanies],
  );

  /**
   * チェックされた類似企業が一つ以上あればモーダルを閉じる
   * @params {selectedCompanies} 既に選択されている企業
   * @params {changedCompany} 選択した企業
   * @return {void}
   */
  const updateRequestSimilaritiesCompanies = useCallback((): void => {
    if (0 > checkedSimilaritiesCompanies.length) {
      setCloseMessage(true);
    }
    setRequestSimilaritiesCompanies(checkedSimilaritiesCompanies);
    setUnlevererdBeta(showAverage(checkedSimilaritiesCompanies, companyValue));
    setModal(false);
  }, [checkedSimilaritiesCompanies, companyValue, showAverage]);

  /**
   * 選択した類似企業から算定に利用できる企業を作成
   * @params companies {CompanyProps[]}
   * @params resourceName {keyof CompanyProps}
   * @return companies {CompanyProps[]}
   */
  const filterRequestSimilaritiesCompanies = useCallback(
    (
      companies: CompanyProps[],
      resourceName: 'dcf' | 'per' | 'ebitda',
    ): CompanyProps[] => {
      const resultCompanies: CompanyProps[] = companies.filter(
        (company: CompanyProps) => {
          if (hasValuationData(company, resourceName)) {
            return company;
          }
        },
      );
      return resultCompanies;
    },
    [hasValuationData],
  );

  /**
   * 業種を利用する場合に業種IDを設定
   * @return void
   */
  const changeRequestIndustry = useCallback((e): void => {
    setRequestIndustry(e.target.value);
  }, []);

  /**
   * 円単位を変換
   * @return string
   */
  const convertYen = useCallback((val: number): string => {
    return Math.floor(Math.round(val)).toLocaleString();
  }, []);

  /**
   * 指定の指標のvalidation
   * @params company {CompanyProps}
   * @params targetValue {keyof CompanyProps}
   * @return string
   */
  const validateTargetValue = useCallback(
    (company: CompanyProps, targetValue: keyof CompanyProps): null | string => {
      if (!company[targetValue] || company[targetValue] === '') {
        return null;
      }
      return Number(company[targetValue]).toFixed(2);
    },
    [],
  );

  /**
   * 類似企業があれば関数を実行
   * @return void
   */
  const checkSimilaritiesCompaniesCount = useCallback((): void => {
    if (0 < similaritiesCompanies?.length) {
      setUnlevererdBeta(
        showAverage(requestSimilaritiesCompanies, companyValue),
      );
    }
  }, [
    companyValue,
    requestSimilaritiesCompanies,
    showAverage,
    similaritiesCompanies?.length,
  ]);

  /**
   * state関数を実行
   * @params resourceName {'dcf' | 'per' | 'ebitda'}
   * @return void
   */
  const operateValuationResource = useCallback(
    (resourceName: 'dcf' | 'per' | 'ebitda'): void => {
      switch (resourceName) {
        case 'dcf':
          setCompanyValue('unlevered_beta');
          setValueText('Unleverdβ');
          break;
        case 'per':
          setCompanyValue('per');
          setValueText('PER');
          break;
        case 'ebitda':
          setCompanyValue('ev_per_ebitda');
          setValueText('EV/EBITDA');
          break;
        default:
          break;
      }
    },
    [],
  );

  const postValuation = useCallback(
    async (e, resourceName, requestPath, callbackPath, token) => {
      if (e.data && e.data.name === 'submitMaForm') {
        const requestData: requestDataProps = {
          results: e.data,
          industry: requestIndustry,
          similarities_list:
            selectedCompaniesRef.current !== null
              ? filterRequestSimilaritiesCompanies(
                  selectedCompaniesRef.current,
                  resourceName,
                )
              : null,
        };
        const requestConfig: postCalculationProps = {
          method: 'post',
          url: requestPath,
          data: requestData,
          // @ts-ignore
          headers: { 'X-CSRF-Token': token },
        };
        const callbackFuncs = {
          _setModalCheck: setModalCheck,
          _setResponseValidatesMessages: setResponseValidatesMessages,
        };
        await postCalculation(requestConfig, callbackPath, callbackFuncs);
      } else if (e.data && e.data.name === 'sendTempFormAction') {
        // @ts-ignore
        document.scrollingElement.scrollTop = window.top;
      } else if (
        e.data &&
        e.data.name === 'cancelMaForm' &&
        e.data.value === 'canceled'
      ) {
        // @ts-ignore
        document.scrollingElement.scrollTop = window.top;
      }
    },
    [
      requestIndustry,
      filterRequestSimilaritiesCompanies,
      setModalCheck,
      setResponseValidatesMessages,
    ],
  );

  const switchResourceValue = useCallback(
    (resourceName) => {
      switch (resourceName) {
        case 'per':
          return { name: 'PER 平均値', value: similarIndustry?.per };
        case 'ebitda':
          return {
            name: 'EV/EBITDA 平均値',
            value: similarIndustry?.ebitda_ratio,
          };
        case 'dcf':
          return { name: 'D/Eレシオ 平均値', value: similarIndustry?.de_ratio };
      }
    },
    [
      similarIndustry?.de_ratio,
      similarIndustry?.ebitda_ratio,
      similarIndustry?.per,
    ],
  );

  return {
    requestIndustry,
    companyValue,
    valueText,
    checkedSimilaritiesCompanies,
    requestSimilaritiesCompanies,
    unlevererdBeta,
    responseValidatesMessages,
    showModal,
    closeMessage,
    modalCheck,
    setUnlevererdBeta,
    setResponseValidatesMessages,
    setModal,
    setCloseMessage,
    setModalCheck,
    showValuation,
    showAverage,
    showMedian,
    showDeRatioAverage,
    hasValuationData,
    isChecked,
    sortCompanies,
    checkCompanies,
    updateRequestSimilaritiesCompanies,
    filterRequestSimilaritiesCompanies,
    changeRequestIndustry,
    convertYen,
    operateValuationResource,
    validateTargetValue,
    checkSimilaritiesCompaniesCount,
    postValuation,
    switchResourceValue,
  };
};

export const useFormulaWaccSelector = (averageUnleveredBeta: number) => {
  const [showModal, setModal] = useState<boolean>(false);
  const [iframeOrigin, setIframeOrigin] = useState<MessageEvent | null>(null);
  const [dcfProps, setDcfProps] = useState<DcfProps | null>(null);
  const [unleveredB, setUnleveredB] = useState<number | null>(null);
  const [riskFreeRate, setRiskFreeRate] = useState<number | null>(null);
  const [equityRiskPremium, setEquityRiskPremium] = useState<number | null>(
    null,
  );
  const [sizeRiskPremium, setSizeRiskPremium] = useState<number | null>(null);

  /**
   * nullを除外した数値を返す
   * @params prop {number | null}
   * @params value {number}
   * @return number
   */
  const castNull = useCallback(
    (prop: number | null | undefined, value: number): number => {
      if (prop === null || typeof prop === 'undefined' || isNaN(prop)) {
        return value;
      }
      return prop;
    },
    [],
  );

  /**
   * 実行税率を取得
   * @return number
   */
  const getEffectiveTaxRate = useCallback((): number => {
    if (typeof dcfProps?.share_capital !== 'number') return 0.0;
    return dcfProps?.share_capital <= 10 ** 8 ? MAX_TAX_RATE : MIN_TAX_RATE;
  }, [dcfProps]);

  /**
   * レバードβを取得
   * @return number
   */
  const getlevererdβ = useCallback((): number => {
    if (
      typeof getEffectiveTaxRate() !== 'number' ||
      typeof dcfProps?.wacc?.de_ratio !== 'number' ||
      typeof unleveredB !== 'number'
    )
      return 0.0;
    return (
      unleveredB *
      (1.0 + (1.0 - getEffectiveTaxRate() / 100) * dcfProps?.wacc?.de_ratio)
    );
  }, [dcfProps?.wacc?.de_ratio, getEffectiveTaxRate, unleveredB]);

  /**
   * 株主資本コストを取得
   * @return number
   */
  const getEquityCost = useCallback((): number => {
    return (
      castNull(riskFreeRate, RISK_FREE_RATE) +
      castNull(equityRiskPremium, EQUITY_RISK_PREMIUM) * getlevererdβ() +
      castNull(sizeRiskPremium, SIZE_RISK_PREMIUM)
    );
  }, [
    castNull,
    equityRiskPremium,
    getlevererdβ,
    riskFreeRate,
    sizeRiskPremium,
  ]);

  /**
   * 税引後負債コストを取得
   * @return number
   */
  const getDebtCost = useCallback((): number => {
    if (
      typeof dcfProps?.interest_expenses !== 'number' ||
      typeof dcfProps?.interest_bearing_debt_before_2term !== 'number' ||
      typeof dcfProps?.interest_bearing_debt !== 'number'
    )
      return 0.0;
    return (
      (dcfProps?.interest_expenses /
        ((dcfProps?.interest_bearing_debt_before_2term +
          dcfProps?.interest_bearing_debt) /
          2)) *
      (1.0 - getEffectiveTaxRate() / 100)
    );
  }, [
    dcfProps?.interest_bearing_debt,
    dcfProps?.interest_bearing_debt_before_2term,
    dcfProps?.interest_expenses,
    getEffectiveTaxRate,
  ]);

  /**
   * 負債比率を取得
   * @return number
   */
  const getDebtRate = useCallback((): number => {
    if (typeof dcfProps?.wacc?.de_ratio !== 'number') return 0.0;
    return dcfProps?.wacc?.de_ratio / (dcfProps?.wacc?.de_ratio + 1.0);
  }, [dcfProps?.wacc?.de_ratio]);

  /**
   * 株主比率を取得
   * @return number
   */
  const getStockRate = useCallback((): number => {
    return 1.0 - getDebtRate();
  }, [getDebtRate]);

  /**
   * WACCを取得
   * @return number
   */
  const getWacc = useCallback((): number => {
    return getDebtCost() * getDebtRate() + getEquityCost() * getStockRate();
  }, [getDebtCost, getDebtRate, getEquityCost, getStockRate]);

  /**
   * iframeにmessage送信
   * @return void
   */
  const postCapmMessage = useCallback((): void => {
    iframeOrigin?.source?.postMessage(
      {
        name: 'updateProps',
        value: {
          size_risk_premium: sizeRiskPremium,
          unlevered_beta: unleveredB,
          equity_risk_premium: equityRiskPremium,
          risk_free_rate: riskFreeRate,
        },
      },
      // @ts-ignore
      '*',
    );
    setModal(!showModal);
  }, [
    equityRiskPremium,
    iframeOrigin?.source,
    riskFreeRate,
    showModal,
    sizeRiskPremium,
    unleveredB,
  ]);

  /**
   * リスナー関数を登録
   * @return void
   */
  const addValuationListener = useCallback(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitSource') {
          setDcfProps(event.data.props.subjects.financials);
          setUnleveredB(
            castNull(
              event.data.props.subjects.financials.wacc?.unlevered_beta,
              averageUnleveredBeta,
            ),
          );
          setRiskFreeRate(
            event.data.props.subjects.financials.wacc?.capm?.risk_free_rate,
          );
          setEquityRiskPremium(
            event.data.props.subjects.financials.wacc?.capm
              ?.equity_risk_premium,
          );
          setSizeRiskPremium(
            event.data.props.subjects.financials.wacc?.capm?.size_risk_premium,
          );
          setIframeOrigin(event);
        }
      },
      false,
    );
  }, [averageUnleveredBeta, castNull]);

  return {
    showModal,
    dcfProps,
    iframeOrigin,
    riskFreeRate,
    equityRiskPremium,
    sizeRiskPremium,
    unleveredB,
    setUnleveredB,
    setSizeRiskPremium,
    setEquityRiskPremium,
    setRiskFreeRate,
    setIframeOrigin,
    setDcfProps,
    setModal,
    getEffectiveTaxRate,
    getlevererdβ,
    getEquityCost,
    getWacc,
    getStockRate,
    getDebtRate,
    getDebtCost,
    castNull,
    postCapmMessage,
    addValuationListener,
  };
};
