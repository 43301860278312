import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';

import { CtContainer } from './CtContainer';
import { InheritanceSubject } from '../concern/InheritanceSubject';
import { SubjectsInputFormProps } from '../../types/props';

export const SubjectsInputForm: React.FC<SubjectsInputFormProps> = React.memo(
  (props: SubjectsInputFormProps) => {
    const { inheritanceValuationId, phase, method } = props;
    const [subject, setSubject] = useState<
      { code: string; value: null | number }[] | []
    >([]);
    const form = document.getElementById('subjects_form');

    const { register, handleSubmit, getValues, setValue, control } = useForm<
      { code: string; value: null | number }[]
    >({
      defaultValues: {
        // @ts-ignore
        subject: '',
      },
    });

    useEffect(() => {
      // @ts-ignore
      setValue('subject', InheritanceSubject);
      // @ts-ignore
      setSubject([InheritanceSubject]);
    }, [setValue]);

    const postSubjects = useCallback(() => {
      const requestData = {
        // @ts-ignore
        subject: getValues('subject'),
        datatype: 'inheritance_financial',
        phase: phase,
        type: 'corporate_tax_return',
      };
      const requestConfig = {
        method: method,
        url: `/inheritances/${inheritanceValuationId}/subjects.json`,
        data: requestData,
        headers: {
          // @ts-ignore
          'X-CSRF-Token': form.getElementsByTagName('input')[0].value,
        },
      };
      axios(requestConfig)
        .then((res) => {
          if (res.status === 200) {
            location.href = `/inheritances/${inheritanceValuationId}`;
          }
        })
        .catch((err) => {
          location.href = `/inheritances/${inheritanceValuationId}`;
          console.log(err);
        });
    }, [form, getValues, inheritanceValuationId, method, phase]);

    // @ts-ignore
    const onSubmit = (data) => {
      postSubjects();
    };

    return (
      <>
        <div className="grid grid-cols-12 pt-1">
          <div className="col-span-2"></div>
          <div className="col-span-8">
            {
              // @ts-ignore
              0 < subject?.length ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-12 text-center pt-2 pb-6">
                      <button
                        type="submit"
                        className="inline-block m-4 px-12 py-2 text-white bg-green-500 hover:bg-green-600 rounded-sm cursor-pointer"
                      >
                        税務申告書情報を適用する
                      </button>
                    </div>
                    {/* <div className="col-span-12 text-right pr-3">
                      <button
                        onClick={() => {
                          location.href = `/inheritances/${inheritanceValuationId}/ocr/analysis_ct/new?phase=${phase}`;
                        }}
                        className="inline-block m-4 px-4 py-1 text-white bg-blue-500 hover:bg-blue-600 rounded-sm cursor-pointer"
                      >
                        OCR取り込み（β版）
                      </button>
                    </div> */}
                  </div>
                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <CtContainer
                        // @ts-ignore
                        register={register}
                        Controller={Controller}
                        control={control}
                        getValues={getValues}
                        // @ts-ignore
                        setValue={setValue}
                        phase={phase}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 text-center pt-12">
                    <button
                      type="submit"
                      className="inline-block m-4 px-12 py-2 text-white bg-green-500 hover:bg-green-600 rounded-sm cursor-pointer"
                    >
                      税務申告書情報を適用する
                    </button>
                  </div>
                </form>
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </>
    );
  },
);
