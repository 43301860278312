import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useOcrResults } from './hooks';
import { OcrResultRow } from './OcrResultRow';
import { BsContainer } from '../../../components/Subject/bs/BsContainer';
import { PlContainer } from '../../../components/Subject/pl/PlContainer';
import { SgaContainer } from '../../../components/Subject/sga/SgaContainer';
import { OcrResultProp } from './types';

export const OcrResult: React.FC<OcrResultProp> = (props) => {
  const { reportId, fileId, pageIds, phase } = props;
  const token = document
    ?.getElementById('ocr_result_form')
    ?.getElementsByTagName('input')[0].value;

  const { handleSubmit, getValues, setValue, control } = useForm({
    defaultValues: {
      subject: '',
    },
    criteriaMode: 'all',
    shouldFocusError: true,
  });
  const {
    subject,
    visibleFlg,
    loadingMessage,
    setTimerId,
    getStatus,
    onSubmit,
  } = useOcrResults({
    reportId,
    fileId,
    setValue,
    pageIds,
    getValues,
    // @ts-ignore
    token,
  });
  useEffect(() => {
    setTimerId(setInterval(getStatus, 3000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 h-auto">
        <div className="col-span-5">
          <p className="text-gray-500 text-left font-base text-xl mt-4">
            解析元決算書ファイル
          </p>
          {pageIds.map((value: string) => (
            <OcrResultRow key={value} pageId={value} reportId={reportId} />
          ))}
        </div>
        <div className="col-span-7">
          {
            // @ts-ignore
            0 < subject?.length ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-right pr-5">
                  {visibleFlg ? (
                    <>
                      <div className="grid grid-cols-12">
                        <div className="col-span-6">
                          <p className="text-gray-500 pl-8 pt-5 text-left font-base text-xl">
                            PDFデータから自動で取り込む
                          </p>
                        </div>
                        <div className="col-span-6">
                          <button
                            type="submit"
                            className="inline-block m-4 px-7 py-2 text-white bg-blue-600 rounded-sm cursor-pointer"
                          >
                            算定に反映する
                          </button>
                        </div>
                      </div>
                      <p className="text-gray-500 pl-8 -mt-3 mb-3 text-left font-base text-xs">
                        データ取り込みが完了しました。取り込みデータを編集することができます。
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="pb-10">
                  <BsContainer
                    setValue={setValue}
                    getValues={getValues}
                    // @ts-ignore
                    control={control}
                    phase={phase}
                  />
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-6">
                    <PlContainer
                      setValue={setValue}
                      getValues={getValues}
                      // @ts-ignore
                      control={control}
                      phase={phase}
                    />
                  </div>
                </div>
                <div className="pt-10 grid grid-cols-12">
                  <div className="col-span-6">
                    <SgaContainer
                      setValue={setValue}
                      getValues={getValues}
                      // @ts-ignore
                      control={control}
                      reportType={'company_medical_reports'}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <div className="text-center pt-36">
                <h1 className="font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-blue-400 to-green-500">
                  {loadingMessage}
                </h1>
                <p className="text-gray-600">
                  ※
                  枚数が多い場合、時間がかかる可能性がございます。ご了承ください。
                </p>
                <p className="mx-auto file_analyzing_image w-32 h-32"></p>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};
