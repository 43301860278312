import React from 'react';
import { SubjectRow } from '../../../SubjectRow/SubjectRow';
import { NetAssetsContainer } from '../net_assets/NetAssetsContainer';
import { SubjectProps, Subjects } from '../../types';

interface InterestBearingDebtProps extends SubjectProps {
  subject: Subjects;
  phase: string;
}

export const InterestBearingDebt: React.FC<InterestBearingDebtProps> = (
  props: InterestBearingDebtProps,
) => {
  const { subject, getValues, setValue, phase, control } = props;
  return (
    <>
      {phase == '1' ? (
        <>
          <div className="flex col-span-12 grid grid-cols-12 py-5">
            <div className="col-span-6 text-left" />
            <div className="col-span-6" />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="flex col-span-12 grid grid-cols-12 py-2.5">
        <div className="col-span-6 text-left pl-2 pt-1">有利子負債</div>
        <div className="col-span-6 pr-3" />
      </div>
      <SubjectRow
        label="短期借入金"
        value={'short_term_debt'}
        subject={'subject.short_term_debt'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「短期借入金」、「関係会社短期借入金」、「一年内返済予定の長期借入金」等、借入金と名の付く科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="長期借入金"
        value={'subject.long_term_debt'}
        subject={'subject.long_term_debt'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「長期借入金」、「関係会社長期借入金」、「役員借入金」等、借入金と名の付く科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="社債"
        value={'subject.bonds'}
        subject={'subject.bonds'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「社債」、「転換社債」、「一年内償還予定の社債」等、社債と名の付く科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="リース債務"
        value={'subject.lease_obligation'}
        subject={'subject.lease_obligation'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「リース債務」、「短期リース債務」、「長期リース債務」等、リース債務と名の付く科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="その他の有利子負債"
        value={'subject.other_interest_bearing_debt'}
        subject={'subject.other_interest_bearing_debt'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「退職給付引当金」、「役員退職慰労引当金」、「資産除去債務」、「未払配当金」、「長期未払金」等、実質的にデットの性質を有する科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="負債の部合計"
        value={'subject.liabilities'}
        subject={'subject.liabilities'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「負債の部合計」科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
      />
      {phase == '1' ? (
        <NetAssetsContainer
          control={control}
          getValues={getValues}
          setValue={setValue}
          subject={subject}
        />
      ) : (
        <></>
      )}
    </>
  );
};
