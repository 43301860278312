import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

export const ColorPicker: React.FC<{
  color: string;
  onColorChange: (color: string) => void;
  name: string;
  label: string;
}> = ({ color, onColorChange, label, name }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="col-span-1">
      <div
        onClick={() => setIsOpen(true)}
        style={{ backgroundColor: color }}
        className="cursor-pointer border border-8 border-gray-100 hover:border-blue-600 w-12 rounded-full py-6 rounded-sm"
      ></div>
      {color && (
        <input
          className="hidden"
          type="text"
          name={name}
          value={color}
          readOnly
        />
      )}
      <div className="mt-3 ml-1 text-gray-700 text-xs font-bold">{label}</div>
      <div className="mt-2 text-gray-400 text-xs">{color}</div>
      {isOpen && (
        <div className="z-10 absolute">
          <div onClick={() => setIsOpen(false)} className="inset-0 fixed" />
          <ChromePicker
            color={color}
            onChange={(c) => onColorChange(c.hex)}
            disableAlpha
          />
        </div>
      )}
    </div>
  );
};
