import React from 'react';
import { SubjectInput } from '../concern/SubjectInput';

export const CorporateTaxReturn: React.FC = (props) => {
  // @ts-ignore
  const { getValues, setValue, phase, Controller, control } = props;

  return (
    <>
      <div className="grid grid-cols-12 divide-y">
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表一（一）
          </div>
          <div className="col-span-5 text-left pt-1">所得金額又は欠損金額</div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[0].value'}
                  control={control}
                />
              )}
              name={'subject[0].code'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表二
          </div>
          <div className="col-span-5 text-left pt-1">
            期末現在の発行済株式の総数又は出資の総額
          </div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[1].value'}
                  control={control}
                />
              )}
              name={'subject[1].code'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表四
          </div>
          <div className="col-span-5 text-left pt-1">当期利益又は当期欠損</div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[2].value'}
                  control={control}
                />
              )}
              name={'subject[2].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表四
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">配当</div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[3].value'}
                  control={control}
                />
              )}
              name={'subject[3].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表五（一）
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">
            利益積立金額の計算に関する明細書 差引合計額
          </div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[4].value'}
                  control={control}
                />
              )}
              name={'subject[4].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表五（一）
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">
            資本金等の額の計算に関する明細書 差引合計額
          </div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[5].value'}
                  control={control}
                />
              )}
              name={'subject[5].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表六（一）
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">
            剰余金の配当、利益の配当、剰余金の分配及び金銭の分配
            収入金額について課される所得税額
          </div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[6].value'}
                  control={control}
                />
              )}
              name={'subject[6].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表七（一）
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">当期控除額 計</div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[7].value'}
                  control={control}
                />
              )}
              name={'subject[7].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-3 text-left pl-2 pt-1 font-semibold">
            別表八（一）
          </div>
          <div className="col-span-5 text-left pr-5 pt-1">
            受取配当等の益金不算入額
          </div>
          <div className="col-span-4 pr-3">
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={0}
                  setValue={setValue}
                  subject={'subject[8].value'}
                  control={control}
                />
              )}
              name={'subject[8].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
      </div>
    </>
  );
};
