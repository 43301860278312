import React from 'react';
import { ModalProvider } from 'react-modal-hook';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { useCompanyMedicalReportDetails } from './hooks';
import { CompanyMedicalReportSubjectsStep } from './CompanyMedicalReportSubjectsStep';
import { CompanyMedicalReportIndustriesStep } from './CompanyMedicalReportIndustriesStep';
import { CompanyMedicalReportValuationsStep } from './CompanyMedicalReportValuationsStep';
import { CompanyMedicalReportPptxStep } from './CompanyMedicalReportPptxStep';
import { CompanyMedicalReportDetailsProps } from './types';

export const CompanyMedicalReportDetails: React.FC<
  CompanyMedicalReportDetailsProps
> = (props) => {
  const { companyMedicalReport } = props;
  const { modalMessage, modalCheck } = useCompanyMedicalReportDetails();
  return (
    <>
      <ModalProvider>
        <div
          id="company_medical_report_details"
          className="shadow pl-24 pr-10 pt-12 pb-56 bg-white"
        >
          <h1 className="pb-7 text-2xl text-gray-500 font-bold">
            {companyMedicalReport?.evaluation_company_name}
          </h1>
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              <CompanyMedicalReportSubjectsStep {...props} />
              <CompanyMedicalReportIndustriesStep {...props} />
              <CompanyMedicalReportValuationsStep {...props} />
              <CompanyMedicalReportPptxStep {...props} />
            </ul>
          </div>
        </div>
        <MessageModal
          setModal={modalCheck}
          modalType="message"
          modalDom="#company_medical_report_details"
          modalMessage={modalMessage}
        />
      </ModalProvider>
    </>
  );
};
