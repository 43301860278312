import React from 'react';
import { useReports } from './hooks';
import { ModalProvider } from 'react-modal-hook';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { reportIndexProps } from './types';

export const Reports: React.FC<reportIndexProps> = (props) => {
  const { valuation_reports } = props;
  const {
    deleteReportId,
    modalCheck,
    setModalCheck,
    showDeleteModal,
    showUpdatedTime,
    showFinancialDate,
  } = useReports();
  return (
    <ModalProvider>
      {valuation_reports?.map((valuation_report) => (
        <tr key={valuation_report.id}>
          <td className="pl-3 py-2 whitespace-nowrap text-sm font-medium text-blue-700 hover:text-blue-800">
            <a href={`/reports/${valuation_report.id}`}>
              {valuation_report.evaluation_company_name}
            </a>
          </td>
          <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500">
            {valuation_report.evaluation_company_financial_date !== null
              ? showFinancialDate(
                  valuation_report.evaluation_company_financial_date,
                )
              : null}
          </td>
          <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500">
            {valuation_report.updated_at !== null
              ? showUpdatedTime(valuation_report.updated_at)
              : null}
          </td>
          <td className="cursor-pointer hover:text-red-700 py-2 whitespace-nowrap text-left text-sm font-medium text-red-500">
            <div className="hidden_box relative inline-block text-left pt-1">
              <label
                htmlFor={`label_${valuation_report.id}`}
                className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                id="menu-button"
              >
                <span className="sr-only">Open options</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M4 12a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM16 12a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </label>
              <input
                type="checkbox"
                value="true"
                name={`label_${valuation_report.id}`}
                id={`label_${valuation_report.id}`}
              />
              <div
                className="hidden_show p-12 origin-top-right absolute left-4 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <a
                  href={`/reports/${valuation_report.id}`}
                  className="cursor-pointer text-gray-700 hover:text-blue-700 block px-4 py-2 text-sm"
                >
                  会社を評価する
                </a>
                <a
                  href={`/reports/${valuation_report.id}/edit`}
                  className="cursor-pointer text-gray-700 hover:text-blue-700 block px-4 py-2 text-sm"
                >
                  会社情報を編集する
                </a>
                <div
                  onClick={() => showDeleteModal(valuation_report.id)}
                  className="cursor-pointer text-gray-700 hover:text-red-700 block px-4 py-2 text-sm"
                >
                  会社を削除する
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
      {deleteReportId !== 0 ? (
        <MessageModal
          setModal={modalCheck}
          modalType="confirm"
          modalDom="#report_index_page"
          url={`/reports/${deleteReportId}`}
          reqAction={'delete'}
          setModalCheck={setModalCheck}
        />
      ) : (
        <></>
      )}
    </ModalProvider>
  );
};
