import React, { useCallback, useEffect, useState } from 'react';
import { ModalProvider } from 'react-modal-hook';

import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { CalculationProps, ValuationCalculation } from '../types/props';
import { MoneyFormatter } from '../../../utils/common';

export const InheritanceValuationCalculation: React.FC<
  CalculationProps & { corporateTaxFileIds: number[]; calculationIndex: number }
> = (
  props: CalculationProps & {
    corporateTaxFileIds: number[];
    calculationIndex: number;
  },
) => {
  const { calculation, calculationIndex, corporateTaxFileIds } = props;

  const [calculationResults, setCalculationResults] = useState<string | null>(
    null,
  );
  const [canUseOcrResult, setCanUseOcrResult] = useState<boolean>(false);
  const [canUseManualSubjectInputResults, setUseManualSubjectInputResults] =
    useState<boolean>(false);
  const [deleteValuationCalculation, setDeleteValuationCalculation] =
    useState<ValuationCalculation>(calculation);
  const [modalCheck, setModalCheck] = useState<boolean>(false);

  // 会社の規模
  const [companySize, setCompanySize] = useState<string>('');
  // 比準要素数1の会社
  const [ratioSemiElements, setRatioSemiElements] = useState<boolean>(false);
  // 比準要素数0の会社
  const [ratioSemiElementsZero, setRatioSemiElementsZero] =
    useState<boolean>(false);
  // 土地保有特定会社
  const [companySizeOwned, setCompanySizeOwned] = useState<boolean>(false);
  // 開業前の会社
  const [beforeOpening, setBeforeOpening] = useState<boolean>(false);
  // 休業中の会社
  const [closed, setClosed] = useState<boolean>(false);
  // 株式等保有特定会社
  const [stockOwned, setStockOwned] = useState<boolean>(false);
  // 開業後3年以上経過している
  const [yearProgress, setYearProgress] = useState<boolean>(false);
  // 清算中の会社
  const [underLiquidation, setUnderLiquidation] = useState<boolean>(true);
  // 原則的評価方式等 | 配当還元方式
  const [calcMethodAfter, setCalcMethodAfter] =
    useState<string>('原則的評価方式等');
  // 同族株主等 | 同族株主等以外の株主
  // const [calcMethod, setCalcMethod] = useState<string>('');
  // 納税義務者が役員である
  // const [isOfficer, setIsOfficer] = useState<boolean>(false);
  // 納税義務者が中心的な同族株主である
  // const [isMainShareholders, setIsMainShareholders] = useState<boolean>(false);
  // 納税義務者以外に中心的な同族株主（又は株主）がいる
  // const [isOtherShareholders, setIsOtherShareholders] = useState<boolean>(false);
  // 一株当たりの株価
  const [stockPrice, setStockPrice] = useState<string>('0');
  // 類似業種比準価格
  const [
    modificationRatioSemiMarketValue2,
    setModificationRatioSemiMarketValue2,
  ] = useState<number>(0);
  // 純資産価格方式
  const [ratioSemiElementsNetAsset1, setRatioSemiElementsNetAsset1] =
    useState<number>(0);
  // 配当還元方式
  const [stockPricePayBack, setStockPricePayBack] = useState<number>(0);

  /**
   * 一株当たりの株価を設定
   * @param result {ValuationCalculation}
   * @param set_stock_price {callback}
   * @returns {void}
   */
  const checkValuationResults = useCallback((result, set_stock_price: void) => {
    if (result && result.result && result.result.result_selected !== null) {
      set_stock_price;
    }
  }, []);

  /**
   * 算定フォームに遷移
   * @param url {string}
   * @returns {void}
   */
  const getValuationForm = useCallback((url: string): void => {
    location.href = url;
  }, []);

  /**
   * 削除モーダルを表示
   * @param delete_calculation {ValuationCalculation}
   * @returns {void}
   */
  const setDeleteModal = useCallback(
    (delete_calculation: ValuationCalculation): void => {
      setDeleteValuationCalculation(delete_calculation);
      setModalCheck(!modalCheck);
    },
    [modalCheck],
  );

  /**
   * 算定結果から一株当たりの価格を設定
   * @param calculation_results {ValuationCalculation}
   * @returns {void}
   */
  const checkStockPrice = useCallback(
    (calculation_results): string => {
      if (stockPricePayBack !== null) {
        return Math.round(stockPricePayBack).toLocaleString();
      } else {
        return calculation_results['result']['stock_price'];
      }
    },
    [stockPricePayBack],
  );

  // const checkCalcMethod = useCallback((): string => {
  //   if (calcMethod == calcMethodAfter) { return calcMethod }
  //   if(!isOfficer && !isMainShareholders && isOtherShareholders) {
  //     return calcMethod;
  //   } else {
  //     return calcMethodAfter;
  //   }
  // }, [calcMethod, calcMethodAfter, isMainShareholders, isOfficer, isOtherShareholders])

  /**
   * @returns {string} 特定の対象会社の会社分類名
   */
  const checkCompanyTypes = useCallback((): string => {
    const companyTypes = [
      { name: '比準要素数1の会社', company_type: ratioSemiElements },
      { name: '比準要素数0の会社', company_type: ratioSemiElementsZero },
      { name: '土地保有特定会社', company_type: companySizeOwned },
      { name: '開業前の会社', company_type: beforeOpening },
      { name: '休業中の会社', company_type: closed },
      { name: '株式等保有特定会社', company_type: stockOwned },
      { name: '開業後3年未満の会社', company_type: yearProgress },
      { name: '清算中の会社', company_type: underLiquidation },
    ];
    const companyType: { name: string; company_type: boolean }[] =
      companyTypes.filter((company_type) => {
        return company_type.company_type === true;
      });
    return companyType[0].name;
  }, [
    beforeOpening,
    closed,
    companySizeOwned,
    ratioSemiElements,
    ratioSemiElementsZero,
    stockOwned,
    underLiquidation,
    yearProgress,
  ]);

  /**
   * @returns {string} 算定結果見出し
   */
  const judgeResults = useCallback((): string => {
    const companyFlag: boolean =
      !ratioSemiElements &&
      !companySizeOwned &&
      !beforeOpening &&
      !closed &&
      !stockOwned &&
      !yearProgress &&
      !underLiquidation;
    if (companyFlag) {
      return `一般の評価会社の株式:${calcMethodAfter} （会社規模：${companySize}）`;
    } else {
      return `特定の評価会社の株式: ${checkCompanyTypes()}`;
    }
  }, [
    beforeOpening,
    calcMethodAfter,
    checkCompanyTypes,
    closed,
    companySize,
    companySizeOwned,
    ratioSemiElements,
    stockOwned,
    underLiquidation,
    yearProgress,
  ]);

  useEffect(() => {
    if (0 < corporateTaxFileIds.length) {
      setCanUseOcrResult(true);
    }
    if (calculation.results !== null) {
      const results = JSON.parse(calculation.results);
      setCalculationResults(results);
      setCalcMethodAfter(results['document']['page_1']['calc_method_after']);
      // setCalcMethod(results['document']['page_1']['calc_method']);
      // setIsOfficer(results['document']['page_1']['is_officer']);
      // setIsMainShareholders(results['document']['page_1']['is_main_shareholders']);
      // setIsOtherShareholders(results['document']['page_1']['is_other_shareholders']);
      setCompanySize(results['result']['company_size']);
      setRatioSemiElements(
        results['document']['page_3']['ratio_semi_elements'],
      );
      setRatioSemiElementsZero(
        results['document']['page_3']['ratio_semi_elements_0'],
      );
      setCompanySizeOwned(results['document']['page_3']['company_size_owned']);
      setBeforeOpening(results['document']['page_3']['before_opening']);
      setClosed(results['document']['page_3']['closed']);
      setStockOwned(results['document']['page_3']['stock_owned']);
      setYearProgress(results['document']['page_3']['year_progress']);
      setUnderLiquidation(results['document']['page_3']['under_liquidation']);
      setModificationRatioSemiMarketValue2(
        results['document']['page_4']['modification_ratio_semi_market_value_2'],
      );
      setRatioSemiElementsNetAsset1(
        results['document']['page_3']['ratio_semi_elements_net_asset_1'],
      );
      setStockPricePayBack(
        results['document']['page_7']['stock_price_pay_back'],
      );
      checkValuationResults(results, setStockPrice(checkStockPrice(results)));
    }
  }, [
    calculation.results,
    checkStockPrice,
    checkValuationResults,
    corporateTaxFileIds.length,
  ]);

  {
    /* 計算ボタン  */
  }
  const setValuationButton = useCallback(
    (valuationType, useOcrResult) => {
      switch (true) {
        case stockPrice === '0':
          return (
            <div className="grid grid-cols-2 gap-1 p-3">
              <div className="text-center pb-6 col-span-2">
                <div
                  onClick={
                    useOcrResult
                      ? () =>
                          getValuationForm(
                            `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/calculation?ocr=true&calculation_id=${calculation.id}`,
                          )
                      : () =>
                          getValuationForm(
                            `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/calculation?ocr=false&calculation_id=${calculation.id}`,
                          )
                  }
                  className="cursor-pointer inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  再計算を行う
                </div>
              </div>
            </div>
          );
        default:
          return (
            <>
              {canUseOcrResult || canUseManualSubjectInputResults ? (
                <div className="grid grid-cols-2 pb-4 pt-3">
                  <div className="text-center pb-1 col-span-1 pl-4">
                    <div
                      onClick={() =>
                        getValuationForm(
                          `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/recalculation?ocr=true&calculation_id=${calculation.id}`,
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      税務情報から再計算
                    </div>
                  </div>
                  <div className="text-center pb-1 col-span-1 pr-1">
                    <div
                      onClick={() =>
                        getValuationForm(
                          `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/recalculation?ocr=false&calculation_id=${calculation.id}`,
                        )
                      }
                      className="cursor-pointer outline-2 inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-green-600 border-green-600 hover:bg-green-600 hover:text-white"
                    >
                      手動で再計算
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-2 pb-1 pt-3">
                  <div className="text-center pb-1 col-span-2">
                    <div
                      onClick={() =>
                        getValuationForm(
                          `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/recalculation?ocr=false&calculation_id=${calculation.id}`,
                        )
                      }
                      className="cursor-pointer inline-flex items-center px-12 py-1.5 border shadow-sm text-sm font-medium rounded text-green-600 hover:text-white bg-white-600 hover:bg-green-500 border-green-500"
                    >
                      手動で再計算する
                    </div>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-12">
                <div className="col-span-12 text-center pb-2">
                  <div
                    onClick={() =>
                      getValuationForm(
                        `/inheritances/${calculation.inheritance_valuation_id}/formula/${valuationType}/result?calculation_id=${calculation.id}`,
                      )
                    }
                    className="underline cursor-pointer inline-flex items-center py-2 text-sm leading-4 font-medium text-blue-500"
                  >
                    詳しく見る
                  </div>
                </div>
              </div>
            </>
          );
      }
    },
    [
      stockPrice,
      canUseOcrResult,
      canUseManualSubjectInputResults,
      getValuationForm,
      calculation,
    ],
  );

  return (
    <ModalProvider>
      <div className="pt-5">
        <div className="bg-white rounded-lg border">
          {/* 算定header */}
          <div className="w-full flex items-center justify-between pl-6 pr-6 pt-2">
            <div className="flex-1 truncate">
              <div className="flex justify-between items-center space-x-3">
                <h3
                  className={
                    calculation.results !== null
                      ? 'text-gray-600 text-base font-semibold truncate pb-3'
                      : 'text-gray-400 text-base font-semibold truncate pb-3'
                  }
                >
                  <span className="-mt-3 mr-3 text-xs bg-gray-300 text-white text-xs px-1.5 rounded-full inline-flex items-center">
                    {calculationIndex + 1}
                  </span>
                  相続税法上の評価
                </h3>
                <div
                  onClick={() => setDeleteModal(calculation)}
                  className="cursor-pointer bg-white text-red-500 -mt-2 hover:bg-red-400 border border-red-400 text-red-400 hover:text-white text-xs px-2 py-0.5 rounded-sm inline-flex items-center"
                >
                  削除
                </div>
              </div>
            </div>
          </div>

          {/* 算定結果TOP */}
          {calculation.results !== null ? (
            <div className="pl-6 pr-6 pb-2 text-blue-600 text-lg font-bold text-center">
              1株当たりの価格 {MoneyFormatter(Number(stockPrice))}
            </div>
          ) : (
            <></>
          )}

          {/* 算定結果詳細 */}
          {calculationResults !== null ? (
            <>
              <p className="pl-6 pr-6 text-gray-500 text-sm break-words">
                {judgeResults()}
              </p>
              <p className="pl-6 pr-6 text-gray-500 text-sm break-words">
                類似業種比準価格:{' '}
                {Math.round(modificationRatioSemiMarketValue2).toLocaleString()}
                円
              </p>
              <p className="pl-6 pr-6 text-gray-500 text-sm break-words">
                純資産価格方式:{' '}
                {Math.round(ratioSemiElementsNetAsset1).toLocaleString()}円
              </p>
              <p className="pl-6 pr-6 text-gray-500 text-sm break-words">
                配当還元方式: {Math.round(stockPricePayBack).toLocaleString()}円
              </p>
            </>
          ) : (
            <p className="pl-6 pr-6 pb-3 text-gray-500 text-sm break-words">
              会社規模、株主の状況に応じて相続税法上の類似比準方式、純資産方式又は特例評価の配当還元方式により株価を簡易的に算出して相続税の観点から診断を行います。
            </p>
          )}

          {/* 計算ボタン  */}
          {calculation.results !== null ? (
            setValuationButton('inheritance', canUseOcrResult)
          ) : (
            <></>
          )}

          {/* 計算済みの場合の計算ボタン */}
          {calculation.results !== null ? (
            <></>
          ) : (
            <>
              {canUseOcrResult || canUseManualSubjectInputResults ? (
                <div className="grid grid-cols-1 pb-3 text-center">
                  <div className="col-span-1">
                    <div
                      onClick={() =>
                        getValuationForm(
                          `/inheritances/${calculation.inheritance_valuation_id}/formula/inheritance/calculation?ocr=true&calculation_id=${calculation.id}&calculation_count=${calculationIndex}`,
                        )
                      }
                      className="cursor-pointer text-center inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      税務情報結果から計算する
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="grid grid-cols-1 pb-20 pt-2 text-center">
                <div className="col-span-1">
                  <div
                    onClick={() =>
                      getValuationForm(
                        `/inheritances/${calculation.inheritance_valuation_id}/formula/inheritance/calculation?ocr=false&calculation_id=${calculation.id}`,
                      )
                    }
                    className="cursor-pointer text-center inline-flex items-center px-14 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-400 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    手動計算する
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {deleteValuationCalculation !== null ||
      typeof deleteValuationCalculation !== 'undefined' ? (
        <MessageModal
          setModal={modalCheck}
          modalType="confirm"
          modalDom="#inheritance_details_page"
          url={`/inheritances/${deleteValuationCalculation.inheritance_valuation_id}/delete_calculation?delete_calculation_id=${deleteValuationCalculation.id}`}
          reqAction={'delete'}
          setModalCheck={setModalCheck}
        />
      ) : (
        <></>
      )}
    </ModalProvider>
  );
};
