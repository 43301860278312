import React, { useCallback, useEffect, useMemo } from 'react';
import { useCompanyMedicalReportValuationStep } from './hooks';
import { CompanyMedicalReportValuation } from './CompanyMedicalReportValuation';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import {
  CompanyMedicalReportValuationsStepProps,
  CompanyMedicalReportvaluationProps,
} from './types';

export const CompanyMedicalReportValuationsStep: React.FC<
  CompanyMedicalReportValuationsStepProps
> = (props) => {
  const {
    companyMedicalReport,
    valuations,
    calculatedValuationsCount,
    allValuationsCalculated,
    batchValuationEnabled,
  } = props;
  const { reportGenPptxStepStyle, setReportGenPptxStep, postBatchValuation } =
    useCompanyMedicalReportValuationStep();
  const token = useMemo(
    () =>
      document
        ?.getElementById('batch_valuations')
        ?.parentElement?.getElementsByTagName('input')[0].value,
    [],
  );
  const handleBatchValuation = useCallback(() => {
    if (!batchValuationEnabled) return;
    postBatchValuation(token, companyMedicalReport.id);
  }, [
    batchValuationEnabled,
    postBatchValuation,
    token,
    companyMedicalReport.id,
  ]);

  useEffect(() => {
    if (!allValuationsCalculated) return;
    setReportGenPptxStep({
      checkedButton:
        'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center ring-8 ring-white',
      flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative pb-8">
      <span
        className={reportGenPptxStepStyle.flowLineStyle}
        aria-hidden="true"
      ></span>
      <div className="relative flex space-x-3">
        <span className={reportGenPptxStepStyle.checkedButton}>
          <svg
            className="h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <div className="pl-4 w-full">
          <div>
            <div className="flex">
              <p className="text-lg text-green-400 font-semibold">STEP3</p>
              <span className="ml-3 mr-2 mt-2">
                <img className="h-3 w-3" src={TimeIcon} />
              </span>
              <p className="text-xs text-gray-400 mt-1.5">
                所要時間 約<span className="font-bold">1</span>分
              </p>
            </div>
            <div className="flex">
              <p className="text-xl text-gray-700 font-bold">株式価値の算定</p>
            </div>
            <p className="pt-1.5 text-sm text-gray-500">
              会計・税務の視点からあなたの会社の価値を算定
            </p>
            <div
              onClick={handleBatchValuation}
              className={`mt-3 inline-flex items-center px-14 py-2 text-sm font-medium rounded text-white ${
                batchValuationEnabled
                  ? 'bg-green-500 hover:bg-green-600 cursor-pointer'
                  : 'bg-gray-400'
              }`}
            >
              一括算定を実行する
            </div>
          </div>
          <div className="pt-2">
            <div className="grid grid-cols-12 pt-3 gap-3">
              {calculatedValuationsCount > 0 ? (
                valuations?.map(
                  (valuation: CompanyMedicalReportvaluationProps, index) => (
                    <div key={index} className="col-span-3 min-w-full">
                      <CompanyMedicalReportValuation
                        valuation={valuation}
                        {...props}
                      />
                    </div>
                  ),
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
