import { useCallback, useRef, useState } from 'react';
import { getFileStatus, getFileSummary, getPdf, postFileSummary } from './api';
import {
  ocrRequestProps,
  requestDataProps,
  useOcrResultRowsProps,
  useOcrResultsProps,
} from './types';

export const useOcrResults = (props: useOcrResultsProps) => {
  const {
    reportId,
    fileId,
    setValue,
    pageIds,
    getValues,
    token,
  } = props;
  const startTime = Date.now();
  const [loadingMessage, setloadingMessage] =
    useState<string>('画像を解析します');
  const [subject, setSubject] = useState([] as unknown);
  const [visibleFlg, setVisibleFlg] = useState<boolean>(false);
  // @ts-ignore
  const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
  const refTimerId = useRef(timerId);
  refTimerId.current = timerId;

  const visibleDownloadButton = useCallback((): void => {
    const proressDoms: HTMLCollectionOf<Element> =
      document.getElementsByClassName('analyze_progress');
    const vFlg = !Array.from(proressDoms).some(
      (proressDom) => proressDom.textContent === 'ただいま解析中...',
    );
    if (vFlg) {
      setVisibleFlg(vFlg);
    }
  }, []);

  const getSummary = useCallback(async (): Promise<void> => {
    const requestConfig: ocrRequestProps = {
      method: 'get',
      url: `/reports/${reportId}/ocr/analysis_fs/${fileId}/file_summary.json`,
    };
    await getFileSummary(
      requestConfig,
      // @ts-ignore
      setValue,
      setSubject,
      visibleDownloadButton,
    );
  }, [fileId, reportId, setValue, visibleDownloadButton]);

  const getStatus = useCallback(async (): Promise<void> => {
    const requestConfig: ocrRequestProps = {
      method: 'get',
      url: `/reports/${reportId}/ocr/analysis_fs/${fileId}/file_status`,
    };
    await getFileStatus(requestConfig, `/reports/${reportId}`, {
      _pageIds: pageIds,
      _refTimerId: refTimerId,
      _getSummary: getSummary,
      _setloadingMessage: setloadingMessage,
      startTime: startTime,
    });
  }, [fileId, getSummary, pageIds, reportId, startTime]);

  const updateFileSummary = useCallback((): void => {
    // @ts-ignore
    const requestData: requestDataProps = { subject: getValues('subject') };
    const requestConfig: ocrRequestProps = {
      method: 'post',
      data: requestData,
      url: `/reports/${reportId}/ocr/analysis_fs/${fileId}/update_file_summary.json`,
      headers: {
        // @ts-ignore
        'X-CSRF-Token': token,
      },
    };
    postFileSummary(requestConfig, `/reports/${reportId}`);
  }, [fileId, getValues, reportId, token]);

  const onSubmit = (): void => {
    updateFileSummary();
  };

  return {
    refTimerId,
    subject,
    visibleFlg,
    startTime,
    loadingMessage,
    setTimerId,
    setloadingMessage,
    setVisibleFlg,
    setSubject,
    visibleDownloadButton,
    getSummary,
    getStatus,
    updateFileSummary,
    onSubmit,
  };
};

export const useOcrResultRows = (props: useOcrResultRowsProps) => {
  const { reportId, pageId } = props;
  const [image, setImage] = useState<string>('');
  const getPdfRequest = useCallback(() => {
    return getPdf({ reportId, pageId, setImage });
  }, [setImage, pageId, reportId]);
  return {
    image,
    setImage,
    getPdfRequest,
  };
};
