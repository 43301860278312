import React, { useEffect } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { useReportCalculation } from './hooks';
import { ReportCalculationProps } from './types';
import { ReportCalculationButton } from './ReportCalculationButton';

export const ReportCalculation: React.FC<ReportCalculationProps> = (props) => {
  const {
    valuationName,
    valuationType,
    report,
    calculation,
    isCalculated,
    hasMultipleCalculations,
    canUseOcrResult,
    canUseManualSubjectInputResults,
    checkValuationResults,
    icon,
    getValuationForm,
    checkValuation,
  } = props;
  const {
    valuation,
    deleteValuationCalculation,
    nonLiquidityDiscount,
    nameStyle,
    modalCheck,
    openDeleteModal,
    setValuation,
    setNonLiquidityDiscount,
    setModalCheck,
  } = useReportCalculation(isCalculated, calculation);

  useEffect(() => {
    if (calculation.result) {
      const persedResults = JSON.parse(calculation.result);
      // @ts-ignore
      checkValuationResults(
        persedResults,
        setValuation(parseFloat(persedResults.result.valuation)),
        setNonLiquidityDiscount(
          persedResults.breakdown[0].non_liquidity_discount,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalProvider>
      <div className="col-span-1 bg-white rounded border border-gray-300">
        <div className="flex items-center justify-between pl-6 pr-6 pt-2 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <img className="h-5 w-5" src={icon} />
              <h3 className={nameStyle}>{valuationName}</h3>
              {hasMultipleCalculations ? (
                <div
                  onClick={() => openDeleteModal(calculation)}
                  className="cursor-pointer bg-white text-red-500 justify-right hover:bg-red-600 border border-red-600 text-red-400 hover:text-white text-xs px-2 py-0.5 rounded-sm inline-flex items-center"
                >
                  削除
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {calculation.result ? (
          checkValuation(valuation, nonLiquidityDiscount)
        ) : (
          <p className="pl-6 pr-6 pb-3 pt-3 text-gray-300 text-2xl text-center">
            未評価
          </p>
        )}
        {calculation.result ? (
          <ReportCalculationButton
            reportId={report.id}
            canUseManualSubjectInputResults={canUseManualSubjectInputResults}
            valuation={valuation}
            valuationType={valuationType}
            canUseOcrResult={canUseOcrResult}
            getValuationForm={getValuationForm}
            calculation={calculation}
          />
        ) : (
          <></>
        )}
        {isCalculated ? (
          <></>
        ) : (
          <>
            {canUseOcrResult || canUseManualSubjectInputResults ? (
              <div className="grid grid-cols-1 pb-3 text-center">
                <div className="col-span-1">
                  <div
                    onClick={() =>
                      getValuationForm(
                        `/reports/${report.id}/formula/${valuationType}/${calculation.id}?ocr=true`,
                      )
                    }
                    className="cursor-pointer text-center inline-flex items-center px-6 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    決算書結果から計算する
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 pb-6 pt-2 text-center">
                <div className="col-span-1">
                  <div
                    onClick={() =>
                      getValuationForm(
                        `/reports/${report.id}/formula/${valuationType}/${calculation.id}?ocr=false`,
                      )
                    }
                    className="cursor-pointer text-center inline-flex items-center px-14 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    計算する
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {deleteValuationCalculation !== null ||
      typeof deleteValuationCalculation !== 'undefined' ? (
        <MessageModal
          setModal={modalCheck}
          modalType="confirm"
          modalDom="#report_details_page"
          url={`/reports/${report.id}/valuation_report_calculations/${deleteValuationCalculation.id}`}
          reqAction={'delete'}
          setModalCheck={setModalCheck}
        />
      ) : (
        <></>
      )}
    </ModalProvider>
  );
};
