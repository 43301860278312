import React from 'react';
import { reportCalculationButtonProps } from './types';

export const ReportCalculationButton: React.FC<reportCalculationButtonProps> = (
  props,
) => {
  const {
    reportId,
    valuation,
    calculation,
    valuationType,
    canUseOcrResult,
    canUseManualSubjectInputResults,
    getValuationForm,
  } = props;

  switch (valuation) {
    case 0:
      return (
        <div className="grid grid-cols-2 gap-1 p-3">
          <div className="text-center pb-6 col-span-2">
            <div
              onClick={
                canUseOcrResult
                  ? () =>
                      getValuationForm(
                        `/reports/${reportId}/formula/${valuationType}/${calculation.id}?ocr=true`,
                      )
                  : () =>
                      getValuationForm(
                        `/reports/${reportId}/formula/${valuationType}/${calculation.id}?ocr=false`,
                      )
              }
              className="cursor-pointer inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              再計算を行う
            </div>
          </div>
        </div>
      );
    default:
      return (
        <>
          {canUseOcrResult || canUseManualSubjectInputResults ? (
            <div className="grid grid-cols-1 pt-3">
              <div className="text-center pb-1 col-span-1 pl-4">
                <div
                  onClick={() =>
                    getValuationForm(
                      `/reports/${reportId}/formula/${valuationType}/${calculation.id}?ocr=true`,
                    )
                  }
                  className="cursor-pointer inline-flex items-center px-12 py-1 border shadow-sm text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  決算書から再計算
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 pt-3">
              <div className="text-center pb-1 col-span-2">
                <div
                  onClick={() =>
                    getValuationForm(
                      `/reports/${reportId}/formula/${valuationType}/${calculation.id}?ocr=false`,
                    )
                  }
                  className="cursor-pointer inline-flex items-center px-12 py-1 border shadow-sm text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  再計算する
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-1">
            <div className="col-span-1 text-center">
              <div
                onClick={() =>
                  getValuationForm(
                    `/reports/${reportId}/formula/${valuationType}/${calculation.id}/result`,
                  )
                }
                className="underline cursor-pointer inline-flex items-center px-28 py-2 text-sm leading-4 font-medium text-blue-600"
              >
                詳しく見る
              </div>
            </div>
          </div>
        </>
      );
  }
};
