import React from 'react';

import { InheritanceValuationSubjects } from './InheritanceValuationSubjects';
import { InheritanceValuationSimilarities } from './InheritanceValuationSimilarities';
import { InheritanceValuationCalculations } from './InheritanceValuationCalculations';
import { InheritanceValuationOutput } from './InheritanceValuationOutput';
import {
  InheritanceValuationProps,
  ValuationCalculation,
} from '../types/props';

export const InheritanceValuation: React.FC<
  InheritanceValuationProps & {
    valuationCalculations: ValuationCalculation[];
    calculatedValuation: ValuationCalculation[];
    hasCalculatedValuation: boolean;
    corporateTaxFileIds: number[];
  }
> = (
  props: InheritanceValuationProps & {
    valuationCalculations: ValuationCalculation[];
    calculatedValuation: ValuationCalculation[];
    hasCalculatedValuation: boolean;
    corporateTaxFileIds: number[];
  },
) => {
  const {
    inheritanceValuation,
    valuationCalculations,
    calculatedValuation,
    hasCalculatedValuation,
    corporateTaxFileIds,
  } = props;

  return (
    <>
      <div className="shadow pl-24 pr-10 pt-12 pb-56 bg-white">
        <h1 className="pb-7 text-2xl text-gray-500 font-bold">
          {inheritanceValuation.evaluation_company_name}
        </h1>
        <ul>
          <InheritanceValuationSubjects
            inheritanceValuation={inheritanceValuation}
          />
          <InheritanceValuationSimilarities
            inheritanceValuation={inheritanceValuation}
          />
          <InheritanceValuationCalculations
            inheritanceValuation={inheritanceValuation}
            valuationCalculations={valuationCalculations}
            calculatedValuation={calculatedValuation}
            hasCalculatedValuation={hasCalculatedValuation}
            corporateTaxFileIds={corporateTaxFileIds}
          />
          <InheritanceValuationOutput
            inheritanceValuation={inheritanceValuation}
            hasCalculatedValuation={hasCalculatedValuation}
          />
        </ul>
      </div>
    </>
  );
};
