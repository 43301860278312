import React from 'react';
import { AccountsPayable } from './AccountsPayable';
import { InterestBearingDebt } from './InterestBearingDebt';
import { SubjectProps, Subjects } from '../../types';

interface LiabilitiesContainerProps extends SubjectProps {
  subject: Subjects;
  phase: string;
}

export const LiabilitiesContainer: React.FC<LiabilitiesContainerProps> = (
  props: LiabilitiesContainerProps,
) => {
  const { subject, getValues, setValue, phase, control } = props;
  return (
    <div className="grid grid-cols-12 divide-y text-sm">
      <div className="flex col-span-12 grid grid-cols-12 bg-gray-200 py-2.5">
        <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
          科目
        </div>
        <div className="col-span-6 text-center text-sm font-semibold text-gray-600">
          金額
        </div>
      </div>
      {phase == '1' ? (
        <>
          <AccountsPayable
            control={control}
            getValues={getValues}
            setValue={setValue}
          />
        </>
      ) : (
        <></>
      )}
      <InterestBearingDebt
        control={control}
        getValues={getValues}
        setValue={setValue}
        subject={subject}
        phase={phase}
      />
    </div>
  );
};
