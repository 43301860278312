import React from 'react';
import { NetAssetsContainer } from '../net_assets/NetAssetsContainer';
import { SubjectInput } from '../../../concern/SubjectInput';
import { SubjectProps, Subjects } from '../../../../types/props';

interface InterestBearingDebtProps extends SubjectProps {
  subject: Subjects;
}

// @ts-ignore
export const InterestBearingDebt: React.FC<InterestBearingDebtProps> =
  // @ts-ignore
  React.memo((props: InterestBearingDebtProps) => {
    const { getValues, setValue, Controller, control } = props;

    return (
      <>
        <div className="flex col-span-12 grid grid-cols-12 py-1">
          <div className="col-span-6 text-left pl-2 pt-32 font-semibold">
            負債の部合計
          </div>
          <div className="col-span-6 pr-3 pt-32">
            {/* @ts-ignore */}
            <Controller
              // @ts-ignore
              render={({ value }) => (
                <SubjectInput
                  className="border text-right w-full p-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-xs"
                  value={value}
                  defaultValue={Number(
                    getValues('subject.liabilities_section.subjects[0].value'),
                  )}
                  setValue={setValue}
                  subject={'subject.liabilities_section.subjects[0].value'}
                  control={control}
                />
              )}
              name={'subject.liabilities_section.subjects[0].value'}
              control={control}
              fixedDecimalScale
            />
          </div>
        </div>
        <NetAssetsContainer />
      </>
    );
  });
