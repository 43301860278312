import React from 'react';
import { ProfitAndLossStatement } from './ProfitAndLossStatement';
import { SubjectProps } from '../types';

interface PlContainerProps extends SubjectProps {
  phase: string;
}

export const PlContainer: React.FC<PlContainerProps> = (
  props: PlContainerProps,
) => {
  const { getValues, setValue, phase, control } = props;
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-9 pl-5">
      <div className="inline-block min-w-full py-1 align-middle md:px-8 lg:px-10">
        <div className="overflow-hidden ring-1 ring-black ring-opacity-10 md:rounded-xs">
          <div className="grid grid-cols-12 divide-x">
            <div className="col-span-12 text-center bg-gray-400 text-lg font-semibold text-white">
              損益計算書
            </div>
            <div className="col-span-6 divide-x py-2.5 bg-gray-200">
              <p className="text-center text-base font-semibold text-gray-600">
                科目
              </p>
            </div>
            <div className="col-span-6 py-2.5 bg-gray-200">
              <p className="text-center text-base font-semibold text-gray-600">
                金額
              </p>
            </div>
            <div className="col-span-12 text-sm">
              <ProfitAndLossStatement
                phase={phase}
                control={control}
                getValues={getValues}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
