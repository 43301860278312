import React, { useCallback } from 'react';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';
import { InheritanceValuationProps } from '../types/props';

export const InheritanceValuationSimilarities: React.FC<
  InheritanceValuationProps
> = (props: InheritanceValuationProps) => {
  const { inheritanceValuation } = props;

  const getSimilaritiesForm = useCallback(() => {
    location.href = `/inheritances/${inheritanceValuation.id}/similarities/new`;
  }, [inheritanceValuation.id]);

  const flowLineStyle = inheritanceValuation.similarities_status
    ? 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300'
    : 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200';

  const checkedButton = inheritanceValuation.similarities_status
    ? 'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center'
    : 'h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center';

  const stepStyle = inheritanceValuation.similarities_status
    ? 'text-lg text-green-400 font-semibold'
    : 'text-lg text-gray-400 font-semibold';

  const similaritiesNameStyle = inheritanceValuation.similarities_status
    ? 'pt-1.5 text-xl text-gray-700 font-bold'
    : 'pt-1.5 text-xl text-gray-400 font-bold';

  const actionButton = inheritanceValuation.similarities_status
    ? 'cursor-pointer inline-flex items-center px-12 py-2 text-sm font-medium rounded text-white bg-green-500'
    : 'cursor-pointer inline-flex items-center px-12 py-2 text-sm font-medium rounded text-white bg-gray-400 hover:bg-green-500';

  return (
    <li>
      <div className="relative pb-8">
        <span className={flowLineStyle} aria-hidden="true"></span>
        <div className="relative flex space-x-3">
          <div>
            <span className={checkedButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
            <div>
              <div className="flex">
                <p className={stepStyle}>STEP2</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">3</span>分
                </p>
              </div>
              <p className={similaritiesNameStyle}>類似業種の選定</p>
              <p className="pt-1.5 text-sm text-gray-500">
                類似業種比準価格の算定に使用する対象会社の業種を選定
              </p>
              <div className="pt-8">
                <div
                  onClick={() => getSimilaritiesForm()}
                  className={actionButton}
                >
                  類似業種を選択する
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
