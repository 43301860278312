import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import { notify } from '../company_medical_reports/company_medical_reports/hooks';
import DocumentIcon from '../../../../assets/images/icons/icon_document.png';
import FreeeIcon from '../../../../assets/images/icons/icon_freee.png';
import YayoiIcon from '../../../../assets/images/icons/icon_yayoi.png';
import BugyouIcon from '../../../../assets/images/icons/icon_bugyou.png';
import { SubjectsUploadFormProps } from './types';

const SERVICE_TYPES: Record<string, string> = {
  template: '決算書テンプレート',
  freee: 'freee',
  yayoi: '弥生会計',
  bugyou: '勘定奉行',
};

export const SubjectsUploadForm: React.FC<SubjectsUploadFormProps> = (
  props,
) => {
  const { company_medical_report_id } = props;
  const token = document?.querySelector?.(
    'meta[name="csrf-token"]',
    // @ts-ignore
  )?.content;
  const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [selectserviceType, setSelectserviceType] = useState<
    'yayoi' | 'bugyou' | 'freee' | 'money_forward' | 'template' | ''
  >('');

  const uploadFile = useCallback(() => {
    const formData = new FormData();
    formData.append('service_type', selectserviceType);
    uploadedFiles.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });

    axios
      .post(
        `/company_medical_reports/${company_medical_report_id}/subjects/upload_subjects`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': token,
          },
        },
      )
      .then((response) => {
        console.log(response);
        notify(response.data.status, response.data.message);
        location.href = `/company_medical_reports/${company_medical_report_id}`;
      })
      .catch((error) => {
        console.error(error);
        notify(error.data.status, error.data.message);
      });
  }, [selectserviceType, uploadedFiles, company_medical_report_id, token]);

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const selectedFiles = Array.from(event.target.files);
        const isValidExtension = selectedFiles.every((file) => {
          const extension = file.name.split('.').pop();
          if (selectserviceType === 'yayoi' && extension !== 'xlsx') {
            return false;
          } else if (selectserviceType === 'bugyou' && extension !== 'xlsx') {
            return false;
          } else if (selectserviceType === 'template' && extension !== 'xlsx') {
            return false;
          } else if (selectserviceType === 'freee' && extension !== 'csv') {
            return false;
          }
          return true;
        });

        if (!isValidExtension) {
          notify(
            'failed',
            `ファイル形式が正しくありません。${
              selectserviceType === 'yayoi' ? 'XLSX' : 'CSV'
            } ファイルをアップロードしてください。`,
          );
          return;
        }
        const newFileNames = selectedFiles.map((file) => file.name);
        const combinedFiles = [...uploadedFiles, ...selectedFiles];
        const combinedFileNames = [...uploadedFileNames, ...newFileNames];

        if (selectserviceType === 'template' && combinedFiles.length > 1) {
          notify('failed', 'アップロードできるファイルは1つまでです。');
          return;
        }

        if (selectserviceType === 'yayoi' && combinedFiles.length > 1) {
          notify('failed', 'アップロードできるファイルは1つまでです。');
          return;
        }

        if (selectserviceType === 'bugyou' && combinedFiles.length > 1) {
          notify('failed', 'アップロードできるファイルは1つまでです。');
          return;
        }

        if (selectserviceType === 'freee' && combinedFiles.length > 2) {
          notify('failed', 'アップロードできるファイルは2つまでです。');
          return;
        }

        setUploadedFileNames(combinedFileNames);
        setUploadedFiles(combinedFiles);
      }
    },
    [selectserviceType, uploadedFileNames, uploadedFiles],
  );

  const changeServiceType = useCallback(
    (
      serviceType:
        | 'yayoi'
        | 'bugyou'
        | 'freee'
        | 'money_forward'
        | 'template'
        | '',
    ) => {
      setSelectserviceType(serviceType);
      setUploadedFileNames([]);
      setUploadedFiles([]);
    },
    [],
  );

  const removeFile = useCallback(
    (index: number) => {
      const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
      const newUploadedFileNames = uploadedFileNames.filter(
        (_, i) => i !== index,
      );
      setUploadedFiles(newUploadedFiles);
      setUploadedFileNames(newUploadedFileNames);
    },
    [uploadedFiles, uploadedFileNames],
  );

  const uploadFileAction = useCallback(() => {
    if (0 < uploadedFiles.length) {
      uploadFile();
    }
  },[uploadFile, uploadedFiles.length])

  const downloadFile = useCallback(async () => {
    const response = await axios.get(
      '/files/download?filename=subjects.xlsx&filetype=application/vnd.ms-excel',
      { responseType: 'blob' },
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'subjects.xlsx');
    document.body.appendChild(link);
    link.click();
  }, []);

  return (
    <>
      <Toaster />
      <div className="container">
        <div
          className="bg-gradient-to-r from-green-400 to-blue-500 absolute left-0 top-0 w-full h-1/3"
          style={{ zIndex: -1000 }}
        ></div>
        <div className="text-center">
          <h1 className="inline-block mb-4 text-white lg:text-2xl md:text-xl text-lg lg:p-6 md:p-2 p-0">
            会計ソフトから決算書ファイルをインポート
          </h1>
        </div>
        <div className="shadow p-6 bg-white text-center pt-8 pb-80">
          <div className="inline-block bg-gray-100 font-base rounded-md text-gray-700 p-6 mb-6">
            <h3 className="font-base text-gray-700 font-semibold">
              各種会計ソフトからエクスポートした決算書ファイルを下記にアップロードしてください
            </h3>
            <a
              className="text-blue-500 align flex mt-6 underline hover:text-blue-700"
              target="_blank"
              href="https://support.yayoi-kk.co.jp/subcontents.html?page_id=18344&grade_id=Std"
              rel="noreferrer"
            >
              ※ 弥生会計からExcel形式の勘定科目をエクスポートする方法はこちら
            </a>
            {/* TODO: オフィシャルに存在しないのでQ&Aページを用意する */}
            {/* <a
               className='text-blue-500 align flex mt-2 underline hover:text-blue-700'
               target='_blank'
               href='https://support.freee.co.jp/hc/ja/articles/4404147984281-freee%E4%BC%9A%E8%A8%88-%E5%90%84%E7%A8%AE%E3%83%87%E3%83%BC%E3%82%BF%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%BE%E3%81%A8%E3%82%81-%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88-%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC#:~:text=%E8%A9%A6%E7%AE%97%E8%A1%A8%EF%BC%9A,%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%97%E3%81%BE%E3%81%99%E3%80%82' rel="noreferrer"
               >
               ※ 勘定奉行からExcel形式の勘定科目をエクスポートする方法はこちら
             </a> */}
            <a
              className="text-blue-500 align flex mt-2 underline hover:text-blue-700"
              target="_blank"
              href="https://support.freee.co.jp/hc/ja/articles/4404147984281-freee%E4%BC%9A%E8%A8%88-%E5%90%84%E7%A8%AE%E3%83%87%E3%83%BC%E3%82%BF%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%BE%E3%81%A8%E3%82%81-%E3%83%AC%E3%83%9D%E3%83%BC%E3%83%88-%E3%83%A1%E3%83%8B%E3%83%A5%E3%83%BC#:~:text=%E8%A9%A6%E7%AE%97%E8%A1%A8%EF%BC%9A,%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E3%82%A8%E3%82%AF%E3%82%B9%E3%83%9D%E3%83%BC%E3%83%88%E3%81%97%E3%81%BE%E3%81%99%E3%80%82"
              rel="noreferrer"
            >
              ※ freeeからCSV形式の勘定科目をエクスポートする方法はこちら
            </a>
          </div>
          <div className="text-left text-blue-500 pt-1 pl-20 hover:text-blue-700">
            <span className="cursor-pointer" onClick={downloadFile}>
              *決算書テンプレート（.xlsx）のダウンロード
            </span>
          </div>

          <div className="grid grid-cols-2 gap-x-4 px-20 pt-3">
            <div className="col-span-1 h-full">
              <div className="justify-center">
                <div className="flex gap-x-6">
                  <div className="flex-auto rounded bg-white text-sm ring-1 ring-gray-300 gap-y-3">
                    <div>
                      <div
                        className={`group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 cursor-pointer ${
                          selectserviceType === 'template'
                            ? 'bg-blue-500 hover:bg-blue-500'
                            : 'bg-white'
                        }`}
                        onClick={() => changeServiceType('template')}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg border border-gray-200 group-hover:bg-white">
                          <img className="h-8 w-8" src={DocumentIcon} />
                        </div>
                        <div>
                          <p
                            className={`font-semibold text-lg text-left ${
                              selectserviceType === 'template'
                                ? 'text-white hover:text-white'
                                : 'text-gray-900'
                            }`}
                          >
                            決算書テンプレート（.xlsx）
                            <span className="absolute inset-0"></span>
                          </p>
                          <p
                            className={`mt-1 text-left text-sm ${
                              selectserviceType === 'template'
                                ? 'text-white hover:text-white'
                                : 'text-gray-600'
                            }`}
                          >
                            決算書テンプレート形式の決算書ファイルを取り込みます
                            <br />
                          </p>
                        </div>
                      </div>
                      <div
                        className={`group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 cursor-pointer ${
                          selectserviceType === 'yayoi'
                            ? 'bg-blue-500 hover:bg-blue-500'
                            : 'bg-white'
                        }`}
                        onClick={() => changeServiceType('yayoi')}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg border border-gray-200 group-hover:bg-white">
                          <img className="h-8 w-8" src={YayoiIcon} />
                        </div>
                        <div className="text-center">
                          <p
                            className={`font-semibold text-lg text-left ${
                              selectserviceType === 'yayoi'
                                ? 'text-white hover:text-white'
                                : 'text-gray-900'
                            }`}
                          >
                            弥生会計（.xlsx）
                            <span className="absolute inset-0"></span>
                          </p>
                          <p
                            className={`mt-1 text-left text-sm ${
                              selectserviceType === 'yayoi'
                                ? 'text-white hover:text-white'
                                : 'text-gray-600'
                            }`}
                          >
                            弥生会計からエクスポートしたExcel形式の決算書ファイルを取り込みます
                          </p>
                        </div>
                      </div>
                      <div
                        className={`group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 cursor-pointer ${
                          selectserviceType === 'bugyou'
                            ? 'bg-blue-500 hover:bg-blue-500'
                            : 'bg-white'
                        }`}
                        onClick={() => changeServiceType('bugyou')}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg border border-gray-200 group-hover:bg-white">
                          <img className="h-8 w-8" src={BugyouIcon} />
                        </div>
                        <div>
                          <p
                            className={`font-semibold text-lg text-left ${
                              selectserviceType === 'bugyou'
                                ? 'text-white hover:text-white'
                                : 'text-gray-900'
                            }`}
                          >
                            勘定奉行（.xlsx）
                            <span className="absolute inset-0"></span>
                          </p>
                          <p
                            className={`mt-1 text-left text-sm ${
                              selectserviceType === 'bugyou'
                                ? 'text-white hover:text-white'
                                : 'text-gray-600'
                            }`}
                          >
                            勘定奉行からエクスポートしたExcel形式の決算書ファイルを取り込みます
                          </p>
                        </div>
                      </div>
                      <div
                        className={`group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50 cursor-pointer ${
                          selectserviceType === 'freee'
                            ? 'bg-blue-500 hover:bg-blue-500'
                            : 'bg-white'
                        }`}
                        onClick={() => changeServiceType('freee')}
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg border border-gray-200 group-hover:bg-white">
                          <img className="h-8 w-8" src={FreeeIcon} />
                        </div>
                        <div>
                          <p
                            className={`font-semibold text-lg text-left ${
                              selectserviceType === 'freee'
                                ? 'text-white hover:text-white'
                                : 'text-gray-900'
                            }`}
                          >
                            freee（.csv）
                            <span className="absolute inset-0"></span>
                          </p>
                          <p
                            className={`mt-1 text-left text-sm ${
                              selectserviceType === 'freee'
                                ? 'text-white hover:text-white'
                                : 'text-gray-600'
                            }`}
                          >
                            freee会計からエクスポートしたCSV形式の試算表ファイルを取り込みます
                            *貸借対照表と損益計算書の二つのCSVが必要です
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 h-full">
              <label className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <div className="text-center px-24">
                  <label>
                    <input
                      className="text-sm cursor-pointer hidden"
                      type="file"
                      multiple
                      disabled={selectserviceType === ''}
                      onChange={(e) => handleFileUpload(e)}
                    />
                    <div
                      className={`text ${
                        selectserviceType === ''
                          ? 'bg-gray-100 text-gray-300'
                          : 'bg-indigo-600 text-white'
                      } border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3`}
                    >
                      {selectserviceType === ''
                        ? '会計ソフトフォーマットを選択してください'
                        : `${SERVICE_TYPES[selectserviceType]}ファイルを選択`}
                    </div>
                  </label>
                  <div
                    className={`title ${
                      selectserviceType === ''
                        ? 'text-gray-300'
                        : 'text-indigo-500'
                    } text-sm pt-2`}
                  >
                    {selectserviceType === ''
                      ? '会計ソフトフォーマットを選択してください'
                      : `${SERVICE_TYPES[selectserviceType]}ファイルをドラッグ＆ドロップ`}
                  </div>
                  {uploadedFileNames.length > 0 && (
                    <div className="uploaded-files">
                      <h3>アップロードされたファイル:</h3>
                      <ul>
                        {uploadedFileNames.map((fileName, index) => (
                          <li key={index}>
                            {fileName}{' '}
                            <button
                              onClick={() => removeFile(index)}
                              className="text-red-500 hover:text-red-700"
                            >
                              削除
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </label>
            </div>
          </div>
          <div className="flex justify-center pt-8">
            <div
              className={`${
                uploadedFiles.length > 0
                  ? 'cursor-pointer bg-indigo-600 hover:bg-indigo-700 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  : 'text-gray-300 bg-gray-100'
              } inline-flex items-center gap-x-2 rounded-md px-5 py-2.5 text-base font-semibold  shadow-sm`}
              onClick={uploadFileAction}
            >
              アップロードする
              <svg
                className="-mr-0.5 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
