import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useFormulaWaccSelector } from './hooks';
import { FormulaWaccSelectorProps } from './types';
import {
  EQUITY_RISK_PREMIUM,
  RISK_FREE_RATE,
  modalStyle,
} from '../../../utils/constant';

export const FormulaWaccSelector: React.FC<FormulaWaccSelectorProps> = (
  props,
) => {
  const { averageUnleveredBeta, userRole } = props;
  const {
    showModal,
    dcfProps,
    riskFreeRate,
    equityRiskPremium,
    unleveredB,
    setUnleveredB,
    setSizeRiskPremium,
    setEquityRiskPremium,
    setRiskFreeRate,
    setModal,
    getEffectiveTaxRate,
    getlevererdβ,
    getEquityCost,
    getWacc,
    getStockRate,
    getDebtRate,
    getDebtCost,
    castNull,
    postCapmMessage,
    addValuationListener,
  } = useFormulaWaccSelector(averageUnleveredBeta);

  useEffect(() => {
    setUnleveredB(averageUnleveredBeta);
  }, [averageUnleveredBeta, setUnleveredB]);
  useEffect(() => {
    addValuationListener();
  }, [averageUnleveredBeta, addValuationListener]);

  return (
    <>
      <div className="border mt-10 mr-6">
        <div className="bg-gray-100 border border-gray-100 flex justify-between">
          <p className="mx-1 py-1 font-semibold">WACC（割引率の計算）</p>
          <div
            onClick={() => setModal(true)}
            className="cursor-pointer bg-transparent py-2 text-xs hover:bg-green-500 text-green-700 font-semibold hover:text-white px-2 bg-white border border-green-500 hover:border-transparent rounded"
          >
            編集
          </div>
        </div>
        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">実効税率</p>
          {dcfProps && <p className="mx-1 py-1">{getEffectiveTaxRate()} %</p>}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">Unlevererdβ 平均値</p>
          {dcfProps && <p className="mx-1 py-1">{unleveredB?.toFixed(2)}</p>}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">Levererdβ</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {Math.floor(getlevererdβ() * 100) / 100}
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">リスクフリーレート</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {(castNull(riskFreeRate, RISK_FREE_RATE) * 100).toFixed(2)} %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">エクイティリスクプレミアム</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {(castNull(equityRiskPremium, EQUITY_RISK_PREMIUM) * 100).toFixed(
                2,
              )}
              %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">株主資本コスト</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {Math.floor(getEquityCost() * 100 * 100) / 100} %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">税引後負債コスト</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {Math.ceil(castNull(getDebtCost(), 0) * 100 * 100) / 100} %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">負債比率</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {castNull(getDebtRate() * 100, 0).toFixed(2)} %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">株式比率</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {castNull(getStockRate() * 100, 0).toFixed(2)} %
            </p>
          )}
        </div>

        <div className="bg-white flex justify-between">
          <p className="mx-1 py-1">WACC</p>
          {dcfProps && (
            <p className="mx-1 py-1">
              {castNull(getWacc() * 100, 0).toFixed(2)} %
            </p>
          )}
        </div>
        {userRole === 1 ? (
          <div className="bg-white mx-1 pt-3">
            <p className="text-left text-xs text-gray-600">
              *株主資本コストはサイズリスクプレミアム適用前の数値を表示しています。
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {showModal ? (
        <Modal isOpen style={modalStyle}>
          <h2 className="font-semibold mx-3">CAPMの設定</h2>
          <div className="mt-5 mr-6">
            <div className="bg-white grid grid-cols-12 py-1">
              <p className="col-span-6 mx-1 py-1">Unlevererdβ</p>
              <input
                type="number"
                autoComplete="off"
                className="col-span-5 rounded-xs bg-white border border-gray-400 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block"
                onChange={(e) => setUnleveredB(Number(e.target.value))}
              ></input>
              <p className="col-span-1 mx-2 mt-1 text-gray-600">%</p>
            </div>

            <div className="bg-white grid grid-cols-12 py-1">
              <p className="col-span-6 mx-1 py-1">リスクフリーレート</p>
              <input
                type="number"
                className="col-span-5 rounded-xs bg-white border border-gray-400 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block"
                onChange={(e) => setRiskFreeRate(Number(e.target.value) / 100)}
              ></input>
              <p className="col-span-1 mx-2 mt-1 text-gray-600">%</p>
            </div>

            <div className="bg-white grid grid-cols-12 py-1">
              <p className="col-span-6 mx-1 py-1">エクイティリスクプレミアム</p>
              <input
                type="number"
                className="col-span-5 rounded-xs bg-white border border-gray-400 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block"
                onChange={(e) =>
                  setEquityRiskPremium(Number(e.target.value) / 100)
                }
              ></input>
              <p className="col-span-1 mx-2 mt-1 text-gray-600">%</p>
            </div>

            <div className="bg-white grid grid-cols-12 py-1">
              <p className="col-span-6 mx-1 py-1">サイズリスクプレミアム</p>
              <input
                type="number"
                className="col-span-5 rounded-xs bg-white border border-gray-400 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block"
                onChange={(e) =>
                  setSizeRiskPremium(Number(e.target.value) / 100)
                }
              ></input>
              <p className="col-span-1 mx-2 mt-1 text-gray-600">%</p>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <u
              className="text-gray-600 hover:text-gray-900 px-16 mt-2 cursor-pointer content-center"
              onClick={() => setModal(false)}
            >
              閉じる
            </u>
            <div
              className="cursor-pointer content-center inline-flex justify-center py-2 px-6 text-sm font-medium rounded-sm text-white bg-green-500 hover:bg-green-600"
              onClick={postCapmMessage}
            >
              反映する
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};
