import React, { useEffect } from 'react';
import { useCompanyMedicalReportIndustriesStep } from './hooks';
import { CompanyMedicalReportProps } from './types';
import TimeIcon from '../../../../../assets/images/icons/icon_time.png';

type CompanyMedicalReportIndustriesStepProps = {
  companyMedicalReport: CompanyMedicalReportProps;
};

export const CompanyMedicalReportIndustriesStep: React.FC<
  CompanyMedicalReportIndustriesStepProps
> = (props) => {
  const { companyMedicalReport } = props;
  const {
    reportSimilaritiesStepStyle,
    setReportSimilaritiesStepStyle,
    getSimilaritiesForm,
  } = useCompanyMedicalReportIndustriesStep(companyMedicalReport);
  useEffect(() => {
    if (companyMedicalReport.is_similar_industry_selected) {
      setReportSimilaritiesStepStyle({
        flowLineStyle: 'absolute top-4 left-4 -ml-px h-full w-0.5 bg-green-300',
        checkedButton:
          'h-8 w-8 rounded-full bg-green-400 flex items-center justify-center',
        actionButton:
          'inline-flex items-center px-14 py-2 text-sm font-medium rounded text-white bg-green-500 hover:bg-green-600 cursor-pointer',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <div className="relative pb-8">
        <span
          className={reportSimilaritiesStepStyle.flowLineStyle}
          aria-hidden="true"
        ></span>
        <div className="relative flex space-x-3">
          <div>
            <span className={reportSimilaritiesStepStyle.checkedButton}>
              <svg
                className="h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="min-w-0 flex-1 pl-3 flex justify-between space-x-4">
            <div>
              <div className="flex">
                <p className="text-lg text-green-400 font-semibold">STEP2</p>
                <span className="ml-3 mr-2 mt-2">
                  <img className="h-3 w-3" src={TimeIcon} />
                </span>
                <p className="text-xs text-gray-400 mt-1.5">
                  所要時間 約<span className="font-bold">1</span>分
                </p>
              </div>
              <p className="text-xl text-gray-700 font-bold">類似業種の選定</p>
              <p className="pt-1.5 text-sm text-gray-500">
                類似する業種の市場株価を算定結果に反映
              </p>
              <div className="pt-4">
                <div
                  onClick={() => getSimilaritiesForm()}
                  className={reportSimilaritiesStepStyle.actionButton}
                >
                  類似業種選定を行う
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
