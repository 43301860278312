import React from 'react';
import { NetAssets } from './NetAssets';
import { SubjectProps, Subjects } from '../../types';

interface NetAssetsContainerProps extends SubjectProps {
  subject: Subjects;
}
export const NetAssetsContainer: React.FC<NetAssetsContainerProps> = (
  props: NetAssetsContainerProps,
) => {
  const { getValues, setValue, control } = props;
  return (
    <NetAssets control={control} getValues={getValues} setValue={setValue} />
  );
};
