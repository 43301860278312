import React, { useEffect } from 'react';
import { ModalProvider } from 'react-modal-hook';
import { useReportDetails } from './hooks';
import { MessageModal } from '../../../components/MessageModal/MessageModal';
import { ReportSubjectsStep } from './ReportSubjectsStep';
import { ReportSimilaritiesStep } from './ReportSimilaritiesStep';
import { ReportCalculationsStep } from './ReportCalculationsStep';
import { ReportGenPptxStep } from './ReportGenPptxStep';
import { reportDetailsProps } from './types';

export const ReportDetails: React.FC<reportDetailsProps> = (props) => {
  const {
    report,
    ocrFileIds,
    usedSimilaritiesResults,
    dcfCalculations,
    isDcfCalculated,
    isDcfEnabled,
    ebitdaCalculations,
    isEbitdaCalculated,
    isEbitdaEnabled,
    perCalculations,
    isPerCalculated,
    isPerEnabled,
  } = props;
  const { modalMessage, modalCheck, checkUsedSimilaritiesResults } =
    useReportDetails(usedSimilaritiesResults);

  useEffect(() => {
    checkUsedSimilaritiesResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalProvider>
        <div
          id="report_details"
          className="shadow pl-24 pr-10 pt-12 pb-56 bg-white"
        >
          <h1 className="pb-7 text-2xl text-gray-500 font-bold">
            {report.evaluation_company_name}
          </h1>
          <div className="flow-root">
            <ul role="list" className="-mb-8">
              <ReportSubjectsStep report={report} />
              <ReportSimilaritiesStep report={report} />
              <ReportCalculationsStep
                report={report}
                ocrFileIds={ocrFileIds}
                dcfCalculations={dcfCalculations}
                ebitdaCalculations={ebitdaCalculations}
                perCalculations={perCalculations}
                isDcfEnabled={isDcfEnabled}
                isEbitdaEnabled={isEbitdaEnabled}
                isPerEnabled={isPerEnabled}
                isDcfCalculated={isDcfCalculated}
                isEbitdaCalculated={isEbitdaCalculated}
                isPerCalculated={isPerCalculated}
              />
              <ReportGenPptxStep
                report={report}
                isDcfEnabled={isDcfEnabled}
                isEbitdaEnabled={isEbitdaEnabled}
                isPerEnabled={isPerEnabled}
                isDcfCalculated={isDcfCalculated}
                isEbitdaCalculated={isEbitdaCalculated}
                isPerCalculated={isPerCalculated}
              />
            </ul>
          </div>
        </div>
        <MessageModal
          setModal={modalCheck}
          modalType="message"
          modalDom="#report_details"
          modalMessage={modalMessage}
        />
      </ModalProvider>
    </>
  );
};
