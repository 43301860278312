import React from 'react';

type ValuationOptionProps = {
  companyName: string;
  titleColor: string;
};

export const HeaderPagePreview: React.FC<ValuationOptionProps> = React.memo(
  (props) => {
    const { companyName, titleColor } = props;

    return (
      <>
        <div className="shadow-md mt-10 w-12/12 h-96 border border-gray-200">
          <div className="grid grid-cols-12">
            <div className="col-span-10"></div>
            <div className="col-span-2 pt-3">
              <p className="text-xs border border-red-200 w-20 text-red-500 mincho">
                confidential
              </p>
            </div>
          </div>
          <div className="grid grid-cols-12 pl-5 pt-20">
            <div className="col-span-2"></div>
            <div className="col-span-9">
              <p
                style={{ color: titleColor }}
                className="pt-10 text-base mincho"
              >
                １．株価算定分析結果サマリー
              </p>
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-2">
                  <div
                    style={{ borderColor: titleColor }}
                    className="border"
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-span-1"></div>
          </div>
          <div className="col-span-12 pt-40 px-5">
            <div className="hidden sm:block" aria-hidden="true">
              <div className="py-2">
                <div className="border border-gray-200"></div>
                <p
                  className="text-center text-gray-400"
                  style={{ fontSize: '6px' }}
                >
                  本報告書は、当社と貴社との契約に基づき実施した調査結果を貴社内部の意思決定の参考とするために作成されており、第三者への開示は当社の事前許可なくできません。
                </p>
                <p
                  className="text-center text-gray-400"
                  style={{ fontSize: '6px' }}
                >
                  ©︎ {new Date().getFullYear()} {companyName}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
