import React, { useMemo } from 'react';

type ValuationOptionProps = {
  companyName: string;
  titleColor: string;
  backgroundColor: string;
  tableColor: string;
  tableTextColor: string;
};

export const SummaryPagePreview: React.FC<ValuationOptionProps> = React.memo(
  (props) => {
    const {
      companyName,
      titleColor,
      backgroundColor,
      tableColor,
      tableTextColor,
    } = props;

    const topTexts = useMemo(() => {
      return [
        '・対象会社の株式価値の妥当性を検討する参考情報を提供すること目的として、株価算定分析を実施した。',
        '・株主資本価値分析の結果、対象会社の株式価値及び１株あたりの株式価値は以下の通りに分析された。',
        '・対象会社の発行済株式数は500000株である。',
        '・評価基準日は、2022年6月6日である。',
      ];
    }, []);

    const columnHeaderValues = useMemo(() => {
      return ['', '', '株式価値', '1株あたり株式価値'];
    }, []);

    const columnValues = useMemo(() => {
      return [
        ['', '', '上限値', '下限値', '上限値', '下限値'],
        [
          'インカムアプローチ',
          'DCF法',
          '1,062,969,980 千円',
          '1,299,185,531 千円',
          '2,125,939 円',
          '2,598,371 円',
        ],
        [
          'マーケットアプローチ',
          'EV/EBITDA倍率法',
          '35,031 千円',
          '42,816 千円',
          '70 円',
          '85 円',
        ],
        [
          'マーケットアプローチ',
          'PER倍率法',
          '5,677 千円',
          '-6,939 千円',
          '-12 円',
          '-14 円',
        ],
      ];
    }, []);

    const chartValues = useMemo(() => {
      return [['PER倍率法', 'EV/EBITDA倍率法', 'DCF法']];
    }, []);

    const bottomTexts = useMemo(() => {
      return [
        '※売上高：前年度比平均から年平均成長率を算出し、成り行き予想をしている。',
        '※過去実績から売上年平均成長率が2.00%と算出されたことから、計画期は2.00％成長するものと仮定している。',
        '※売上原価、販管費は対売上比率平均を算出し、成り行き予想をしている。',
        '※過去実績から売上原価率（売上原価÷売上高）が100.00%、販管費比率（販管費÷売上高）が100.00%と算定されたことから、計画値も同様の水準で推移するものと仮定している。',
      ];
    }, []);

    return (
      <>
        <div className="shadow-md mt-10 w-12/12 h-96 border border-gray-200">
          <div className="grid grid-cols-12">
            {/* ページタイトル */}
            <div className="col-span-12 pl-5">
              <div className="grid grid-cols-12">
                <div className="col-span-10">
                  <p
                    style={{ color: titleColor, fontSize: '10px' }}
                    className="pt-3 mincho"
                  >
                    1.株価算定分析結果サマリー
                  </p>
                </div>
                <div className="col-span-2 pt-3">
                  <p className="text-xs border border-red-200 w-20 text-red-500 mincho">
                    confidential
                  </p>
                </div>
              </div>
              <p style={{ color: titleColor }} className="text-sm mincho">
                株価算定分析結果サマリー
              </p>
            </div>

            {/* 冒頭説明文 */}
            <div className="col-span-12 pl-5">
              {topTexts.map((val) => (
                <p
                  key={val}
                  style={{ fontSize: '6px' }}
                  className="pt-0.5 mincho"
                >
                  {val}
                </p>
              ))}
            </div>

            {/* バリュエーションテーブル */}
            <div className="col-span-12 pt-1 pl-5">
              <table className="border-collapse border border-slate-500">
                <thead>
                  <tr>
                    {columnHeaderValues.map((val, idx) =>
                      idx === 2 || idx === 3 ? (
                        <td
                          key={val + idx.toString()}
                          colSpan={2}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="px-2 lg:w-32 text-center"
                        >
                          {val}
                        </td>
                      ) : (
                        <th
                          key={val + idx.toString()}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="px-6 w-28 border border-slate-600"
                        ></th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {columnValues[0].map((val, idx) =>
                      idx === 0 || idx === 1 ? (
                        <td
                          key={val + idx.toString()}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="px-2 border border-slate-700"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val + idx.toString()}
                          style={{
                            fontSize: '6px',
                            backgroundColor: tableColor,
                            color: tableTextColor,
                          }}
                          className="px-2 border border-slate-700 text-center"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[1].map((val, idx) =>
                      idx === 0 || idx === 1 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[2].map((val, idx) =>
                      idx === 0 || idx === 1 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                  <tr>
                    {columnValues[3].map((val, idx) =>
                      idx === 0 || idx === 1 ? (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700"
                        >
                          {val}
                        </td>
                      ) : (
                        <td
                          key={val}
                          style={{ fontSize: '6px' }}
                          className="px-2 border border-slate-700 text-right"
                        >
                          {val}
                        </td>
                      ),
                    )}
                  </tr>
                </tbody>
              </table>
            </div>

            {/* バリュエーションチャート */}
            <div className="grid grid-cols-12 col-span-12 pt-3 pl-5">
              <div className="col-span-1 pt-6">
                {chartValues[0].map((val) => (
                  <p
                    key={val}
                    className="m-3 text-right"
                    style={{ fontSize: '6px' }}
                  >
                    {val}
                  </p>
                ))}
              </div>
              <div className="col-span-2 pt-6 pl-2">
                <p style={{ width: '1px' }} className="bg-gray-400 h-20"></p>
                <p style={{ fontSize: '6px' }}>0</p>
              </div>
              <div className="col-span-2 pt-6 pl-2">
                <p style={{ width: '1px' }} className="bg-gray-400 h-20"></p>
                <p className="-ml-4" style={{ fontSize: '6px' }}>
                  500,000,000
                </p>
              </div>
              <div className="col-span-2 pt-6 pl-2">
                <p style={{ width: '1px' }} className="bg-gray-400 h-20"></p>
                <div
                  style={{ backgroundColor: backgroundColor }}
                  className="w-32 h-5 -ml-16 -mt-6"
                ></div>
                <p className="-ml-4 mt-1" style={{ fontSize: '6px' }}>
                  1000,000,000
                </p>
              </div>
              <div className="col-span-2 pt-6 pl-2">
                <p style={{ width: '1px' }} className="bg-gray-400 h-20"></p>
                <p className="-ml-4" style={{ fontSize: '6px' }}>
                  1500,000,000
                </p>
              </div>
              <div className="col-span-2 pt-6 pl-2">
                <p style={{ width: '1px' }} className="bg-gray-400 h-20"></p>
                <p className="-ml-4" style={{ fontSize: '6px' }}>
                  2000,000,000
                </p>
              </div>
            </div>

            {/* 末尾説明文 */}
            <div className="col-span-12 pt-5 pl-5">
              {bottomTexts.map((val) => (
                <p key={val} style={{ fontSize: '6px' }} className="mincho">
                  {val}
                </p>
              ))}
            </div>

            {/* コピーライト */}
            <div className="col-span-12 pt-1 px-5">
              <div className="hidden sm:block" aria-hidden="true">
                <div className="py-2">
                  <div className="border border-gray-200"></div>
                  <p
                    className="text-center text-gray-400"
                    style={{ fontSize: '6px' }}
                  >
                    本報告書は、当社と貴社との契約に基づき実施した調査結果を貴社内部の意思決定の参考とするために作成されており、第三者への開示は当社の事前許可なくできません。
                  </p>
                  <p
                    className="text-center text-gray-400"
                    style={{ fontSize: '6px' }}
                  >
                    ©︎ {new Date().getFullYear()} {companyName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
