import React, { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { BsContainer } from '../subjects/financial_statement/bs/BsContainer';
import { OcrResultRow } from './OcrResultRow';
import { OcrPageProps, OcrResultProp } from '../types/props';

export const OcrResult: React.FC<OcrResultProp> = React.memo(
  (props: OcrResultProp) => {
    const { inheritanceValuationId, fileId, pageIds, phase } = props;
    const [visibleFlg, setVisibleFlg] = useState<boolean>(false);
    // @ts-ignore
    const [timerId, setTimerId] = useState<NodeJS.Timeout | number>(0);
    const [startTime] = useState(Date.now());
    const [loadingMessage, setloadingMessage] =
      useState<string>('画像を解析します');
    const refTimerId = useRef(timerId);
    const [subject, setSubject] = useState([] as unknown);
    const form = document.getElementById('ocr_result_form');

    const { register, handleSubmit, getValues, setValue, control } =
      // @ts-ignore
      useForm<unknown>({
        defaultValues: {
          subject: '',
        },
      });

    useEffect(() => {
      refTimerId.current = timerId;
    }, [timerId]);

    const visibleDownloadButton = useCallback(() => {
      const prog = document.getElementsByClassName('analyze_progress');
      const vFlg = !Array.from(prog).some(
        (pro) => pro.textContent === 'ただいま解析中...',
      );
      if (vFlg) {
        setVisibleFlg(vFlg);
      }
    }, []);

    const getSummary = useCallback(() => {
      axios
        .get(
          `/inheritances/${inheritanceValuationId}/ocr/analysis_fs/${fileId}/file_summary.json`,
        )
        .then((res) => {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          setValue('subject', res.data.subjects);
          setSubject([res.data.subjects]);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          visibleDownloadButton();
        })
        .catch((err) => {
          console.log(err);
        });
    }, [fileId, inheritanceValuationId, setValue, visibleDownloadButton]);

    const getStatus = useCallback(() => {
      axios
        .get(
          `/inheritances/${inheritanceValuationId}/ocr/analysis_fs/${fileId}/file_status`,
        )
        .then((res) => {
          if ((Date.now() - startTime) / 1000 > 60) {
            clearInterval(refTimerId.current as number);
            setloadingMessage('画像解析中にエラーが発生しました。');
            location.href = `/inheritances/${inheritanceValuationId}`;
          }
          const analyzedFile: OcrPageProps[] = res.data.pages.filter(
            (page: OcrPageProps) =>
              page.status === 'success' || page.status === 'failed',
          );
          if (analyzedFile.length === pageIds.length) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getSummary();
            clearInterval(refTimerId.current as number);
          }
        })
        .catch((err) => {
          console.log(err);
          clearInterval(refTimerId.current as number);
        });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }, [fileId, getSummary, pageIds.length, inheritanceValuationId, startTime]);

    useEffect(() => {
      // @ts-ignore
      setTimerId(setInterval(getStatus, 3000));
    }, [getStatus]);

    const updateFileSummary = useCallback(() => {
      // @ts-ignore
      const postData = { subject: getValues('subject') };
      axios
        .post(
          `/inheritances/${inheritanceValuationId}/ocr/analysis_fs/${fileId}/update_file_summary.json`,
          postData,
          {
            headers: {
              // @ts-ignore
              'X-CSRF-Token': form.getElementsByTagName('input')[0].value,
            },
          },
        )
        .then((res) => {
          if (res.data.status === 201) {
            location.href = `/inheritances/${inheritanceValuationId}`;
          }
        })
        .catch((err) => {
          location.href = `/inheritances/${inheritanceValuationId}`;
          console.log(err);
        });
    }, [fileId, form, getValues, inheritanceValuationId]);

    // @ts-ignore
    const onSubmit = (data) => {
      updateFileSummary();
    };

    return (
      <>
        <div className="grid grid-cols-12">
          <div className="col-span-5">
            <p className="text-gray-500 text-left font-base text-xl mt-4">
              解析元決算書ファイル
            </p>
            {/* @ts-ignore */}
            {pageIds.map((value) => (
              <OcrResultRow
                key={value}
                pageId={value}
                inheritanceValuationId={inheritanceValuationId}
              />
            ))}
          </div>
          <div className="col-span-7">
            {
              // @ts-ignore
              0 < subject?.length ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-right pr-5">
                    {visibleFlg ? (
                      <>
                        <div className="grid grid-cols-12">
                          <div className="col-span-6">
                            <p className="text-gray-500 pl-8 pt-5 text-left font-base text-xl">
                              PDFデータから自動で取り込む
                            </p>
                          </div>
                          <div className="col-span-6">
                            <button
                              type="submit"
                              className="inline-block m-4 px-7 py-2 text-white bg-blue-600 rounded-sm cursor-pointer"
                            >
                              算定に反映する
                            </button>
                          </div>
                        </div>
                        <p className="text-gray-500 pl-8 -mt-3 mb-3 text-left font-base text-xs">
                          データ取り込みが完了しました。取り込みデータを編集することができます。
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <BsContainer
                        // @ts-ignore
                        register={register}
                        Controller={Controller}
                        control={control}
                        getValues={getValues}
                        // @ts-ignore
                        setValue={setValue}
                        // @ts-ignore
                        subject={subject}
                        phase={phase}
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <>
                  <div className="text-center pt-36">
                    <h1 className="font-bold text-transparent text-2xl bg-clip-text bg-gradient-to-r from-blue-400 to-green-500">
                      {loadingMessage}
                    </h1>
                    <p className="text-gray-600">
                      ※
                      枚数が多い場合、時間がかかる可能性がございます。ご了承ください。
                    </p>
                    <p className="mx-auto file_analyzing_image w-32 h-32"></p>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </>
    );
  },
);
