import React, { useEffect } from 'react';
import axios from 'axios';
import { IndustriesFormProps } from './types';

export const IndustriesForm: React.FC<IndustriesFormProps> = (props) => {
  const { reportId } = props;
  const token = document
    ?.getElementById('similarities_form')
    ?.parentElement?.getElementsByTagName('input')[0].value;

  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data && event.data.name === 'submitMaForm') {
          axios
            .put(
              `/company_medical_reports/${reportId}/industries`,
              {
                similar_type: 'industries',
                result: event.data,
              },
              {
                headers: {
                  'X-CSRF-Token': token,
                },
              },
            )
            .then((res) => {
              if (res.data.status === 'success') {
                console.debug(res);
                location.href = `/company_medical_reports/${reportId}`;
              } else {
                console.debug(res);
              }
            })
            .catch((err) => {
              console.debug(err);
            });
        } else if (
          event.data &&
          event.data.name === 'cancelMaForm' &&
          event.data.value === 'canceled'
        ) {
          location.href = `/company_medical_reports/${reportId}`;
        }
      },
      false,
    );
  }, [reportId, token]);
  return <></>;
};
