import React from 'react';
import { ValuationReportPreview } from './previews/ValuationReportPreview';
import { valuationReportOptionProps } from './types';
import { ValuationReportOptionProps } from '../users/types';
import { ColorPicker } from '../../components/ColorPicker/ColorPicker';

type ValuationReportOptionsProps = {
  company_name: string;
  company_color: string;
  formState: valuationReportOptionProps;
  setFormState: React.Dispatch<
    React.SetStateAction<ValuationReportOptionProps>
  >;
};

export const ValuationReportOptions: React.FC<ValuationReportOptionsProps> = (
  props,
) => {
  const { company_name, formState, setFormState } = props;

  return (
    <>
      <p className="mb-5 block text-md font-medium leading-6 text-gray-900">
        レポートカラー設定
      </p>
      <div className="grid grid-cols-4">
        <ColorPicker
          color={formState.title_color}
          label="タイトルカラー"
          name="title_color"
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, title_color: color }))
          }
        />
        <ColorPicker
          label="バックグラウンドカラー"
          color={formState.background_color}
          name="background_color"
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, background_color: color }))
          }
        />
        <ColorPicker
          label="テーブルカラー"
          color={formState.table_color}
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, table_color: color }))
          }
          name="table_color"
        />
        <ColorPicker
          color={formState.table_text_color}
          onColorChange={(color) =>
            setFormState((prev) => ({ ...prev, table_text_color: color }))
          }
          label="テーブルテキストテキスト"
          name="table_text_color"
        />
      </div>

      <ValuationReportPreview
        companyName={company_name}
        titleColor={formState.title_color}
        backgroundColor={formState.background_color}
        tableColor={formState.table_color}
        tableTextColor={formState.table_text_color}
      />
    </>
  );
};
