import React, { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import { NumberInputProps } from './types';

export const NumberInput = (props: NumberInputProps) => {
  const { defaultValue, inputName, placeholder } = props;
  const [inputValue, setInputValue] = useState<number>(defaultValue);
  const [rawValue, setRawValue] = useState<string>('');

  const handleValueChange = useCallback(
    (values: { formattedValue: string; value: string; floatValue: number }) => {
      const { value } = values;
      setInputValue(values.floatValue);
      setRawValue(value);
    },
    [],
  );

  return (
    <>
      <NumberFormat
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-sm"
        thousandSeparator={true}
        value={inputValue}
        // @ts-ignore
        onValueChange={handleValueChange}
        placeholder={placeholder}
      />
      <input type="hidden" name={inputName} value={rawValue} />
    </>
  );
};
