import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import '../stylesheets/application.css';
import '../ocr/analysis.js';
import '../ocr/hif.js';

Rails.start();
ActiveStorage.start();

// Support component names relative to this directory:
// eslint-disable-next-line no-undef
var componentRequireContext = require.context('packs', true);
// eslint-disable-next-line no-undef
var ReactRailsUJS = require('react_ujs');
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
