import React from 'react';
import { UsersFunctionsProps } from './types';

export const UsersFunctions: React.FC<UsersFunctionsProps> = ({
  role,
  allFunctions,
  formState,
  setFormState,
}) => {
  const handleCheckboxChange = (functionId: number, checked: boolean) => {
    if (checked) {
      setFormState((prevState) => ({
        ...prevState,
        function_ids: [...prevState.function_ids, functionId],
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        function_ids: prevState.function_ids.filter((id) => id !== functionId),
      }));
    }
  };

  return (
    <>
      {role === '一般' && (
        <div className="sm:col-span-full pt-4">
          <div id="functions">
            <p className="font-medium text-gray-700 mt-6">提供する機能</p>
            <div className="min-w-full pt-4">
              {allFunctions.map((func) => (
                <div key={func.id} className="grid grid-cols-12 gap-4 mb-6">
                  <div className="col-span-2">
                    <label
                      htmlFor={`function_${func.id}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {func.description}
                    </label>
                  </div>
                  <div className="col-span-3">
                    <input
                      type="checkbox"
                      id={`function_${func.id}`}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      checked={formState.function_ids.includes(func.id)}
                      onChange={(e) =>
                        handleCheckboxChange(func.id, e.target.checked)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
