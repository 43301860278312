import React from 'react';
import { SubjectRow } from '../../../SubjectRow/SubjectRow';
import { SubjectProps } from '../../types';

type AccountsPayableProps = SubjectProps;

export const AccountsPayable: React.FC<AccountsPayableProps> = (props) => {
  const { getValues, setValue, control } = props;
  return (
    <>
      <div className="flex col-span-12 grid grid-cols-12 py-2.5">
        <div className="col-span-6 text-left pl-2">仕入債務</div>
        <div className="col-span-6"></div>
      </div>
      <SubjectRow
        label="買掛金"
        value={'subject.accounts_payable_trade'}
        subject={'subject.accounts_payable_trade'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「買掛金」、「関係会社買掛金」等、買掛金と名の付く全ての科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="支払手形"
        value={'subject.notes_payable'}
        subject={'subject.notes_payable'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「支払手形」と名の付く科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
      <SubjectRow
        label="その他の仕入債務"
        value={'subject.other_payable'}
        subject={'subject.other_payable'}
        control={control}
        getValues={getValues}
        setValue={setValue}
        toolTipProps={{
          text: '「電子記録債務」、「前受金」等、仕入債務の性質を有する科目を集計して入力する',
          placement: 'bottom-6 -left-36',
        }}
        indent
      />
    </>
  );
};
