import React, { useMemo } from 'react';
import { MessageModal } from '../../../components/MessageModal/MessageModal'
import { useCompanyMedicalReports } from './hooks';
import { CompanyMedicalReportProps } from './types';
const VALUATIONS_COUNT = 4;

export const CompanyMedicalReportTableRow: React.FC<{
  company_medical_report: CompanyMedicalReportProps;
}> = (props) => {
  const { company_medical_report } = props;
  const {
    showDeleteModal,
    showUpdatedTime,
    countValidResults,
    deleteReportId,
    modalCheck,
    setModalCheck
  } = useCompanyMedicalReports();

  const step1Color = useMemo(
    () =>
      company_medical_report.manual_file_id !== null ||
      company_medical_report.ocr_upload_pages !== null
        ? 'text-green-500'
        : 'text-gray-500',
    [
      company_medical_report.manual_file_id,
      company_medical_report.ocr_upload_pages,
    ],
  );
  const step2Color = useMemo(
    () =>
      company_medical_report.is_similar_industry_selected
        ? 'text-green-500'
        : 'text-gray-500',
    [company_medical_report.is_similar_industry_selected],
  );
  const step3Color = useMemo(
    () =>
      countValidResults(
        company_medical_report.company_medical_report_valuations,
      ) === VALUATIONS_COUNT
        ? 'text-green-500'
        : 'text-gray-500',
    [
      company_medical_report.company_medical_report_valuations,
      countValidResults,
    ],
  );
  const step4Color = useMemo(
    () =>
      (company_medical_report.manual_file_id !== null ||
        company_medical_report.ocr_upload_pages !== null) &&
      company_medical_report.is_similar_industry_selected &&
      countValidResults(
        company_medical_report.company_medical_report_valuations,
      ) === VALUATIONS_COUNT
        ? 'text-green-500'
        : 'text-gray-500',
    [
      company_medical_report.company_medical_report_valuations,
      company_medical_report.is_similar_industry_selected,
      company_medical_report.manual_file_id,
      company_medical_report.ocr_upload_pages,
      countValidResults,
    ],
  );
  const updatedTime = useMemo(
    () =>
      company_medical_report.updated_at !== null
        ? showUpdatedTime(company_medical_report.updated_at)
        : null,
    [company_medical_report.updated_at, showUpdatedTime],
  );

  return (
    <tr key={company_medical_report.id}>
      <td className="pl-3 py-2 whitespace-nowrap text-sm font-medium text-blue-700 hover:text-blue-800">
        <a href={`/company_medical_reports/${company_medical_report.id}`}>
          {company_medical_report.evaluation_company_name}
        </a>
      </td>
      <td className="pt-3 whitespace-nowrap text-left text-sm flex gap-4 font-semibold">
        <p className={`${step1Color}`}>STEP1</p>
        <p className={`${step2Color}`}>STEP2</p>
        <p className={`${step3Color}`}>STEP3</p>
        <p className={`${step4Color}`}>STEP4</p>
      </td>
      <td className="py-2 whitespace-nowrap text-left text-sm text-gray-500">
        {updatedTime}
      </td>
      <td className="cursor-pointer hover:text-red-700 py-2 whitespace-nowrap text-left text-sm font-medium text-red-500">
        <div className="hidden_box relative inline-block text-left pt-1">
          <label
            htmlFor={`label_${company_medical_report.id}`}
            className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="menu-button"
          >
            <span className="sr-only">Open options</span>
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M4 12a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM16 12a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </label>
          <input
            type="checkbox"
            value="true"
            name={`label_${company_medical_report.id}`}
            id={`label_${company_medical_report.id}`}
          />
          <div
            className="hidden_show p-12 origin-top-right absolute left-4 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <a
              href={`/company_medical_reports/${company_medical_report.id}`}
              className="cursor-pointer text-gray-700 hover:text-blue-700 block px-4 py-2 text-sm"
            >
              会社を評価する
            </a>
            <a
              href={`/company_medical_reports/${company_medical_report.id}/edit`}
              className="cursor-pointer text-gray-700 hover:text-blue-700 block px-4 py-2 text-sm"
            >
              会社情報を編集する
            </a>
            <div
              onClick={() => showDeleteModal(company_medical_report.id)}
              className="cursor-pointer text-gray-700 hover:text-red-700 block px-4 py-2 text-sm"
            >
              会社を削除する
            </div>
          </div>
        </div>
      </td>
      {deleteReportId !== 0 ? (
        <MessageModal
          setModal={modalCheck}
          modalType="confirm"
          modalDom="#company_medical_report_index_page"
          url={`/company_medical_reports/${deleteReportId}`}
          reqAction={'delete'}
          setModalCheck={setModalCheck}
        />
      ) : (
        <></>
      )}
    </tr>
  );
};
