import React, { ChangeEventHandler } from 'react';
type RadioGroupProps = {
  name: string;
  value: boolean | string;
  label: string;
  options: { value: boolean | string; label: string }[];
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { name, value, onChange, options, label } = props;

  return (
    <>
      <fieldset>
        <legend className="flex text-md font-semibold leading-6 text-gray-900">
          {label}
        </legend>
        <div className="mt-4 flex gap-12">
          {options.map((option, _i) => (
            // @ts-ignore
            <div className="flex items-center gap-x-3" key={option.label}>
              <input
                type="radio"
                name={name}
                // @ts-ignore
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="block text-md font-medium leading-6 text-gray-900">
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </>
  );
};
